import { Centralizer } from './Centralizer';
import {
  BaseProviderProps,
  CentralizedProvider,
} from './provider/CentralizedProvider';

export class CentralizerContext<
  TObj extends Record<string, unknown>,
  TProps extends BaseProviderProps = BaseProviderProps,
> extends Centralizer<TObj> {
  public constructor(
    private readonly provider: CentralizedProvider<TObj, TProps>,
  ) {
    super(
      provider.useValue.bind(provider),
      provider.useArrayValue.bind(provider),
      provider.useEntireObject.bind(provider),
    );
  }

  public get Provider() {
    return this.provider.Provider;
  }
}
