import Typography from '@mui/material/Typography';
import { memo } from '../util/memo';
import {
  hasUnreadMatchChannel,
  HasUnreadMatchChannelParams,
} from '../util/messaging/hasUnreadMatchChannel';
import { useTheme, SxProps } from '@mui/material/styles';
import { useUnreadMessageCount } from '../contexts/UnreadMessageCountContext';
import { ReactNode } from 'react';

export type PulsateUnreadMatchTypographyProps = Omit<
  HasUnreadMatchChannelParams,
  'unreadMessageCount'
> & {
  text: ReactNode;
  sx?: SxProps;
};

const PulsateUnreadMatchTypographyUnmemoized = ({
  text,
  sx = {},
  ...rest
}: PulsateUnreadMatchTypographyProps) => {
  const theme = useTheme();
  const { unreadMessageCount } = useUnreadMessageCount();

  const isLatestMatchChannelUnread = hasUnreadMatchChannel({
    unreadMessageCount,
    ...rest,
  });

  const keyframes = isLatestMatchChannelUnread
    ? {
        [`@keyframes pulse`]: {
          '0%': {
            color: theme.palette.text.primary,
          },
          '50%': {
            color: theme.palette.error.main,
          },
          '100%': {
            color: theme.palette.text.primary,
          },
        },
      }
    : undefined;

  return (
    <Typography
      variant="subtitle1"
      color="text.primary"
      sx={{
        textTransform: 'uppercase',
        animation: isLatestMatchChannelUnread ? `pulse 2s infinite` : undefined,
        ...keyframes,
        ...sx,
      }}
    >
      {text}
    </Typography>
  );
};

export const PulsateUnreadMatchTypography = memo(
  PulsateUnreadMatchTypographyUnmemoized,
);
