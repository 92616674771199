import { BadgeProps } from '@mui/material/Badge';
import { memo } from '../../../util/memo';
import {
  FriendRequestsBadge,
  FriendRequestsBadgeProps,
} from './FriendRequestsBadge';

const SLOT_PROPS_BADGE: BadgeProps['slotProps'] = {
  badge: {
    style: { top: '4px', right: '4px' },
  },
};

export const FriendRequestBadgePane = memo(
  function FriendRequestBadgePaneUnmemoized({
    children,
    ...props
  }: FriendRequestsBadgeProps) {
    return (
      <FriendRequestsBadge slotProps={SLOT_PROPS_BADGE} {...props}>
        {children}
      </FriendRequestsBadge>
    );
  },
);
