/* eslint-disable @blumintinc/blumint/require-dynamic-firebase-imports */
import type { Query, DocumentData } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import {
  RealtimeContextFactory,
  RealtimeContextType,
} from './realtime/RealtimeContextFactory';
import { Notification } from 'functions/src/types/firestore/User/Notification';
import { toUserNotificationCollectionPath } from 'functions/src/types/firestore/User/Notification/path';

export type UnreadNotification = Omit<Notification<Date>, 'status'> & {
  status: 'unread';
};
export type RealtimeUnreadNotificationsContextType =
  RealtimeContextType<UnreadNotification>;

const buildUnreadQuery = (
  collectionRef: Query<UnreadNotification, DocumentData>,
) => {
  return query(collectionRef, where('status', '==', 'unread')) as Query<
    UnreadNotification,
    DocumentData
  >;
};

const {
  Context: RealtimeUnreadNotificationsContext,
  Provider: RealtimeUnreadNotificationsProvider,
  useRealtimeContext: useRealtimeUnreadNotifications,
} = RealtimeContextFactory.build<UnreadNotification>({
  getPath: toUserNotificationCollectionPath,
  buildQuery: buildUnreadQuery,
  timeKey: 'createdAt',
});

export {
  RealtimeUnreadNotificationsContext,
  RealtimeUnreadNotificationsProvider,
  useRealtimeUnreadNotifications,
};
