import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import { memo } from '../../util/memo';

export type AvatarSurplusProps = {
  surplus: number;
  sx?: SxProps;
};

const AvatarSurplusUnmemoized = ({ surplus, sx }: AvatarSurplusProps) => {
  const theme = useTheme();

  const surplusSx = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.action.active,
    ...sx,
  };

  if (surplus > 9) {
    return <GroupsRoundedIcon sx={surplusSx} />;
  }

  return <Typography sx={surplusSx}>{surplus}</Typography>;
};

export const AvatarSurplus = memo(AvatarSurplusUnmemoized);
