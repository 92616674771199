import { ReactElement, ReactNode, useMemo, useCallback } from 'react';
import { insertInnermostChild } from '../../util/insertInnermostChild';
import { DialogCentered, DialogCenteredProps } from '../DialogCentered';
import { memo } from '../../util/memo';
import { useWizard } from './Wizard';

export type WizardDialogProps = DialogCenteredProps & {
  Wrapper?: ReactElement<{ children?: ReactNode }>;
};

const WizardDialogUnmemoized = ({
  onClose: onCloseInner,
  Wrapper,
  ...rest
}: WizardDialogProps) => {
  const { go } = useWizard();

  const close = useCallback(() => {
    onCloseInner?.();
    return go(undefined);
  }, [go, onCloseInner]);

  const DialogRendered = useMemo(() => {
    return <DialogCentered onClose={close} {...rest} />;
  }, [close, rest]);

  if (Wrapper) {
    return insertInnermostChild(Wrapper, DialogRendered);
  }
  return DialogRendered;
};

export const WizardDialog = memo(WizardDialogUnmemoized);
