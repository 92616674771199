import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useDialog } from '../useDialog';
import { ChipUser } from '../../components/ChipUser';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { useAuth } from '../../contexts/AuthContext';
import { cancelShipRequest } from '../../util/ship/cancelShipRequest';

export const CANCEL_FRIEND_REQUEST_DIALOG_ID =
  'CANCEL_FRIEND_REQUEST_DIALOG' as const;

export type UseCancelFriendRequestDialogProps = {
  username: string;
  imgUrl: string;
  userId: string;
};

export const useCancelFriendRequestDialog = ({
  username,
  imgUrl,
  userId,
}: UseCancelFriendRequestDialogProps) => {
  const { uid } = useAuth();
  const { open: openDialog, close } = useDialog(
    CANCEL_FRIEND_REQUEST_DIALOG_ID,
  );

  const cancelFriendRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    await cancelShipRequest({
      userId: uid,
      groupId: userId,
      variant: 'friendship',
    });
  }, [uid, userId]);

  const unsend = useCallback(async () => {
    await cancelFriendRequest();
    close();
  }, [cancelFriendRequest, close]);

  const open = () => {
    openDialog({
      title: 'Unsend Friend Request',
      description: (
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography>
            Are you sure you want to unsend your friend request to
          </Typography>
          <ChipUser
            avatarUrl={imgUrl}
            href={`/${userId}`}
            username={username}
          />
          <Typography>?</Typography>
        </Stack>
      ),
      shouldShowCloseIcon: true,
      children: (
        <Stack alignItems="center" direction="row" spacing={2} width="100%">
          <LoadingButton
            color="error"
            fullWidth
            variant="contained"
            onClick={unsend}
          >
            Unsend
          </LoadingButton>
          <Button color="primary" fullWidth variant="contained" onClick={close}>
            Cancel
          </Button>
        </Stack>
      ),
    });
  };
  return { open, close } as const;
};
