import { useState, useEffect, useMemo } from 'react';
import { EditProps } from '../../components/edit/EditProps';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export const VALIDATING_PLACEHOLDER = 'Validating...' as const;

type UseEditableValueProps<TValue, TValueBase = TValue> = EditProps<
  TValue,
  TValueBase
> & {
  baseToValue?: (base: TValueBase) => TValue;
  setValueUnvalidated: (value: TValueBase) => void;
  valueUnvalidated: TValueBase;
  validatingPlaceholder?: string;
  onValidationComplete?: (isValid: boolean) => void;
};

export function useErrorValidation<TValue, TValueBase = TValue>({
  value,
  onChange,
  options,
  onError,
  baseToValue = (base: TValueBase) => {
    // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
    return base as unknown as TValue;
  },
  setValueUnvalidated: setValueUnvalidatedExternal,
  valueUnvalidated: valueUnvalidatedExternal,
  validatingPlaceholder = VALIDATING_PLACEHOLDER,
}: UseEditableValueProps<TValue, TValueBase>) {
  // eslint-disable-next-line react/hook-use-state
  const [valueUnvalidatedInternal, setValueUnvalidatedInternal] =
    useState<TValueBase>(valueUnvalidatedExternal);
  useEffect(() => {
    setErrorOrPlaceholder(validatingPlaceholder);
    setValueUnvalidatedInternal(valueUnvalidatedExternal);
  }, [
    valueUnvalidatedExternal,
    setValueUnvalidatedInternal,
    validatingPlaceholder,
  ]);

  const [errorOrPlaceholder, setErrorOrPlaceholder] = useState<
    string | typeof VALIDATING_PLACEHOLDER | false
  >(false);

  const error = useMemo(() => {
    if (errorOrPlaceholder === validatingPlaceholder) {
      return;
    }
    return errorOrPlaceholder || undefined;
  }, [errorOrPlaceholder, validatingPlaceholder]);

  useEffect(() => {
    const validate = async () => {
      const isValid =
        options === undefined ||
        (Array.isArray(options)
          ? options.includes(baseToValue(valueUnvalidatedInternal))
          : await options(valueUnvalidatedInternal, value));

      const isError = typeof isValid === 'string' ? isValid : false;
      setErrorOrPlaceholder(isError);
      onError?.(isError);
    };
    validate();
  }, [options, value, valueUnvalidatedInternal, onError, baseToValue]);

  const valueValidated = useMemo(() => {
    if (errorOrPlaceholder) {
      return;
    }
    return valueUnvalidatedInternal;
  }, [errorOrPlaceholder, valueUnvalidatedInternal]);

  useEffect(() => {
    if (valueValidated === undefined || valueValidated === value) {
      return;
    }
    if (onChange === 'disabled') {
      throw new HttpsError(
        'failed-precondition',
        'onChange is disabled but valueValidated is not undefined and not equal to value',
        {
          valueValidated,
          value,
        },
      );
    }
    onChange(valueValidated, value);
  }, [onChange, value, valueValidated]);

  const validateAndChange = (newValue: TValueBase) => {
    setErrorOrPlaceholder(validatingPlaceholder);
    setValueUnvalidatedExternal(newValue);
    setValueUnvalidatedInternal(newValue);
  };

  return {
    error,
    validateAndChange,
  } as const;
}
