import Chip from '@mui/material/Chip';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useTheme, SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { generateDownloadFilename } from '../../util/generateDownloadFilename';
import { useVideo } from '../../contexts/video/VideoContext';

const STYLE_DEFAULT = {} as const;

export type DownloadLivestreamButtonProps = {
  sx?: SxProps;
};

const DownloadLivestreamButtonUnmemoized = ({
  sx = STYLE_DEFAULT,
}: DownloadLivestreamButtonProps) => {
  const theme = useTheme();
  const { downloadUrl } = useVideo();

  const fileName = generateDownloadFilename('livestream');
  const downloadUrlComplete = `${downloadUrl}${fileName}`;

  return (
    <Chip
      clickable
      component="a"
      href={downloadUrlComplete}
      icon={<DownloadRoundedIcon />}
      label="Download"
      sx={{
        display: downloadUrl ? 'flex' : 'none',
        '.MuiChip-icon': {
          height: '20px',
          color: theme.palette.text.primary,
        },
        '.MuiChip-label': {
          fontSize: '14px',
          fontWeight: 600,
        },
        ...sx,
      }}
    />
  );
};

export const DownloadLivestreamButton = memo(
  DownloadLivestreamButtonUnmemoized,
);
