import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { useSocialDrawer } from '../../../hooks/useSocialDrawer';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { ChannelGroupSidebarPlaceholder } from './ChannelGroupSidebarPlaceholder';

const VoiceChannelGroupPlaceholderUnmemoized = () => {
  const theme = useTheme();
  const { openSocialDrawer } = useSocialDrawer();
  const { closeChannelGroup } = useActiveChannelGroup();

  const viewFriends = useCallback(() => {
    closeChannelGroup();
    openSocialDrawer();
  }, [openSocialDrawer, closeChannelGroup]);

  return (
    <Tooltip arrow placement="left" title="Invite friends to party!">
      <Box sx={{ py: 2 }}>
        <ChannelGroupSidebarPlaceholder
          IconComponent={HeadphonesRoundedIcon}
          sx={{
            border: `2px dashed ${theme.palette.primary.main}`,
            borderRadius: '50%',
          }}
          onClick={viewFriends}
        />
      </Box>
    </Tooltip>
  );
};

export const VoiceChannelGroupPlaceholder = memo(
  VoiceChannelGroupPlaceholderUnmemoized,
);
