import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Omit } from 'utility-types';
import { memo } from '../../../util/memo';
import { AlgoliaLayout, EMPTY_FILTER } from '../../algolia/AlgoliaLayout';
import {
  FriendVerticalCarousel,
  // useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { FriendCardEdit } from '../../cards/friend/FriendCardEdit';
import { NoContent } from '../../algolia/NoContent';
import { useAuth } from '../../../contexts/AuthContext';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { FriendCarouselHeader } from './FriendCarouselHeader';
// import { useMobile } from '../../../hooks/useMobile';
// import { FRIENDS_EDIT_AD } from '../../../../functions/src/util/ads/adIds';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from './FriendsAdd';

export type FriendsEditProps = {
  onlineFriendsCount: number;
};

const FRIENDS_HITS_PER_PAGE = 50 as const;

const FriendsEditUnmemoized: FC<FriendsEditProps> = ({
  onlineFriendsCount,
}) => {
  const { uid } = useAuth();
  const theme = useTheme();

  const friendFilter = uid
    ? fillTemplate({
        template: REALTIME_CONFIGURATION_TEMPLATES['friend'],
        placeholderValue: uid,
      })
    : EMPTY_FILTER;
  const friendConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendFilter,
    } as const;
  }, [friendFilter]);

  const FriendsEditCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof FriendVerticalCarousel>,
        'RenderFriendHit' | 'header' | 'NoFriends' | 'containerSx'
      >,
    ) => {
      return (
        <FriendVerticalCarousel
          RenderFriendHit={FriendCardEdit}
          {...props}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
          header={
            <FriendCarouselHeader
              onlineCount={onlineFriendsCount}
              title="FRIENDS"
            />
          }
          noFriends={<NoContent isSelf variant="friends" />}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFriendsCount],
  );

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 2,
      }}
    >
      <Stack
        justifyContent={'space-between'}
        spacing={6}
        sx={{
          px: 4,
          height: '100%',
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FriendsEditCatalogWrapper}
            configureOptions={friendConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
    </Stack>
  );
};
export const FriendsEdit = memo(FriendsEditUnmemoized);
