import { ReactNode, useMemo, FC } from 'react';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../catalogs/generic/VerticalCarousel';
import { memo } from '../../../util/memo';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { useAuth } from '../../../contexts/AuthContext';
import { withAdInjection } from '../../../util/algolia/withAdInjections';
import { useRenderHits } from '../../../hooks/algolia/useRenderHits';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';
import { OrNode } from '../../../../functions/src/types/Hit';

export type FriendsSince = {
  _seconds: number;
  _nanoseconds: number;
};

export type FriendRequest = {
  status: string;
  message?: string;
};

export type FriendVerticalCarouselProps = Omit<
  VerticalCarouselProps,
  'content'
> & {
  hits: OrNode<FriendHit>[];
  RenderFriendHit: FC<FriendHit>;
  noFriends?: ReactNode;
};

const FriendVerticalCarouselUnmemoized = ({
  hits,
  RenderFriendHit,
  noFriends,
  share = false,
  cardSpacing = '8px',
  ...props
}: FriendVerticalCarouselProps) => {
  const { userData } = useAuth();

  const renderHit = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return (hit: FriendHit) => {
      return <RenderFriendHit {...hit} />;
    };
  }, [RenderFriendHit]);

  const transformBefore = useMemo(() => {
    return (hitsInitial: OrNode<FriendHit>[]) => {
      const hitsNotRejected = hitsInitial.filter((hit) => {
        if ('Node' in hit && 'key' in hit) {
          return true;
        }
        return hit.receiver.request?.status !== 'rejected';
      });

      return hitsNotRejected.sort((a, b) => {
        if (('Node' in a && 'key' in a) || ('Node' in b && 'key' in b)) {
          return 0;
        }
        const notSelfA =
          a.sender.username === userData?.username ? a.receiver : a.sender;
        const notSelfB =
          b.sender.username === userData?.username ? b.receiver : b.sender;
        if (notSelfA.username > notSelfB.username) {
          return 1;
        }
        if (notSelfB.username > notSelfA.username) {
          return -1;
        }
        return 0;
      });
    };
  }, [userData?.username]);

  const content = useRenderHits({
    hits,
    transformBefore,
    render: renderHit,
    noResultsSearchless: noFriends,
  });

  return (
    <VerticalCarousel
      cardSpacing={cardSpacing}
      content={content}
      share={share}
      {...props}
    />
  );
};

FriendVerticalCarouselUnmemoized.displayName =
  'FriendVerticalCarouselUnmemoized';

export const FriendVerticalCarousel = memo(FriendVerticalCarouselUnmemoized);

FriendVerticalCarousel.displayName = 'FriendVerticalCarousel';

export const useFriendVerticalCarouselAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: FriendVerticalCarousel,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
    });
  }, [containerId]);
};
