export const EMAIL_REGEX = /^[\w%+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
export function cleanEmail(email: string) {
  const cleanedEmail = email.trim().toLowerCase().replaceAll(/\s+/g, '');
  return cleanedEmail || '';
}

export function isValidEmail(email?: string) {
  if (!email) return false;
  const cleanedEmail = cleanEmail(email);
  return (
    !!cleanedEmail && cleanedEmail.length >= 3 && EMAIL_REGEX.test(cleanedEmail)
  );
}
