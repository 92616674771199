import { ReactNode, Fragment } from 'react';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Box, { BoxProps } from '@mui/material/Box';
import { memo } from 'src/util/memo';

const BACKGROUND_OVERLAY_COLOR = 'rgba(0, 0, 0, 0.3)' as const;

const WRAPPER_SX = {
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer',
  '&:hover .editoverlay': {
    backgroundColor: BACKGROUND_OVERLAY_COLOR,
    '& > svg': {
      opacity: 1,
    },
  },
} as const;

const OVERLAY_SX = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  transition: 'all 0.2s ease-in-out',
  pointerEvents: 'none',
} as const;

export type EditOverlayProps = BoxProps & {
  children: ReactNode;
  isAlwaysShowingIcon?: boolean;
  forceContent?: ReactNode;
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  disabled?: boolean;
};

export const EditOverlay = memo(function EditOverlayUnmemoized({
  children,
  sx,
  isAlwaysShowingIcon = false,
  forceContent,
  disabled = false,
  ...rest
}: EditOverlayProps) {
  if (disabled) {
    return <Fragment>{children}</Fragment>;
  }
  return (
    <Box sx={{ ...WRAPPER_SX, ...sx }} {...rest}>
      {children}
      <Box
        className="editoverlay"
        sx={{
          ...OVERLAY_SX,
          ...sx,
          backgroundColor: forceContent ? BACKGROUND_OVERLAY_COLOR : undefined,
        }}
      >
        {forceContent || (
          <EditRoundedIcon
            sx={{
              fontSize: 24,
              opacity: isAlwaysShowingIcon ? 1 : 0,
              transition: 'inherit',
              pointerEvents: 'none',
            }}
          />
        )}
      </Box>
    </Box>
  );
});
