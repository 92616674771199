import Box from '@mui/material/Box';
import { ReactNode, CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { stableHash } from '../../../functions/src/util/hash/stableHash';

const DEFAULT_STYLE = {} as const;

export const DEFAULT_OPACITY_RANGE: [number, number] = [0, 1];

export type PulsateProps = {
  children: ReactNode;
  shouldPulsate: boolean;
  opacityRange?: [number, number];
  color?: string;
  durationMs?: number;
  iterations?: number | 'infinite';
  style?: CSSProperties;
  /**
   * Additional spacing between the child and the pulsating border
   * @default 2
   */
  spacing?: number;
};

const PulsateUnmemoized = ({
  children,
  shouldPulsate,
  color: propsColor,
  opacityRange = DEFAULT_OPACITY_RANGE,
  durationMs = 2000,
  iterations = 'infinite',
  style = DEFAULT_STYLE,
  spacing = 2,
}: PulsateProps) => {
  const theme = useTheme();
  const colorFallback = propsColor || theme.palette.primary.main;
  const animationId = `pulse-${stableHash(colorFallback)}`;

  const keyframes = shouldPulsate
    ? {
        [`@keyframes ${animationId}`]: {
          '0%': {
            opacity: opacityRange[0],
          },
          '50%': {
            opacity: opacityRange[1],
          },
          '100%': {
            opacity: opacityRange[0],
          },
        },
      }
    : undefined;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...(shouldPulsate && {
          '&::before': {
            content: '""',
            position: 'absolute',
            borderRadius: 'inherit',
            top: -spacing,
            left: -spacing,
            right: -spacing,
            bottom: -spacing,
            border: `2px solid ${colorFallback}`,
            pointerEvents: 'none',
            animation: `${animationId} ${durationMs}ms ${iterations}`,
            ...keyframes,
            ...style,
          },
        }),
      }}
    >
      {children}
    </Box>
  );
};

export const Pulsate = memo(PulsateUnmemoized);
