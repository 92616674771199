import Stack from '@mui/material/Stack';
import Button, { ButtonProps } from '@mui/material/Button';
import { LoadingButton, LoadingButtonProps } from '../buttons/LoadingButton';
import { memo } from '../../util/memo';
import { uuidv4Base62 } from '../../../functions/src/util/uuidv4Base62';

export type AsyncronousActionButtonProps = LoadingButtonProps & {
  isAsync: true;
};

export type SyncronousActionButtonProps = ButtonProps & {
  isAsync: false;
};

export type ActionButtonProps =
  | AsyncronousActionButtonProps
  | SyncronousActionButtonProps;

export type DialogActionsStandardProps = {
  buttons: ActionButtonProps[];
};

function DialogActionsStandardUnmemoized({
  buttons,
}: DialogActionsStandardProps) {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={4}
      width="100%"
    >
      {buttons.map((button) => {
        const key = uuidv4Base62();
        const { isAsync, ...other } = button;
        return isAsync ? (
          <LoadingButton
            key={key}
            fullWidth
            size="large"
            sx={{ flex: 1, width: '100%' }}
            variant="contained"
            {...(other as LoadingButtonProps)}
          />
        ) : (
          <Button
            key={key}
            fullWidth
            size="large"
            sx={{ flex: 1, width: '100%' }}
            variant="contained"
            {...(other as ButtonProps)}
          />
        );
      })}
    </Stack>
  );
}

export const DialogActionsStandard = memo(DialogActionsStandardUnmemoized);
