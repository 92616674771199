import { ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { memo } from '../../util/memo';
import { AvatarNext, AvatarNextProps } from './AvatarNext';

export const DEFAULT_SX = {} as const;

export type AvatarNextOverlayProps = Omit<AvatarNextProps, 'sx'> & {
  overlay?: ReactNode;
  overlaySx?: SxProps;
  sx?: SxProps;
};

const AvatarNextOverlayUnmemoized = ({
  overlay,
  overlaySx = DEFAULT_SX,
  sx = DEFAULT_SX,
  ...props
}: AvatarNextOverlayProps) => {
  if (!overlay) {
    return <AvatarNext {...props} />;
  }

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <AvatarNext {...props} />
      <Box
        sx={{
          position: 'absolute',
          ...overlaySx,
        }}
      >
        {overlay}
      </Box>
    </Box>
  );
};

export const AvatarNextOverlay = memo(AvatarNextOverlayUnmemoized);
