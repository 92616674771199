import Tooltip from '@mui/material/Tooltip';
import { useCallback, MouseEvent, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { Link } from '../Link';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { useLivestreamRouting } from '../../hooks/mux-player/useLivestreamRouting';

export const THEATER_MODE_BUTTON_ID = 'theater-mode-button';

const TOOLTIP_TITLE =
  'Entering theater mode will redirect you to the livestream event page.' as const;

export type TheaterModeButtonProps = {
  controlBar?: Element | null;
};

const TheaterModeButtonUnmemoized = ({
  controlBar,
}: TheaterModeButtonProps) => {
  const theme = useTheme();
  const { isTheaterMode, enterTheaterMode, enterMiniPlayerMode } =
    useLivestreamMode();

  const toggleTheaterMode = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      if (isTheaterMode) {
        return enterMiniPlayerMode();
      }
      return enterTheaterMode();
    },
    [isTheaterMode, enterTheaterMode, enterMiniPlayerMode],
  );

  const theaterModeButton = useMemo(() => {
    return (
      <div
        id={THEATER_MODE_BUTTON_ID}
        style={{
          order: 1,
          display: 'flex',
          alignItems: isTheaterMode ? 'flex-end' : 'center',
          marginBottom: isTheaterMode ? '8px' : '0px',
          marginRight: '8px',
          marginLeft: '4px',
          cursor: 'pointer',
        }}
        onClick={toggleTheaterMode}
      >
        <div
          style={{
            height: isTheaterMode ? '3px' : '10px',
            width: '16px',
            backgroundColor: isTheaterMode
              ? theme.palette.text.primary
              : 'transparent',
            border: isTheaterMode
              ? 'none'
              : `1px solid ${theme.palette.text.primary}`,
            borderRadius: '2px',
          }}
        />
      </div>
    );
  }, [isTheaterMode, theme.palette.text.primary, toggleTheaterMode]);

  const { eventHref } = useLivestreamRouting();

  if (!controlBar) {
    return null;
  }

  if (!eventHref) {
    return createPortal(theaterModeButton, controlBar);
  }

  return createPortal(
    // eslint-disable-next-line @blumintinc/blumint/require-usememo-object-literals
    <Link href={eventHref} style={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip arrow placement="top" title={TOOLTIP_TITLE}>
        {theaterModeButton}
      </Tooltip>
    </Link>,
    controlBar,
  );
};

export const TheaterModeButton = memo(TheaterModeButtonUnmemoized);
