import { useTheme } from '@mui/material/styles';
import Stack, { StackProps } from '@mui/material/Stack';
import { ReactNode } from 'react';
import { memo } from 'src/util/memo';

export const CONTENT_OVERLAY_TEXT_SX = {
  textShadow:
    'rgba(0, 0, 0, 1) 0px 0px 3px, rgba(0, 0, 0, 1) 0px 0px 5px, rgba(0, 0, 0, 0.5) 0px 0px 7px, rgba(0, 0, 0, 1) 0px 0px 10px',
  fontWeight: 700,
} as const;

export type ContentOverlayProps = StackProps & {
  children: ReactNode;
};

export const ContentOverlay = memo(function ContentOverlayUnmemoized({
  children,
  sx,
  ...rest
}: ContentOverlayProps) {
  const theme = useTheme();
  const textSx = {
    ...theme.typography.subtitle2,
    ...CONTENT_OVERLAY_TEXT_SX,
  };
  return (
    <Stack
      sx={{
        borderRadius: '4px',
        background: theme.palette.background.shades.opacity60,
        '& .MuiTypography-root': textSx,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
});
