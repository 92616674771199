import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { memo } from '../util/memo';
import { NowResponse } from 'src/pages/api/time/now';

const ENDPOINT_UTC = '/api/time/now';

const fetchServerMillis = async () => {
  try {
    const response = await fetch(ENDPOINT_UTC);
    const { millis } = (await response.json()) as NowResponse;
    return millis;
  } catch (error) {
    console.error('Failed to fetch server time', error);
    return Date.now();
  }
};

export type ServerTimeContextType = {
  now: () => number;
};

const defaultState: ServerTimeContextType = {
  now: () => {
    return Date.now();
  },
};

const ServerTimeContext = createContext(defaultState);

export function useServerTime() {
  return useContext(ServerTimeContext);
}

export type ServerTimeProviderProps = {
  children: ReactElement;
};

const LENIENCY_MILLIS = 1_000 * 60 * 60;

export const ServerTimeProvider = memo(function ServerTimeProviderUnmemoized({
  children,
}: ServerTimeProviderProps) {
  const [startMillisClient, setStartMillisClient] = useState(0);
  const [startMillisServer, setStartMillisServer] = useState(0);

  const updateStartMillis = () => {
    fetchServerMillis().then((millis) => {
      setStartMillisServer(millis);
    });
    setStartMillisClient(Date.now());
  };

  useEffect(() => {
    updateStartMillis();

    let lastMillisClient = 0;
    const interval = setInterval(() => {
      const currentMillisClient = Date.now();
      const diff = currentMillisClient - lastMillisClient;
      if (Math.abs(diff) > LENIENCY_MILLIS) {
        updateStartMillis();
      }

      lastMillisClient = currentMillisClient;
    }, LENIENCY_MILLIS / 10);
    return () => {
      return clearInterval(interval);
    };
  }, []);

  const memoizedValue = useMemo(() => {
    return {
      now: () => {
        const currentMillisClient = Date.now();
        const diff = currentMillisClient - startMillisClient;
        const currentMillisServer = startMillisServer + diff;
        return currentMillisServer;
      },
    };
  }, [startMillisClient, startMillisServer]);

  return (
    <ServerTimeContext.Provider value={memoizedValue}>
      {children}
    </ServerTimeContext.Provider>
  );
});
