import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from 'src/util/memo';

const MatchSummaryTitleUnmemoized = () => {
  return (
    <GradientTypography
      gradientColor="primary.vertical"
      textTransform={'uppercase'}
      variant="h6"
    >
      SUMMARY
    </GradientTypography>
  );
};

export const MatchSummaryTitle = memo(MatchSummaryTitleUnmemoized);
