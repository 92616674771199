import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export const MESSAGE_INPUT_ID = 'message-input' as const;

export type MessageInputFocusContextProps = {
  focusedInputId: string | null;
  setFocus: (inputId: string) => void;
  unsetFocus: (inputId: string) => void;
  isFocused: (inputId: string) => boolean;
};

export const MessageInputFocusContext = createContext<
  MessageInputFocusContextProps | undefined
>(undefined);

export type MessageInputFocusProviderProps = {
  children: ReactNode;
};

export const useMessageInputFocus = () => {
  const context = useContext(MessageInputFocusContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useMessageInputFocus must be used within a MessageInputFocusProvider',
    );
  }
  return context;
};

const MessageInputFocusProviderUnmemoized: FC<
  MessageInputFocusProviderProps
> = ({ children }) => {
  const [focusedInputId, setFocusedInputId] = useState<string | null>(
    MESSAGE_INPUT_ID,
  );

  const setFocus = useCallback((inputId: string) => {
    setFocusedInputId(inputId);
  }, []);

  const unsetFocus = useCallback((inputId: string) => {
    setFocusedInputId((prev) => {
      return prev === inputId ? null : prev;
    });
  }, []);

  const isFocused = useCallback(
    (inputId: string) => {
      return focusedInputId === inputId;
    },
    [focusedInputId],
  );

  const memoizedValue = useMemo(() => {
    return { focusedInputId, setFocus, unsetFocus, isFocused } as const;
  }, [focusedInputId, setFocus, unsetFocus, isFocused]);

  return (
    <MessageInputFocusContext.Provider value={memoizedValue}>
      {children}
    </MessageInputFocusContext.Provider>
  );
};

export const MessageInputFocusProvider = memo(
  MessageInputFocusProviderUnmemoized,
);
