import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { ReactNode, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { Link } from '../../Link';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { PermanenceButton } from '../PermanenceButton';
import { ChannelGroup } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { useChannelList } from '../../../contexts/ChannelListContext';
import { ELLIPSIS_SX } from '../../../util/string';
import { ChannelNavigationButton } from '../channel/ChannelNavigationButton';
import { GradientTypography } from '../../gradients/GradientTypography';
import { isTemporaryPermanence } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isTemporary';

export const CHANNEL_GROUP_HEADER_HEIGHT = 60 as const;
export const ICON_BUTTON_WIDTH = 30 as const;

export type ChannelGroupHeaderProps = Pick<
  ChannelGroup,
  'permanence' | 'title' | 'subtitle'
> & {
  gradientColor?: string;
  Figure: ReactNode;
  href?: string;
  tournamentId?: string;
};

const TITLE_MAX_WIDTH = 224 as const;
const LINK_STYLE = { textDecoration: 'none', display: 'flex' } as const;
const GRADIENT_ICON_STLYE = {
  height: '28px',
  width: '28px',
  alignSelf: 'center',
} as const;

const ChannelGroupHeaderUnmemoized = ({
  title,
  subtitle,
  Figure,
  href,
  tournamentId,
  permanence,
  gradientColor = 'primary.vertical',
}: ChannelGroupHeaderProps) => {
  const theme = useTheme();
  const { isChannelListVisible } = useChannelList();
  const { closeChannelGroup, channelGroupId } = useActiveChannelGroup();

  const content = useMemo(() => {
    return (
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{
          minHeight: `${CHANNEL_GROUP_HEADER_HEIGHT - 1}px`,
        }}
      >
        {Figure}
        <Stack direction="column">
          <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={2}
          >
            <GradientTypography
              gradientColor={gradientColor}
              sx={{ ...ELLIPSIS_SX, maxWidth: `${TITLE_MAX_WIDTH}px` }}
              textTransform="uppercase"
              variant="h6"
            >
              {title}
            </GradientTypography>
          </Stack>

          <Typography
            color="text.secondary"
            sx={ELLIPSIS_SX}
            variant="subtitle2"
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    );
  }, [Figure, gradientColor, title, subtitle]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.elevationSolid[0],
        display: { xs: isChannelListVisible ? 'flex' : 'none', md: 'flex' },
        borderBottom: {
          xs: 'none',
          md: theme.border,
        },
        pl: 4,
        pr: 2,
      }}
    >
      {href ? (
        <Link href={href} style={LINK_STYLE}>
          {content}
        </Link>
      ) : (
        content
      )}
      <Stack alignItems="center" direction="row" sx={{ width: 'fit-content' }}>
        <ChannelNavigationButton tournamentId={tournamentId} />
        {!!channelGroupId && isTemporaryPermanence(permanence) && (
          <PermanenceButton
            channelGroupId={channelGroupId}
            permanence={permanence}
          />
        )}
        <GradientIconButton
          IconComponent={CloseIcon}
          sx={{ height: '24px', width: '24px' }}
          sxOuter={GRADIENT_ICON_STLYE}
          onClick={closeChannelGroup}
        />
      </Stack>
    </Stack>
  );
};

export const ChannelGroupHeader = memo(ChannelGroupHeaderUnmemoized);
