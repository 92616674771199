export const LIVESTREAM_COLLECTION = 'Livestream' as const;
export const LIVESTREAM_COLLECTION_PATH =
  `/User/{userId}/${LIVESTREAM_COLLECTION}` as const;
export const LIVESTREAM_PATH =
  `${LIVESTREAM_COLLECTION_PATH}/{playbackId}` as const;

export type LivestreamPath = typeof LIVESTREAM_PATH;

export type LivestreamPathParams = {
  userId: string;
  playbackId: string;
};

export const toLivestreamPath = ({
  userId,
  playbackId,
}: LivestreamPathParams) => {
  return LIVESTREAM_PATH.replace('{userId}', userId).replace(
    '{playbackId}',
    playbackId,
  );
};

export const toLivestreamCollectionPath = (userId: string) => {
  return LIVESTREAM_COLLECTION_PATH.replace('{userId}', userId);
};
