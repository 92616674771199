import { Timestamp } from 'firebase-admin/firestore';
import {
  ChannelGroup,
  GroupFilterMap,
  ChannelGroupType,
} from '../../../types/firestore/User/ChannelGroup';
import { isGroup } from '../../../types/firestore/User/ChannelGroup/util/isGroup';
import { isDm } from '../../../types/firestore/User/ChannelGroup/util/isDm';
import { isPlayback } from '../../../types/firestore/User/ChannelGroup/util/isPlayback';
import { isPersonalGroup } from '../../../types/firestore/User/ChannelGroup/util/isPersonalGroup';
import { isSupport } from '../../../types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../../types/firestore/User/ChannelGroup/util/isTournament';
import { HttpsError } from '../../../util/errors/HttpsError';

export const deduceChannelGroupType = <TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type
): ChannelGroupType => {
  if (isTournament(channelGroup)) return 'Tournament';
  if (isGroup(channelGroup)) return 'Group';
  if (isPlayback(channelGroup)) return 'Playback';
  if (isSupport(channelGroup)) return 'Support';
  if (isDm(channelGroup)) return 'Dm';
  if (isPersonalGroup(channelGroup)) return 'PersonalGroup';

  throw new HttpsError(
    'internal',
    'Unknown channel group type given group filter: ',
    channelGroup.groupFilter,
  );
};
