import { useCallback } from 'react';
import { useDialog } from '../useDialog';
import { NotificationLayout } from '../../components/notification/NotificationLayout';
import { useRouterState } from '../routing/useRouterState';
import { Notification } from 'functions/src/types/firestore/User/Notification';

export const NOTIFICATION_DIALOG_ID = 'NOTIFICATION_DIALOG' as const;
export const ROUTER_KEY_NOTIFICATION = 'notif' as const;

export const useNotificationDialog = () => {
  const { open } = useDialog(NOTIFICATION_DIALOG_ID);
  const [, setNotif] = useRouterState({ key: ROUTER_KEY_NOTIFICATION });

  const openNotificationDialog = useCallback(
    (notification: Notification<Date>) => {
      open({
        title: notification.title,
        shouldPreventBackdropClick: false,
        shouldShowCloseIcon: true,
        onClose: () => {
          setNotif(undefined);
        },
        description: <NotificationLayout notification={notification} />,
      });
    },
    [open, setNotif],
  );

  return { openNotificationDialog } as const;
};
