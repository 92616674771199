import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import { ChannelGroupType } from '../../../functions/src/types/firestore/User/ChannelGroup';
import {
  TOURNAMENT_PHASE_EDITABLE,
  TOURNAMENT_PHASE_PAST,
  TOURNAMENT_PHASE_PRESENT,
  TournamentPhase,
  TournamentPhaseEditable,
  TournamentPhasePast,
  TournamentPhasePresent,
} from '../../../functions/src/types/firestore/Game/Tournament';

export type UseAvatarBorderColorProps = {
  phase?: TournamentPhase;
  isConnectedToVoice?: boolean;
  channelGroupType: ChannelGroupType;
};

export const useAvatarBorderColor = () => {
  const theme = useTheme();

  return useCallback(
    ({
      phase,
      channelGroupType,
      isConnectedToVoice,
    }: UseAvatarBorderColorProps) => {
      if (isConnectedToVoice) {
        return theme.palette.secondary.main;
      }
      if (channelGroupType === 'Support') {
        return theme.palette.primary.main;
      }
      if (channelGroupType === 'Playback') {
        return theme.palette.error.main;
      }
      if (!phase) {
        return;
      }
      if (TOURNAMENT_PHASE_PRESENT.includes(phase as TournamentPhasePresent)) {
        return theme.palette.warning.main;
      }
      if (TOURNAMENT_PHASE_PAST.includes(phase as TournamentPhasePast)) {
        return theme.palette.text.disabled;
      }
      if (
        !TOURNAMENT_PHASE_EDITABLE.includes(phase as TournamentPhaseEditable)
      ) {
        return;
      }
      return theme.palette.secondary.main;
    },
    [theme],
  );
};
