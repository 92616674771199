/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentType,
  useState,
  MouseEvent,
  useMemo,
  forwardRef,
  ForwardedRef,
  useCallback,
  CSSProperties,
  Fragment,
} from 'react';
import { MenuProps } from '@mui/material/Menu';
import Box from '@mui/material/Box';
import { memo, compareDeeply } from '../../../util/memo';

export type WithMenuProps<TMenuProps extends MenuProps = MenuProps> = {
  menu?: Omit<TMenuProps, 'onClose' | 'open' | 'anchorEl'>;
};

/**
 * @param WrappedComponent must forward ref
 */
// eslint-disable-next-line @blumintinc/blumint/prefer-settings-object
export function withMenu<
  TProps extends {
    onClick?: (event: MouseEvent) => void;
    style?: CSSProperties;
  },
  TMenuProps extends MenuProps = MenuProps,
>(
  WrappedComponent: ComponentType<TProps>,
  MenuComponent: ComponentType<
    Omit<TMenuProps, 'onClose' | 'open'> & {
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      open: boolean;
      onClose: () => void;
      anchorEl: HTMLElement | null;
    }
  >,
) {
  const WithMenuComponentUnmemoized = forwardRef(
    function WithMenuComponentRefless(
      {
        menu,
        onClick: originalOnClick,
        style: originalStyle,
        ...rest
      }: TProps & WithMenuProps<TMenuProps>,
      ref: ForwardedRef<HTMLElement>,
    ) {
      const [isMenuOpen, setIsMenuOpen] = useState(false);
      const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

      const mergedOnClick = useCallback(
        (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          setIsMenuOpen(true);
          originalOnClick?.(event);
        },
        [originalOnClick],
      );

      const closeMenu = useCallback(() => {
        setIsMenuOpen(false);
      }, []);

      const menuProps = useMemo(() => {
        return menu || {};
      }, [menu]);

      const mergedStyle = useMemo(() => {
        return {
          cursor: 'pointer',
          ...originalStyle,
        };
      }, [originalStyle]);

      return (
        <Fragment>
          <Box ref={setAnchorEl} onClick={mergedOnClick}>
            <WrappedComponent
              ref={ref}
              // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
              {...(rest as any)}
              style={mergedStyle}
            />
          </Box>
          <MenuComponent
            // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
            {...(menuProps as any)}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
          />
        </Fragment>
      );
    },
  );

  return memo(WithMenuComponentUnmemoized, compareDeeply('menu'));
}
