import Menu from '@mui/material/Menu';
import { useMemo } from 'react';
import { useSignInItems } from '../../hooks/useSignInItems';
import { memo } from '../../util/memo';
import { SignInMenuItem } from './SignInMenuItem';

export type SignInMenuProps = {
  isOpen: boolean;
  anchorEl: Element;
  onMenuClose: () => void;
};

export const SignInMenu = memo(function SignInMenuUnmemoized({
  isOpen,
  anchorEl,
  onMenuClose,
}: SignInMenuProps) {
  const menuItems = useSignInItems(onMenuClose);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const menuItemElements = useMemo(() => {
    return menuItems.map((item) => {
      const { label, IconComponent } = item;
      return (
        <SignInMenuItem key={`${label}-${IconComponent?.name}`} {...item} />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems]);

  return (
    <Menu anchorEl={anchorEl} open={isOpen} onClose={onMenuClose}>
      {menuItemElements}
    </Menu>
  );
});
