import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { memo } from '../../../util/memo';
import { useSignOut } from '../../../hooks/auth/useSignOut';
import { ONE_MINUTE_MILLIS } from '../../../../functions/src/util/conversions';
import { getItem, setItem } from '../../../util/webStorage';
import { useEmailVerification } from '../../../hooks/auth/useEmailVerification';
import { LottieLoader } from 'src/components/LottieLoader';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { useAuth } from 'src/contexts/AuthContext';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { useWizard } from 'src/components/wizard/Wizard';
import {
  ResendEmailButton,
  AUTH_MAIL_SEND_KEY,
} from 'src/components/buttons/ResendEmailButton';

export const REFRESH_EMAIL_CHECK_INTERVAL = 5000 as const;
const DIALOG_TITLE = 'Verify Your Email' as const;

const AuthenticationVerifyingEmailDialogUnmemoized: React.FC = () => {
  const { user } = useAuth();
  const { go, reset } = useWizard();
  const { signOut } = useSignOut();
  const [emailSent, setEmailSent] = useState(() => {
    const sentStartTime = getItem<string>(AUTH_MAIL_SEND_KEY);
    if (!sentStartTime) {
      return false;
    }
    return Date.now() - new Date(sentStartTime).getTime() < ONE_MINUTE_MILLIS;
  });

  const resetAuth = useCallback(async () => {
    await signOut();
    reset();
    go('Sign In Options');
  }, [go, reset, signOut]);

  const sendFirstEmail = useCallback(async () => {
    if (emailSent || !user?.email) {
      return;
    }

    const { sendVerificationEmail } = await import(
      '../../../firebaseCloud/mail/sendVerificationEmail'
    );
    await sendVerificationEmail();
    setEmailSent(true);
    setItem(AUTH_MAIL_SEND_KEY, new Date().toISOString());
    user?.reload?.();
  }, [emailSent, user]);

  const goSuccess = useCallback(() => {
    go('Verifying Email Success');
  }, [go]);

  const { verifyEmail } = useEmailVerification({
    onVerified: goSuccess,
  });

  useEffect(() => {
    sendFirstEmail();
    // eslint-disable-next-line no-restricted-globals
    const intervalId = setInterval(async () => {
      await verifyEmail();
    }, REFRESH_EMAIL_CHECK_INTERVAL);

    return () => {
      return clearInterval(intervalId);
    };
  }, [sendFirstEmail, verifyEmail]);

  return (
    <DialogBodyStandard
      description={
        <Stack alignItems="center" maxWidth="440px" pb={2} spacing={4}>
          <LottieLoader sx={{ height: '40px', width: '40px' }} />
          <Typography textAlign="center" variant="body1">
            Please click the link in the email we have just sent to
            <span style={{ paddingLeft: '4px' }}>{user?.email}</span>.
          </Typography>
        </Stack>
      }
      title={DIALOG_TITLE}
    >
      <Stack
        alignSelf="center"
        direction="row"
        maxWidth="440px"
        spacing={4}
        width="100%"
      >
        <ResendEmailButton />
        <LoadingButton
          color="error"
          size="large"
          sx={{ flex: 1 }}
          variant="contained"
          onClick={resetAuth}
        >
          Sign Out
        </LoadingButton>
      </Stack>
    </DialogBodyStandard>
  );
};

export const AuthenticationVerifyingEmailDialog = memo(
  AuthenticationVerifyingEmailDialogUnmemoized,
);
