import { RefObject, useState, useEffect, useCallback, useMemo } from 'react';
import { useDeepCompareEffect } from '@blumintinc/use-deep-compare';
import { findAllScrollableAncestors } from '../../util/findAllScrollableAncestors';
import { assertSafe } from 'functions/src/util/assertSafe';

export type VisibilityObserverResult = {
  target: HTMLElement | null;
  intersectionRatio: number;
  /**ESLINT bug report has been filed:  */
  // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
  isIntersecting: boolean;
};

const DEFAULT_OPTIONS = {} as const;

export const DEFAULT_VISIBILITY_RESULT = {
  target: null,
  intersectionRatio: 0,
  // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
  isIntersecting: false,
} as const;

export type UseVisibilityObserverProps<
  TElement extends HTMLElement = HTMLElement,
> = {
  target: RefObject<TElement | null> | TElement | null;
  options?: Omit<IntersectionObserverInit, 'root'>;
};

/**
 * This will NOT return isVisible: false when an element is
 * dismounted from the DOM. This is too expensive performance-wise to
 * encapsulate in this hook.
 */
export function useVisibilityObserver<
  TElement extends HTMLElement = HTMLElement,
>({ target, options = DEFAULT_OPTIONS }: UseVisibilityObserverProps<TElement>) {
  const element = useMemo(() => {
    return target instanceof Element ? target : target?.current || null;
  }, [target]);

  const [intersectionStates, setIntersectionStates] = useState<
    (IntersectionObserverEntry | null)[]
  >([]);
  const updateIntersectionState = useCallback(
    (index: number, entry: IntersectionObserverEntry) => {
      setIntersectionStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[assertSafe(Number(index))] = entry;
        return newStates;
      });
    },
    [],
  );

  const [result, setResult] = useState<VisibilityObserverResult>({
    ...DEFAULT_VISIBILITY_RESULT,
    target: element,
  });

  useDeepCompareEffect(() => {
    if (!element || !document.contains(element)) {
      setResult({ ...DEFAULT_VISIBILITY_RESULT, target: element });
      setIntersectionStates([]);
      return;
    }
    const scrollableAncestors = findAllScrollableAncestors(element);
    const observers: IntersectionObserver[] = [];

    for (const [index, root] of scrollableAncestors.entries()) {
      const observer = new IntersectionObserver(
        (entries) => {
          const firstEntry = entries[0];
          if (firstEntry) {
            updateIntersectionState(index, firstEntry);
          }
        },
        { ...options, root },
      );

      observer.observe(element);
      observers.push(observer);
    }

    const intersectionStatesEmpty = Array.from(
      { length: scrollableAncestors.length },
      () => {
        return null;
      },
    );

    setIntersectionStates(intersectionStatesEmpty);

    return () => {
      for (const observer of observers) {
        observer.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, options, updateIntersectionState]);

  useEffect(() => {
    if (
      intersectionStates.includes(null) ||
      intersectionStates.length === 0 ||
      !element
    ) {
      return;
    }

    const lowestRatio = Math.min(
      ...intersectionStates.map((entry) => {
        return entry?.intersectionRatio ?? 0;
      }),
    );
    // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
    const allIntersecting = intersectionStates.every((entry) => {
      return entry?.isIntersecting ?? false;
    });

    setResult({
      target: element,
      intersectionRatio: lowestRatio,
      // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
      isIntersecting: allIntersecting,
    });
  }, [intersectionStates, element]);

  return result;
}
