import useBaseIntersectionObserver, {
  IntersectionObserverOptions,
} from '@react-hook/intersection-observer';
import { RefObject, useMemo } from 'react';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { findScrollableAncestor } from '../../util/findScrollableAncestor';

const DEFAULT_PROPS = {} as const;

export type ExtendedIntersectionObserverProps = Omit<
  IntersectionObserverOptions,
  'root'
> & {
  root?: IntersectionObserverOptions['root'] | 'nearest-scrollable';
};

/**
 * You do NOT need to memoize the second paramter of this hook because
 * changes to options do not cause re-renders.
 */
export function useIntersectionObserver<
  TElement extends HTMLElement = HTMLElement,
>(
  target: RefObject<TElement | null> | TElement | null,
  { root, ...options }: ExtendedIntersectionObserverProps = DEFAULT_PROPS,
) {
  const element = useMemo(() => {
    return target instanceof Element ? target : target?.current || null;
  }, [target]);

  const rootFound = useMemo(() => {
    if (root !== 'nearest-scrollable') {
      return root;
    }
    if (!target) {
      return null;
    }
    return findScrollableAncestor(element);
  }, [element, root, target]);

  const baseOptions: IntersectionObserverOptions = useDeepCompareMemo(() => {
    return {
      ...options,
      root: rootFound,
    };
  }, [rootFound, options]);

  return useBaseIntersectionObserver(element, baseOptions);
}
