import stringify from 'safe-stable-stringify';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type GenerateParticipantTokenProps = {
  roomName: string;
  userId: string;
  username: string;
  imgUrl: string;
};

export const generateParticipantToken = async ({
  roomName,
  userId,
  username,
  imgUrl,
}: GenerateParticipantTokenProps) => {
  const response = await fetch('/api/livekit/generateParticipantToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: stringify({ roomName, userId, username, imgUrl }),
  });

  if (!response.ok) {
    throw new HttpsError('internal', 'Failed to fetch participant token', {
      response,
    });
  }

  const data = await response.json();
  return data;
};
