import Stack from '@mui/material/Stack';
import { useEffect, useMemo, ReactNode, Fragment } from 'react';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { TabRoutedFull, TabsRouted, TabsRoutedProps } from './TabsRouted';
import { PanesRouted, Pane } from './PanesRouted';

const TABS_SX_DEFAULT = {} as const;

export type TabPane<TValue extends string> = TabRoutedFull<TValue> &
  Pane<TValue>;

export type TabPanesProps<TValue extends string> = {
  tabPanes: TabPane<TValue>[];
  tabsSx?: SxProps;
  action?: ReactNode;
} & Pick<TabsRoutedProps<TValue>, 'variant' | 'state'>;

function TabPanesUnmemoized<TValue extends string>({
  tabPanes,
  variant,
  state,
  action,
  tabsSx = TABS_SX_DEFAULT,
}: TabPanesProps<TValue>) {
  const [stateValue, setStateValue] = state;
  const isOnDisabled = useMemo(() => {
    return tabPanes.some((tabPane) => {
      return tabPane.customization?.disabled && stateValue === tabPane.value;
    });
  }, [stateValue, tabPanes]);

  useEffect(() => {
    if (isOnDisabled) {
      setStateValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnDisabled]);

  const tabs = useMemo(() => {
    return tabPanes.map(({ component, ...rest }) => {
      return rest;
    });
  }, [tabPanes]);
  const panes = useMemo(() => {
    return tabPanes.map(({ customization, ...rest }) => {
      return rest;
    });
  }, [tabPanes]);

  return (
    <Fragment>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {action}
        <TabsRouted state={state} sx={tabsSx} tabs={tabs} variant={variant} />
      </Stack>

      {!isOnDisabled && <PanesRouted panes={panes} state={state} />}
    </Fragment>
  );
}

export const TabPanes = memo(TabPanesUnmemoized);
