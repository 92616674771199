/* eslint-disable @blumintinc/blumint/no-hungarian */
import type { Timestamp } from 'firebase-admin/firestore';
import type { Message } from 'stream-chat';
import { Optional } from 'utility-types';
import { TournamentPhase } from '../../Game/Tournament';
import { Roled } from '../../../Roled';
import { Identifiable } from '../../../Identifiable';

export const DM_CHANNEL_TYPE = 'dm' as const;
export const GROUP_CHANNEL_TYPE = 'group' as const;
export const MATCH_CHANNEL_TYPE = 'match' as const;
export const MATCH_PUBLIC_CHANNEL_TYPE = 'matchPublic' as const;
export const SUPPORT_CHANNEL_TYPE = 'support' as const;
export const GENERAL_CHANNEL_TYPE = 'general' as const;
export const PLAYBACK_CHANNEL_TYPE = 'playback' as const;
export const PERSONAL_GROUP_CHANNEL_TYPE = 'personalGroup' as const;

export type GeneralType = typeof GENERAL_CHANNEL_TYPE;

export type MatchType = typeof MATCH_CHANNEL_TYPE;

export type GroupType = typeof GROUP_CHANNEL_TYPE;

export type PersonalGroupType = typeof PERSONAL_GROUP_CHANNEL_TYPE;

export type SupportType = typeof SUPPORT_CHANNEL_TYPE;

export const PERSONAL_TYPES = [
  SUPPORT_CHANNEL_TYPE,
  PERSONAL_GROUP_CHANNEL_TYPE,
  DM_CHANNEL_TYPE,
] as const;
export type PersonalType = (typeof PERSONAL_TYPES)[number];

export const TOURNAMENT_TYPES = [
  GENERAL_CHANNEL_TYPE,
  MATCH_CHANNEL_TYPE,
  MATCH_PUBLIC_CHANNEL_TYPE,
] as const;
export type TournamentType = (typeof TOURNAMENT_TYPES)[number];

export const PLAYBACK_TYPES = [PLAYBACK_CHANNEL_TYPE] as const;
export type PlaybackType = (typeof PLAYBACK_TYPES)[number];

export const TEMPORARY_CHANNEL_GROUP_TYPES = [
  GENERAL_CHANNEL_TYPE,
  GROUP_CHANNEL_TYPE,
  PLAYBACK_CHANNEL_TYPE,
] as const;
export type TemporaryChannelGroupType =
  (typeof TEMPORARY_CHANNEL_GROUP_TYPES)[number];

export const CHANNEL_GROUP_FILTER_TYPES = [
  ...PERSONAL_TYPES,
  ...TOURNAMENT_TYPES,
  GROUP_CHANNEL_TYPE,
  PLAYBACK_CHANNEL_TYPE,
] as const;
export type ChannelGroupFilterType =
  (typeof CHANNEL_GROUP_FILTER_TYPES)[number];

export const MATCH_CHANNEL_TYPES = ['match', 'matchPublic'] as const;
export type MatchChannelType = (typeof MATCH_CHANNEL_TYPES)[number];

export const CHANNEL_GROUP_PERMANENCE = [
  'temporary',
  'fixed',
  'pinned',
] as const;
export type ChannelGroupPermanence = (typeof CHANNEL_GROUP_PERMANENCE)[number];

export type IncludesUser = { members: { $in: [string] } };
export type IncludesGroupId = { groupId: { $in: string[] } };
export type IncludesChannelId = { cid: { $in: string[] } };

// eslint-disable-next-line @blumintinc/blumint/enforce-singular-type-names
export type PersonalWithUsers = { members: { $eq: string[] } };

export type PersonalGroupFilter<TType extends PersonalType> = [
  { type: TType } & PersonalWithUsers,
];

/**
 * In the future, always make the first index of the group filter array
 * the group filter that specifies a single Channel that must necessarily
 * be built as soon as the ChannelGroup is created.
 *
 * If there are multiple necessary Channels, you will need to modify the
 * implementation of ChannelFactory.buildNecessaryChannels
 */
export type GroupFilterMap = {
  Tournament: [
    {
      type: GeneralType;
      tournamentId: string;
      // gameId: string;
    },
    {
      type: MatchType;
      tournamentId: string;
    } & IncludesUser,
    // { type: 'matchPublic'; tournamentId: string }, TODO: @shaffy9633, come back to this, to add this we would need to run a script to modify old channel groups, if we modify old channel groups, their hashed filters will no longer corresponding to the channel id.
  ];
  // Note: When migrating to Firestore, combine the Group and PersonalGroup types, we can't use the same filter to query both on GetStream.
  Group:
    | [
        {
          type: GroupType;
        } & IncludesGroupId,
      ]
    | [
        {
          type: GroupType;
        } & IncludesGroupId,
        IncludesChannelId,
      ];
  Playback: [
    {
      type: PlaybackType;
      playbackId: string;
    },
  ];
  Support: PersonalGroupFilter<SupportType>;
  Dm: PersonalGroupFilter<PersonalType>;
  PersonalGroup: PersonalGroupFilter<PersonalGroupType>;
  Direct: [{ type: PersonalType } & IncludesChannelId];
};

/**
 * We need GetStream to introduce ability to duplicate channels in order
 * to bring back the gameId above and run a proper migration script,
 * tentatively callable/scripts/duplicateChannelsGetStream
 */

export type ChannelGroupType = keyof GroupFilterMap;

export type GroupFilter<TGroup extends ChannelGroupType = ChannelGroupType> =
  GroupFilterMap[TGroup];

export type GroupFilterElement<
  TGroup extends ChannelGroupType = ChannelGroupType,
  TIndex extends number = number,
> = GroupFilter<TGroup>[TIndex];

export type ChannelGroupUser = {
  username?: string;
  image: string;
  userId?: string;
  isAdmin?: boolean;
};

export type ChannelGroup<
  TGroupKey extends ChannelGroupType = ChannelGroupType,
  TTime = Timestamp,
> = Identifiable &
  Optional<Roled<string>> & {
    groupFilter: GroupFilter<TGroupKey>;
    members?: ChannelGroupUser[];
    /**
     * This is an aggregation of members necessary for running collectionGroup queries that proliferate users' username / image changes.
     */
    memberIds?: string[];
    groupId?: string;
    imgUrl: string;
    title: string;
    subtitle?: string;
    lastUpdated: TTime;
    hasMultipleChannels: boolean;
    isThisProliferation: boolean;
    messagesInitial?: Message[] | null;
    permanence: ChannelGroupPermanence;
    isChannelCreated: boolean;
    /**
     * ID of the ChannelGroup that this document has replaced
     */
    replacedId?: string;
    date?: TTime;
    phase?: TournamentPhase;
    mainUserId: string;
  };
