// eslint-disable-next-line @blumintinc/blumint/require-dynamic-firebase-imports
import { query } from 'firebase/firestore';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../functions/src/types/firestore/User/ChannelGroup';
import { toChannelGroupCollectionPath } from '../../functions/src/types/firestore/User/ChannelGroup/path';
import { useChannelGroupFilter } from '../hooks/messaging/useChannelGroupFilter';
import { RealtimeContextFactory } from './realtime/RealtimeContextFactory';

export const LAST_UPDATED_TIME_KEY = 'lastUpdated' as const;

const {
  Provider: RealtimeChannelGroupsProvider,
  useRealtimeContext: useRealtimeChannelGroups,
} = RealtimeContextFactory.build<ChannelGroup<keyof GroupFilterMap, Date>>({
  getPath: toChannelGroupCollectionPath,
  buildQuery: (collectionRef) => {
    return query(collectionRef);
  },
  timeKey: LAST_UPDATED_TIME_KEY,
  useFilter: useChannelGroupFilter,
});

export { RealtimeChannelGroupsProvider, useRealtimeChannelGroups };
