import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useCallback, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { memo } from '../../../util/memo';
import { useDateFormatter } from '../../../hooks/useDateFormatter';
import { NUMERIC_NOZONE_NOCOMMA } from '../../../util/dates/presets/dateTimes';
import { PulsateUnreadMatch } from '../../PulsateUnreadMatch';
import { useParseChannelPreview } from '../../../hooks/messaging/useParseChannelPreview';
import { useRemoveChannelGroupDialog } from '../../../hooks/messaging/useRemoveChannelGroupDialog';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { useConnectionStatus } from '../../../hooks/voice-chat/useConnectionStatus';
import { useAvatarBorderColor } from '../../../hooks/styles/useAvatarBorderColor';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_MOBILE } from '../../SideDrawerToggle';
import { withDynamicImport } from '../../../util/withDynamicImport';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import {
  DynamicImport,
  LIVEKIT_COMPONENTS_REACT,
  LivekitComponentsReact,
} from '../../../../functions/src/types/DynamicModule';
import { ChannelGroupBadges } from './ChannelGroupBadges';
import { ChannelGroupAvatar } from './ChannelGroupAvatar';
import { ChannelGroupDivider } from './ChannelGroupDivider';

const PULSATE_STYLE = { borderRadius: '50%' } as const;

export type ChannelGroupSidebarProps = {
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>;
  isLastTemporaryChannelGroup?: boolean;
  modules: DynamicImport<[LivekitComponentsReact]>;
};

const ChannelGroupSidebarUnmemoized = ({
  channelGroup,
  isLastTemporaryChannelGroup = false,
  modules,
}: ChannelGroupSidebarProps) => {
  const theme = useTheme();

  const livekitModule = modules[assertSafe(LIVEKIT_COMPONENTS_REACT)];
  const connectionStatus = useConnectionStatus({ livekitModule });

  const { parseChannelPreview } = useParseChannelPreview();
  const format = useDateFormatter(NUMERIC_NOZONE_NOCOMMA);

  const {
    openChannelGroup,
    closeChannelGroup,
    closeChannel,
    channelGroupId: channelGroupActiveId,
  } = useActiveChannelGroup();

  const {
    channelGroupUnreadCount,
    permanence,
    isSelected,
    title,
    tournamentId,
    id: channelGroupId,
    phase,
    date,
    avatarEntities,
    channelGroupType,
    groupFilter,
    avatarGroupTotal,
  } = parseChannelPreview(channelGroup);

  const { useRoomContext } = livekitModule;
  const room = useRoomContext();

  const isConnectedToVoice = useMemo(() => {
    const isConnected = connectionStatus === 'connected';
    return (
      isConnected && !!room?.name && room.name.split('-')[0] === channelGroupId
    );
  }, [connectionStatus, room?.name, channelGroupId]);

  const determineBorderColor = useAvatarBorderColor();
  const borderColor = useMemo(() => {
    return determineBorderColor({
      phase,
      channelGroupType,
      isConnectedToVoice,
    });
  }, [determineBorderColor, phase, channelGroupType, isConnectedToVoice]);

  const toggleSelected = useCallback(() => {
    if (channelGroupActiveId === channelGroupId) {
      closeChannelGroup();
    } else {
      closeChannel();
      openChannelGroup({ channelGroupId });
    }
  }, [
    channelGroupActiveId,
    channelGroupId,
    closeChannel,
    closeChannelGroup,
    openChannelGroup,
  ]);

  const togglePermanence = useCallback(async () => {
    const permanenceToggled =
      permanence === 'temporary' ? 'pinned' : 'temporary';

    const { setChannelGroupPermanence } = await import(
      '../../../firebaseCloud/messaging/setChannelGroupPermanence'
    );

    return await setChannelGroupPermanence({
      channelGroupId,
      permanence: permanenceToggled,
    });
  }, [channelGroupId, permanence]);

  const { open: openRemoveChannelGroupDialog } = useRemoveChannelGroupDialog({
    groupFilter,
    channelGroupType,
  });

  const dateFormatted = date ? format(date) : undefined;

  return (
    <Stack alignItems="center" pb={1}>
      <Tooltip arrow placement="left" title={title}>
        <Stack
          alignItems="center"
          p={1}
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
            width: {
              xs: SIDEBAR_WIDTH_MOBILE,
              md: SIDEBAR_WIDTH,
            },
            background: isSelected
              ? theme.palette.background.elevation[12]
              : undefined,
          }}
        >
          <PulsateUnreadMatch style={PULSATE_STYLE} tournamentId={tournamentId}>
            <ChannelGroupBadges
              channelGroupType={channelGroupType}
              channelGroupUnreadCount={channelGroupUnreadCount}
              openRemoveChannelGroupDialog={openRemoveChannelGroupDialog}
              permanence={permanence}
              togglePermanence={togglePermanence}
            >
              <ChannelGroupAvatar
                borderColor={borderColor}
                channelGroupType={channelGroupType}
                dateFormatted={dateFormatted}
                entities={avatarEntities}
                isConnectedToVoice={isConnectedToVoice}
                isSelected={isSelected}
                total={avatarGroupTotal}
                tournamentId={tournamentId}
                onClick={toggleSelected}
              />
            </ChannelGroupBadges>
          </PulsateUnreadMatch>
        </Stack>
      </Tooltip>

      {!!isLastTemporaryChannelGroup && <ChannelGroupDivider />}
    </Stack>
  );
};

export const ChannelGroupSidebar = memo(
  withDynamicImport({
    Component: ChannelGroupSidebarUnmemoized,
    moduleNames: [LIVEKIT_COMPONENTS_REACT],
  }),
);
