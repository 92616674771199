import { useEffect, useMemo, useState, useRef } from 'react';
import type { DocumentData } from 'firebase/firestore';
import {
  UseOnSnapshotInfiniteScrollProps,
  useOnSnapshotInfiniteScroll,
} from './useOnSnapshotInfiniteScroll';
import { assertSafe } from 'functions/src/util/assertSafe';

export type UseRealtimeInfiniteScrollProps<TDoc extends DocumentData> = Omit<
  UseOnSnapshotInfiniteScrollProps<TDoc>,
  'startAfter'
> & {
  timeKey: keyof TDoc & string;
  startTime?: Date;
};

export function useRealtimeInfiniteScroll<TDoc extends DocumentData>({
  timeKey,
  startTime: startTimeSpecified,
  query,
  ...props
}: UseRealtimeInfiniteScrollProps<TDoc>) {
  const startTime = useMemo(() => {
    return startTimeSpecified || new Date();
  }, [startTimeSpecified]);

  const queryInfinite = useMemo(async () => {
    const queryAwaited = await query;
    if (!queryAwaited) {
      return;
    }

    const {
      query: firestoreQuery,
      where,
      orderBy,
    } = await import('firebase/firestore');

    return firestoreQuery(
      queryAwaited,
      where(timeKey, '<=', startTime),
      orderBy(timeKey, 'desc'),
    );
  }, [query, timeKey, startTime]);

  const { containerRef, documents: infiniteScrollDocuments } =
    useOnSnapshotInfiniteScroll<TDoc>({
      query: queryInfinite,
      ...props,
    });

  const [realtimeDocuments, setRealtimeDocuments] = useState<TDoc[]>([]);
  const unsubscribeRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    const subscribeRealtimeDocuments = async () => {
      const queryAwaited = await query;

      if (!queryAwaited) {
        return;
      }
      const {
        query: firestoreQuery,
        where,
        onSnapshot,
      } = await import('firebase/firestore');

      const queryRealtime = firestoreQuery(
        queryAwaited,
        where(timeKey, '>', startTime),
      );

      unsubscribeRef.current = onSnapshot(
        queryRealtime,
        (snapshot) => {
          const newDocs = snapshot.docs.map((doc) => {
            return doc.data();
          });
          setRealtimeDocuments(newDocs);
        },
        (error) => {
          console.error(error);
        },
      );
    };
    subscribeRealtimeDocuments();

    return () => {
      unsubscribeRef.current?.();
    };
  }, [timeKey, query, startTime]);

  const documents = useMemo(() => {
    const combinedDocs = [...realtimeDocuments, ...infiniteScrollDocuments];

    const uniqueDocsMap = new Map<string, TDoc>(
      combinedDocs.map((doc) => {
        return [doc.id, doc] as const;
      }),
    );

    const uniqueDocs = [...uniqueDocsMap.values()];
    const sortedDocs = uniqueDocs.sort((a, b) => {
      return b[assertSafe(timeKey)] - a[assertSafe(timeKey)];
    });

    return sortedDocs;
  }, [realtimeDocuments, infiniteScrollDocuments, timeKey]);

  return {
    containerRef,
    documents,
  } as const;
}
