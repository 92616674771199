import {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import type { DocumentReference } from 'firebase/firestore';
import {
  useDocSnapshot,
  UseDocSnapshotProps,
} from '../hooks/firestore/useDocSnapshot';
import { memo } from '../util/memo';
import { useDynamic } from '../hooks/useDynamic';
import type { SessionStorage } from '../../functions/src/types/firestore/User/SessionStorage';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { assertSafe } from '../../functions/src/util/assertSafe';
import { useSession } from './SessionContext';
import { useAuth } from './AuthContext';

const INITIAL_SESSION_STORAGE: SessionStorage = {};

export const SESSION_STORAGE_DOC_SNAPSHOT_OPTIONS: UseDocSnapshotProps<SessionStorage> =
  {
    includeMetadataChanges: true,
    ignoreCache: true,
  };

export type SessionStorageContextProps = {
  sessionStorage?: SessionStorage[string];
  updateSessionStorage: (prev: SessionStorage[string]) => Promise<void>;
};

export const SessionStorageContext = createContext<
  SessionStorageContextProps | undefined
>(undefined);

export type SessionStorageProviderProps = {
  children: ReactNode;
};

export const useSessionStorage = () => {
  const context = useContext(SessionStorageContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useSessionStorage must be used within a SessionStorageProvider',
    );
  }
  return context;
};

const SessionStorageProviderUnmemoized = ({
  children,
}: SessionStorageProviderProps) => {
  const { uidFull } = useAuth();

  const { sessionId } = useSession();

  const sessionStoragePath = useMemo(() => {
    if (!uidFull) {
      return;
    }
    return `User/${uidFull}/SessionStorage/SessionStorage`;
  }, [uidFull]);

  const sessionStorage = useDocSnapshot<SessionStorage>({
    docPath: sessionStoragePath,
    initialData: INITIAL_SESSION_STORAGE,
    options: SESSION_STORAGE_DOC_SNAPSHOT_OPTIONS,
  });

  const firestoreModule = useDynamic(
    import('../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  const sessionStorageRef = useMemo(() => {
    if (!firebaseFirestoreModule || !firestoreModule || !sessionStoragePath) {
      return;
    }

    const { doc } = firebaseFirestoreModule;
    const { firestore } = firestoreModule;
    const storageRef = doc(
      firestore,
      sessionStoragePath,
    ) as DocumentReference<SessionStorage>;

    return storageRef;
  }, [firebaseFirestoreModule, firestoreModule, sessionStoragePath]);

  const updateSessionStorage = useCallback(
    async (update: SessionStorage[string]) => {
      if (!sessionStorageRef || !sessionId || !firebaseFirestoreModule) {
        return;
      }

      const { setDoc } = firebaseFirestoreModule;

      await setDoc(sessionStorageRef, { [sessionId]: update }, { merge: true });
    },
    [sessionStorageRef, firebaseFirestoreModule, sessionId],
  );

  const sessionStorageCurrent = useMemo(() => {
    if (!sessionId) {
      return;
    }
    return sessionStorage?.[assertSafe(sessionId)];
  }, [sessionStorage, sessionId]);

  const memoizedValue = useMemo(() => {
    return {
      sessionStorage: sessionStorageCurrent,
      updateSessionStorage,
    } as const;
  }, [sessionStorageCurrent, updateSessionStorage]);

  return (
    <SessionStorageContext.Provider value={memoizedValue}>
      {children}
    </SessionStorageContext.Provider>
  );
};

export const SessionStorageProvider = memo(SessionStorageProviderUnmemoized);
