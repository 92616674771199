import { useEffect, useCallback } from 'react';
import {
  DynamicModule,
  LivekitComponentsReact,
} from '../../../functions/src/types/DynamicModule';
import { useVoiceChannel } from './useVoiceChannel';
import { useConnectionStatus } from './useConnectionStatus';

export type UseLeaveCallProps = {
  livekitModule: DynamicModule[LivekitComponentsReact];
};
export const useLeaveCall = ({ livekitModule }: UseLeaveCallProps) => {
  const { voiceChannel, setVoiceChannel } = useVoiceChannel();
  const connectionStatus = useConnectionStatus({ livekitModule });

  const { useRoomContext, useParticipants } = livekitModule;

  const room = useRoomContext();
  const participants = useParticipants({ room });

  const leaveCall = useCallback(async () => {
    if (!room || !participants) {
      return;
    }

    const shouldStopTracks = participants.length === 1;

    await room.disconnect(shouldStopTracks);
    setVoiceChannel({ roomToken: undefined });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants?.length, room, setVoiceChannel]);

  useEffect(() => {
    const leave = async () => {
      const roomPath = voiceChannel?.current;
      const isConnected = connectionStatus === 'connected';

      if (roomPath || !isConnected) {
        return;
      }
      await leaveCall();
    };

    leave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionStatus, leaveCall, voiceChannel?.current]);

  return leaveCall;
};
