import stringify from 'safe-stable-stringify';
import { convertToHash } from '../convertToHash';

// eslint-disable-next-line @blumintinc/blumint/enforce-verb-noun-naming
export const stableHash = <TValue>(value: TValue) => {
  const stringified = stringify(value);

  // eslint-disable-next-line no-restricted-syntax
  if (!stringified && stringified !== '') {
    return `${stringified}`;
  }

  return convertToHash(stringified);
};
