export const toPixels = (value: string | number) => {
  if (typeof value === 'string' && value.endsWith('px')) {
    return Number.parseInt(value, 10) || 0;
  }

  if (typeof value === 'number') {
    return value * 4;
  }

  return 0;
};
