/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import stringify from 'safe-stable-stringify';
import { assertSafe } from 'functions/src/util/assertSafe';

export type ConsoleMethod = 'log' | 'info' | 'warn' | 'error';

const ORIGINAL_CONSOLE_METHODS: Record<ConsoleMethod, typeof console.log> = {
  log: console.log,
  info: console.info,
  warn: console.warn,
  error: console.error,
};

/**
 * @important React's warning system uses string formatting with placeholders
 * (e.g. Warning: Unsupported style property %s)
 * So before adding a new pattern to IGNORED_MESSAGES, check if the message uses format placeholders
 * (the message may be a string with %s placeholders).
 *
 * You can check by adding a debugger statement to the
 * createConsoleMethod function to see if the message uses format placeholders
 * (the `message` variable will be a string with %s placeholders).
 *
 * @important Make sure to escape special REGEX characters!
 */
export const IGNORED_MESSAGES: readonly RegExp[] = [
  // Error patterns
  /^Warning: Cannot update a component \(`%s`\) while rendering a different component \(`%s`\)/,
  /^AlgoliaLayoutBidirectionalUnmemoized/,
  /TypeError: Cannot read properties of undefined \(reading 'slice'\).*at AlgoliaSearchHelper/,
  /Unsupported style property %s\. Did you mean %s\?%s@media/,
  /^\[InstantSearch.js]: The `searchFunction` option is deprecated. Use `onStateChange` instead.$/,
];

export const shouldIgnoreMessage = (
  message: string,
  regexes: readonly RegExp[] = IGNORED_MESSAGES,
) => {
  return regexes.some((regex) => {
    return regex.test(message);
  });
};

export const createConsoleMethod = (
  method: ConsoleMethod,
  customHandler?: (message: string, ...args: any[]) => void,
) => {
  return (...args: any[]) => {
    const argsStringified = args.map((arg) => {
      if (typeof arg !== 'object') {
        return String(arg);
      }
      try {
        return stringify(arg);
      } catch {
        // eslint-disable-next-line no-restricted-syntax
        return '';
      }
    });
    // eslint-disable-next-line no-restricted-syntax
    const messageJoined = argsStringified.join('');

    if (shouldIgnoreMessage(messageJoined)) {
      return;
    }

    if (customHandler) {
      customHandler(messageJoined, ...args);
    }

    ORIGINAL_CONSOLE_METHODS[assertSafe(method)](...args);
  };
};

export const setupConsoleSuppression = (
  customHandlers?: Partial<
    Record<ConsoleMethod, (message: string, ...args: any[]) => void>
  >,
) => {
  // eslint-disable-next-line no-restricted-properties
  for (const method of Object.keys(
    ORIGINAL_CONSOLE_METHODS,
  ) as ConsoleMethod[]) {
    console[assertSafe(method)] = createConsoleMethod(
      method,
      customHandlers?.[assertSafe(method)],
    );
  }
};

setupConsoleSuppression();
