import { NotificationStatus } from '../../../functions/lib/types/firestore/User/Notification';

export function isRead(status: NotificationStatus) {
  return status === 'read';
}

export function isUnread(status: NotificationStatus) {
  return status === 'unread';
}

export function isArchived(status: NotificationStatus) {
  return status === 'archived';
}
