/* eslint-disable @blumintinc/blumint/enforce-identifiable-firestore-type */
import type { UpdateEvent } from './OverwolfUpdate';

export const VALORANT_FEATURES = [
  'gep_internal',
  'me',
  'game_info',
  'match_info',
  'kill',
  'death',
] as const;
export type ValorantFeature = (typeof VALORANT_FEATURES)[number];

export const FORTNITE_FEATURES = [
  'gep_internal',
  'kill',
  'killed',
  'killer',
  'revived',
  'death',
  'match',
  'match_info',
  'rank',
  'me',
  'phase',
  'location',
  'team',
  'items',
  'counters',
  'map',
] as const;
export type FortniteFeature = (typeof FORTNITE_FEATURES)[number];

export const ROCKET_LEAGUE_FEATURES = [
  'match',
  'gep_internal',
  'me',
  'match_info',
  'stats',
  'game_info',
  'death',
  'training',
  'roster',
] as const;
export type RocketLeagueFeature = (typeof ROCKET_LEAGUE_FEATURES)[number];

export const ALL_FEATURES = [
  ...VALORANT_FEATURES,
  ...FORTNITE_FEATURES,
  ...ROCKET_LEAGUE_FEATURES,
  'game-detected',
  'game-exit',
] as const;

export type Feature = (typeof ALL_FEATURES)[number];

export const VALORANT_GAME_ID = '21640' as const;
export const ROCKET_LEAGUE_GAME_ID = '10798' as const;
export const FORTNITE_GAME_ID = '21216' as const;
export const GAME_IDS_AVAILABLE = [
  VALORANT_GAME_ID,
  FORTNITE_GAME_ID,
  ROCKET_LEAGUE_GAME_ID,
] as const;
export type OverwolfGameId = (typeof GAME_IDS_AVAILABLE)[number];
export const GAME_NAMES_AVAILABLE = [
  'valorant',
  'fortnite',
  'rocket-league',
] as const;
export type GameName = (typeof GAME_NAMES_AVAILABLE)[number];
export const GAME_IDS_NUMERIC = [21640, 21216, 10798] as const;
export type OverwolfGameIdNumeric = (typeof GAME_IDS_NUMERIC)[number];

export const OVERWOLF_GAMES_AVAILABLE = [
  {
    id: VALORANT_GAME_ID,
    name: 'valorant',
    requiredFeatures: VALORANT_FEATURES,
  },
  {
    id: FORTNITE_GAME_ID,
    name: 'fortnite',
    requiredFeatures: FORTNITE_FEATURES,
  },
  {
    id: ROCKET_LEAGUE_GAME_ID,
    name: 'rocket-league',
    requiredFeatures: ROCKET_LEAGUE_FEATURES,
  },
] as const;

export type OverwolfGameAvailable = (typeof OVERWOLF_GAMES_AVAILABLE)[number];

export type OverwolfGame = OverwolfGameAvailable;

export type GameEventUpdateOverwolf = {
  gameId: OverwolfGameIdNumeric;
  data: UpdateEvent;
};

export type GameInfoUpdateOverwolf = GameEventUpdateOverwolf;
//TODO: @refactor to InfoEvent or whatever the fix is in the firestore types, when overwolf fixes the feature bug on gameEvent
