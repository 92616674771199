/**
 * Gets a value from an object using a dot-notation path
 *
 * @example
 * const obj = { a: { b: { c: 1 } } };
 * getPathValue(obj, 'a.b.c') // returns 1
 */
export function getPathValue<
  TObj extends Record<string, unknown>,
  TPath extends string,
>(obj: TObj, path: TPath): unknown {
  return path.split('.').reduce((acc, part) => {
    // eslint-disable-next-line security/detect-object-injection
    return acc?.[part];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, obj as any);
}
