import { useCallback, useState, useRef } from 'react';
import { ViewSize } from '../components/edit/EditProps';

/**
 * Hook for measuring the dimensions of a component
 * @returns An object with:
 * - ref: The ref to attach to the element being measured
 * - viewSize: The measured dimensions (width and height)
 * - measure: Function to trigger measurement
 */
export const useMeasureViewSize = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [viewSize, setViewSize] = useState<ViewSize>({});

  const measure = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const newWidth = Math.round(rect.width);
      const newHeight = Math.round(rect.height);

      setViewSize({ width: newWidth, height: newHeight });
    }
  }, []);

  return { ref, viewSize, measure } as const;
};
