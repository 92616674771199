import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import {
  GradientIconButton,
  GradientIconButtonProps,
} from '../../gradients/GradientIconButton';
import { memo } from '../../../util/memo';

export const CHANNELGROUP_PLACEHOLDER_HEIGHT = '56px' as const;

export type ChannelGroupSidebarPlaceholderProps = Omit<
  GradientIconButtonProps,
  'sx'
> & {
  sx?: SxProps;
};

const ChannelGroupSidebarPlaceholderUnmemoized = ({
  IconComponent,
  onClick,
  sx,
  ...props
}: ChannelGroupSidebarPlaceholderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <GradientIconButton
        IconComponent={IconComponent}
        sx={{
          width: {
            xs: '28px',
            md: '36px',
          },
          height: {
            xs: '28px',
            md: '36px',
          },
        }}
        onClick={onClick}
        {...props}
      />
    </Box>
  );
};

export const ChannelGroupSidebarPlaceholder = memo(
  ChannelGroupSidebarPlaceholderUnmemoized,
);
