import { User } from '../../../functions/src/types/firestore/User';
import { useAuth } from '../../contexts/AuthContext';

// eslint-disable-next-line @blumintinc/blumint/enforce-id-capitalization
export type FriendCardStatusProps = Pick<User, 'status' | 'id'> & {
  mutualFriendsCount?: number;
};

export function useFriendCardStatus({
  id,
  status,
  mutualFriendsCount,
}: FriendCardStatusProps) {
  const { userData } = useAuth();

  const isFriend = userData?.friends?.includes(id);

  if (isFriend && mutualFriendsCount !== undefined) {
    const count = mutualFriendsCount >= 10 ? '9+' : mutualFriendsCount;
    // eslint-disable-next-line no-restricted-syntax
    return `${count} Mutual Friend${mutualFriendsCount === 1 ? '' : 's'}`;
  }

  if (status && status.toLowerCase() !== 'unknown') {
    return status;
  }
}
