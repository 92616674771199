import { useMemo, ReactNode, useCallback } from 'react';
import {
  ANCHOR_ORIGIN_TOP_RIGHT,
  ANCHOR_ORIGIN_BOTTOM_RIGHT,
} from '../../../util/styles/anchorOrigin';
import { memo } from '../../../util/memo';
import { Attendable } from '../Attendable';
import {
  ChannelGroupBadgeContent,
  ChannelGroupBadgeContentProps,
} from './ChannelGroupBadgeContent';

export const MAX_UNREAD_COUNT_TEXT = '9+' as const;

// eslint-disable-next-line @blumintinc/blumint/no-unused-props
export type ChannelGroupBadgesProps = ChannelGroupBadgeContentProps & {
  channelGroupUnreadCount: number;
  togglePermanence: () => Promise<void>;
  openRemoveChannelGroupDialog: () => void;
  children: ReactNode;
};

const ChannelGroupBadgesUnmemoized = ({
  channelGroupUnreadCount,
  permanence,
  channelGroupType,
  togglePermanence,
  openRemoveChannelGroupDialog,
  children,
}: ChannelGroupBadgesProps) => {
  const badgeContentUnread = useMemo(() => {
    if (channelGroupUnreadCount > 0 && channelGroupUnreadCount < 10) {
      return channelGroupUnreadCount;
    }
    if (channelGroupUnreadCount <= 10) {
      return;
    }
    return MAX_UNREAD_COUNT_TEXT;
  }, [channelGroupUnreadCount]);

  const attendableAction = useCallback(async () => {
    if (permanence === 'temporary' || permanence === 'pinned') {
      return await togglePermanence();
    }
    if (channelGroupType !== 'Support') {
      return;
    }
    return openRemoveChannelGroupDialog();
  }, [
    channelGroupType,
    permanence,
    openRemoveChannelGroupDialog,
    togglePermanence,
  ]);

  return (
    <Attendable
      anchorOrigin={ANCHOR_ORIGIN_TOP_RIGHT}
      badgeContent={badgeContentUnread}
      color={'error'}
    >
      {((permanence === 'temporary' || permanence === 'pinned') &&
        channelGroupType !== 'Playback') ||
      channelGroupType === 'Support' ? (
        <Attendable
          anchorOrigin={ANCHOR_ORIGIN_BOTTOM_RIGHT}
          badgeContent={
            <ChannelGroupBadgeContent
              channelGroupType={channelGroupType}
              permanence={permanence}
            />
          }
          color={'primary'}
          sx={{
            '.MuiBadge-badge': {
              height: '17px',
              minWidth: '17px',
              width: '100%',
              right: '8px',
            },
          }}
          onClick={attendableAction}
        >
          {children}
        </Attendable>
      ) : (
        children
      )}
    </Attendable>
  );
};

export const ChannelGroupBadges = memo(ChannelGroupBadgesUnmemoized);
