import Stack from '@mui/material/Stack';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { GradientTypography } from '../gradients/GradientTypography';
import { toPresencePath } from '../../../functions/src/util/presence/toPresencePath';
import { useRealtimeCount } from '../../hooks/presence/useRealtimeCount';
import { memo } from '../../util/memo';
import { useMemo } from 'react';
import { GradientIcon } from '../gradients/GradientIcon';

export type ViewersProps = {
  elementId: string;
};

const ViewersUnmemoized = ({ elementId }: ViewersProps) => {
  const realtimeCountPath = toPresencePath({
    elementId,
    choice: 'liveViewerCount',
  });

  const liveViewers = useRealtimeCount(realtimeCountPath);

  return useMemo(() => {
    if (!liveViewers || liveViewers === 'unknown') {
      return <></>;
    }
    return (
      <Stack direction="row" alignItems="center" spacing="2px" sx={{ px: 0 }}>
        <GradientTypography variant="subtitle2" sx={{ lineHeight: '18px' }}>
          {liveViewers}
        </GradientTypography>
        <GradientIcon
          IconComponent={PersonRoundedIcon}
          sx={{ height: '18px', width: '18px' }}
        />
      </Stack>
    );
  }, [liveViewers]);
};

export const Viewers = memo(ViewersUnmemoized);
