import { useTheme } from '@mui/material/styles';
import type { OnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status';
import type { SpreadColorKey } from '../../styles/colors';
import { isOnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status/util';
import { assertSafe } from '../../../functions/src/util/assertSafe';

export const STATUS_TO_COLOR: Record<
  OnlineStatusEnum,
  SpreadColorKey | 'disabled'
> = {
  online: 'secondary',
  offline: 'disabled',
  away: 'warning',
  'do not disturb': 'error',
} as const;

export const useStatusColor = <TColor extends string>(color: TColor) => {
  const { palette } = useTheme();

  if (!isOnlineStatusEnum(color)) {
    return color;
  }

  const colorKey = STATUS_TO_COLOR[assertSafe(color)] || 'primary';
  return palette[assertSafe(colorKey)].main;
};
