import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { Link } from '../Link';
import { GradientTypography } from '../gradients/GradientTypography';
import { useVideo } from '../../contexts/video/VideoContext';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { ViewerCount } from './ViewerCount';
import { LINK_STYLE } from './AvatarLivestream';
import { LivestreamTitleEditable } from './LivestreamTitleEditable';

export type LivestreamInfoHeaderProps = {
  streamerId: string;
  username: string;
  sx?: SxProps;
};

const LivestreamInfoHeaderUnmemoized = ({
  streamerId,
  username,
  sx,
}: LivestreamInfoHeaderProps) => {
  const { isMiniPlayerMode } = useLivestreamMode();
  const { viewingPlaybackId } = useVideo();

  return (
    <Stack alignItems="flex-start" pt={1} spacing={1} sx={sx}>
      {isMiniPlayerMode ? (
        <LivestreamTitleEditable streamerId={streamerId} />
      ) : (
        <Link href={`/${streamerId}`} style={LINK_STYLE}>
          <GradientTypography variant="body1">{username}</GradientTypography>
        </Link>
      )}

      {!!viewingPlaybackId && <ViewerCount playbackId={viewingPlaybackId} />}
    </Stack>
  );
};

export const LivestreamInfoHeader = memo(LivestreamInfoHeaderUnmemoized);
