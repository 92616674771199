import { FC, ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';

export type VideoPlaceholderProps = {
  placeholder?: ReactNode;
};

const VideoPlaceholderUnmemoized: FC<VideoPlaceholderProps> = ({
  placeholder = 'Livestream Disconnected',
}) => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.elevation[6],
      }}
    >
      <Typography color="text.secondary" variant="body1">
        {placeholder}
      </Typography>
    </Stack>
  );
};

export const VideoPlaceholder = memo(VideoPlaceholderUnmemoized);
