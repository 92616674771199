import { SxProps, useTheme } from '@mui/material/styles';
import { useMemo, ComponentProps } from 'react';
import { memo } from '../../util/memo';
import { StatusBadge } from '../status/StatusBadge';
import { useAvatarBorder } from '../../hooks/useAvatarBorder';
import { ResponsiveSize } from '../image/ImageOptimized';
import { useOnlineStatus } from '../../hooks/status/useOnlineStatus';
import { determineBadgeSize } from '../../util/avatar/determineBadgeSize';
import { AvatarNext, AvatarNextProps } from './AvatarNext';

export const DEFAULT_SX = {} as const;

export type AvatarStatusProps = Omit<
  AvatarNextProps,
  'height' | 'width' | 'sx'
> & {
  groupId?: string;
  size?: string | number | ResponsiveSize;
  sx?: SxProps;
};

const AvatarStatusUnmemoized = ({
  size,
  groupId,
  sx = DEFAULT_SX,
  ...props
}: AvatarStatusProps) => {
  const theme = useTheme();

  const {
    borderColor = theme.palette.background.elevationSolid['10'],
    ...restSx
  } = sx as SxProps & { borderColor?: string };
  const { deriveBorderDimensions } = useAvatarBorder(borderColor);
  const onlineStatus = useOnlineStatus(groupId);

  const sxResolved = useMemo(() => {
    return {
      ...restSx,
      ...(size && deriveBorderDimensions(size)),
    };
  }, [deriveBorderDimensions, size, restSx]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const avatar = useMemo(() => {
    return <AvatarNext {...props} size={size} sx={sxResolved} />;
  }, [size, sxResolved, props]);

  if (!onlineStatus) {
    return avatar;
  }

  const badgeSize = determineBadgeSize(size);

  return (
    <StatusBadge
      color={
        onlineStatus as unknown as ComponentProps<typeof StatusBadge>['color']
      }
      sx={{
        '& .MuiBadge-badge': {
          borderRadius: '50%',
          height: badgeSize,
          width: badgeSize,
          border: `2px solid ${borderColor}`,
        },
      }}
    >
      {avatar}
    </StatusBadge>
  );
};

export const AvatarStatus = memo(AvatarStatusUnmemoized);
