import { useCallback, useMemo } from 'react';
import type { Hit } from 'instantsearch.js';
import type { PlainSearchParameters } from 'algoliasearch-helper';
import { useLocalStorage } from '../../contexts/LocalStorage';
import {
  cleanAlgoliaState,
  hashAlgoliaParams,
} from '../../../functions/src/util/algolia/config/hashAlgoliaParams';

export type InfiniteHitsCachedHits<THit extends Hit> = {
  [page: number]: Hit<THit>[];
};

export type AlgoliaCacheLocalStorage = {
  data: InfiniteHitsCachedHits<Hit>;
  state: PlainSearchParameters;
  timestampMillis: number;
};

export const CACHE_INVALIDATION_TIMEOUT = 60 * 1000;

export const isExpired = (timestampMillis: number) => {
  return Date.now() - timestampMillis > CACHE_INVALIDATION_TIMEOUT;
};

export const useAlgoliaLocalStorage = () => {
  const { getItem, setItem, removeItem, keys } = useLocalStorage();

  const readCache = useCallback(
    (state?: PlainSearchParameters) => {
      if (!state) {
        return null;
      }
      const stateCleaned = cleanAlgoliaState(state);
      const key = hashAlgoliaParams(stateCleaned);
      const item = getItem<AlgoliaCacheLocalStorage>(key);
      if (!item) {
        return item;
      }
      const { data, timestampMillis } = item;
      if (isExpired(timestampMillis)) {
        removeItem(key);
        return null;
      }
      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const writeCache = useCallback(
    (state: PlainSearchParameters, data: AlgoliaCacheLocalStorage | null) => {
      const stateCleaned = cleanAlgoliaState(state);
      const key = hashAlgoliaParams(stateCleaned);
      if (data === null) {
        removeItem(key);
      } else {
        setItem(key, data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const expungeExpiredCache = useCallback(() => {
    for (const key of keys()) {
      const item = getItem(key);
      if (
        item &&
        typeof item === 'object' &&
        'timestampMillis' in item &&
        !!item.timestampMillis &&
        typeof item.timestampMillis === 'number' &&
        isExpired(item.timestampMillis)
      ) {
        removeItem(key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    return {
      /**
       * undefined indicates that the cache may be
       * in IndexDB but IndexDB has not finished initializing.
       */
      readCache,
      writeCache,
      expungeExpiredCache,
    };
  }, [readCache, writeCache, expungeExpiredCache]);
};
