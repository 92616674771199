import { ComponentType, useMemo } from 'react';
import { memo } from '../../util/memo';
import { ArrayOverlay, ArrayOverlayProps } from './ArrayOverlay';
import { ArrayValueUrlChangeable, ValueUrlChangeable } from './EditProps';
import { assertSafe } from 'functions/src/util/assertSafe';

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export type EditableArrayProps<TValue, TUrl extends string = string> = Omit<
  ArrayOverlayProps,
  'elements' | 'onAdd' | 'onDelete'
> &
  ArrayValueUrlChangeable<TValue, TValue, TUrl> & {
    EditableElement: ComponentType<ValueUrlChangeable<TValue, TValue, TUrl>>;
  };
/**
 * Do not centralize each EditableElement. Instead, centralize the EditableArray.
 */
// eslint-disable-next-line @blumintinc/blumint/no-hungarian
function EditableArrayUnmemoized<TValue, TUrl extends string = string>({
  values,
  onElementChange,
  urls,
  onElementUrlChange,
  EditableElement,
  ...arrayOverlayProps
}: EditableArrayProps<TValue, TUrl>) {
  const emitUpdate = useMemo(() => {
    if (onElementChange === 'disabled') return 'disabled';
    return async (index: number, newValue?: TValue, oldValue?: TValue) => {
      // Must convert undefined to null because the backend expects null for missing values
      // and undefined for the element being added/removed.
      await onElementChange(index, newValue ?? null, oldValue ?? null);
    };
  }, [onElementChange]);

  const emitUrlUpdate = useMemo(() => {
    if (onElementUrlChange === 'disabled') return 'disabled';
    return async (index: number, newUrl?: TUrl, oldUrl?: TUrl) => {
      // Must convert undefined to null because the backend expects null for missing values
      // and undefined for the element being added/removed.
      await onElementUrlChange(index, newUrl ?? null, oldUrl ?? null);
    };
  }, [onElementUrlChange]);

  const emitAdd = useMemo(() => {
    if (onElementChange === 'disabled') return 'disabled';
    return async () => {
      await Promise.all([
        onElementChange(values.length, null, undefined),
        onElementUrlChange === 'disabled'
          ? undefined
          : onElementUrlChange?.(values.length, null, undefined),
      ]);
    };
  }, [values.length, onElementChange, onElementUrlChange]);

  const emitDelete = useMemo(() => {
    if (onElementChange === 'disabled') return 'disabled';
    return async (index: number) => {
      const value = values[assertSafe(Number(index))];
      const url = urls?.[assertSafe(Number(index))];
      await Promise.all([
        onElementChange(index, undefined, value),
        onElementUrlChange === 'disabled'
          ? undefined
          : onElementUrlChange?.(index, undefined, url),
      ]);
    };
  }, [values, urls, onElementChange, onElementUrlChange]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const elements = useMemo(() => {
    return values.map((value, index) => {
      return (
        <EditableElement
          // eslint-disable-next-line react/no-array-index-key
          key={`editable-element-${index}`}
          url={urls?.[assertSafe(Number(index))] ?? undefined}
          value={value ?? undefined}
          onChange={
            emitUpdate === 'disabled'
              ? 'disabled'
              : (newValue, oldValue) => {
                  return emitUpdate(index, newValue, oldValue);
                }
          }
          onChangeUrl={
            emitUrlUpdate === 'disabled'
              ? 'disabled'
              : (newUrl) => {
                  return emitUrlUpdate(
                    index,
                    newUrl,
                    urls?.[assertSafe(Number(index))] ?? undefined,
                  );
                }
          }
        />
      );
    });
  }, [values, urls, EditableElement, emitUpdate, emitUrlUpdate]);

  return (
    <ArrayOverlay
      elements={elements}
      onAdd={emitAdd}
      onDelete={emitDelete}
      {...arrayOverlayProps}
    />
  );
}

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const EditableArray = memo(
  EditableArrayUnmemoized,
) as typeof EditableArrayUnmemoized;
