/* eslint-disable @blumintinc/blumint/require-dynamic-firebase-imports */
import React, {
  FC,
  createContext,
  useContext,
  useMemo,
  ReactNode,
  MutableRefObject,
} from 'react';
import { Query, collection, DocumentData } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { useRealtimeInfiniteScroll } from '../../hooks/infinite-scroll/useRealtimeInfiniteScroll';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { firestore } from '../../config/firebase-client/firestore';
import { memo } from '../../util/memo';

export const DEFAULT_PAGE_SIZE = 20 as const;

export type RealtimeContextType<TDoc extends DocumentData> = {
  realtimeDocuments: TDoc[];
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export type RealtimeContextConfig<TDoc extends DocumentData> = {
  getPath: (uidFull: string) => string;
  buildQuery: (collectionRef: Query<TDoc>) => Query<TDoc>;
  pageSize?: number;
  timeKey: keyof TDoc & string;
  useFilter?: () => (docs: TDoc[]) => TDoc[];
};

export type RealtimeProviderProps = {
  children: ReactNode;
};

export class RealtimeContextFactory {
  public static build<TDoc extends DocumentData>(
    config: RealtimeContextConfig<TDoc>,
  ) {
    const Context = createContext<RealtimeContextType<TDoc> | undefined>(
      undefined,
    );

    const useRealtimeContext = () => {
      const context = useContext(Context);
      if (!context) {
        throw new HttpsError(
          'failed-precondition',
          'useRealtimeContext must be used within its Provider',
        );
      }
      return context;
    };

    const ProviderUnmemoized: FC<RealtimeProviderProps> = ({ children }) => {
      const { uidFull } = useAuth();
      const filterFn = config.useFilter?.();

      const queryPromise = useMemo(() => {
        if (!uidFull) return;

        const collectionRef = collection(
          firestore,
          config.getPath(uidFull),
        ).withConverter<TDoc>(ConverterFactory.buildDateConverter());

        return config.buildQuery(collectionRef);
      }, [uidFull]);

      const { containerRef, documents } = useRealtimeInfiniteScroll<TDoc>({
        query: queryPromise,
        pageSize: config.pageSize ?? DEFAULT_PAGE_SIZE,
        timeKey: config.timeKey,
      });

      const filteredDocuments = useMemo(() => {
        return filterFn ? filterFn(documents) : documents;
      }, [documents, filterFn]);

      const value = useMemo(() => {
        return {
          realtimeDocuments: filteredDocuments,
          containerRef,
        };
      }, [filteredDocuments, containerRef]);

      return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    const Provider = memo(ProviderUnmemoized);

    return {
      Context,
      Provider,
      useRealtimeContext,
    };
  }
}
