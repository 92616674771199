import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import type { UpdateData } from 'firebase/firestore';
import isEqual from 'fast-deep-equal';
import { applyNestedUpdate } from '../../util/object/applyNestedUpdate';

/**
 * Generic hook for managing path-based state updates
 * @param initialState Initial state for the values
 * @returns Object with values and methods to update them
 */
export function usePathState<TObj extends Record<string, unknown>>(
  initialState: Partial<TObj> = {} as TObj,
) {
  const [values, setValues] = useState<TObj>(initialState as TObj);
  const previousInitialStateRef = useRef<TObj>(initialState as TObj);

  const setValue = useCallback(
    <TPath extends string>(path: TPath, value: unknown) => {
      setValues((prev) => {
        const current = structuredClone(prev);
        return applyNestedUpdate(current, path, value);
      });
    },
    [],
  );

  const update = useCallback((newValues: UpdateData<TObj>) => {
    setValues((prev) => {
      const current = structuredClone(prev);

      // eslint-disable-next-line no-restricted-properties
      for (const [path, value] of Object.entries(newValues)) {
        applyNestedUpdate(current, path, value);
      }

      return current;
    });
  }, []);

  useEffect(() => {
    if (isEqual(initialState, previousInitialStateRef.current)) {
      return;
    }
    previousInitialStateRef.current = initialState as TObj;
    update(initialState as UpdateData<TObj>);
  }, [initialState, update]);

  return useMemo(() => {
    return {
      values,
      setValue,
      update,
    } as const;
  }, [values, setValue, update]);
}
