import { useContext, useMemo } from 'react';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { useAuth } from '../../contexts/AuthContext';
import { isModerator as isUserModerator } from '../../../functions/src/util/tournaments/isModerator';
import { isContributor as isUserContributor } from '../../../functions/src/util/tournaments/isContributor';
import { isTournamentAdmin as isUserTournamentAdmin } from '../../../functions/src/util/tournaments/isTournamentAdmin';

export const useTournamentPermissions = () => {
  const { uid, userData } = useAuth();
  const { roles } = useContext(TournamentContext);
  const { contributor, moderator } = roles;

  const isModerator = useMemo(() => {
    return isUserModerator(moderator, uid);
  }, [uid, moderator]);

  const isContributor = useMemo(() => {
    return isUserContributor(contributor, uid);
  }, [contributor, uid]);

  const isTournamentAdmin = useMemo(() => {
    return isUserTournamentAdmin({ roles, uid, email: userData?.email });
  }, [roles, userData?.email, uid]);

  return { isContributor, isModerator, isTournamentAdmin } as const;
};
