import { useCallback } from 'react';
import { RealtimeDbHandler, useRealtimeDb } from './useRealtimeDb';

/**
 * Custom hook to establish and manage real-time listeners using onValue at a specified database path
 *
 * @param dbPath Path to the location in RealtimeDB
 * @param initialData Initial data loaded from RealtimeDB SSR
 * @returns realtime data at the specified path
 */
export function useRealtimeDbSnapshot<TData>(
  dbPath?: string,
  initialData?: TData,
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type, @blumintinc/blumint/no-type-assertion-returns
): TData | undefined {
  const subscribeRealtimeDb = useCallback<RealtimeDbHandler<TData>>(
    // eslint-disable-next-line max-params
    (realtimeDbModule, firebaseRealtimeDbModule, setData) => {
      if (!dbPath) {
        return;
      }
      const { database } = realtimeDbModule;
      const { ref, onValue, off } = firebaseRealtimeDbModule;

      const dbRef = ref(database, dbPath);

      onValue(
        dbRef,
        (snapshot) => {
          setData(snapshot.val());
        },
        (error) => {
          console.error(error);
        },
      );

      // Cleanup function to unsubscribe from the listener
      return () => {
        off(dbRef);
      };
    },
    [dbPath],
  );

  return useRealtimeDb(subscribeRealtimeDb, initialData);
}
