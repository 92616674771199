import { useTheme } from '@mui/material/styles';
import Chip, { ChipProps } from '@mui/material/Chip';
import { memo } from '../../../util/memo';
import { GradientTypography } from '../../gradients/GradientTypography';
import { User } from '../../../../functions/src/types/firestore/User';
import { useChannelMember } from '../../messaging/ChannelMembersContext';
import { GroupInfoBasic } from '../../../../functions/src/types/firestore/Guild';
import { useEstimateMutualFriends } from '../../../hooks/friends/useEstimateMutualFriends';
import { FriendCardLayout } from './FriendCardLayout';

export type UserCardAddProps = ChipProps &
  GroupInfoBasic &
  Pick<User, 'friends'>;

const UserCardAddUnmemoized = (props: UserCardAddProps) => {
  const theme = useTheme();
  const { imgUrl, username, id, friends, ...rest } = props;

  const { option, toggle } = useChannelMember({
    userId: id,
    username,
    image: imgUrl,
  });

  const mutualFriendsCountEstimate = useEstimateMutualFriends({
    friends,
  });

  return (
    <FriendCardLayout {...{ username, imgUrl, mutualFriendsCountEstimate, id }}>
      <Chip
        {...rest}
        label={
          <GradientTypography variant="subtitle1">{option}</GradientTypography>
        }
        sx={{
          backgroundColor: theme.palette.background.elevation[14],
          width: '100px',
          height: '36px',
          '.MuiChip-label': {
            padding: 0,
          },
        }}
        onClick={toggle}
      />
    </FriendCardLayout>
  );
};

export const UserCardAdd = memo(UserCardAddUnmemoized);
