import Box from '@mui/material/Box';
import type { Required } from 'utility-types';
import { FC, useMemo } from 'react';
import { Configure, UseConfigureProps } from 'react-instantsearch';
import { memo, compareDeeply } from '../../util/memo';
import { PreemptStateProvider } from '../../contexts/algolia/PreemptStateContext';
import { RefreshHits } from './RefreshHits';
import { CustomHitsPreempted } from './CustomHitsPreempted';
import { PreemptedInstantSearch } from './PreemptedInstantSearch';
import { CatalogWrapperProps } from './catalog-wrappers/CatalogWrapperProps';

export const EMPTY_FILTER = 'EMPTY_FILTER' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RenderCatalogWrapper = FC<CatalogWrapperProps<any>>;

export type AlgoliaLayoutProps = {
  CatalogWrapper: RenderCatalogWrapper;
  configureOptions: Required<UseConfigureProps, 'filters'>;
  index: string;
  /**
   * This was introduced to enable extension components to skip the hits that have already been loaded of parent components, which
   * is necessary for components like `AlgoliaLayoutBidirectional` that are infinitely scrollable in two directions.
   */
  skipHits?: string[];
  onHitsChange?: (hits: Record<string, unknown>[]) => void;
};

const clean = (configureOptions: Required<UseConfigureProps, 'filters'>) => {
  const { index: __, ...cleaned } = configureOptions;
  return cleaned;
};

const AlgoliaLayoutUnmemoized = ({
  CatalogWrapper,
  configureOptions,
  index,
  skipHits,
  onHitsChange,
}: AlgoliaLayoutProps) => {
  const configureOptionsCleaned = useMemo(() => {
    return clean(configureOptions);
  }, [configureOptions]);
  const { filters } = configureOptions;

  return (
    <PreemptStateProvider>
      <PreemptedInstantSearch indexName={index}>
        <RefreshHits filters={filters}>
          <Configure {...configureOptionsCleaned} />
          <Box
            sx={{
              height: '100%',
              animation: 'fadeIn ease 500ms',
              '@keyframes fadeIn': {
                '0%': { opacity: 0 },
                '3%': { opacity: 0 },
                '100%': { opacity: 1 },
              },
            }}
          >
            <CustomHitsPreempted
              CatalogWrapper={CatalogWrapper}
              skipHits={skipHits}
              onHitsChange={onHitsChange}
            />
          </Box>
        </RefreshHits>
      </PreemptedInstantSearch>
    </PreemptStateProvider>
  );
};

export const AlgoliaLayout = memo(
  AlgoliaLayoutUnmemoized,
  compareDeeply('configureOptions'),
) as typeof AlgoliaLayoutUnmemoized;
