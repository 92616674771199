import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import { NotificationCountUser } from '../../functions/src/types/firestore/User/NotificationCount';
import { useDocSnapshot } from '../hooks/firestore/useDocSnapshot';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { useAuth } from './AuthContext';

export type NotificationCountContextType = Required<
  Omit<NotificationCountUser, 'id'>
>;

export const NotificationCountContext = createContext<
  NotificationCountContextType | undefined
>(undefined);

export type NotificationCountProviderProps = {
  children: ReactNode;
};

export const useUserNotificationCount = () => {
  const context = useContext(NotificationCountContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useUserNotificationCount must be used within a UserNotificationCountProvider',
    );
  }
  return context;
};

const UserNotificationCountProviderUnmemoized: FC<
  NotificationCountProviderProps
> = ({ children }) => {
  const { uid } = useAuth();
  const docPath = useMemo(() => {
    return uid ? `User/${uid}/NotificationCount/NotificationCount` : undefined;
  }, [uid]);

  const notificationCountUncleaned = useDocSnapshot<NotificationCountUser>({
    docPath,
  });
  const notificationCount = useMemo<NotificationCountContextType>(() => {
    if (!notificationCountUncleaned) {
      return { friendRequests: 0, membershipRequests: 0 };
    }

    return Object.keys(notificationCountUncleaned).reduce((acc, key) => {
      if (key === 'id') {
        return acc;
      }
      acc[String(key)] = notificationCountUncleaned[String(key)] || 0;
      return acc;
    }, {} as NotificationCountContextType);
  }, [notificationCountUncleaned]);

  return (
    <NotificationCountContext.Provider value={notificationCount}>
      {children}
    </NotificationCountContext.Provider>
  );
};

export const UserNotificationCountProvider = memo(
  UserNotificationCountProviderUnmemoized,
);
