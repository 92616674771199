import { MouseEventHandler, useCallback } from 'react';
import Stack from '@mui/material/Stack';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { memo } from '../../util/memo';
import { NormalizedTab } from '../../util/normalizeTab';
import { RenderChipTab } from './RenderChipTab';

export type NormalizedChipTabProps = NormalizedTab & {
  routeTab: (event: unknown, newValue: string | null) => void;
  activeTab: string | null;
  variant: string;
};

export const NormalizedChipTab = memo(function NormalizedChipTab(
  props: NormalizedChipTabProps,
) {
  const { value, routeTab, activeTab, variant, Wrapper, customization } = props;
  const { IconComponent } = customization || {};
  const chipVariant = variant === 'chip-large' ? 'large' : 'small';
  const go: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      return routeTab(event, value);
    },
    [routeTab, value],
  );
  const chip = useDeepCompareMemo(() => {
    return (
      <RenderChipTab
        {...props}
        go={go}
        IconComponent={IconComponent}
        isActive={activeTab === value}
        label={value}
        variant={chipVariant}
      />
    );
  }, [props, activeTab, value, chipVariant, go, IconComponent]);
  const {
    props: {
      sx: { flex, display },
    },
  } = chip;
  return (
    <Stack sx={{ flex, display }}>
      {Wrapper ? <Wrapper>{chip}</Wrapper> : chip}
    </Stack>
  );
});
