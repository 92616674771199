import { RESULT_SUMMARY_COLLECTION } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary/path';
import { useMatchDetails } from '../../../contexts/MatchDetailsContext';
import { ResultsContextProvider } from '../../../contexts/ResultsContext';
import { MatchSummaryTable } from './MatchSummaryTable';

export const MatchSummary = () => {
  const { matchDocPath } = useMatchDetails();
  const resultSummaryPath = `${matchDocPath}/${RESULT_SUMMARY_COLLECTION}`;

  return (
    <ResultsContextProvider path={resultSummaryPath}>
      <MatchSummaryTable />
    </ResultsContextProvider>
  );
};
