/* eslint-disable max-lines */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @blumintinc/blumint/prefer-settings-object */
/* eslint-disable max-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
import { ComponentType, FC, useMemo } from 'react';
import { Optional } from 'utility-types';
import {
  OnChange,
  OnArrayChange,
  ArrayUrlChangeable,
  ArrayValueChangeable,
  ValueChangeable,
} from '../../components/edit/EditProps';
import {
  EditableArray,
  EditableArrayProps,
} from '../../components/edit/EditableArray';
import {
  UseValue,
  UseArrayValue,
  PathValue,
  UseEntireObject,
} from './provider/CentralizedProvider';
import { memo } from 'src/util/memo';

export type CentralizedWrapperProps<TProps extends Record<string, unknown>> =
  Optional<TProps, 'onChange' | 'onChangeUrl' | 'value' | 'url'>;

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export type CentralizedEditableArrayProps<TValue> = Optional<
  EditableArrayProps<TValue, string>,
  'onElementChange' | 'onElementUrlChange' | 'values' | 'urls'
>;

export class Centralizer<TObj extends Record<string, unknown>> {
  // eslint-disable-next-line @blumintinc/blumint/no-hungarian
  public EditableArray = <TValue,>({
    path,
    pathLink,
    onElementChange: parentOnElementChange,
    onElementUrlChange: parentOnElementUrlChange,
    values: valuesDefault,
    urls: urlsDefault,
    ...props
  }: CentralizedEditableArrayProps<TValue> & {
    path: string;
    pathLink?: string;
  }) => {
    const { values = valuesDefault, onElementChange } =
      this.useArrayValueWithParentCallback({
        onElementChange: parentOnElementChange as any,
        path,
      });

    const { values: urls = urlsDefault, onElementChange: onElementUrlChange } =
      this.useArrayValueWithParentCallback({
        onElementChange: parentOnElementUrlChange as any,
        path: pathLink,
      }) as ArrayValueChangeable<TValue>;

    const urlProps = useMemo(() => {
      return urls ? { urls, onElementUrlChange } : {};
    }, [urls, onElementUrlChange]) as ArrayUrlChangeable<string>;

    return (
      <EditableArray
        // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
        {...(props as any)}
        values={values}
        onElementChange={onElementChange}
        {...urlProps}
      />
    );
  };

  public centralize = <
    TPath extends string,
    TProps extends ValueChangeable<PathValue<TObj, TPath>>,
    TPathLink extends string | undefined = undefined,
  >(
    EditableWrapper: ComponentType<TProps>,
    path: TPath,
    pathLink?: TPathLink,
  ) => {
    const UnmemoizedWrapper = ({
      onChange: parentOnChange,
      onChangeUrl: parentOnChangeUrl,
      value: valueDefault,
      url: urlDefault,
      ...props
    }: CentralizedWrapperProps<TProps>) => {
      const { value = valueDefault, onChange } =
        this.useValueWithParentCallback({
          onChange: parentOnChange,
          path,
        });

      const { value: url = urlDefault, onChange: onChangeUrl } =
        this.useValueWithParentCallback({
          onChange: parentOnChangeUrl as
            | OnChange<PathValue<TObj, string>>
            | 'disabled',
          path: pathLink,
        });

      if (!onChangeUrl || !pathLink) {
        return (
          <EditableWrapper
            // props MUST appear before value and onChange
            // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
            {...(props as any)}
            value={value}
            onChange={onChange}
          />
        );
      }

      return (
        <EditableWrapper
          // props MUST appear first
          // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
          {...(props as any)}
          url={url}
          value={value}
          onChange={onChange}
          onChangeUrl={onChangeUrl}
        />
      );
    };

    // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
    return memo(UnmemoizedWrapper) as unknown as FC<
      CentralizedWrapperProps<
        TProps & {
          onChangeUrl?: TPathLink extends undefined
            ? undefined
            : OnChange<PathValue<TObj, TPathLink>> | 'disabled';
          url?: TPathLink extends undefined
            ? undefined
            : PathValue<TObj, TPathLink>;
        }
      >
    >;
  };

  public constructor(
    private readonly useValue: UseValue<TObj>,
    private readonly useArrayValue: UseArrayValue<TObj>,
    public readonly useEntireObject: UseEntireObject<TObj>,
  ) {}

  private useValueWithParentCallback<TPath extends string>({
    onChange: parentOnChange,
    path,
  }: {
    onChange?:
      | OnChange<PathValue<TObj, TPath>, PathValue<TObj, TPath>>
      | 'disabled';
    path: TPath | undefined;
  }) {
    const { value, setValue } = this.useValue(path);

    const propagateChange = useMemo(() => {
      if (parentOnChange === 'disabled') {
        return 'disabled';
      }
      return (
        newValue: PathValue<TObj, TPath>,
        oldValue?: PathValue<TObj, TPath>,
      ) => {
        if (path === undefined) {
          return;
        }
        // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
        setValue(newValue as any);
        parentOnChange?.(newValue, oldValue);
      };
    }, [path, setValue, parentOnChange]);

    return {
      value,
      onChange: propagateChange,
    } as const;
  }

  // eslint-disable-next-line @blumintinc/blumint/no-hungarian
  private useArrayValueWithParentCallback<TPath extends string>({
    onElementChange: parentOnElementChange,
    path,
  }: {
    onElementChange?: OnArrayChange<PathValue<TObj, TPath>> | 'disabled';
    path: TPath | undefined;
  }) {
    const { values, onElementChange } = this.useArrayValue(path);

    const propagateChange = useMemo(() => {
      if (parentOnElementChange === 'disabled') {
        return 'disabled';
      }
      return (
        index: number,
        newValue?: PathValue<TObj, TPath> | null,
        oldValue?: PathValue<TObj, TPath> | null,
      ) => {
        if (path === undefined) {
          return;
        }
        onElementChange(index, newValue, oldValue);
        parentOnElementChange?.(index, newValue, oldValue);
      };
    }, [path, onElementChange, parentOnElementChange]);

    return {
      values,
      onElementChange: propagateChange,
    } as const;
  }
}
