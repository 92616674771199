/* eslint-disable max-depth */
import { useAudio } from '../useAudio';
import { SOUND_URLS } from '../../../functions/src/util/sound/constants';

export const NEW_NOTIFICATION_SOUND_URL = SOUND_URLS.NOTIFICATION.INCOMING;

export const useNotificationSound = () => {
  const { playSound: playIncomingNotificationSound } = useAudio(
    SOUND_URLS.NOTIFICATION.INCOMING,
    {
      volume: 0.5,
      autoDestroy: false,
    },
  );

  return { playIncomingNotificationSound };
};
