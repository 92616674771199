export const MATCH_COLLECTION = 'Match' as const;

export const MATCH_PATH =
  'Game/{gameId}/Tournament/{tournamentId}/Round/{roundId}/Match/{matchId}' as const;
export type MatchPath = typeof MATCH_PATH;

export const toMatchCollectionPath = ({
  gameId,
  tournamentId,
  roundId,
}: {
  gameId: string;
  tournamentId: string;
  roundId: string;
}) => {
  return `Game/${gameId}/Tournament/${tournamentId}/Round/${roundId}/Match`;
};

export const toMatchPath = ({
  gameId,
  tournamentId,
  roundId,
  matchId,
}: {
  gameId: string;
  tournamentId: string;
  roundId: string;
  matchId: string;
}) => {
  return `${toMatchCollectionPath({
    gameId,
    tournamentId,
    roundId,
  })}/${matchId}`;
};
