import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { withDynamicImport } from '../../../util/withDynamicImport';
import {
  STREAM_CHAT_REACT,
  DynamicImport,
  StreamChatReact,
} from '../../../../functions/src/types/DynamicModule';
import { assertSafe } from '../../../../functions/src/util/assertSafe';

export type ThreadStartProps = {
  modules: DynamicImport<[StreamChatReact]>;
};

const ThreadStartUnmemoized = ({ modules }: ThreadStartProps) => {
  const theme = useTheme();

  const { useChannelStateContext } = modules[assertSafe(STREAM_CHAT_REACT)];
  const { thread } = useChannelStateContext();

  const replyText = useMemo(() => {
    const replyCount = thread?.reply_count;
    return (
      !!replyCount && `${replyCount} repl${replyCount === 1 ? 'y' : 'ies'}`
    );
  }, [thread?.reply_count]);

  return (
    <Stack alignItems="center" direction="row" spacing={2} sx={{ pt: 1 }}>
      <Typography
        color={theme.palette.text.disabled}
        sx={{
          textTransform: 'lowercase',
        }}
        variant="subtitle2"
      >
        {replyText}
      </Typography>
      <Divider
        sx={{
          flex: 1,
        }}
      />
    </Stack>
  );
};

export const ThreadStart = memo(
  withDynamicImport({
    Component: ThreadStartUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
