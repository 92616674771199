export type ToRouterOptimisticKeyProps = {
  key: string;
  location: string;
};

export const toRouterOptimisticKey = ({
  key,
  location,
}: ToRouterOptimisticKeyProps) => {
  return `routerState-${location}-${key}`;
};
