import { useState, useEffect } from 'react';
import { assertSafe } from 'functions/src/util/assertSafe';
import {
  MODULE_IMPORTS,
  DynamicImport,
  DynamicModule,
} from 'functions/src/types/DynamicModule';

/**
 * Overloads `useDynamic` to handle both:
 * - Dynamic module imports via `string[]`
 * - Direct `import()` calls
 */

// Case 1: Multiple modules via `string[]`
export function useDynamic<TModuleNameList extends (keyof DynamicModule)[]>(
  moduleNames: TModuleNameList,
): DynamicImport<TModuleNameList> | undefined;

// Case 2: Single module via `import()`
export function useDynamic<TModule>(
  moduleImport: Promise<TModule>,
): TModule | undefined;

// Implementation
export function useDynamic<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TModuleNameList extends (keyof DynamicModule)[] | Promise<any>,
>(input: TModuleNameList) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [moduleResult, setModuleResult] = useState<any>(undefined);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const loadModules = async () => {
      if (Array.isArray(input)) {
        // Case 1: moduleNames as `string[]`
        const moduleNames = input as (keyof DynamicModule)[];
        const importedModules = await Promise.all(
          moduleNames.map((name) => {
            return MODULE_IMPORTS[assertSafe(name)]();
          }),
        );

        const modules = Object.fromEntries(
          moduleNames.map((name, index) => {
            return [name, importedModules[assertSafe(index)]] as const;
          }),
        );

        setModuleResult(modules);
      } else {
        // Case 2: Direct import() promise
        const result = await input;
        setModuleResult(result);
      }
    };

    loadModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return moduleResult;
}
