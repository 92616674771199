import { Link } from '../Link';
import { memo } from '../../util/memo';
import { AvatarNextLive } from '../avatar/AvatarNextLive';
import { AVATAR_SIZE_PX_PRIMARY } from '../avatar/AvatarNext';

export const LINK_STYLE = {
  textDecoration: 'none',
} as const;

export type AvatarLivestreamProps = {
  streamerId: string;
  username: string;
  imgUrl: string;
};

const AvatarLivestreamUnmemoized = ({
  streamerId,
  username,
  imgUrl,
}: AvatarLivestreamProps) => {
  return (
    <Link href={`/${streamerId}`} style={LINK_STYLE}>
      <AvatarNextLive
        alt={username}
        size={AVATAR_SIZE_PX_PRIMARY}
        src={imgUrl}
      />
    </Link>
  );
};

export const AvatarLivestream = memo(AvatarLivestreamUnmemoized);
