/* eslint-disable @typescript-eslint/no-explicit-any */
export const applyNestedUpdate = <TObj extends Record<string, unknown>>(
  current: TObj,
  path: string,
  value: unknown,
): TObj => {
  const pathParts = path.split('.');
  const lastPart = pathParts.pop();
  if (!lastPart) return current;

  let target = current as Record<string, Record<string, unknown>>;

  for (const part of pathParts) {
    target[String(part)] = { ...target[String(part)] } as any;
    target = target[String(part)] as any;
  }

  (target as Record<string, unknown>)[String(lastPart)] = value;
  return current;
};
