import { useTheme, SxProps } from '@mui/material/styles';
import { useMemo, FC, ReactNode } from 'react';
// import { ShareUrlToClipboard } from '../headers/ShareUrlToClipboard';
import Stack from '@mui/material/Stack';
import { memo } from '../../../../util/memo';
import { ViewMoreChip } from '../../headers/ViewMoreChip';
import { useNearEndContent } from '../../../../hooks/content/useNearEndContent';
import { ExtendedIntersectionObserverProps } from '../../../../hooks/visibility/useIntersectionObserver';
import { InfiniteCatalog } from './InfiniteCatalog';

export type VerticalCarouselProps = InfiniteCatalog & {
  viewMoreLink?: string;
  header?: ReactNode;
  cardSpacing?: string;
  share?: boolean;
  containerSx?: SxProps;
  contentSx?: SxProps;
  background?: number;
  intersectionOptions?: ExtendedIntersectionObserverProps;
};

const VerticalCarouselUnmemoized: FC<VerticalCarouselProps> = ({
  content,
  header,
  onNearEnd,
  viewMoreLink,
  cardSpacing = '0px',
  containerSx,
  contentSx,
  intersectionOptions,
}) => {
  const { nearEndContent } = useNearEndContent({
    content,
    onNearEnd,
    intersectionOptions,
    scrollDirection: 'down',
  });

  const containerAllSx = useMemo(() => {
    const sx: SxProps = {
      borderRadius: '10px',
      ...containerSx,
    };
    return sx;
  }, [containerSx]);

  const theme = useTheme();
  const contentAllSx = useMemo(() => {
    const sx: SxProps = {
      height: '100%',
      borderRadius: '4px',
      overflowY: 'auto',
      position: 'relative',
      ...theme.scrollbars.invisible,
      ...contentSx,
    };
    return sx;
  }, [contentSx, theme.scrollbars.invisible]);

  return (
    <Stack direction="column" height="100%" spacing={3} sx={containerAllSx}>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack
          direction="row"
          flex={1}
          spacing={2}
          sx={{ alignItems: 'center' }}
        >
          {header}
          {!!viewMoreLink && <ViewMoreChip href={viewMoreLink} />}
        </Stack>
        {/* {share && <ShareUrlToClipboard></ShareUrlToClipboard>} */}
      </Stack>
      <Stack spacing={cardSpacing} sx={contentAllSx}>
        {nearEndContent}
      </Stack>
    </Stack>
  );
};

export const VerticalCarousel = memo(VerticalCarouselUnmemoized);
