import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import { ReactNode, useMemo } from 'react';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import { OverlayAvatar } from '../OverlayAvatar';
import { memo } from '../../../util/memo';
import {
  AvatarGroupNext,
  AvatarGroupNextProps,
} from '../../avatar/AvatarGroupNext';
import { IconOverlayAvatar } from '../IconOverlayAvatar';
import { TextOverlayAvatar } from '../TextOverlayAvatar';
import { ChannelGroupType } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { LiveBadge } from '../../LiveBadge';
import { CyclingOverlayAvatar } from '../CyclingOverlayAvatar';
import {
  AVATAR_SIZE_PX_RESPONSIVE_SECONDARY,
  AVATAR_SIZE_PX_RESPONSIVE_PRIMARY,
} from '../../avatar/AvatarNext';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_MOBILE } from '../../SideDrawerToggle';

const CHANNEL_GROUP_AVATAR_HEIGHT = '68px' as const;
const CHANNEL_GROUP_AVATAR_HEIGHT_MOBILE = '54px' as const;
const ICON_OVERLAY_SIZE = {
  xs: '28px',
  md: '36px',
} as const;
const SURPLUS_SX = { pb: { xs: '6px', md: '10px' } } as const;

export type ChannelGroupAvatarProps = Pick<
  AvatarGroupNextProps,
  'entities' | 'total'
> & {
  isConnectedToVoice: boolean;
  dateFormatted?: string;
  isSelected: boolean;
  borderColor?: string;
  channelGroupType: ChannelGroupType;
  tournamentId?: string;
  onClick: () => void;
};

const ChannelGroupAvatarUnmemoized = ({
  entities,
  isConnectedToVoice,
  dateFormatted,
  isSelected,
  borderColor,
  channelGroupType,
  tournamentId,
  total,
  onClick,
}: ChannelGroupAvatarProps) => {
  const theme = useTheme();
  const isTeam = channelGroupType === 'PersonalGroup' && !!tournamentId;
  const isPlayback = channelGroupType === 'Playback';

  const overlayText = useMemo(() => {
    if (isTeam) {
      return 'TEAM';
    }

    return dateFormatted || '-';
  }, [isTeam, dateFormatted]);

  const overlays = useMemo(() => {
    const overlayElements: ReactNode[] = [];

    if (isPlayback) {
      overlayElements.push(
        <OverlayAvatar>
          <LiveBadge sx={{ height: '18px' }} />
        </OverlayAvatar>,
      );
    }

    if (isConnectedToVoice) {
      overlayElements.push(
        <IconOverlayAvatar
          IconComponent={HeadphonesRoundedIcon}
          iconSx={{
            color: theme.palette.secondary.main,
            height: ICON_OVERLAY_SIZE,
            width: ICON_OVERLAY_SIZE,
          }}
        />,
      );
    }

    if (dateFormatted || isTeam) {
      overlayElements.push(<TextOverlayAvatar text={overlayText} />);
    }

    return overlayElements;
  }, [
    isPlayback,
    isConnectedToVoice,
    dateFormatted,
    isTeam,
    theme.palette.secondary.main,
    overlayText,
  ]);

  const isGroup = total && total > 1;

  const avatarGroupSx = isGroup
    ? {
        '.MuiAvatar-root': {
          backgroundColor: theme.palette.action.disabled,
          border: `2px solid ${
            theme.palette.background.elevationSolid[
              assertSafe(isSelected ? 12 : 4)
            ]
          }`,
        },
        '& > :first-child': {
          position: 'absolute',
          right: 4,
          top: 0,
        },
        '& > :last-child': {
          position: 'absolute',
          left: 4,
          bottom: 0,
          zIndex: 1,
        },
      }
    : undefined;

  const avatarSx = useMemo(() => {
    return {
      borderColor,
    } as const;
  }, [borderColor]);

  return (
    <Box
      position="relative"
      sx={{
        height: isGroup
          ? {
              xs: CHANNEL_GROUP_AVATAR_HEIGHT_MOBILE,
              md: CHANNEL_GROUP_AVATAR_HEIGHT,
            }
          : undefined,
        width: isGroup
          ? { xs: SIDEBAR_WIDTH_MOBILE, md: SIDEBAR_WIDTH }
          : undefined,
      }}
      onClick={onClick}
    >
      <AvatarGroupNext
        avatarSx={avatarSx}
        entities={entities}
        max={2}
        size={
          isGroup
            ? AVATAR_SIZE_PX_RESPONSIVE_SECONDARY
            : AVATAR_SIZE_PX_RESPONSIVE_PRIMARY
        }
        surplusSx={SURPLUS_SX}
        sx={avatarGroupSx}
        total={total}
      />
      {overlays.length > 1 ? (
        <CyclingOverlayAvatar overlays={overlays} />
      ) : (
        overlays[0]
      )}
    </Box>
  );
};

export const ChannelGroupAvatar = memo(ChannelGroupAvatarUnmemoized);
