import {
  ChannelGroup,
  GroupFilterMap,
  TOURNAMENT_TYPES,
  TournamentType,
  GroupFilter,
  ChannelGroupFilterType,
} from '..';

export function isTournament<TTime = Date>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<'Tournament', TTime> {
  const { groupFilter } = channelGroup;
  return isTournamentFilter(groupFilter);
}

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export function isTournamentType(
  type: ChannelGroupFilterType,
): type is TournamentType {
  return TOURNAMENT_TYPES.includes(type as TournamentType);
}

export function isTournamentFilter(
  filter: GroupFilter,
): filter is GroupFilter<'Tournament'> {
  return (
    filter.length === 2 &&
    filter.every((segment) => {
      return 'type' in segment && isTournamentType(segment.type);
    }) &&
    'tournamentId' in filter[0]
  );
}
