/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { DateTimeFormatterUtc } from './DateTimeFormatterUtc';
import { NUMERIC_TIMEZONE } from './presets/times';
import { NUMERIC_NOYEAR } from './presets/dates';
import {
  Announcement,
  Timed,
} from 'functions/src/types/firestore/Game/Announcement';

export const DEFAULT_SEPARATOR = ' ' as const;
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD HH:mm:00' as const;
export const DATE_NOW = new Date(Date.now());

export type DateDifference = {
  isDate1LaterThanDate2: boolean;
  isDatesSame: boolean;
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  weeks: number;
  months: number;
  years: number;
};

export const DAY_NAMES = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

export type UnitDifference = {
  [key in Intl.RelativeTimeFormatUnit]: number;
};
export const YEAR_IN_MS = 365.25 * 24 * 60 * 60 * 1000;
export const MONTH_IN_MS = (365.25 / 12) * 24 * 60 * 60 * 1000;
export const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;
export const HOUR_IN_MS = 60 * 60 * 1000;
export const MINUTE_IN_MS = 60 * 1000;
export const SECOND_IN_MS = 1000 as const;
export const ONE_HOUR_MINS = 60 as const;
export const ONE_HOUR = 1 as const;

export type ComputeTimeBetweenDatesDatesProps = {
  startDate: Date;
  endDate: Date;
};

export function computeTimeBetweenDates({
  startDate,
  endDate,
}: ComputeTimeBetweenDatesDatesProps) {
  const timeUntilDate = Math.abs(Number(startDate) - Number(endDate));

  const isStartDateLaterThanEndDate = Number(startDate) - Number(endDate) > 0;
  // eslint-disable-next-line no-unused-vars, prefer-const
  let differenceRemainingMs = timeUntilDate;

  const yearsPassed = Math.floor(differenceRemainingMs / YEAR_IN_MS);
  differenceRemainingMs -= yearsPassed * YEAR_IN_MS;
  const monthsPassed = Math.floor(differenceRemainingMs / MONTH_IN_MS);
  differenceRemainingMs -= monthsPassed * MONTH_IN_MS;
  const weeksPassed = Math.floor(differenceRemainingMs / WEEK_IN_MS);
  differenceRemainingMs -= weeksPassed * WEEK_IN_MS;
  const daysPassed = Math.floor(differenceRemainingMs / DAY_IN_MS);
  differenceRemainingMs -= daysPassed * DAY_IN_MS;
  const hoursPassed = Math.floor(differenceRemainingMs / HOUR_IN_MS);
  differenceRemainingMs -= hoursPassed * HOUR_IN_MS;
  const minutesPassed = Math.floor(differenceRemainingMs / MINUTE_IN_MS);
  differenceRemainingMs -= minutesPassed * MINUTE_IN_MS;
  const secondsPassed = Math.floor(differenceRemainingMs / SECOND_IN_MS);
  differenceRemainingMs -= secondsPassed * SECOND_IN_MS;
  const isSameDate = timeUntilDate === 0;

  return {
    startDateLaterThanEndDate: isStartDateLaterThanEndDate,
    datesAreSame: isSameDate,
    seconds: secondsPassed,
    minutes: minutesPassed,
    hours: hoursPassed,
    days: daysPassed,
    weeks: weeksPassed,
    months: monthsPassed,
    years: yearsPassed,
  } as const;
}

function difference({ startDate, endDate }: ComputeTimeBetweenDatesDatesProps) {
  const {
    startDateLaterThanEndDate,
    seconds: secondsPassed,
    minutes: minutesPassed,
    hours: hoursPassed,
    days: daysPassed,
    weeks: weeksPassed,
    months: monthsPassed,
    years: yearsPassed,
  } = computeTimeBetweenDates({
    startDate,
    endDate,
  });

  const coefficient = startDateLaterThanEndDate ? -1 : 1; // 1 if date in the past

  return {
    second: (coefficient * secondsPassed) % 60,
    minute: (coefficient * minutesPassed) % 60,
    hour: (coefficient * hoursPassed) % 24,
    day: (coefficient * daysPassed) % 7,
    week: (coefficient * weeksPassed) % 4,
    month: coefficient * monthsPassed,
    year: coefficient * yearsPassed,
  } as const;
}

const UNITS_LARGE_TO_SMALL: Intl.RelativeTimeFormatUnit[] = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
];

export type FormatRelativeProps = {
  date: Date;
  relativeTo?: Date;
  unitsCount?: number;
  options?: Intl.RelativeTimeFormatOptions;
};

export function formatRelative({
  date,
  options,
  unitsCount = 1,
  relativeTo = new Date(),
}: FormatRelativeProps) {
  const differences = difference({ startDate: relativeTo, endDate: date });
  const unitsFiltered = UNITS_LARGE_TO_SMALL.filter((unit) => {
    return differences[assertSafe(unit)] !== 0;
  });
  if (unitsFiltered.length === 0) {
    return 'just now';
  }
  const units = unitsFiltered.slice(
    0,
    Math.min(unitsCount, unitsFiltered.length),
  );

  const rtf = new Intl.RelativeTimeFormat('en', options);
  return units
    .map((unit, i) => {
      let formatted = rtf.format(differences[assertSafe(unit)], unit);
      if (i > 0) {
        formatted = formatted.replace('in ', '');
      }
      if (i < units.length - 1) {
        formatted = formatted.replace(' ago', '');
      }
      return formatted;
    })
    .join(' ');
}

export type ChooseDateProps = {
  date: Date;
  checkInDate?: Date;
  endDate?: Date;
};

export type BuildDateLineContentProps = Timed<Date> & {
  type: Announcement['type'];
};

export function buildDateLineContent({
  date,
  endDate,
  checkInDate,
  type,
}: BuildDateLineContentProps) {
  const isBeforeCheckIn = !!checkInDate && Date.now() < checkInDate.getTime(); // TODO: useServerTime
  const isAfterEndDate = !!endDate && Date.now() > endDate.getTime(); // TODO: useServerTime

  const dateChosen = isBeforeCheckIn
    ? checkInDate
    : isAfterEndDate
    ? endDate
    : date;

  const formatted = formatRelative({ date: dateChosen, unitsCount: 2 });
  const isPast = formatted.includes('ago');

  if (type === 'asset' || type === 'patch') {
    return `Release${isPast ? 'd' : 's'} ${formatted}`;
  }

  if (isBeforeCheckIn) {
    return `Check-in starts ${formatted}`;
  }
  if (isAfterEndDate) {
    return `Ended ${formatted}`;
  }
  return `Start${isPast ? 'ed' : 's'} ${formatted}`;
}

export type DateTimeOptionProps = {
  dateFormat?: Intl.DateTimeFormatOptions | false;
  timeFormat?: Intl.DateTimeFormatOptions | false;
  separator?: string;
};

export type FormatDateTimeProps = {
  date: Date;
  dateOverrides?: string[];
  optionsDefault?: DateTimeOptionProps;
};

export function formatDateTime({
  date,
  dateOverrides,
  optionsDefault = {},
}: FormatDateTimeProps) {
  const options = {
    dateFormat: NUMERIC_NOYEAR,
    timeFormat: NUMERIC_TIMEZONE,
    separator: DEFAULT_SEPARATOR,
    ...optionsDefault,
  };

  const { timeFormat, dateFormat, separator } = options;

  const time = timeFormat
    ? new DateTimeFormatterUtc(timeFormat).format(date)
    : // eslint-disable-next-line no-restricted-syntax
      '';

  const timeFormatted =
    timeFormat === false
      ? // eslint-disable-next-line no-restricted-syntax
        ''
      : separator === DEFAULT_SEPARATOR && dateFormat !== false
      ? `${separator}(${time})`
      : `${separator}${time}`;

  const dateFormatted =
    dateFormat === false
      ? // eslint-disable-next-line no-restricted-syntax
        ''
      : new DateTimeFormatterUtc(dateFormat).format(date);

  return `${
    dateOverrides?.length
      ? replaceDay({ date, dateFormatted, dateOverrides })
      : dateFormatted
  }${timeFormatted}`;
}

// Expand when needed (e.g. 'Last Week': -7)
export const DATE_OFFSETS = {
  Yesterday: -1,
  Today: 0,
  Tomorrow: 1,
} as const;

export type ReplaceDayProps = {
  date: Date;
  dateFormatted: string;
  dateOverrides?: string[];
};

export function replaceDay({
  date,
  dateFormatted,
  dateOverrides = [],
}: ReplaceDayProps) {
  const dateCurrent = new Date();
  dateCurrent.setHours(0, 0, 0, 0);
  const offset = Math.floor(
    (date.getTime() - dateCurrent.getTime()) / DAY_IN_MS,
  );
  const replacement = dateOverrides.find((override) => {
    return DATE_OFFSETS[assertSafe(override)] === offset;
  });
  return replacement
    ? dateFormatted.replace(DAY_NAMES[assertSafe(date.getDay())], replacement)
    : dateFormatted;
}

export type DateRangeProps = {
  dateFormat?: Intl.DateTimeFormatOptions;
  timeFormat?: Intl.DateTimeFormatOptions;
  separator?: string;
};

export type FormatDateRangeProps = {
  startDate: Date;
  endDate: Date;
  optionsDefault?: DateRangeProps;
};

export function formatDateRange({
  startDate,
  endDate,
  optionsDefault = {},
}: FormatDateRangeProps) {
  const options = {
    dateFormat: NUMERIC_NOYEAR,
    timeFormat: NUMERIC_TIMEZONE,
    separator: DEFAULT_SEPARATOR,
    ...optionsDefault,
  };
  const { timeFormat, dateFormat, separator } = options;
  if (startDate.getDate() === endDate.getDate()) {
    const startDateTime = new DateTimeFormatterUtc({
      ...timeFormat,
      timeZoneName: undefined,
    }).format(startDate, true);

    const endDateTime = new DateTimeFormatterUtc(timeFormat).format(endDate);

    const timeFormatted =
      separator === DEFAULT_SEPARATOR
        ? `${separator}(${startDateTime} - ${endDateTime})`
        : `${separator}${startDateTime} - ${endDateTime}`;

    const dateFormatted = new DateTimeFormatterUtc(dateFormat).format(
      startDate,
      true,
    );
    return `${dateFormatted}${timeFormatted}`;
  }

  return `${formatDateTime({
    date: startDate,
    optionsDefault,
  })} - ${formatDateTime({
    date: endDate,
    optionsDefault,
  })}`;
}

export const dateMonthYearTime = (registrationTime: Date) => {
  const date = new Date(registrationTime);

  const day = date.getDate().toString().padStart(2, '0');
  const month = date
    .toLocaleString('default', { month: 'short' })
    .toUpperCase();
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours || 12;

  return `${day}TH ${month} ${year}, ${hours}:${minutes}${period}`;
};
