import { prependPrefix } from '../Strings';
import { HttpsError } from '../errors/HttpsError';

export const UTC_REGEX = /utc[+-](\d+\.?\d*)/;

export const SUPPORTED_UTC_OFFSETS = [
  -8, -7, -6, -5, -4, -3, 0, 1, 2, 3, 3.5, 4, 5, 5.5, 6, 7, 8, 9, 10,
] as const;

const UTC_TO_IANA_MAP = {
  'utc-8': 'Pacific/Pitcairn',
  'utc-7': 'America/Phoenix',
  'utc-6': 'America/Regina',
  'utc-5': 'America/Bogota',
  'utc-4': 'America/La_Paz',
  'utc-3': 'America/Argentina/Buenos_Aires',
  'utc+0': 'Africa/Casablanca',
  'utc+1': 'Africa/Algiers',
  'utc+2': 'Africa/Johannesburg',
  'utc+3': 'Africa/Nairobi',
  'utc+3.5': 'Asia/Tehran',
  'utc+4': 'Asia/Dubai',
  'utc+5': 'Asia/Karachi',
  'utc+5.5': 'Asia/Kolkata',
  'utc+6': 'Asia/Almaty',
  'utc+7': 'Asia/Jakarta',
  'utc+8': 'Asia/Ulaanbaatar',
  'utc+9': 'Asia/Chita',
  'utc+10': 'Australia/Brisbane',
} as const;

export type Utc = keyof typeof UTC_TO_IANA_MAP;

export type UtcOffset = typeof SUPPORTED_UTC_OFFSETS[number];

export const formatUtc = (utcOffset: UtcOffset): Utc => {
  return `utc${utcOffset >= 0 ? '+' : ''}${utcOffset}` as Utc;
};

export const parseUtc = (utcString: Utc): UtcOffset => {
  const match = /utc([+-]\d+\.?\d*)/.exec(utcString);
  if (!match || typeof match[1] !== 'string') {
    throw new HttpsError(
      'invalid-argument',
      'Invalid utc format',
      `utc String: ${utcString}`,
    );
  }

  const numericOffset = parseFloat(match[1]);
  if (!SUPPORTED_UTC_OFFSETS.includes(numericOffset as UtcOffset)) {
    throw new HttpsError(
      'unimplemented',
      'Unsupported utc offset',
      `utc offset: ${numericOffset}`,
    );
  }

  return numericOffset as UtcOffset;
};

export const sampleIana = (utcOffset: UtcOffset | Utc): string => {
  const utcKey =
    typeof utcOffset === 'number' ? formatUtc(utcOffset) : utcOffset;
  // eslint-disable-next-line security/detect-object-injection
  const ianaTimezone = UTC_TO_IANA_MAP[utcKey];

  if (!ianaTimezone) {
    throw new HttpsError(
      `unimplemented`,
      `Unsupported utc offset`,
      `utc offset: ${utcKey}`,
    );
  }

  return ianaTimezone;
};

export const prependUtc = (
  asPath: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: any,
) => {
  if (typeof href === 'object' && ('pathname' in href || 'query' in href)) {
    return {
      ...href,
      pathname: prependPrefix(asPath, href.pathname || '/'),
    };
  }
  return prependPrefix(asPath, `${href}`);
};
