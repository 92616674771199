import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { useLivestreamViewerCount } from '../../hooks/livestream/useLivestreamViewerCount';
import { useVideo } from '../../contexts/video/VideoContext';

export type ViewerCountProps = {
  playbackId: string;
};

const ViewerCountUnmemoized = ({ playbackId }: ViewerCountProps) => {
  const { isLive } = useVideo();
  const { liveViewers, totalViews } = useLivestreamViewerCount({
    playbackId,
  });

  const isValidLiveCount = isLive && liveViewers !== 'unknown';
  const isValidTotalCount = !isLive && totalViews !== 'unknown';

  if (!isValidLiveCount && !isValidTotalCount) {
    return null;
  }

  const count = isLive ? liveViewers : totalViews;

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <Typography sx={{ textTransform: 'uppercase' }} variant="body1">
        {`${count} ${
          // eslint-disable-next-line no-restricted-syntax
          isValidLiveCount ? 'Watching' : `View${Number(count) > 1 ? 's' : ''}`
        }`}
      </Typography>
    </Stack>
  );
};

export const ViewerCount = memo(ViewerCountUnmemoized);
