import Stack from '@mui/material/Stack';
import { MatchSummary } from './MatchSummary';
import { GameTables } from './GameTables';

export function MatchDetails() {
  return (
    <Stack spacing={3} sx={{ minWidth: { xs: '100%', md: '750px' } }}>
      <MatchSummary />
      <GameTables />
    </Stack>
  );
}
