import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { SxProps } from '@mui/material/styles';
import {
  useDeepCompareCallback,
  useDeepCompareMemo,
} from '@blumintinc/use-deep-compare';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { AvatarGroupEntity } from '../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { adjustSize } from '../../util/styles/adjustSize';
import { stableHash } from '../../../functions/src/util/hash/stableHash';
import { AvatarSurplus } from './AvatarSurplus';
import { AvatarStatus, AvatarStatusProps } from './AvatarStatus';

export const AVATAR_GROUP_MAX = 5 as const;
const DEFAULT_SX: SxProps = {};

export type AvatarGroupNextProps = AvatarGroupProps &
  Omit<AvatarStatusProps, 'sx'> & {
    entities: AvatarGroupEntity[];
    surplusSx?: SxProps;
    avatarSx?: SxProps;
  };

const AvatarGroupNextUnmemoized = (props: AvatarGroupNextProps) => {
  const {
    entities,
    size,
    total,
    sx = DEFAULT_SX,
    avatarSx = DEFAULT_SX,
    surplusSx = DEFAULT_SX,
    ...rest
  } = props;

  const surplus = useDeepCompareMemo(() => {
    if (!total) {
      return;
    }
    return total - entities.length;
  }, [total, entities]);

  const Surplus = useDeepCompareCallback(
    (_surplus: number) => {
      return (
        surplus && (
          <AvatarSurplus
            surplus={surplus}
            sx={{
              ...surplusSx,
              fontSize: adjustSize(size, (n: number) => {
                return n / 1.5;
              }),
            }}
          />
        )
      );
    },
    [surplus, surplusSx, size],
  );

  const sxResolved = useMemo(() => {
    return {
      ...sx,
      '.MuiAvatar-root': {
        border: 'none',
        m: 0,
        height: size,
        width: size,
        ...sx?.['.MuiAvatar-root'],
      },
    };
  }, [sx, size]);

  return (
    <AvatarGroup renderSurplus={Surplus} sx={sxResolved} total={total}>
      {entities.map(({ id, imgUrl }, index) => {
        return (
          <AvatarStatus
            key={stableHash(`${id}-${imgUrl}-${index}`)}
            groupId={id}
            size={size}
            src={imgUrl}
            sx={avatarSx}
            {...rest}
          />
        );
      })}
    </AvatarGroup>
  );
};
export const AvatarGroupNext = memo(AvatarGroupNextUnmemoized);
