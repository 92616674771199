import {
  DynamicModule,
  LivekitComponentsReact,
} from '../../../functions/src/types/DynamicModule';

export type UseConnectionStatusProps = {
  livekitModule: DynamicModule[LivekitComponentsReact];
};

export const useConnectionStatus = ({
  livekitModule,
}: UseConnectionStatusProps) => {
  const { useRoomContext, useConnectionState } = livekitModule;

  const room = useRoomContext();
  const connectionState = useConnectionState(room);

  return connectionState;
};
