import {
  TOURNAMENT_PHASE_PRESENT,
  TournamentPhasePresent,
} from '../../../functions/src/types/firestore/Game/Tournament';
import { UnreadMessageCountGroup } from '../../contexts/UnreadMessageCountContext';
import { assertSafe } from 'functions/src/util/assertSafe';

// eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
export const hasUnreadChannel = (
  channel: UnreadMessageCountGroup['channels'][string],
) => {
  return !!channel.isUnread;
};

export const isSomeChannelUnseen = (group: UnreadMessageCountGroup) => {
  // eslint-disable-next-line no-restricted-properties
  return Object.values(group.channels).some((channel) => {
    return hasUnreadChannel(channel);
  });
};

export const isChannelUnseen = (
  group: UnreadMessageCountGroup,
  channelId: string,
) => {
  return (
    channelId &&
    group.channels[assertSafe(channelId)] &&
    hasUnreadChannel(group.channels[assertSafe(channelId)])
  );
};

export const isSomeTournamentChannelUnseen = (
  group: UnreadMessageCountGroup,
  tournamentId: string,
) => {
  // eslint-disable-next-line no-restricted-properties
  return Object.values(group.channels).some((channel) => {
    return channel.tournamentId === tournamentId && hasUnreadChannel(channel);
  });
};

export const isMatchChannelUnseen = (
  group: UnreadMessageCountGroup,
  matchId: string,
) => {
  // eslint-disable-next-line no-restricted-properties
  return Object.values(group.channels).some((channel) => {
    return channel.matchId === matchId && hasUnreadChannel(channel);
  });
};

export const isPresentMatchChannel = (
  channel: UnreadMessageCountGroup['channels'][string],
) => {
  return (
    TOURNAMENT_PHASE_PRESENT.includes(
      channel.phase as TournamentPhasePresent,
    ) && !!channel.matchId
  );
};
