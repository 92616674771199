import { Timestamp } from 'firebase-admin/firestore';
import {
  ChannelGroup,
  GroupFilterMap,
  ChannelGroupFilterType,
  GROUP_CHANNEL_TYPE,
  GroupFilter,
  GroupType,
} from '..';

export function isGroup<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<'Group', TTime> {
  const { groupFilter } = channelGroup;
  return (
    (groupFilter.length === 2 || groupFilter.length === 1) &&
    isGroupChannelType(groupFilter[0].type)
  );
}

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const isGroupChannelType = (
  type: ChannelGroupFilterType,
): type is GroupType => {
  return GROUP_CHANNEL_TYPE === type;
};

export function isGroupGroupFilter(
  filter: GroupFilter,
): filter is GroupFilter<Capitalize<GroupType>> {
  return (
    filter.length === 1 &&
    isGroupChannelType(filter[0].type) &&
    'groupId' in filter[0]
  );
}
