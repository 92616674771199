/* eslint-disable @blumintinc/blumint/require-hooks-default-params */
import { ReactNode, useMemo } from 'react';
import { Hit, OrNode } from '../../../functions/src/types/Hit';
import { renderHits } from '../../util/algolia/renderHits';
import { PLACEHOLDER_KEY } from '../content/useNearEndContent';
import { NoSearchResults } from '../../components/algolia/search/NoSearchResults';
import { usePreemptState } from '../../contexts/algolia/PreemptStateContext';

export type TransformHits<THit extends Hit> = (
  hits: OrNode<THit>[],
) => OrNode<THit>[];

export type UseRenderHitsProps<THit extends Hit> = {
  hits: OrNode<THit>[];
  /** @important Make sure transformBefore is memoized! */
  transformBefore?: TransformHits<THit>;
  /**
   * @important You CANNOT pass a React component directly to
   * the render prop. You must wrap it in an arrow function.
   * @important Make sure render is memoized!
   */
  render: (hit: THit) => ReactNode;
  /**
   * Component to show when there are no results and no search query
   * If not provided, will return an empty array
   */
  noResultsSearchless?: ReactNode;
  /**
   * Component to show when there are no results but there is a search query
   * @default NoSearchResults
   */
  noResultsSearch?: ReactNode;
};

export const useRenderHits = <THit extends Hit>(
  props: UseRenderHitsProps<THit>,
) => {
  const defaultNoResultsSearch = useMemo(() => {
    return <NoSearchResults />;
  }, []);

  const {
    hits,
    transformBefore,
    render,
    noResultsSearchless,
    noResultsSearch = defaultNoResultsSearch,
  } = props;

  const state = usePreemptState();
  const hasSearchQuery = !!state?.query;

  return useMemo(() => {
    if (hits.length === 0) {
      if (hasSearchQuery) {
        return [
          {
            key: PLACEHOLDER_KEY,
            Node: noResultsSearch,
          },
        ];
      }

      if (noResultsSearchless) {
        return [
          {
            key: PLACEHOLDER_KEY,
            Node: noResultsSearchless,
          },
        ];
      }

      return [];
    }

    const transformedHits = transformBefore ? transformBefore(hits) : hits;
    return renderHits(transformedHits, render);
  }, [
    hits,
    transformBefore,
    render,
    hasSearchQuery,
    noResultsSearchless,
    noResultsSearch,
  ]);
};
