import { Fragment } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { memo } from '../../../util/memo';
import { useAuthProviders } from '../../../hooks/auth/useAuthProviders';
// eslint-disable-next-line import/no-cycle
import { AuthenticationOptions } from './AuthenticationOptions';

const STACK_SPACING = { xs: 1, sm: 2 } as const;

export const AuthenticationStack = memo(
  function AuthenticationStackUnmemoized() {
    const { providerProps } = useAuthProviders();
    const { top = [], generic = [], custom = [] } = providerProps;

    const hasAdditionalOptions = generic.length > 0 || custom.length > 0;

    const bottomOptions = useDeepCompareMemo(() => {
      return [...generic, ...custom];
    }, [custom, generic]);

    return (
      <Stack alignItems={'center'} justifyContent={'center'} py={2} spacing={4}>
        {!!hasAdditionalOptions && (
          <Fragment>
            <Stack
              alignItems={'center'}
              direction="row"
              flexWrap="wrap"
              justifyContent={'space-between'}
              spacing={STACK_SPACING}
              useFlexGap
            >
              <AuthenticationOptions options={bottomOptions} />
            </Stack>
          </Fragment>
        )}
        <Typography
          color={'text.secondary'}
          textAlign={'center'}
          textTransform={'uppercase'}
        >
          Or
        </Typography>
        <AuthenticationOptions options={top} />
      </Stack>
    );
  },
);
