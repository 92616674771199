export const LIVEKIT_COMPONENTS_REACT = '@livekit/components-react' as const;
export type LivekitComponentsReact = typeof LIVEKIT_COMPONENTS_REACT;

export const LIVEKIT_KRISP_NOISE_FILTER =
  '@livekit/krisp-noise-filter' as const;
export type LivekitKrispNoiseFilter = typeof LIVEKIT_KRISP_NOISE_FILTER;

export const STREAM_CHAT_REACT = 'stream-chat-react' as const;
export type StreamChatReact = typeof STREAM_CHAT_REACT;

export const LIVEKIT_CLIENT = 'livekit-client' as const;
export type LivekitClient = typeof LIVEKIT_CLIENT;

export type DynamicModule = {
  [LIVEKIT_KRISP_NOISE_FILTER]: typeof import('@livekit/krisp-noise-filter');
  [LIVEKIT_COMPONENTS_REACT]: typeof import('@livekit/components-react');
  [STREAM_CHAT_REACT]: typeof import('stream-chat-react');
  [LIVEKIT_CLIENT]: typeof import('livekit-client');
};

export type ModuleImportMap = {
  [K in keyof DynamicModule]: () => Promise<DynamicModule[K]>;
};

export const MODULE_IMPORTS: ModuleImportMap = {
  [STREAM_CHAT_REACT]: () => {
    return import('stream-chat-react');
  },
  [LIVEKIT_CLIENT]: () => {
    return import('livekit-client');
  },
  [LIVEKIT_KRISP_NOISE_FILTER]: () => {
    return import('@livekit/krisp-noise-filter');
  },
  [LIVEKIT_COMPONENTS_REACT]: () => {
    return import('@livekit/components-react');
  },
};

export type DynamicImport<TModuleNameList extends (keyof DynamicModule)[]> = {
  [K in TModuleNameList[number]]: DynamicModule[K];
};
