import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { TeamDisplayTooltip } from './TeamDisplayTooltip';
import { Link } from 'src/components/Link';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
// eslint-disable-next-line @blumintinc/blumint/enforce-css-media-queries
import { useMobile } from 'src/hooks/useMobile';
import { TypographyTruncated } from 'src/components/TypographyTruncated';

const LINK_STYLE = { textDecoration: 'none' } as const;
const TEXT_COLOR = 'text.primary' as const;
const MEMBERS_DEFAULT: Member[] = [] as const;

export type UsernameProps = {
  imgUrl: string;
  name: string;
  userIdCaptain: string;
  members?: Member[];
};

const UsernameUnmemoized = ({
  imgUrl,
  name,
  userIdCaptain,
  members = MEMBERS_DEFAULT,
}: UsernameProps) => {
  const isSolo = !!members && members.length === 1;
  // eslint-disable-next-line @blumintinc/blumint/enforce-css-media-queries
  const isMobile = useMobile();

  const content = useMemo(() => {
    return (
      <Stack alignItems="center" flexDirection="row" justifyContent="center">
        <AvatarNext alt={name} src={imgUrl} />
        <TypographyTruncated
          color={TEXT_COLOR}
          sx={{
            pl: '8px',
            pointerEvents: isSolo ? undefined : 'none',
          }}
          text={name}
          textMaxLength={isMobile ? 15 : 20}
          variant="body1"
        />
      </Stack>
    );
  }, [imgUrl, name, isMobile, isSolo]);

  const teamDisplayProps = useMemo(() => {
    return { avatarUrl: imgUrl, members, name } as const;
  }, [imgUrl, members, name]);

  if (!isSolo) {
    return (
      <TeamDisplayTooltip teamDisplayProps={teamDisplayProps}>
        {content}
      </TeamDisplayTooltip>
    );
  }

  if (!userIdCaptain) return content;

  return (
    <Link href={`/${userIdCaptain}`} style={LINK_STYLE}>
      {content}
    </Link>
  );
};

export const Username = memo(UsernameUnmemoized);
