/* eslint-disable @blumintinc/blumint/enforce-boolean-naming-prefixes */
import {
  createContext,
  useCallback,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react';
import type { AudioCaptureOptions } from 'livekit-client';
import { LiveKitRoom } from '@livekit/components-react';
import { assertSafe } from '../../functions/src/util/assertSafe';
import { memo } from '../util/memo';
import { toRoomId } from '../../functions/src/util/liveKit/toRoomId';
import { AudioTracks } from '../components/voice-chat/AudioTracks';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { useAudio } from '../hooks/useAudio';
import { useVoiceChannel } from '../hooks/voice-chat/useVoiceChannel';

export const LEAVE_CALL_AUDIO = '/assets/audio/leave-call.mp3' as const;
export const JOIN_CALL_AUDIO = '/assets/audio/join-call.mp3' as const;
export const AUDIO_CAPTURE_OPTIONS: AudioCaptureOptions = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
  // voiceIsolation: true, NOTE: found this in docs but seems to not be part of AudioCaptureOptions
};
export const ROOM_OPTIONS = { disconnectOnPageLeave: true } as const;

export type VoiceChatMode = 'arena' | 'studio';

export type SetRoomProps = {
  mode: VoiceChatMode;
  roomPath: string;
};

export type RoomState = {
  roomPath: string;
  roomId: string;
} | null;

export type RoomContextProps = {
  setRoom: (props: SetRoomProps) => void;
  rooms: Record<VoiceChatMode, RoomState>;
  isRoomInitialized: (props: SetRoomProps) => boolean;
};

export const RoomContext = createContext<RoomContextProps | undefined>(
  undefined,
);

export const useRoom = () => {
  const context = useContext(RoomContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useRoom must be used within a RoomProvider',
    );
  }
  return context;
};

export type RoomProviderProps = {
  children: ReactNode;
};

const RoomProviderUnmemoized = ({ children }: RoomProviderProps) => {
  const [rooms, setRooms] = useState<Record<VoiceChatMode, RoomState>>({
    arena: null,
    studio: null,
  });

  const { playSound: playConnect } = useAudio(JOIN_CALL_AUDIO);
  const { playSound: playDisconnect } = useAudio(LEAVE_CALL_AUDIO);

  const isRoomInitialized = useCallback(
    ({ mode, roomPath }: SetRoomProps) => {
      return rooms[assertSafe<VoiceChatMode>(mode)]?.roomPath === roomPath;
    },
    [rooms],
  );

  const setRoom = useCallback(
    ({ mode, roomPath }: SetRoomProps) => {
      if (isRoomInitialized({ mode, roomPath })) {
        return;
      }

      setRooms((prev) => {
        return {
          ...prev,
          [mode]: {
            roomPath,
            roomId: toRoomId(roomPath),
          },
        };
      });
    },
    [isRoomInitialized],
  );

  const { voiceChannel } = useVoiceChannel();

  const memoizedValue = useMemo(() => {
    return {
      setRoom,
      rooms,
      isRoomInitialized,
    } as const;
  }, [setRoom, rooms, isRoomInitialized]);

  return (
    <RoomContext.Provider value={memoizedValue}>
      {/* @ts-expect-error typescript complains a ReactNode isn't a valid JSX element */}
      <LiveKitRoom
        audio={AUDIO_CAPTURE_OPTIONS}
        options={ROOM_OPTIONS}
        serverUrl={process.env.NEXT_PUBLIC_LIVEKIT_HOST}
        token={voiceChannel?.roomToken}
        video={false}
        onConnected={playConnect}
        onDisconnected={playDisconnect}
      >
        <AudioTracks />
        {children}
      </LiveKitRoom>
    </RoomContext.Provider>
  );
};

export const RoomProvider = memo(RoomProviderUnmemoized);
