import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { Stage } from '../../../../functions/src/types/Staged';
import { TeamRecord } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';

export type RecordDisplayProps = {
  teamRecord: TeamRecord;
  stage?: Stage;
};

export const RecordDisplay = memo(function RecordDisplayUnmemoized({
  teamRecord,
  stage,
}: RecordDisplayProps) {
  const { wins, losses } = teamRecord;

  const color = useMemo(() => {
    if (stage !== 'complete') {
      return 'text.primary';
    }
    if (wins > losses) {
      return 'success.main';
    }
    if (losses > wins) {
      return 'error.main';
    }
    return 'text.primary';
  }, [stage, wins, losses]);

  return (
    <Typography color={color} variant="body2">
      {`${wins}-${losses}`}
    </Typography>
  );
});
