import type {
  ArrayTwoOrMore,
  ChannelFilters as StreamChannelFilters,
} from 'stream-chat';
import type {
  GroupFilterMap,
  GroupFilter,
} from '../../../types/firestore/User/ChannelGroup';
import { withGetStreamMembers } from '../mapId';

export const toChannelFilters = <TGroupKey extends keyof GroupFilterMap>(
  groupFilter: GroupFilter<TGroupKey>,
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type
): StreamChannelFilters => {
  const groupFilterPrefixed = groupFilter.map((filter) => {
    return withGetStreamMembers(filter as StreamChannelFilters);
  });
  if (groupFilterPrefixed.length === 0) {
    return {};
  }
  if (groupFilterPrefixed.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return groupFilterPrefixed[0]!;
  }
  return {
    $or: groupFilterPrefixed as ArrayTwoOrMore<StreamChannelFilters>,
  };
};
