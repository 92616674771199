import { useEffect, useMemo, useRef } from 'react';
import type { PlainSearchParameters } from 'algoliasearch-helper';
import type { Hit } from 'instantsearch.js';
import {
  cleanAlgoliaState,
  hashAlgoliaParams,
} from '../../../functions/src/util/algolia/config/hashAlgoliaParams';
import {
  AlgoliaCacheLocalStorage,
  CACHE_INVALIDATION_TIMEOUT,
  InfiniteHitsCachedHits,
  useAlgoliaLocalStorage,
} from './useAlgoliaLocalStorage';

export type AlgoliaCacheState = {
  state: PlainSearchParameters;
};

export const useAlgoliaCache = () => {
  const { readCache, writeCache, expungeExpiredCache } =
    useAlgoliaLocalStorage();
  const lastHash = useRef<string | null>(null);

  const cache = useMemo(() => {
    return {
      lastHash: lastHash.current,
      /**
       * undefined indicates that the value may be
       * in IndexDB but IndexDB has not finished initializing.
       */
      read: ({ state }: AlgoliaCacheState) => {
        return readCache(state);
      },
      write: ({
        state,
        hits,
      }: AlgoliaCacheState & { hits: InfiniteHitsCachedHits<Hit> }) => {
        const oldHits = readCache(state) || {};
        const newResult: AlgoliaCacheLocalStorage = {
          data: {
            ...oldHits,
            ...hits,
          },
          state: cleanAlgoliaState(state),
          timestampMillis: Date.now(),
        };
        writeCache(state, newResult);
        lastHash.current = hashAlgoliaParams(state);
      },
      refresh: () => {
        if (lastHash.current !== null) {
          writeCache(lastHash.current, null);
        }
      },
    };
  }, [readCache, writeCache]);

  useEffect(() => {
    expungeExpiredCache();
    const interval = setInterval(
      expungeExpiredCache,
      CACHE_INVALIDATION_TIMEOUT,
    );
    return () => {
      clearInterval(interval);
    };
  }, [expungeExpiredCache]);

  return useMemo(() => {
    return { cache };
  }, [cache]);
};
