export const DANGEROUS_PROPERTY_NAMES = [
  'constructor',
  '__proto__',
  'prototype',
  'toString',
  'valueOf',
  'hasOwnProperty',
  'isPrototypeOf',
  'propertyIsEnumerable',
  'toLocaleString',
] as const;

export type DangerousPropertyName = (typeof DANGEROUS_PROPERTY_NAMES)[number];

export const isDangerousPropertyName = (key: string) => {
  return DANGEROUS_PROPERTY_NAMES.includes(key as DangerousPropertyName);
};
