import { memo } from '../../../util/memo';
import { useVoiceChannelGroupRealtime } from '../../../hooks/voice-chat/useVoiceChannelGroupRealtime';
import { ChannelGroupSidebar } from './ChannelGroupSidebar';
import { VoiceChannelGroupPlaceholder } from './VoiceChannelGroupPlaceholder';

const VoiceChannelGroupSidebarUnmemoized = () => {
  const { voiceChannelGroup } = useVoiceChannelGroupRealtime();

  if (!voiceChannelGroup) {
    return <VoiceChannelGroupPlaceholder />;
  }

  return (
    <ChannelGroupSidebar
      key={voiceChannelGroup.id}
      channelGroup={voiceChannelGroup}
    />
  );
};

export const VoiceChannelGroupSidebar = memo(
  VoiceChannelGroupSidebarUnmemoized,
);
