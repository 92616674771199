import { useEffect, useRef } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { isTemporaryPermanence } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isTemporary';
import {
  DynamicModule,
  LivekitComponentsReact,
} from '../../../functions/src/types/DynamicModule';
import { useConnectionStatus } from './useConnectionStatus';
import { useVoiceChannel } from './useVoiceChannel';

export type UseEstablishLivekitAudioProps = {
  livekitModule: DynamicModule[LivekitComponentsReact];
};

export const useEstablishLivekitAudio = ({
  livekitModule,
}: UseEstablishLivekitAudioProps) => {
  const { useRoomContext, useAudioPlayback } = livekitModule;

  const room = useRoomContext();
  const audioPlayback = useAudioPlayback(room);

  const connectionStatus = useConnectionStatus({ livekitModule });
  const { channelGroupActive } = useActiveChannelGroup();
  const { voiceChannel } = useVoiceChannel();

  const { canPlayAudio, startAudio } = audioPlayback;

  const hasEstablishedAudioRef = useRef(false);

  useEffect(() => {
    const isConnected = connectionStatus === 'connected';

    if (
      !voiceChannel?.roomToken ||
      !canPlayAudio ||
      !isConnected ||
      hasEstablishedAudioRef.current
    ) {
      return;
    }

    const establishAudioAndChannelGroup = async () => {
      await startAudio();
      hasEstablishedAudioRef.current = true;

      const { permanence, id } = channelGroupActive || {};

      if (!id || !permanence || !isTemporaryPermanence(permanence)) {
        return;
      }
      const { setChannelGroupPermanence } = await import(
        '../../firebaseCloud/messaging/setChannelGroupPermanence'
      );
      await setChannelGroupPermanence({
        channelGroupId: id,
        permanence: 'pinned',
      });
    };

    establishAudioAndChannelGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    channelGroupActive?.id,
    channelGroupActive?.permanence,
    connectionStatus,
    canPlayAudio,
    voiceChannel?.roomToken,
    startAudio,
  ]);
};
