import Button from '@mui/material/Button';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { useMemo, useCallback, Fragment } from 'react';
import { useActiveTournamentChannel } from '../../../hooks/messaging/useActiveTournamentChannel';
import { useAuth } from '../../../contexts/AuthContext';
import { memo } from '../../../util/memo';
import { withDynamicImport } from '../../../util/withDynamicImport';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import {
  StreamChatReact,
  STREAM_CHAT_REACT,
  DynamicImport,
} from '../../../../functions/src/types/DynamicModule';

export type ChannelNavigationButtonProps = {
  tournamentId?: string;
  modules: DynamicImport<[StreamChatReact]>;
};

const ChannelNavigationButtonUnmemoized = ({
  tournamentId,
  modules,
}: ChannelNavigationButtonProps) => {
  const { userData } = useAuth();
  const { useChatContext } = modules[assertSafe(STREAM_CHAT_REACT)];
  const { channel } = useChatContext();

  const { openTeamChannel, openMatchChannel, teamChannelGroup } =
    useActiveTournamentChannel(tournamentId);

  const matchId = useMemo(() => {
    return userData?.activeTournament?.match;
  }, [userData?.activeTournament?.match]);

  const openChannel = useCallback(() => {
    const { type } = channel || {};

    if (!type) {
      return;
    }

    if (type === 'match' || type === 'general') {
      return openTeamChannel();
    }

    if (!matchId) {
      return;
    }

    openMatchChannel(matchId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMatchChannel, openTeamChannel, matchId, channel?.type]);

  const label = useMemo(() => {
    const { type, data } = channel || {};

    if ((type === 'match' || type === 'general') && !!teamChannelGroup) {
      return 'Team Chat';
    }

    if (type !== 'personalGroup' || !(data?.tournamentId && matchId)) {
      return;
    }

    return 'Match Lobby';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.data, channel?.type, matchId, teamChannelGroup]);

  return label ? (
    <Button
      endIcon={<ArrowOutwardRoundedIcon />}
      sx={{
        py: 0,
        px: 2,
        width: 'fit-content',
        borderRadius: '4px',
      }}
      variant="contained"
      onClick={openChannel}
    >
      {label}
    </Button>
  ) : (
    <Fragment />
  );
};

export const ChannelNavigationButton = memo(
  withDynamicImport({
    Component: ChannelNavigationButtonUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
