import { Timestamp } from 'firebase-admin/firestore';
import {
  ChannelGroup,
  PERSONAL_TYPES,
  PersonalType,
  ChannelGroupFilterType,
  GroupFilterMap,
  GroupFilter,
} from '..';

export function isPersonal<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<Capitalize<PersonalType>, TTime> {
  const { groupFilter } = channelGroup;
  return (
    groupFilter.length === 1 &&
    PERSONAL_TYPES.includes(groupFilter[0].type as PersonalType)
  );
}

export function isSpecificPersonal<
  TType extends PersonalType,
  TTime = Timestamp,
>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
  type: TType,
): channelGroup is ChannelGroup<Capitalize<TType>, TTime> {
  const { groupFilter } = channelGroup;
  return groupFilter.length === 1 && groupFilter[0].type === type;
}

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export function isPersonalType(
  type: ChannelGroupFilterType,
): type is ChannelGroupFilterType {
  return PERSONAL_TYPES.includes(type as PersonalType);
}

export function isPersonalFilter(
  filter: GroupFilter,
): filter is GroupFilter<Capitalize<PersonalType>> {
  return (
    filter.length === 1 &&
    isPersonalType(filter[0].type) &&
    'groupId' in filter[0]
  );
}
