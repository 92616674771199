import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { formatDuration } from '../../../../functions/src/util/date/formatDuration';
import { Score } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';

export const GAME_STATUS = ['winner', 'loser'] as const;
export type GameOutcomeStatus = (typeof GAME_STATUS)[number];

export type ScoreProps = {
  score: Omit<Score, 'index'>;
  isTwoTeams?: boolean;
  status?: GameOutcomeStatus;
};

const ScoreUnmemoized = ({
  score: { value, units },
  isTwoTeams,
  status,
}: ScoreProps) => {
  const color = useMemo(() => {
    if (status === 'winner') return 'success.main';
    if (status === 'loser') return 'error.main';
    return 'primary.text';
  }, [status]);

  const isHeadToHeadWinner = isTwoTeams && status === 'winner';
  const isHeadToHeadLoser = isTwoTeams && status === 'loser';
  const formattedScore = useMemo(() => {
    if (isHeadToHeadWinner) {
      return 'W';
    }
    if (isHeadToHeadLoser) {
      return 'L';
    }
    if (units === 'time') {
      return formatDuration(value);
    }
    return value;
  }, [units, value, isHeadToHeadWinner, isHeadToHeadLoser]);

  const sx = useMemo(() => {
    return status === 'winner' && { sx: { fontWeight: 700 } };
  }, [status]);

  return (
    <Typography
      variant={status === 'winner' ? 'body1' : 'body2'}
      {...sx}
      color={color}
    >
      {formattedScore}
    </Typography>
  );
};

export const ScoreDisplay = memo(ScoreUnmemoized);
