import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { ChipUser } from '../../ChipUser';

export type RemoveFriendDialogDescriptionProps = {
  imgUrl: string;
  username: string;
  userId: string;
};

const RemoveFriendDialogDescriptionUnmemoized = ({
  imgUrl,
  userId,
  username,
}: RemoveFriendDialogDescriptionProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="center"
      spacing={2}
    >
      <Typography>Are you sure you want to remove</Typography>
      <ChipUser avatarUrl={imgUrl} href={`/${userId}`} username={username} />
      <Typography>as a friend?</Typography>
    </Stack>
  );
};

export const RemoveFriendDialogDescription = memo(
  RemoveFriendDialogDescriptionUnmemoized,
);
