import { Member, Team } from '../Team';

export function toName<TTime = Date>(team: Team<TTime>, anyLength = false) {
  const { name, members } = team;
  if (anyLength || members.length > 1 || members.length === 0) {
    return name;
  }
  const captain = members.find(({ status }) => {
    return status === 'captain';
  });

  return captain?.username || members[0]?.username || name;
}

export function toAvatar(team: Team, teamLength = 1) {
  const { avatarUrl, members } = team;
  if (teamLength > 1) {
    return avatarUrl;
  }
  return (
    members.find(({ status }) => {
      return status === 'captain';
    })?.imgUrl ||
    members.find(({ imgUrl }) => {
      return !!imgUrl;
    })?.imgUrl ||
    avatarUrl
  );
}

export const NOT_ON_TEAM_STATUSES = ['pending', 'declined'] as const;

export function isOnTeam({ status }: Pick<Member, 'status'>) {
  return !NOT_ON_TEAM_STATUSES.includes(
    status as (typeof NOT_ON_TEAM_STATUSES)[number],
  );
}
