export type Animation = 'shake' | 'bounce' | 'flash' | 'pulse' | 'wobble';

export const DEFAULT_ANIMATION_DURATION = '0.5s' as const;
export const DEFAULT_ANIMATION_ITERATION = 'infinite' as const;

type KeyframeDefinition = {
  transform?: string;
  opacity?: number;
};

type AnimationKeyframes = {
  [key: `@keyframes ${string}`]: {
    [percentage: string]: KeyframeDefinition;
  };
};

export const ANIMATION_KEYFRAMES: Record<Animation, AnimationKeyframes> = {
  shake: {
    '@keyframes shake': {
      '0%': { transform: 'translateX(0)' },
      '25%': { transform: 'translateX(5px)' },
      '75%': { transform: 'translateX(-5px)' },
      '100%': { transform: 'translateX(0)' },
    },
  },
  bounce: {
    '@keyframes bounce': {
      '0%, 100%': { transform: 'translateY(0)' },
      '50%': { transform: 'translateY(-10px)' },
    },
  },
  flash: {
    '@keyframes flash': {
      '0%, 100%': { opacity: 1 },
      '50%': { opacity: 0.5 },
    },
  },
  pulse: {
    '@keyframes pulse': {
      '0%': { transform: 'scale(1)' },
      '50%': { transform: 'scale(1.05)' },
      '100%': { transform: 'scale(1)' },
    },
  },
  wobble: {
    '@keyframes wobble': {
      '0%': { transform: 'rotate(0deg)' },
      '25%': { transform: 'rotate(5deg)' },
      '75%': { transform: 'rotate(-5deg)' },
      '100%': { transform: 'rotate(0deg)' },
    },
  },
};
