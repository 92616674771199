import { useMemo } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { KeyedNode } from '../../../functions/src/types/Hit';
import {
  useNearEndObserver,
  UseNearEndObserverOptions,
} from '../visibility/useNearEndObserver';
import { useLoadingWrapper } from '../useLoadingWrapper';

export const PLACEHOLDER_KEY = 'placeholder' as const;

export type NearEndContentProps = Omit<
  UseNearEndObserverOptions,
  'onNearEnd'
> & {
  content: KeyedNode[];
  /**
   * NOTE be careful to memoize onNearEnd!
   */
  onNearEnd?: () => Promise<unknown>;
  /**
   * NOTE be careful to memoize wrapperProps!
   *
   * @param key the key of the wrapped element in Content
   * @param index the index of the wrapped element in Content
   * @returns props to pass to the wrapped element
   */
  wrapperProps?: BoxProps | ((key: string, index: number) => BoxProps);
};

export const useNearEndContent = ({
  content,
  onNearEnd,
  wrapperProps,
  ...observerProps
}: NearEndContentProps) => {
  const {
    LoadingWrapper,
    isLoading,
    funcWrapped: onNearEndWrapped,
  } = useLoadingWrapper(onNearEnd);

  const { setNearEnd } = useNearEndObserver({
    onNearEnd: onNearEndWrapped,
    ...observerProps,
  });

  const NearEndContentWithoutLoading = useMemo(() => {
    return content.map(({ key, Node }, index) => {
      const isNearEndTrigger =
        index === content.length - 1 && key !== PLACEHOLDER_KEY;
      const props =
        typeof wrapperProps === 'function'
          ? wrapperProps(key, index)
          : wrapperProps;

      return (
        <Box
          key={key}
          ref={isNearEndTrigger ? setNearEnd : undefined}
          {...props}
        >
          {Node}
        </Box>
      );
    });
  }, [content, setNearEnd, wrapperProps]);

  const loadingFooter = useMemo(() => {
    return (
      content.length > 1 && (
        <Stack
          key="loading"
          sx={{
            minHeight: '46px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            bottom: 0,
            position: 'sticky',
            // eslint-disable-next-line @blumintinc/blumint/no-compositing-layer-props
            opacity: 0.5,
          }}
        >
          <LoadingWrapper sx={{ height: '32px', width: '32px' }} />
        </Stack>
      )
    );
  }, [LoadingWrapper, content.length]);

  const nearEndContent = useMemo(() => {
    return loadingFooter
      ? [...NearEndContentWithoutLoading, loadingFooter]
      : NearEndContentWithoutLoading;
  }, [NearEndContentWithoutLoading, loadingFooter]);

  return {
    nearEndContent,
    isLoading,
  } as const;
};
