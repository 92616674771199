import { createContext, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import { useAuth } from '../AuthContext';
import { useDocSnapshot } from '../../hooks/firestore/useDocSnapshot';
import { toTournamentPath } from '../../../functions/src/types/firestore/Game/Tournament/path';

export type ActiveTournamentContextProps =
  | { tournament?: Tournament<Date> }
  | undefined;

export const ActiveTournamentContext = createContext<
  ActiveTournamentContextProps | undefined
>(undefined);

export const useActiveTournament = () => {
  const context = useContext(ActiveTournamentContext);
  if (!context) {
    throw new Error(
      'useActiveTournament must be used within an ActiveTournamentProvider',
    );
  }
  return context;
};

export type ActiveTournamentProviderProps = {
  children: ReactNode;
};

export const ActiveTournamentProvider = memo(
  function ActiveTournamentProviderUnmemoized({
    children,
  }: ActiveTournamentProviderProps) {
    const { userData } = useAuth();

    const tournamentId = userData?.activeTournament?.tournamentId;
    const gameId = userData?.activeTournament?.gameId;
    const tournamentPath = useMemo(() => {
      if (!tournamentId || !gameId) {
        return;
      }
      return toTournamentPath({
        gameId,
        tournamentId,
      });
    }, [gameId, tournamentId]);

    const tournament = useDocSnapshot<Tournament<Date>>({
      docPath: tournamentPath,
    });

    const tournamentData = useMemo(() => {
      return { tournament } as const;
    }, [tournament]);

    return (
      <ActiveTournamentContext.Provider value={tournamentData}>
        {children}
      </ActiveTournamentContext.Provider>
    );
  },
);
