import { ResponsiveSize } from '../../components/image/ImageOptimized';
import { assertSafe } from 'functions/src/util/assertSafe';

export const BADGE_SCALE_FACTOR = 0.3 as const;

export const determineBadgeSize = (size?: string | number | ResponsiveSize) => {
  if (!size) {
    return '10px';
  }

  if (typeof size === 'number') {
    return `${size * BADGE_SCALE_FACTOR}px`;
  }

  if (typeof size === 'object') {
    const responsiveSize: ResponsiveSize = {};

    // eslint-disable-next-line no-restricted-properties
    for (const [breakpoint, value] of Object.entries(size)) {
      responsiveSize[assertSafe(breakpoint)] = value * BADGE_SCALE_FACTOR;
    }

    return responsiveSize;
  }

  return `calc(${size} * 0.3)`;
};
