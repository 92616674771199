import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { memo } from '../util/memo';
import { useWebSocketWarningSnackbar } from '../hooks/useWebSocketWarningSnackbar';
import { useActionHandler } from '../hooks/useActionHandler';
import { useAppUrlOpen } from '../hooks/capacitor/useAppUrlOpen';
import { useOAuthCustomApp } from '../hooks/auth/useOAuthCustomApp';
import { useTemporaryChannelGroupSyncer } from '../hooks/messaging/useTemporaryChannelGroupSyncer';
import { useDeleteAccount } from '../hooks/useDeleteAccount';
import { useDatadog } from '../hooks/useDatadog';
import { useShowAdBlock } from '../hooks/ads/useShowAdBlock';
import { useVerifyEmail } from '../hooks/auth/useVerifyEmail';
import { useNotificationSnackbar } from '../hooks/notification/useNotificationSnackbar';
import { useElectronDeepLinking } from '../hooks/routing/useElectronDeepLinking';
import { useActiveGameUpdates } from '../hooks/electron/useActiveGameUpdates';
import { useNotificationRouter } from '../hooks/notification/useNotificationRouter';
import { useFcmTokenEventListener } from '../hooks/fcm-token/useFcmTokenEventListener';
import { useFcmTokenConfigurer } from '../hooks/fcm-token/useFcmTokenConfigurer';
import { usePictureInPictureListener } from '../hooks/mux-player/usePictureInPictureListener';
import { LivekitDynamic } from './dynamic/LivekitDynamic';
import { AdFooter } from './ads/AdFooter';
import { Sidebar } from './Sidebar';
import { HeaderTabbed } from './header/HeaderTabbed';
import { Framework } from './Framework';
import { Explore } from './Explore';
import { GlobalVideoPlayer } from './livestream/GlobalVideoPlayer';

export type MainLayoutProps = {
  children: ReactNode;
};

const MainLayoutUnmemoized = ({ children }: MainLayoutProps) => {
  useElectronDeepLinking();
  useActiveGameUpdates();
  useTemporaryChannelGroupSyncer();
  useWebSocketWarningSnackbar();
  useVerifyEmail();
  useActionHandler();
  useAppUrlOpen();
  useOAuthCustomApp();
  useDeleteAccount();
  useDatadog();
  useShowAdBlock();
  useNotificationRouter();
  useNotificationSnackbar();
  useFcmTokenEventListener();
  useFcmTokenConfigurer();
  usePictureInPictureListener();

  return (
    <Stack
      height="100dvh"
      // This is so the actual footer won't be hidden under the footer ad.
      // eslint-disable-next-line @blumintinc/blumint/no-margin-properties
      marginTop="-24px !important"
      overflow="hidden"
    >
      <HeaderTabbed />
      <Framework
        Explore={<Explore>{children}</Explore>}
        Sidebar={<Sidebar />}
        sx={{
          flexGrow: 1,
          minHeight: 0,
        }}
      />
      <AdFooter />
      <LivekitDynamic />
      <GlobalVideoPlayer />
    </Stack>
  );
};

export const MainLayout = memo(MainLayoutUnmemoized);
