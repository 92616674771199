import { useCallback, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { LeaderboardStandard } from '../LeaderboardStandard';
import { useMatchDetails } from '../../../contexts/MatchDetailsContext';
import {
  RESULTS_PAGE_SIZE_DEFAULT,
  useResults,
} from '../../../contexts/ResultsContext';
import { ResultSummary } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary';
import { useTeamColumn } from '../../../hooks/leaderboards/useTeamColumn';
import { useRecordColumn } from '../../../hooks/leaderboards/useRecordColumn';
import { MatchSummaryTitle } from './MatchSummaryTitle';
import { PAYOUT_COLUMN } from './PayoutGridColDef';
import { memo } from 'src/util/memo';

export const LEADERBOARD_PAGINATION_MODE_SERVER = 'server' as const;
export const LEADERBOARD_STANDARD_SX = {
  '& .MuiDataGrid-row': { border: 'none' },
} as const;

const MatchSummaryTableUnmemoized = () => {
  const { teamCount } = useMatchDetails();
  const { results, page, setPage } = useResults<ResultSummary>();

  const teamColumn = useTeamColumn<ResultSummary>();
  const recordColumn = useRecordColumn();
  const columns = useMemo(() => {
    const value: GridColDef<ResultSummary>[] = [
      teamColumn,
      recordColumn,
      PAYOUT_COLUMN,
    ];
    return value;
  }, [teamColumn, recordColumn]);

  const calculateRowSpacing = useCallback(() => {
    return { top: 5, bottom: 5 } as const;
  }, []);

  return (
    <LeaderboardStandard
      autoHeight
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      getRowSpacing={calculateRowSpacing}
      page={page}
      pageSize={RESULTS_PAGE_SIZE_DEFAULT}
      pagination
      paginationMode={LEADERBOARD_PAGINATION_MODE_SERVER}
      rowCount={teamCount}
      rows={results}
      sx={LEADERBOARD_STANDARD_SX}
      title={<MatchSummaryTitle />}
      onPageChange={setPage}
    />
  );
};

export const MatchSummaryTable = memo(MatchSummaryTableUnmemoized);
