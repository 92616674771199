import { useMemo } from 'react';
import { useIndexingHits } from '../../contexts/algolia/IndexingHitsContext';
import { toRealtimeTemplateHash } from '../../../functions/src/util/algoliaRealtime/toRealtimeTemplateHash';
import { Hit } from '../../../functions/src/types/Hit';

type RemoveHitsParams = {
  hits: Hit[];
  hitsBeingRemoved: string[];
};

const removeHitsFromArray = ({ hits, hitsBeingRemoved }: RemoveHitsParams) => {
  if (hitsBeingRemoved.length === 0) {
    return hits;
  }

  return hits.filter((hit) => {
    return !hitsBeingRemoved.includes(hit.objectID);
  });
};

type AddHitsParams = {
  hits: Hit[];
  hitsBeingAdded: Record<string, Hit>;
};

const addOrUpdateHits = ({ hits, hitsBeingAdded }: AddHitsParams) => {
  if (Object.keys(hitsBeingAdded).length === 0) {
    return hits;
  }

  const updatedExistingHits = hits.map((hit) => {
    const updatedHit = hitsBeingAdded[hit.objectID];
    return updatedHit || hit;
  });

  const newHits = Object.values(hitsBeingAdded).filter((hitToAdd) => {
    return !hits.some((hit) => {
      return hit.objectID === hitToAdd.objectID;
    });
  });

  return [...updatedExistingHits, ...newHits];
};

export const useMergeIndexingHits = (
  hitsConverted: Hit[],
  filters?: string,
) => {
  const templateFilledHash = toRealtimeTemplateHash(filters);
  const { indexingHits } = useIndexingHits();

  return useMemo(() => {
    if (!templateFilledHash || !indexingHits?.[String(templateFilledHash)]) {
      return hitsConverted;
    }

    const { hitsBeingAdded, hitsBeingRemoved } =
      indexingHits[String(templateFilledHash)];

    const hitsAfterRemoval = removeHitsFromArray({
      hits: hitsConverted,
      hitsBeingRemoved,
    });
    return addOrUpdateHits({ hits: hitsAfterRemoval, hitsBeingAdded });
  }, [templateFilledHash, indexingHits, hitsConverted]);
};
