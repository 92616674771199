import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { memo } from '../util/memo';
import { useTrackPresence } from '../hooks/presence/useTrackPresence';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export type UserPresenceContextProps = {
  addUserPresence: (elementId: string) => Promise<void>;
  removeUserPresence: (elementId: string) => Promise<void>;
};

export const UserPresenceContext = createContext<
  UserPresenceContextProps | undefined
>(undefined);

export type UserPresenceProviderProps = {
  children: ReactNode;
};

export const useUserPresence = () => {
  const context = useContext(UserPresenceContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useUserPresence must be used within a UserPresenceProvider',
    );
  }
  return context;
};

const UserPresenceProviderUnmemoized: FC<UserPresenceProviderProps> = ({
  children,
}) => {
  const updatePresence = useTrackPresence();
  const elementIds = useRef<Set<string>>(new Set());

  const addUserPresence = useCallback(
    async (elementId: string) => {
      if (!elementIds.current.has(elementId)) {
        await updatePresence(elementId, 'add');
        elementIds.current.add(elementId);
      }
    },
    [updatePresence, elementIds],
  );

  const removeUserPresence = useCallback(
    async (elementId: string) => {
      if (elementIds.current.has(elementId)) {
        await updatePresence(elementId, 'remove');
        elementIds.current.delete(elementId);
      }
    },
    [updatePresence, elementIds],
  );

  const memoizedValue = useMemo(() => {
    return { addUserPresence, removeUserPresence };
  }, [addUserPresence, removeUserPresence]);

  return (
    <UserPresenceContext.Provider value={memoizedValue}>
      {children}
    </UserPresenceContext.Provider>
  );
};

export const UserPresenceProvider = memo(UserPresenceProviderUnmemoized);
