import { Fragment } from 'react';
import { memo } from '../../util/memo';
import { NormalizedTab } from '../../util/normalizeTab';
import { NormalizedChipTab, NormalizedChipTabProps } from './NormalizedChipTab';

export type NormalizedChipTabsProps = {
  tabs: NormalizedTab[];
} & Pick<NormalizedChipTabProps, 'activeTab' | 'routeTab' | 'variant'>;

export const NormalizedChipTabs = memo(function NormalizedChipTabs({
  tabs,
  activeTab,
  routeTab,
  variant,
}: NormalizedChipTabsProps) {
  return (
    <Fragment>
      {tabs.map((tabNormalized) => {
        return (
          <NormalizedChipTab
            {...tabNormalized}
            key={tabNormalized.id}
            activeTab={activeTab}
            routeTab={routeTab}
            variant={variant}
          />
        );
      })}
    </Fragment>
  );
});
