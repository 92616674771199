import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';

const ChannelGroupDividerUnmemoized = () => {
  const theme = useTheme();

  return (
    <Divider
      sx={{
        color: theme.palette.border,
        height: '1px',
        width: {
          xs: '44px',
          md: '64px',
        },
      }}
    />
  );
};

export const ChannelGroupDivider = memo(ChannelGroupDividerUnmemoized);
