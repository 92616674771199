import { useEffect, useState } from 'react';
import { IS_ELECTRON } from '../../util/platform';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export function useShowCMP() {
  const [shouldShowPrivacySettings, setShouldShowPrivacySettings] =
    useState(false);

  useEffect(() => {
    const isCMPRequired = async () => {
      if (!IS_ELECTRON || !window.electron) {
        return;
      }

      try {
        const isRequired = await window.electron.isCMPRequired();
        setShouldShowPrivacySettings(isRequired);
      } catch (error) {
        throw new HttpsError(
          'internal',
          'Error checking if CMP is required',
          `${error}`,
        );
      }
    };

    isCMPRequired();
  }, []);

  return shouldShowPrivacySettings;
}
