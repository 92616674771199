import { useCallback } from 'react';
import {
  GROUP_CHANNEL_TYPE,
  GroupFilterMap,
  GroupFilter,
  ChannelGroup,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { useTemporaryChannelGroups } from './useTemporaryChannelGroups';

export const useFilterTemporaryChannelGroups = () => {
  const { temporaryChannelGroups } = useTemporaryChannelGroups();

  const isTemporaryForSession = useCallback(
    (groupFilter: GroupFilter) => {
      if (!temporaryChannelGroups) {
        return false;
      }

      // eslint-disable-next-line no-restricted-properties
      return Object.entries(temporaryChannelGroups).some(
        ([identifierKey, identifier]) => {
          const groupFilterEntry = groupFilter[0];

          const channelGroupIdentifier =
            groupFilterEntry[assertSafe(identifierKey)];

          return (
            channelGroupIdentifier &&
            (identifier === channelGroupIdentifier ||
              (groupFilterEntry.type === GROUP_CHANNEL_TYPE &&
                channelGroupIdentifier.$in.includes(identifier)))
          );
        },
      );
    },
    [temporaryChannelGroups],
  );

  const filterTemporaryChannelGroups = useCallback(
    (channelGroups: ChannelGroup<keyof GroupFilterMap, Date>[]) => {
      return channelGroups.filter(({ permanence, groupFilter }) => {
        return permanence !== 'temporary' || isTemporaryForSession(groupFilter);
      });
    },
    [isTemporaryForSession],
  );

  return {
    filterTemporaryChannelGroups,
  } as const;
};
