import { useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { memo } from '../../util/memo';
import { useAuthSubmit } from '../../contexts/AuthSubmitContext';
// eslint-disable-next-line no-restricted-imports
import type { EditablePhoneProps } from '../edit/EditablePhone.dynamic';

const EditablePhone = dynamic(async () => {
  const mod = await import('../edit/EditablePhone.dynamic');
  return mod.EditablePhone;
});

const CUSTOM_INPUT_PROPS: EditablePhoneProps['inputProps'] = {
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  required: true,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  autoFocus: true,
};

const CUSTOM_INPUT_STYLE: EditablePhoneProps['inputStyle'] = {
  height: '44px',
} as const;

export type PhoneNumberInputProps = Omit<
  EditablePhoneProps,
  'onChange' | 'value' | 'inputProps' | 'containerClass'
>;

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const PhoneNumberInput = memo(function PhoneNumberInputUnmemoized({
  inputStyle,
  ...props
}: PhoneNumberInputProps) {
  const { onInputChange, userCredentials } = useAuthSubmit();
  const { phoneNumber } = userCredentials;

  const change = useCallback(
    (newValue?: string) => {
      // eslint-disable-next-line no-restricted-syntax
      onInputChange('phoneNumber', newValue || '');
    },
    [onInputChange],
  );

  const inputStyleCombined = useMemo(() => {
    return {
      ...CUSTOM_INPUT_STYLE,
      ...inputStyle,
    };
  }, [inputStyle]);

  return (
    <EditablePhone
      containerClass="input-gradient-border"
      inputProps={CUSTOM_INPUT_PROPS}
      inputStyle={inputStyleCombined}
      value={phoneNumber}
      onChange={change}
      {...props}
    />
  );
});
