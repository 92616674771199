import { useMemo } from 'react';
import { ButtonProps } from '@mui/material/Button';
import { memo } from '../../../util/memo';
import { useScoringButtonDetails } from '../../../hooks/messaging/useScoringButtonDetails';
import { useActiveMatch } from '../../../contexts/active-tournament/ActiveMatchContext';
import { useAuth } from '../../../contexts/AuthContext';
import { isCurrentSessionScoredBy } from '../../../../functions/src/util/tournaments/moderation/isCurrentSessionScoredBy';
import { ActiveMatchButtonBase } from './ActiveMatchButtonBase';

export const SCORING_BUTTON_MAX_WIDTH = 154 as const;

export type ActiveMatchButtonProps = Omit<
  ButtonProps,
  'onClick' | 'startIcon' | 'children'
>;
export const ActiveMatchButton = memo(function ActiveMatchButtonUnmemoized({
  ...rest
}: ActiveMatchButtonProps) {
  const activeMatchContext = useActiveMatch();
  const { uid } = useAuth();
  const { activeResults, activeSessionIndex = 0 } = activeMatchContext || {};

  const team1Result = activeResults?.[0];
  const team2Result = activeResults?.[1];
  const team1 = team1Result?.team;
  const team2 = team2Result?.team;

  const team1Scores = team1Result?.scoresMatch;
  const team2Scores = team2Result?.scoresMatch;

  const sessionScored = useMemo(() => {
    return isCurrentSessionScoredBy(
      activeSessionIndex,
      team1Scores,
      team2Scores,
      [team1?.acceptedUserIds || [], team2?.acceptedUserIds || []],
      uid,
    );
  }, [activeSessionIndex, team1Scores, team2Scores, team1, team2, uid]);

  const { text: scoreButtonText, icon: startIcon } = useScoringButtonDetails();

  return (
    <ActiveMatchButtonBase
      color={sessionScored ? 'secondary' : undefined}
      startIcon={startIcon}
      {...rest}
    >
      {scoreButtonText}
    </ActiveMatchButtonBase>
  );
});
