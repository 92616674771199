import { useState, useEffect } from 'react';

export const useRealtimeCount = (path?: string) => {
  const [count, setCount] = useState<number | 'unknown'>('unknown');

  useEffect(() => {
    if (!path) {
      return;
    }

    let unsubscribe: (() => void) | undefined;

    const subscribeRealtimeCount = async () => {
      const { ref, onValue } = await import('firebase/database');
      const { database } = await import(
        '../../config/firebase-client/database'
      );

      const countRef = ref(database, path);

      unsubscribe = onValue(countRef, (snapshot) => {
        setCount(snapshot.val() || 0);
      });
    };

    subscribeRealtimeCount();

    return () => {
      unsubscribe?.();
    };
  }, [path]);

  return count;
};
