export type ActionButtonProps = {
  label: string;
  href: string;
};

export function transformAttributesToActionButtonProps(
  attributes: Record<string, string>,
) {
  // eslint-disable-next-line no-restricted-properties
  return Object.entries(attributes)
    .sort(([a], [b]) => {
      return a.localeCompare(b);
    })
    .map(([label, href]) => {
      return {
        label,
        href,
      } as const;
    });
}
