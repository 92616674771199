import { ReactNode, Fragment, FC } from 'react';
import { SxProps } from '@mui/material/styles';
import { memo } from '../util/memo';
import { LottieLoader } from './LottieLoader';

export type LoadingComponentProps = {
  children?: ReactNode;
  sx?: SxProps;
};

export type LoadingWrapperProps = {
  sx?: SxProps;
  isLoading: boolean;
  children?: ReactNode;
  LoadingComponent?: FC<LoadingComponentProps>;
};

const LoadingWrapperUnmemoized = ({
  isLoading,
  children,
  sx,
  LoadingComponent = LottieLoader,
}: LoadingWrapperProps) => {
  if (isLoading) {
    return <LoadingComponent sx={sx}>{children}</LoadingComponent>;
  }

  return <Fragment>{children}</Fragment>;
};

export const LoadingWrapper = memo(LoadingWrapperUnmemoized);
