import {
  WizardDialog,
  WizardDialogProps,
} from 'src/components/wizard/WizardDialog';
import { createUseWizard } from 'src/hooks/wizard/createUseWizard';

const WIZARD_DIALOG_ID = 'WIZARD_DIALOG' as const;

export const useWizardDialog = createUseWizard<WizardDialogProps>({
  WrapperComponent: WizardDialog,
  wrapperId: WIZARD_DIALOG_ID,
});
