import { useCallback } from 'react';
import { Props as SetPersonalChannelGroupProps } from '../../../functions/src/callable/messaging/setPersonalChannelGroup.f';
import type { Props as ExitChannelGroupProps } from '../../../functions/src/callable/messaging/exitChannelGroup.f';

export const usePersonalChannelGroup = () => {
  const set = useCallback(
    async ({
      imgUrl,
      title,
      subtitle,
      type,
      friendIds,
      messagesInitial,
    }: SetPersonalChannelGroupProps) => {
      const { setPersonalChannelGroup } = await import(
        'src/firebaseCloud/messaging/setPersonalChannelGroup'
      );
      const channelGroup = await setPersonalChannelGroup({
        imgUrl,
        title,
        subtitle,
        type,
        friendIds,
        messagesInitial,
      });
      return channelGroup;
    },
    [],
  );

  const remove = useCallback(async ({ groupFilter }: ExitChannelGroupProps) => {
    const { exitChannelGroup } = await import(
      'src/firebaseCloud/messaging/exitChannelGroup'
    );
    await exitChannelGroup({ groupFilter });
  }, []);

  return { set, remove } as const;
};
