import { ColorSpreader } from './ColorSpreader';

const PRIMARY_HUE = 199 as const;
const PRIMARY_DARK_HUE = 214 as const;
const SECONDARY_HUE = 176 as const;
const ERROR_HUE = 15 as const;
const WARNING_HUE = 40 as const;
const INFO_HUE = PRIMARY_HUE;
const INFO_DARK_HUE = PRIMARY_DARK_HUE;
const SUCCESS_HUE = SECONDARY_HUE;
const ROYAL_HUE = 275 as const;
const ROYAL_LIGHTNESS = 80 as const;

export const COLORS = {
  primary: ColorSpreader.apply({
    lightHue: PRIMARY_HUE,
    darkHue: PRIMARY_DARK_HUE,
  }),
  secondary: ColorSpreader.apply({
    lightHue: SECONDARY_HUE,
  }),
  error: ColorSpreader.apply({
    lightHue: ERROR_HUE,
  }),
  warning: ColorSpreader.apply({
    lightHue: WARNING_HUE,
  }),
  info: ColorSpreader.apply({
    lightHue: INFO_HUE,
    darkHue: INFO_DARK_HUE,
  }),
  success: ColorSpreader.apply({
    lightHue: SUCCESS_HUE,
  }),
  royal: ColorSpreader.apply({
    lightHue: ROYAL_HUE,
    lightness: ROYAL_LIGHTNESS,
  }),
} as const;

export type SpreadColorKey = keyof typeof COLORS;
