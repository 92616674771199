import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { LivestreamFieldEditable } from './LivestreamFieldEditable';

export const LIVESTREAM_DESCRIPTION_PLACEHOLDER =
  'Enter a description for your livestream' as const;

export type LivestreamDescriptionEditableProps = {
  streamerId: string;
};

const LivestreamDescriptionEditableUnmemoized = ({
  streamerId,
}: LivestreamDescriptionEditableProps) => {
  const theme = useTheme();

  return (
    <LivestreamFieldEditable
      fieldName="description"
      streamerId={streamerId}
      sx={{
        color: theme.palette.text.secondary,
      }}
      variant="subtitle2"
    />
  );
};

export const LivestreamDescriptionEditable = memo(
  LivestreamDescriptionEditableUnmemoized,
);
