import { memo } from '../../../util/memo';
import {
  FriendRequestsBadge,
  FriendRequestsBadgeProps,
} from './FriendRequestsBadge';

export const FriendRequestBadgeNavigation = memo(
  function FriendRequestBadgeNavigationUnmemoized({
    children,
    ...props
  }: FriendRequestsBadgeProps) {
    return (
      <FriendRequestsBadge
        sx={{
          flex: 1,
          '.MuiBadge-badge': {
            display: {
              xs: 'flex',
              md: 'none',
            },
          },
        }}
        {...props}
      >
        {children}
      </FriendRequestsBadge>
    );
  },
);
