import { forwardRef } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { memo } from 'src/util/memo';

export type MenuItemTruncatedProps = MenuItemProps<
  'li',
  { href?: string; target?: string }
> & {
  /**
   * This will be shown in full in the tooltip.
   */
  primary: string;
  /**
   * Maximum width in pixels before truncation
   * @default 200
   */
  maxWidth?: number;
  tooltipProps?: TooltipProps;
};

export const MenuItemTruncatedUnmemoized = forwardRef<
  HTMLLIElement,
  MenuItemTruncatedProps
>(
  (
    { primary, maxWidth = 200, children, tooltipProps, ...menuItemProps },
    ref,
  ) => {
    return (
      <Tooltip placement="right" title={primary} {...tooltipProps}>
        <MenuItem ref={ref} {...menuItemProps}>
          <ListItemText
            primary={primary}
            sx={{
              textTransform: 'none',
              '.MuiTypography-root': {
                maxWidth: `${maxWidth}px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
            }}
          />
          {children}
        </MenuItem>
      </Tooltip>
    );
  },
);
MenuItemTruncatedUnmemoized.displayName = 'MenuItemTruncatedUnmemoized';

export const MenuItemTruncated = memo(MenuItemTruncatedUnmemoized);
