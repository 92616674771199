import { FC } from 'react';
import { useTheme } from '@mui/material/styles';

export const ReadDot: FC = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: `0.5px solid ${theme.palette.primary.main}`,
        transition: theme.transitions.create('background-color'),
      }}
    />
  );
};

export const UnreadDot: FC = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        transition: theme.transitions.create('background-color'),
      }}
    />
  );
};
