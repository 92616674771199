import Box from '@mui/material/Box';
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import IconButton from '@mui/material/IconButton';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltRounded';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import { LottieLoader } from '../LottieLoader';
import { memo } from '../../util/memo';
import { useClickAwayListener } from '../../hooks/useClickAwayListener';
import { ANCHOR_ORIGIN_BOTTOM_LEFT } from '../../util/styles/anchorOrigin';
import { withDynamicImport } from '../../util/withDynamicImport';
import {
  DynamicImport,
  StreamChatReact,
  STREAM_CHAT_REACT,
} from '../../../functions/src/types/DynamicModule';
import { assertSafe } from 'functions/src/util/assertSafe';

const Picker = dynamic(
  async () => {
    const mod = await import('@emoji-mart/react');
    return mod.default;
  },
  {
    loading: () => {
      return <LottieLoader sx={{ height: '24px', width: '24px' }} />;
    },
    ssr: false,
  },
);

export type EmojiPickerProps = {
  modules: DynamicImport<[StreamChatReact]>;
};

const EmojiPickerUnmemoized = ({ modules }: EmojiPickerProps) => {
  const [emojiData, setEmojiData] = useState<Record<string, unknown> | null>(
    null,
  );
  const [anchorEl, setAnchorEl] = useState<
    HTMLTextAreaElement | null | undefined
  >(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const { useMessageInputContext } = modules[assertSafe(STREAM_CHAT_REACT)];
  const { insertText, textareaRef } = useMessageInputContext();

  useClickAwayListener({
    containerRefs: [emojiPickerRef],
    onClick: () => {
      setAnchorEl(null);
    },
  });

  useEffect(() => {
    const fetchEmojiData = async () => {
      const response = await fetch(
        'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
      );
      const data = await response.json();
      setEmojiData(data);
    };

    fetchEmojiData();
  }, []);

  const insertEmoji = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (emoji: any) => {
      insertText(emoji.native);
      textareaRef.current?.focus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textareaRef],
  );

  const setAnchorElement = useCallback(() => {
    return setAnchorEl(textareaRef.current);
  }, [textareaRef]);

  return (
    <Fragment>
      <IconButton
        sx={{
          p: 0,
          height: '24px',
          width: '24px',
          alignSelf: 'flex-end',
          display: { xs: 'none', md: 'flex' },
        }}
        onClick={setAnchorElement}
      >
        <SentimentSatisfiedAltIcon
          sx={{
            height: '24px',
            width: '24px',
            color: theme.palette.primary.mid,
          }}
        />
      </IconButton>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={ANCHOR_ORIGIN_BOTTOM_LEFT}
        className="emoji-picker-popover"
        open={!!anchorEl}
        sx={{
          '& .MuiPaper-root': {
            top: '396px !important',
          },
          'em-emoji-picker > #shadow-root > section': {
            backgroundColor: `${theme.palette.background.elevation[8]} !important`,
          },
        }}
      >
        {!!emojiData && (
          <Box ref={emojiPickerRef}>
            <Picker
              data={emojiData}
              enableFrequentEmojiSort
              maxFrequentRows={1}
              onEmojiSelect={insertEmoji}
            />
          </Box>
        )}
      </Popover>
    </Fragment>
  );
};

export const EmojiPicker = memo(
  withDynamicImport({
    Component: EmojiPickerUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
