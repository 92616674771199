import { memo } from '../../util/memo';
import { ReadDot, UnreadDot } from './StatusDots';

export type StatusIndicatorProps = {
  isShowUnread: boolean;
};

export const StatusIndicator = memo(function StatusIndicatorUnmemoized({
  isShowUnread,
}: StatusIndicatorProps) {
  if (isShowUnread) {
    return <UnreadDot />;
  }

  return <ReadDot />;
});
