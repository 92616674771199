/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useMemo, MouseEvent as ReactMouseEvent } from 'react';
import { Stack, Typography } from '@mui/material';
import { GradientBorder } from '../gradients/GradientBorder';
import { memo } from '../../util/memo';
import { RelativeTimeFormatter } from '../../../functions/src/util/date/RelativeTimeFormatter';
import { AvatarNext } from '../avatar/AvatarNext';
import { useUpdateNotification } from '../../hooks/notification/useUpdateNotification';
import { isArchived, isUnread } from '../../util/notifications/status';
import { NotificationActionButtonGroup } from './NotificationActionButtonGroup';
import { StatusIndicator } from './StatusIndicator';
import { Notification } from 'functions/src/types/firestore/User/Notification';

export type NotificationLayoutProps = {
  notification: Notification<Date>;
};

export const NotificationLayoutUnmemoized: FC<NotificationLayoutProps> = ({
  notification,
}) => {
  const { id, status, createdAt, title, subtitle, imgUrl, attributes, toId } =
    notification;
  const relativeFormatter = new RelativeTimeFormatter({ unitsCount: 1 });
  const { markAsRead } = useUpdateNotification({
    toId,
    id,
  });

  const markRead = useCallback(
    (event: ReactMouseEvent<HTMLDivElement>) => {
      if (
        (event.target as HTMLElement).closest('button') ||
        isArchived(status)
      ) {
        return;
      }
      markAsRead();
    },
    [markAsRead, status],
  );

  const isShown = useMemo(() => {
    return !isArchived(status);
  }, [status]);

  const showUnread = useMemo(() => {
    return isShown && isUnread(status);
  }, [isShown, status]);

  const createdAtFormatted = useMemo(() => {
    return relativeFormatter.format(createdAt);
  }, [relativeFormatter.format, createdAt]);

  return (
    <GradientBorder
      borderRadius="10px"
      borderWidth={1}
      disableBorder
      sx={{
        width: '600px',
        position: 'relative',
      }}
      onClick={markRead}
    >
      <Stack alignItems="flex-start" direction="row" padding={2} spacing={3}>
        <AvatarNext alt="" size={50} src={imgUrl} />
        <Stack direction="column" flexGrow={1} spacing={0.5}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography color="text.primary" variant="h6">
              {title}
            </Typography>
            <Stack alignItems="center" direction="row" spacing={1}>
              {!!isShown && !!attributes && (
                <NotificationActionButtonGroup
                  attributes={attributes}
                  id={id}
                />
              )}
              <StatusIndicator isShowUnread={showUnread} />
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography color="text.primary" variant="body2">
              {subtitle}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              {createdAtFormatted}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </GradientBorder>
  );
};

export const NotificationLayout = memo(NotificationLayoutUnmemoized);
