import { toNotificationCountPath } from '../../../functions/src/types/realtimeDb/NotificationCount/path';
import { useRealtimeDbSnapshot } from '../realtimeDb/useRealtimeDbSnapshot';
import { useAuth } from '../../contexts/AuthContext';
import { NotificationCount } from '../../../functions/src/types/realtimeDb/NotificationCount';

export const useNotificationCount = () => {
  const { uidFull } = useAuth();
  const snapshot = useRealtimeDbSnapshot<NotificationCount>(
    uidFull ? toNotificationCountPath(uidFull) : undefined,
  );

  return {
    total: snapshot?.total ?? 0,
    unread: snapshot?.unread ?? 0,
  };
};
