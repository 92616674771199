import type { Timestamp } from 'firebase-admin/firestore';
import type { DocumentDataEverywhere } from '../../types/DocumentDataEverywhere';
import { isEvmAddress } from '../evmAddresses';
import { Converter } from './Converter';
import { isTimestamp, fromTimestamp, SafeTimestamp } from './timestamp';

export class ConverterFactory {
  public static buildDateConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, Date>({
      shouldConvertValue: isTimestamp,
      convertValue: (value) => {
        return fromTimestamp(value, (date) => {
          return date;
        });
      },
      shouldPreserveTimestamp: false,
    });
  }

  // eslint-disable-next-line @blumintinc/blumint/no-hungarian
  public static buildDateStringConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, string>({
      shouldConvertValue: isTimestamp,
      convertValue: (value) => {
        return fromTimestamp(value, (date) => {
          return date.toISOString();
        });
      },
      shouldPreserveTimestamp: false,
    });
  }

  public static buildTimestampDeserializer<T extends DocumentDataEverywhere>(
    toTimestamp: (value: SafeTimestamp) => Timestamp,
  ) {
    return new Converter<T, SafeTimestamp, Timestamp>({
      shouldConvertValue: isTimestamp,
      convertValue: (value) => {
        return toTimestamp(value);
      },
      shouldPreserveTimestamp: false,
    });
  }

  public static buildEvmAddressConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, string, string>({
      shouldConvertValue: (value: unknown) => {
        return typeof value === 'string' && isEvmAddress(value);
      },
      convertValue: (value) => {
        return value.toLowerCase();
      },
    });
  }

  public static buildUnixTimeConverter<T extends DocumentDataEverywhere>() {
    return new Converter<T, Timestamp, number>({
      shouldConvertValue: isTimestamp,
      convertValue: (value) => {
        return fromTimestamp(value, (date) => {
          return date.getTime();
        });
      },
      shouldPreserveTimestamp: false,
    });
  }
}
