import { useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { connectedProvidersOf } from '../../../functions/src/util/auth/connectedProvidersOf';
import { extractProviderIds } from '../../../functions/src/util/auth/extractProviderIds';
import { filterNonRedirectSignInMethods } from '../../util/auth/signInMethodFilter';
import type {
  NonRedirectSignInMethod,
  GenericSignInMethod,
  CustomSignInMethod,
  UserProviderInfo,
} from 'functions/src/types/firestore/User';

export type ProviderProps = {
  top: NonRedirectSignInMethod[];
  generic: GenericSignInMethod[];
  custom: CustomSignInMethod[];
};

export const PROVIDER_OPTIONS_ALL: ProviderProps = {
  top: ['phone', 'password'], //wallet
  generic: ['apple', 'google'],
  custom: ['discord', 'twitch', 'epic'],
} as const;

export const useAuthProviders = () => {
  const { user, userData } = useAuth();

  const providersConnected = useMemo<UserProviderInfo[] | undefined>(() => {
    if (!user || !userData) {
      return;
    }
    return connectedProvidersOf(userData, user);
  }, [user, userData]);

  const providerProps = useMemo<ProviderProps>(() => {
    if (!user) {
      return PROVIDER_OPTIONS_ALL;
    }

    if (providersConnected) {
      const providerIds = extractProviderIds(providersConnected);
      const idsWithoutWallet = providerIds.filter((id) => {
        return id !== 'wallet';
      });
      const topOptions = filterNonRedirectSignInMethods(idsWithoutWallet);
      const { email, emailVerified } = user;
      // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
      const hasNoPassword =
        !!email && !!emailVerified && !idsWithoutWallet.includes('password');

      return {
        top: hasNoPassword
          ? topOptions.filter((option) => {
              return option !== 'password';
            })
          : topOptions,
        generic: PROVIDER_OPTIONS_ALL.generic,
        custom: PROVIDER_OPTIONS_ALL.custom,
      } as const;
    }

    return PROVIDER_OPTIONS_ALL;
  }, [providersConnected, user]);

  return { providerProps, providersConnected } as const;
};
