/* eslint-disable security/detect-unsafe-regex */

const PROTOCOL_AND_DOMAIN_RE = /^(?:\w+:)?\/\/(\S+)$/;
const LOCALHOST_DOMAIN_RE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const NON_LOCALHOST_DOMAIN_RE = /^[^\s\.]+\.\S{2,}$/;

function isValidDomain(domain: string) {
  return !!(
    LOCALHOST_DOMAIN_RE.test(domain) || NON_LOCALHOST_DOMAIN_RE.test(domain)
  );
}

export function isOptionallyPrefixedUrl(value: unknown): value is string {
  if (typeof value !== 'string') return false;

  const matchWithProtocol = value.match(PROTOCOL_AND_DOMAIN_RE);
  if (!matchWithProtocol) return isValidDomain(value);

  const domain = matchWithProtocol[1];

  return !!domain && isValidDomain(domain);
}
