// eslint-disable-next-line @blumintinc/blumint/use-custom-memo
import { ReactNode, ComponentType, memo as reactMemo } from 'react';
import isEqual from 'fast-deep-equal/react';
import { assertSafe } from 'functions/src/util/assertSafe';

export type Props<T extends object = object> = {
  [key: string]: Date | T | ReactNode;
  sx?: T;
};

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
function isObject(value: unknown) {
  return value !== null && typeof value === 'object';
}

export type ShouldDeepCompareKey<TProps> = (
  key: keyof TProps & string,
) => boolean;

export type CompareProps<TProps> = {
  prevProps: TProps;
  nextProps: TProps;
};

export const withDeepCompareMatching = <TProps extends Record<string, unknown>>(
  shouldDeepCompare: ShouldDeepCompareKey<TProps>,
) => {
  return ({ prevProps, nextProps }: CompareProps<TProps>) => {
    if (!isObject(prevProps) || !isObject(nextProps)) {
      return prevProps === nextProps;
    }

    // eslint-disable-next-line no-restricted-properties
    const keys = Object.keys({
      ...prevProps,
      ...nextProps,
    }) as (keyof TProps & string)[];

    return keys.every((key) => {
      const prevValue = prevProps[assertSafe(key)];
      const nextValue = nextProps[assertSafe(key)];

      if (prevValue instanceof Date && nextValue instanceof Date) {
        return prevValue.getTime() === nextValue.getTime();
      }

      if (
        typeof prevValue === 'object' &&
        typeof nextValue === 'object' &&
        shouldDeepCompare(key)
      ) {
        return isEqual(prevValue, nextValue);
      }

      return prevValue === nextValue;
    });
  };
};

/**
 * all other props beside the ones specified in keysToCompareDeeply
 * will be compared shallowly.
 */
export const compareDeeply = <TProps extends Record<string, unknown>>(
  ...keysToCompareDeeply:
    | readonly (keyof TProps & string)[]
    | (keyof TProps & string)[]
) => {
  return withDeepCompareMatching((key) => {
    return keysToCompareDeeply.includes(key);
  });
};

// eslint-disable-next-line @blumintinc/blumint/enforce-verb-noun-naming
function blumintAreEqual<TProps>({
  prevProps,
  nextProps,
}: CompareProps<TProps>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return withDeepCompareMatching<any>((key) => {
    return (
      key === 'sx' ||
      key.endsWith('Sx') ||
      key === 'style' ||
      key.endsWith('Style')
    );
  })({ prevProps, nextProps });
}

// eslint-disable-next-line @blumintinc/blumint/enforce-verb-noun-naming
const memo = <TProps>(
  Component: ComponentType<TProps>,
  areEqual?: ({ prevProps, nextProps }: CompareProps<TProps>) => boolean,
) => {
  // eslint-disable-next-line @blumintinc/blumint/prefer-settings-object
  const eitherEqual = (prevProps: TProps, nextProps: TProps) => {
    return (
      blumintAreEqual({ prevProps, nextProps }) ||
      areEqual?.({ prevProps, nextProps }) ||
      false
    );
  };

  return reactMemo(Component, eitherEqual);
};

export { memo };
