import { PLACEHOLDER_TEXT } from './templates';

export type FillTemplateProps = {
  template: string;
  placeholderValue: string;
};

export const fillTemplate = ({
  template,
  placeholderValue,
}: FillTemplateProps) => {
  return template.includes(PLACEHOLDER_TEXT)
    ? template.replaceAll(PLACEHOLDER_TEXT, placeholderValue)
    : template;
};
