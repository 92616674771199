/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useEffect,
  useMemo,
  useState,
  useContext,
  ReactNode,
  useCallback,
} from 'react';
import { memo } from '../../util/memo';
import { useDynamic } from '../../hooks/useDynamic';
import type { Web3Modal } from '@web3modal/wagmi/dist/types/src/client';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type OnFinishImportListener = (values: {
  wagmiCoreModule: typeof import('@wagmi/core');
  web3Modal: Web3Modal;
}) => void;

export type WatchSubscriber = {
  subscribersCount: number;
  unsubscribePromise?: Promise<() => void>;
};

const Web3Context = createContext<{
  web3Modal?: Web3Modal;
  wagmiCoreModule?: typeof import('@wagmi/core');
  onFinishImport: (listener: OnFinishImportListener) => void;
  watchSubscribers: Record<string, WatchSubscriber>;
  watchResults: Record<string, any>;
  setResult: (key: string, result: any) => void;
}>({
  onFinishImport: () => {
    /* */
  },
  watchSubscribers: {},
  watchResults: {},
  setResult: () => {
    /* */
  },
});

const Web3ProviderUnmemoized: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const chainsModule = useDynamic(import('@wagmi/core/chains'));
  const chains = useMemo(() => {
    if (!chainsModule) {
      return undefined;
    }
    const {
      arbitrum,
      arbitrumGoerli,
      avalanche,
      avalancheFuji,
      bsc,
      bscTestnet,
      fantom,
      fantomTestnet,
      goerli,
      mainnet,
      optimism,
      optimismGoerli,
      polygon,
      polygonMumbai,
      localhost,
    } = chainsModule;

    return [
      arbitrum,
      arbitrumGoerli,
      // aurora,
      // auroraTestnet,
      avalanche,
      avalancheFuji,
      // bronos,
      // bronosTestnet,
      bsc,
      bscTestnet,
      // canto,
      // celo,
      // celoAlfajores,
      // crossbell,
      // evmos,
      // evmosTestnet,
      fantom,
      fantomTestnet,
      // filecoin,
      // filecoinCalibration,
      // filecoinHyperspace,
      // foundry,
      // gnosis,
      // gnosisChiado,
      goerli,
      // hardhat,
      // iotex,
      // iotexTestnet,
      // localhost,
      mainnet,
      // metis,
      // metisGoerli,
      // moonbaseAlpha,
      // moonbeam,
      // moonriver,
      // okc,
      optimism,
      optimismGoerli,
      polygon,
      polygonMumbai,
      // sepolia,
      // taraxa,
      // taraxaTestnet,
      // telos,
      // telosTestnet,
      // zkSync,
      // zkSyncTestnet,
      localhost,
    ];
  }, [chainsModule]);

  const web3ModalModule = useDynamic(import('@web3modal/wagmi/react'));
  const wagmiConfig = useMemo(() => {
    if (!chains || !web3ModalModule) {
      return undefined;
    }
    const { defaultWagmiConfig } = web3ModalModule;

    return defaultWagmiConfig({
      chains,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID!,
    });
  }, [chains, web3ModalModule]);

  const [web3Modal, setWeb3Modal] = useState<Web3Modal | undefined>(undefined);
  useEffect(() => {
    if (!chains || !wagmiConfig || !web3ModalModule || web3Modal) {
      return;
    }
    const { createWeb3Modal } = web3ModalModule;

    setWeb3Modal(
      createWeb3Modal({
        wagmiConfig,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID!,
        chains,
      }),
    );
  }, [chains, wagmiConfig, web3Modal, web3ModalModule]);

  const wagmiCoreModule = useDynamic(import('@wagmi/core'));

  const [importListeners, setImportListeners] = useState<
    OnFinishImportListener[]
  >([]);
  useEffect(() => {
    if (wagmiCoreModule && web3Modal && importListeners.length > 0) {
      importListeners.forEach((listener) => {
        listener({ wagmiCoreModule, web3Modal });
      });
      setImportListeners([]);
    }
  }, [importListeners, wagmiCoreModule, web3Modal]);

  const [watchResults, setWatchResults] = useState({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setResult = useCallback((key: string, result: any) => {
    setWatchResults((prev) => {
      return {
        ...prev,
        [key]: result,
      };
    });
  }, []);

  const values = useMemo(() => {
    const isReadyToExpose = wagmiConfig && web3Modal && wagmiCoreModule;

    return {
      web3Modal: isReadyToExpose ? web3Modal : undefined,
      wagmiCoreModule: isReadyToExpose ? wagmiCoreModule : undefined,
      onFinishImport: (listener: OnFinishImportListener) => {
        if (!isReadyToExpose) {
          setImportListeners((prev) => {
            return [...prev, listener];
          });
        } else {
          listener({ wagmiCoreModule, web3Modal });
        }
      },
      watchSubscribers: {},
      watchResults,
      setResult,
    };
  }, [setResult, wagmiConfig, wagmiCoreModule, watchResults, web3Modal]);

  return <Web3Context.Provider value={values}>{children}</Web3Context.Provider>;
};

export const Web3Provider = memo(Web3ProviderUnmemoized);

export const useWeb3 = () => {
  const context = useContext(Web3Context);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useWeb3Context must be used within a Web3Provider',
    );
  }
  return context;
};
