import Box from '@mui/material/Box';
import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import { SearchAlgolia } from '../../algolia/search/SearchAlgolia';
import { GradientTypography } from '../../gradients/GradientTypography';
import { memo } from '../../../util/memo';

export type FriendCarouselHeaderProps = {
  title: string;
  onlineCount?: number;
  friendCount?: number;
  notificationCount?: number;
  separateSearch?: boolean;
};

const FriendCarouselHeaderUnmemoized = ({
  title,
  onlineCount,
  friendCount,
  notificationCount = 0,
  separateSearch = false,
}: FriendCarouselHeaderProps) => {
  const integratedSearch = useMemo(() => {
    if (separateSearch) {
      return;
    }
    return <SearchAlgolia />;
  }, [separateSearch]);

  const theme = useTheme();
  const separateSearchCard = useMemo(() => {
    return separateSearch ? (
      <SearchAlgolia
        collapsable={false}
        sx={{ backgroundColor: theme.palette.background.elevation[9] }}
      />
    ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [separateSearch]);

  const statusText = useMemo(() => {
    if (onlineCount === undefined) {
      return;
    }
    return (
      <GradientTypography
        gradientColor={'secondary.vertical'}
        noWrap
        variant="subtitle1"
      >
        {`${onlineCount} Online`}
      </GradientTypography>
    );
  }, [onlineCount]);

  return (
    <Stack pb={2} spacing={2} width="100%">
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        width="100%"
      >
        <Stack alignItems="center" direction="row" flex={1} spacing={2}>
          <Badge badgeContent={notificationCount}>
            <Stack alignItems="center" direction="row" spacing={2}>
              {!!friendCount && (
                <Box
                  sx={{
                    background: theme.palette.background.elevation[24],
                    borderRadius: '24px',
                    px: '12px',
                  }}
                >
                  <GradientTypography
                    gradientColor={'primary.vertical'}
                    noWrap
                    sx={{ lineHeight: '22px' }}
                    variant="h6"
                  >
                    {friendCount}
                  </GradientTypography>
                </Box>
              )}
              <GradientTypography
                gradientColor={'primary.vertical'}
                noWrap
                sx={{ flexShrink: 0 }}
                variant="h6"
                width="fit-content"
              >
                {title}
              </GradientTypography>
            </Stack>
          </Badge>
          {integratedSearch}
        </Stack>
        {statusText}
      </Stack>
      {separateSearchCard}
    </Stack>
  );
};

export const FriendCarouselHeader = memo(FriendCarouselHeaderUnmemoized);
