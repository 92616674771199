import Stack from '@mui/material/Stack';
import { Required } from 'utility-types';
import { memo } from '../../util/memo';
import { MemberListItem } from '../cards/friend/MemberListItem';
import { ChannelGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup';

export type MemberListProps = {
  members: ChannelGroupUser[];
  onRemove: (userId: string) => void;
};

const MemberListUnmemoized = ({ members, onRemove }: MemberListProps) => {
  return (
    <Stack spacing={2}>
      {members
        .filter((member): member is Required<ChannelGroupUser, 'username'> => {
          return !!member.username;
        })
        .map((member) => {
          return (
            <MemberListItem
              key={member.userId}
              onRemove={onRemove}
              {...member}
            />
          );
        })}
    </Stack>
  );
};

export const MemberList = memo(MemberListUnmemoized);
