/* eslint-disable react-hooks/rules-of-hooks */
import { Context, createContext, useMemo, useContext, FC } from 'react';
import type { UpdateData } from 'firebase/firestore';
import { getPathValue } from '../../../util/object/getPathValue';
import { HttpsError } from '../../../../functions/src/util/errors/HttpsError';
import {
  BaseProviderProps,
  CentralizedProvider,
  PathValue,
} from './CentralizedProvider';
import { assertSafe } from 'functions/src/util/assertSafe';

export type PathCentralizedContextType<TObj extends Record<string, unknown>> = {
  values: TObj;
  setValue: <TPath extends string>(
    path: TPath,
    value: PathValue<TObj, TPath>,
  ) => void | Promise<void>;
  updateObj: (newValues: UpdateData<TObj>) => void | Promise<void>;
};

type UseValuesState<
  TObj extends Record<string, unknown>,
  TProps extends BaseProviderProps = BaseProviderProps,
> = (props: Omit<TProps, 'children'>) => TObj | undefined;

export abstract class PathCentralizedProvider<
  TObj extends Record<string, unknown>,
  TProps extends BaseProviderProps = BaseProviderProps,
> implements CentralizedProvider<TObj, TProps>
{
  protected readonly context: Context<PathCentralizedContextType<TObj> | null>;

  public constructor(
    protected readonly useValuesState?:
      | UseValuesState<TObj, TProps>
      | undefined,
  ) {
    this.context = createContext<PathCentralizedContextType<TObj> | null>(null);
  }

  abstract get Provider(): FC<TProps>;

  public useValue<TPath extends string | undefined>(path: TPath) {
    const context = useContext(this.context);
    if (!context) {
      throw new HttpsError(
        'failed-precondition',
        'useValue must be used within a PathCentralizedProvider',
      );
    }
    const { values, setValue } = context;

    const value = (
      path ? getPathValue(values, path) : undefined
    ) as TPath extends undefined
      ? undefined
      : PathValue<TObj, Exclude<TPath, undefined>>;

    return useMemo(() => {
      return {
        value,
        setValue: (
          newValue: TPath extends undefined
            ? undefined
            : PathValue<TObj, Exclude<TPath, undefined>>,
        ) => {
          return path === undefined
            ? undefined
            : setValue(
                path,
                newValue as PathValue<TObj, Exclude<TPath, undefined>>,
              );
        },
      } as const;
    }, [value, setValue, path]);
  }

  // eslint-disable-next-line @blumintinc/blumint/no-hungarian
  public useArrayValue<TPath extends string | undefined>(path: TPath) {
    const context = useContext(this.context);
    if (!context) {
      throw new HttpsError(
        'failed-precondition',
        'useArrayValue must be used within a PathCentralizedProvider',
      );
    }
    const { values, setValue } = context;

    // eslint-disable-next-line @blumintinc/blumint/no-hungarian
    const arrayValues = useMemo(() => {
      return path ? getPathValue(values, path) || [] : [];
    }, [values, path]) as TPath extends undefined
      ? never[]
      : (PathValue<TObj, Exclude<TPath, undefined>> | null)[];

    return useMemo(() => {
      return {
        values: arrayValues,
        onElementChange: (
          index: number,
          value?: PathValue<TObj, Exclude<TPath, undefined>> | null,
        ) => {
          if (!path) return;

          const newValues = [...arrayValues];
          if (value === undefined) {
            newValues.splice(index, 1);
          } else {
            newValues[assertSafe(Number(index))] = value;
          }
          setValue(
            path,
            newValues as PathValue<TObj, Exclude<TPath, undefined>>,
          );
        },
      } as const;
    }, [arrayValues, setValue, path]);
  }

  // eslint-disable-next-line @blumintinc/blumint/no-hungarian
  public useEntireObject() {
    const context = useContext(this.context);
    if (!context) {
      throw new HttpsError(
        'failed-precondition',
        'useEntireObject must be used within its Provider',
      );
    }
    const { values, updateObj } = context;

    return useMemo(() => {
      return {
        obj: values,
        updateObj,
      } as const;
    }, [values, updateObj]);
  }
}
