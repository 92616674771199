import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { useRealtimeChannelGroups } from '../../../contexts/RealtimeChannelGroupContext';
import { ChannelGroupAvatars } from './ChannelGroupAvatars';
import { VoiceChannelGroupSidebar } from './VoiceChannelGroupSidebar';

const ChannelGroupSidebarListUnmemoized = () => {
  const theme = useTheme();

  const { containerRef } = useRealtimeChannelGroups();

  return (
    <Stack
      ref={containerRef}
      alignItems="center"
      sx={{
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
        ...theme.scrollbars.invisible,
      }}
    >
      <VoiceChannelGroupSidebar />
      <ChannelGroupAvatars />
    </Stack>
  );
};

export const ChannelGroupSidebarList = memo(ChannelGroupSidebarListUnmemoized);
