import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { useTheme } from '@mui/material/styles';
import { useCallback } from 'react';
import { Required } from 'utility-types';
import { ChannelGroupUser } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { ChipUser } from '../../ChipUser';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { memo } from '../../../util/memo';

// eslint-disable-next-line @blumintinc/blumint/no-unused-props
export type MemberListItemProps = Required<ChannelGroupUser, 'username'> & {
  onRemove: (userId: string) => void;
};

const MemberListItemUnmemoized = ({
  image,
  username,
  userId,
  onRemove,
  isAdmin = false,
}: MemberListItemProps) => {
  const theme = useTheme();

  const remove = useCallback(() => {
    if (!userId) {
      return;
    }
    onRemove(userId);
  }, [userId, onRemove]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      p={2}
      sx={{
        background: theme.palette.background.elevationSolid[8],
        borderRadius: '10px',
        height: '52px',
        width: '100%',
      }}
    >
      <ChipUser avatarUrl={image} href={`/${userId}`} username={username} />
      <Stack direction="row" spacing={1}>
        {isAdmin ? (
          <Typography
            color="text.secondary"
            sx={{ fontStyle: 'italic', pr: 2 }}
            variant="subtitle2"
          >
            Admin
          </Typography>
        ) : (
          <GradientIconButton
            IconComponent={HighlightOffIcon}
            onClick={remove}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const MemberListItem = memo(MemberListItemUnmemoized);
