import {
  useMemo,
  useCallback,
  MutableRefObject,
  ReactNode,
  ComponentType,
  MemoExoticComponent,
} from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { SxProps } from '@mui/material/styles';
import { ToggleButtonProps } from '@mui/material/ToggleButton';
import { TabProps } from '@mui/material/Tab';
import { RouterState } from '../../hooks/routing/useRouterState';
import { memo } from '../../util/memo';
import { useRouter } from '../../hooks/routing/useRouter';
import { ChipTabProps } from '../ChipTab';
import { TabsButtonGroup } from './TabsButtonGroup';
import { TabsChip } from './TabsChip';
import { TabsScrollable } from './TabsScrollable';

export type TabRoutedFull<TValue extends string> = {
  value: TValue;
  onClick?: (ref: MutableRefObject<Element | null>, go: () => void) => void;
  customization?: Partial<
    ToggleButtonProps &
      TabProps &
      Omit<ChipTabProps, 'variant'> & { isIconOnly: boolean }
  >;
  Wrapper?: MemoExoticComponent<ComponentType<{ children: ReactNode }>>;
};

export type TabRouted<TValue extends string> = TValue | TabRoutedFull<TValue>;

export type TabsVariantProps = {
  variant: string;
  tabs: TabRouted<string>[];
  activeTab: string;
  routeTab: (event: unknown, newValue: string | null) => void;
  sx: SxProps;
};

export type TabsRoutedProps<TValue extends string> = {
  tabs: TabRouted<TValue>[];
  variant?: 'tabs' | 'toggle-button' | 'chip' | 'chip-large';
  state: RouterState;
  sx?: SxProps;
};

export const DEFAULT_VARIANT = 'tabs' as const;
const SX_DEFAULT = {} as const;

function TabsRoutedUnmemoized({
  tabs,
  variant = DEFAULT_VARIANT,
  state,
  sx = SX_DEFAULT,
}: TabsRoutedProps<string>) {
  const [activeTab, setActiveTab] = state;
  const activeTabDefaulted = useMemo(() => {
    const hasActiveTab =
      activeTab &&
      tabs.find((tab) => {
        if (typeof tab === 'object') {
          return tab.value === activeTab;
        }
        return tab === activeTab;
      });
    if (hasActiveTab) {
      return activeTab;
    }
    const tabFirst = tabs[0];
    return typeof tabFirst === 'object' ? tabFirst.value : tabFirst;
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [activeTab, tabs]);

  const { push } = useRouter();
  const routeTabBounced = useCallback(
    async (_: unknown, newValue: string | null) => {
      if (
        (activeTab === 'Events' || activeTab === 'Default') &&
        newValue === 'Events'
      ) {
        await push('/');
      }
      if (newValue) {
        setActiveTab(newValue);
      }
    },
    [activeTab, push, setActiveTab],
  );
  const routeTab = useDebounceCallback(routeTabBounced, 500, true);

  const TabsVariant = useMemo(() => {
    if (variant === 'toggle-button') {
      return TabsButtonGroup;
    }
    if (variant === 'chip' || variant === 'chip-large') {
      return TabsChip;
    }
    return TabsScrollable;
  }, [variant]);

  return (
    <TabsVariant
      activeTab={activeTabDefaulted}
      routeTab={routeTab}
      sx={sx}
      tabs={tabs}
      variant={variant}
    />
  );
}

export const TabsRouted = memo(TabsRoutedUnmemoized);
