import { useMemo } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toChannelGroupId } from '../../../functions/src/util/messaging/toChannelGroupPath';
import { GroupFilter } from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useRouterState } from '../routing/useRouterState';

export const useIsViewingLivestreamEvent = () => {
  const [event] = useRouterState({ key: 'event' });

  const tournamentId = useMemo(() => {
    return event?.split('Tournament/')[1];
  }, [event]);

  const { channelGroupId: channelGroupIdRouter } = useActiveChannelGroup();

  const channelGroupId = useMemo(() => {
    if (!tournamentId) {
      return;
    }
    return toChannelGroupId([
      { type: 'general', tournamentId },
    ] as unknown as GroupFilter<'Tournament'>);
  }, [tournamentId]);

  return useMemo(() => {
    return (
      !!channelGroupIdRouter &&
      !!channelGroupId &&
      channelGroupIdRouter === channelGroupId
    );
  }, [channelGroupIdRouter, channelGroupId]);
};
