import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { useLoadingWrapperContext } from '../../../contexts/LoadingWrapperContext';
import { EditOverlay, EditOverlayProps } from './EditOverlay';
import { memo } from 'src/util/memo';

export type EditLoadingOverlayProps = Omit<EditOverlayProps, 'forceContent'>;

export const EditLoadingOverlay = memo(function EditLoadingOverlayUnmemoized(
  props: EditLoadingOverlayProps,
) {
  const { isLoading } = useLoadingWrapperContext();

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const loadingContent = useMemo(() => {
    return isLoading ? (
      <CircularProgress size={24} sx={{ color: 'white' }} />
    ) : undefined;
  }, [isLoading]);

  return <EditOverlay {...props} forceContent={loadingContent} />;
});
