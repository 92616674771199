import { useEffect } from 'react';
import { generateParticipantToken } from '../../util/voice-chat/generateParticipantToken';
import { useAuth } from '../../contexts/AuthContext';
import {
  DynamicModule,
  LivekitComponentsReact,
} from '../../../functions/src/types/DynamicModule';
import { useConnectionStatus } from './useConnectionStatus';
import { useVoiceChannel } from './useVoiceChannel';
import { useLeaveCall } from './useLeaveCall';

export type UseJoinCallProps = {
  livekitModule: DynamicModule[LivekitComponentsReact];
};

export const useJoinCall = ({ livekitModule }: UseJoinCallProps) => {
  const { userData } = useAuth();

  const { voiceChannel, setVoiceChannel } = useVoiceChannel();
  const connectionStatus = useConnectionStatus({ livekitModule });

  const { useRoomContext, useParticipants } = livekitModule;

  const room = useRoomContext();
  const participants = useParticipants({ room });
  const leaveCall = useLeaveCall({ livekitModule });

  const { id: userId, username, imgUrl } = userData || {};

  useEffect(() => {
    if (!room) return;

    // eslint-disable-next-line complexity
    const enterRoom = async () => {
      const { current } = voiceChannel || {};
      const isConnected = connectionStatus === 'connected';
      const isConnecting = connectionStatus === 'connecting';

      const isAlreadyConnected =
        isConnected && !!room.name && room.name === current;

      if (
        !current ||
        !userId ||
        !username ||
        !imgUrl ||
        isAlreadyConnected ||
        isConnecting
      ) {
        return;
      }

      if (isConnected) {
        await leaveCall();
      }

      try {
        const response = await generateParticipantToken({
          roomName: current,
          username,
          userId,
          imgUrl,
        });

        setVoiceChannel({ roomToken: response.token });
      } catch (error) {
        console.error('Failed to join voice chat:', error);
        setVoiceChannel({ roomToken: undefined });
      }
    };

    enterRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    voiceChannel,
    connectionStatus,
    setVoiceChannel,
    room?.name,
    participants?.length,
    userId,
    username,
    imgUrl,
  ]);
};
