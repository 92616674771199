/* eslint-disable @blumintinc/blumint/react-usememo-should-be-component */
import { cloneElement, ReactNode, useMemo, Children } from 'react';
import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import { useEditLinkDialog } from '../../../hooks/edit/useEditLinkDialog';
import { UrlChangeable } from '../EditProps';
import { memo } from '../../../util/memo';
import { EditLinkDialogBodyProps } from './EditLinkDialogBody';
import { LinkMenuItem } from './LinkMenuItem';

const EMPTY_MENU_ITEMS: ReactNode[] = [];

export type EditLinkMenuProps<TUrl extends string = string> = MenuProps &
  Pick<EditLinkDialogBodyProps<TUrl>, 'urlOptions'> &
  UrlChangeable<TUrl> & {
    /**
     * These MenuItems will be shown before the URL options.
     */
    menuItemsOther?: ReactNode[];
  };

function EditLinkMenuUnmemoized<TUrl extends string = string>({
  open,
  onClose,
  url,
  urlOptions,
  onChangeUrl,
  menuItemsOther = EMPTY_MENU_ITEMS,
  ...menuProps
}: EditLinkMenuProps<TUrl>) {
  const { open: openEditDialog } = useEditLinkDialog();

  const edit = useMemo(() => {
    if (onChangeUrl === 'disabled') {
      return 'disabled';
    }

    return () => {
      openEditDialog({
        initialUrl: url,
        urlOptions,
        onSave: onChangeUrl,
      });
    };
  }, [openEditDialog, url, urlOptions, onChangeUrl]);

  const remove = useMemo(() => {
    if (onChangeUrl === 'disabled') {
      return 'disabled';
    }

    return async () => {
      await onChangeUrl(undefined);
    };
  }, [onChangeUrl]);

  const editText = `${url ? 'Edit' : 'Add'} Link`;

  const menuItemsOtherWithKeys = useMemo(() => {
    return Children.map(menuItemsOther, (item, index) => {
      const element = item as React.ReactElement;
      const elementKey = element.key || index;
      // Since this will always be applied to a pop-up Menu, it shouldn't
      // negatively impact performance.
      return cloneElement(element, {
        key: `other-item-${elementKey}`,
      });
    });
  }, [menuItemsOther]);

  return (
    <Menu open={open} onClose={onClose} {...menuProps}>
      {menuItemsOtherWithKeys}
      {!!url && <LinkMenuItem url={url} />}
      {edit !== 'disabled' && (
        <MenuItem key="edit-link" onClick={edit}>
          <ListItemText primary={editText} />
        </MenuItem>
      )}
      {!!url && remove !== 'disabled' && (
        <MenuItem key="remove-link" onClick={remove}>
          <ListItemText primary="Remove Link" />
        </MenuItem>
      )}
    </Menu>
  );
}

export const EditLinkMenu = memo(
  EditLinkMenuUnmemoized,
) as typeof EditLinkMenuUnmemoized;
