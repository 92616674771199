import { HttpsError } from './errors/HttpsError';
import { isDangerousPropertyName } from './isDangerousPropertyName';

export function assertSafe<T extends PropertyKey>(key: T) {
  if (typeof key !== 'string' && typeof key !== 'number') {
    throw new HttpsError(
      'invalid-argument',
      'Invalid Key Type',
      `${typeof key}. Keys must be strings or numbers.`,
    );
  }

  const keyStr = String(key);

  if (isDangerousPropertyName(keyStr)) {
    throw new HttpsError(
      'invalid-argument',
      'Dangerous property name',
      `${keyStr}. This could lead to prototype pollution.`,
    );
  }

  return key;
}
