import { useAuth } from '../../contexts/AuthContext';

export type EstimateMutualFriendsProps = {
  friends?: string[];
};

export const useEstimateMutualFriends = ({
  friends,
}: EstimateMutualFriendsProps) => {
  const { userData } = useAuth();
  const { friends: myFriends } = userData || {};

  if (!myFriends || !friends) {
    return 0;
  }
  const mutualFriends = myFriends.filter((friendId) => {
    return friends.includes(friendId);
  });
  return mutualFriends.length;
};
