import Tooltip from '@mui/material/Tooltip';
import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { ChannelGroupPermanence } from '../../../functions/src/types/firestore/User/ChannelGroup';
import { PushPinIcon } from '../icons/PushPinIcon';
import { PushPinCancelIcon } from '../icons/PushPinCancelIcon';
import { LoadingGradientIconButton } from '../buttons/LoadingGradientIconButton';

export type PermanenceButtonProps = {
  permanence: Omit<ChannelGroupPermanence, 'fixed'>;
  channelGroupId: string;
};

const PermanenceButtonUnmemoized = ({
  channelGroupId,
  permanence,
}: PermanenceButtonProps) => {
  const isTemporary = permanence === 'temporary';

  const togglePermanence = useCallback(async () => {
    const { setChannelGroupPermanence } = await import(
      '../../firebaseCloud/messaging/setChannelGroupPermanence'
    );

    await setChannelGroupPermanence({
      channelGroupId,
      permanence: isTemporary ? 'pinned' : 'temporary',
    });
  }, [isTemporary, channelGroupId]);

  const theme = useTheme();
  const CancelIcon = useCallback(
    (props) => {
      return (
        <PushPinCancelIcon
          {...props}
          strikethroughColor={theme.palette.background.elevationSolid[4]}
        />
      );
    },
    [theme.palette.background.elevationSolid],
  );

  return (
    <Tooltip
      arrow
      disableHoverListener={!isTemporary}
      title={'This is a temporary chat. Pin to your sidebar?'}
    >
      <LoadingGradientIconButton
        gradientColor="primary.horizontal"
        IconComponent={isTemporary ? PushPinIcon : CancelIcon}
        sx={{
          height: '20px',
          width: '20px',
        }}
        onClick={togglePermanence}
      />
    </Tooltip>
  );
};

export const PermanenceButton = memo(PermanenceButtonUnmemoized);
