/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentType,
  forwardRef,
  ForwardedRef,
  MouseEvent,
  CSSProperties,
  useMemo,
} from 'react';
import { withMenu } from '../menu/withMenu';
import { memo, compareDeeply } from '../../../util/memo';
import { UrlChangeable } from '../EditProps';
import { EditLinkMenu, EditLinkMenuProps } from './EditLinkMenu';

export type WithEditLinkMenuProps<TUrl extends string = string> = {
  link: Omit<
    EditLinkMenuProps<TUrl>,
    'onClose' | 'open' | 'anchorEl' | keyof UrlChangeable<TUrl>
  >;
} & UrlChangeable<TUrl>;

export type MenuAchorProps = {
  onClick?: (event: MouseEvent) => void;
  style?: CSSProperties;
};

/**
 * @param WrappedComponent must forward ref
 */
export function withEditLinkMenu<
  TProps extends MenuAchorProps,
  TUrl extends string = string,
>(WrappedComponent: ComponentType<TProps>) {
  const WithMenuComponent = withMenu<TProps, EditLinkMenuProps<TUrl>>(
    WrappedComponent,
    EditLinkMenu,
  );

  const WithEditLinkMenuComponentUnmemoized = forwardRef(
    function WithEditLinkMenuComponentRefless(
      { link, url, onChangeUrl, ...rest }: TProps & WithEditLinkMenuProps<TUrl>,
      ref: ForwardedRef<HTMLElement>,
    ) {
      const menu = useMemo(() => {
        return { ...link, url, onChangeUrl };
      }, [link, url, onChangeUrl]);
      // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
      return <WithMenuComponent ref={ref} {...(rest as any)} menu={menu} />;
    },
  );

  return memo(WithEditLinkMenuComponentUnmemoized, compareDeeply('link'));
}
