import { useMemo } from 'react';
import { toStatusPath } from '../../../functions/src/types/realtimeDb/Status/path';
import { OnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status';
import { useRealtimeDbSnapshot } from '../realtimeDb/useRealtimeDbSnapshot';

export const useOnlineStatus = (id?: string) => {
  const dbPath = useMemo(() => {
    if (!id) return;
    return toStatusPath(id);
  }, [id]);

  return useRealtimeDbSnapshot<OnlineStatusEnum>(dbPath);
};
