import SettingsIcon from '@mui/icons-material/Settings'; // Import Settings icon
import IconButton from '@mui/material/IconButton'; // Import IconButton
import { memo } from '../../util/memo';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'src/components/Link';

type AccountSettingsButtonProps = {
  onClick?: () => void;
};

const AccountSettingsButtonUnmemoized = ({
  onClick,
}: AccountSettingsButtonProps) => {
  const { uidFull } = useAuth();

  return (
    <Link href={`/${uidFull}/account-settings`} onClick={onClick}>
      <IconButton>
        <SettingsIcon />
      </IconButton>
    </Link>
  );
};

export const AccountSettingsButton = memo(AccountSettingsButtonUnmemoized);
