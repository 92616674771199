import { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { DialogActionsStandard } from 'src/components/dialog/DialogActionsStandard';
import { memo } from '../../../../util/memo';
import { useGroupSubgroups } from '../../../../hooks/group/useGroupSubgroups';
import type { Props as SetGroupChannelParams } from '../../../../firebaseCloud/messaging/setGroupChannel';
import { useGroupRouter } from '../../../../hooks/group/useGroupRouter';

export type GroupChannelCreationDialogProps = {
  onSubmit?: (channelData: ChannelData) => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
};

export type ChannelData = {
  title: string;
  privacy: 'public' | 'private';
  allowedSubgroups: string[];
};

export const GroupChannelCreationDialogUnmemoized: FC<
  GroupChannelCreationDialogProps
> = ({ onSubmit, onCancel }) => {
  const [channelData, setChannelData] = useState<ChannelData>({
    title: '',
    privacy: 'public',
    allowedSubgroups: [],
  });

  const subgroups = useGroupSubgroups();
  const { groupId } = useGroupRouter();

  useEffect(() => {
    if (channelData.privacy === 'public') {
      setChannelData((prevData) => {
        return { ...prevData, allowedSubgroups: [] };
      });
    }
  }, [channelData.privacy]);

  const inputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setChannelData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const subgroupChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setChannelData((prevData) => {
      return {
        ...prevData,
        allowedSubgroups: typeof value === 'string' ? value.split(',') : value,
      };
    });
  };

  const submit = useCallback(async () => {
    const params: SetGroupChannelParams = {
      name: channelData.title,
      groupId,
      subgroupIds:
        channelData.privacy === 'public'
          ? []
          : channelData.allowedSubgroups.map((subgroup) => {
              return `${groupId}-${subgroup}`;
            }),
    };

    const { setGroupChannel } = await import(
      '../../../../firebaseCloud/messaging/setGroupChannel'
    );
    await setGroupChannel(params);
    if (onSubmit) {
      await onSubmit(channelData);
    }
  }, [channelData, groupId, onSubmit]);

  const cancel = useCallback(async () => {
    if (onCancel) {
      await onCancel();
    }
  }, [onCancel]);

  return (
    <DialogBodyStandard
      title="Create New Channel"
      description={
        <Stack spacing={3}>
          <Box>
            <Typography variant="h6">Channel Details:</Typography>
          </Box>

          <TextField
            fullWidth
            label="Title"
            name="title"
            value={channelData.title}
            onChange={inputChange}
          />
          <FormControl component="fieldset">
            <Typography variant="subtitle1">Privacy:</Typography>
            <RadioGroup
              name="privacy"
              value={channelData.privacy}
              onChange={inputChange}
              row
            >
              <FormControlLabel
                value="public"
                control={<Radio />}
                label="Public"
              />
              <FormControlLabel
                value="private"
                control={<Radio />}
                label="Private"
              />
            </RadioGroup>
          </FormControl>
          {channelData.privacy === 'private' && (
            <FormControl fullWidth>
              <Stack spacing={3}>
                <Typography variant="subtitle1">Allowed Subgroups:</Typography>
                <Select
                  multiple
                  value={channelData.allowedSubgroups}
                  onChange={subgroupChange}
                  renderValue={(selected) => {
                    return (selected as string[]).join(', ');
                  }}
                  sx={{ maxWidth: '196px' }}
                >
                  {subgroups.map((subgroup) => {
                    const { username } = subgroup;
                    return (
                      <MenuItem key={username} value={username}>
                        <Checkbox
                          checked={
                            channelData.allowedSubgroups.indexOf(username) > -1
                          }
                        />
                        <ListItemText primary={subgroup.username} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
            </FormControl>
          )}
        </Stack>
      }
    >
      <DialogActionsStandard
        buttons={[
          {
            children: 'Cancel',
            isAsync: true,
            onClick: cancel,
            sx: { textTransform: 'uppercase', flex: 1, ml: 1 },
            color: 'error',
          },
          {
            children: 'Create Channel',
            isAsync: true,
            onClick: submit,
            color: 'primary',
            sx: { flex: 2 },
          },
        ]}
      />
    </DialogBodyStandard>
  );
};

export const GroupChannelCreationDialog = memo(
  GroupChannelCreationDialogUnmemoized,
);
