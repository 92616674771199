import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { memo } from '../util/memo';
import { ImageOptimized, ImageOptimizedProps } from './image/ImageOptimized';
import { Link } from './Link';

export type LogoProps = Omit<ImageOptimizedProps, 'alt' | 'src'> & {
  isLink?: boolean;
};

export const BLUMINT_LOGO_URL = '/assets/images/logo.svg' as const;

export function LogoUnmemoized({ isLink = true, ...props }: LogoProps) {
  const logo = useMemo(() => {
    return (
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <ImageOptimized
          alt={'BluMint Logo'}
          src={BLUMINT_LOGO_URL}
          {...props}
        />
      </Box>
    );
  }, [props]);

  if (!isLink) return logo;

  return <Link href="/">{logo}</Link>;
}
export const Logo = memo(LogoUnmemoized);
