import { ReactNode, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { AVATAR_SIZE_PX_PRIMARY_MOBILE } from '../../avatar/AvatarNext';
import { UsernameAvatar, UsernameAvatarProps } from './UsernameAvatar';

export type UserCardLayoutProps = {
  sx?: SxProps;
  children?: ReactNode;
} & UsernameAvatarProps;

const UserCardLayoutUnmemoized = ({
  children,
  avatarProps,
  sx,
  ...usernameAvatarProps
}: UserCardLayoutProps) => {
  const avatarPropsResolved = useMemo(() => {
    return {
      size: AVATAR_SIZE_PX_PRIMARY_MOBILE,
      ...avatarProps,
    } as const;
  }, [avatarProps]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      spacing={2}
      sx={{
        borderRadius: '10px',
        px: 2,
        py: 1,
        ...sx,
      }}
    >
      <UsernameAvatar
        {...usernameAvatarProps}
        avatarProps={avatarPropsResolved}
      />
      {children}
    </Stack>
  );
};

export const UserCardLayout = memo(UserCardLayoutUnmemoized);
