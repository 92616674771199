/* eslint-disable max-depth */
import { useEffect, useRef, useCallback } from 'react';

type UseAudioOptions = {
  volume?: number;
  autoDestroy?: boolean;
};

const DEFAULT_VOLUME = 0.5 as const;

export const useAudio = (soundUrl?: string, options: UseAudioOptions = {}) => {
  const { volume = DEFAULT_VOLUME, autoDestroy = true } = options;
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined' || !soundUrl) return;

    audioRef.current = new Audio(soundUrl);
    audioRef.current.volume = volume;
    audioRef.current.preload = 'auto';

    return () => {
      if (autoDestroy && audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, [soundUrl, volume, autoDestroy]);

  const playSound = useCallback(async () => {
    try {
      if (!audioRef.current) return;
      await audioRef.current.play();
    } catch (error) {
      if (error instanceof Error) {
        switch (error.name) {
          case 'NotAllowedError':
            console.warn('Audio playback blocked by browser autoplay policy');
            break;
          case 'NotSupportedError':
            console.warn('Audio format not supported');
            break;
          case 'SecurityError':
            console.warn('Audio playback blocked by security policy');
            break;
          case 'AbortError':
            console.warn('Audio playback was interrupted');
            break;
          default:
            throw error;
        }
      } else {
        throw error;
      }
    }
  }, []);

  const pauseSound = useCallback(() => {
    if (!audioRef.current) return;
    audioRef.current.pause();
  }, []);

  return { playSound, pauseSound };
};
