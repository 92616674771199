import { TournamentRole } from '../../types/firestore/Game/Tournament';
import { isAdmin } from '../../types/firestore/User/util';
import { RoleMap } from '../../types/Roles';
import { Loadable } from '../isLoading';
import { isContributor } from './isContributor';
import { isModerator } from './isModerator';

export type IsTournamentAdminProps = {
  roles?: RoleMap<TournamentRole>;
  uid?: Loadable<string>;
  email?: string;
};

export const isTournamentAdmin = ({
  roles,
  uid,
  email,
}: IsTournamentAdminProps) => {
  const { contributor, moderator } = roles || {};
  return (
    isModerator(moderator, uid) ||
    isContributor(contributor, uid) ||
    isAdmin(email)
  );
};
