import { useCallback, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material/styles';
import { LottieLoader } from '../LottieLoader';
import { memo } from '../../util/memo';

const PASSWORD_RESET_EMAIL_SENT =
  'Email sent! Please check your inbox and spam folder.' as const;

export type PasswordResetButton = {
  email: string;
  text: string;
  sx?: SxProps;
};
export const PasswordResetButton = memo(function PasswordResetButtonUnmemoized({
  email,
  text,
  sx,
}: PasswordResetButton) {
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const sendForgotPassword = useCallback(async () => {
    setClicked(true);
    const { sendResetPasswordEmail } = await import(
      '../../firebaseCloud/mail/sendResetPasswordEmail'
    );
    const response = await sendResetPasswordEmail({
      toEmail: email,
    });

    if (typeof response === 'string') {
      setErrorMessage(response);
      setEmailSent(false);
      setClicked(false);
      return;
    }
    setEmailSent(true);
  }, [email]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const status = useMemo(() => {
    if (!clicked) {
      return errorMessage ? (
        <Typography color={'error'} variant="body2">
          {errorMessage}
        </Typography>
      ) : (
        <Typography
          color={'text.secondary'}
          sx={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          variant="body2"
          onClick={sendForgotPassword}
        >
          {text}
        </Typography>
      );
    }

    if (emailSent) {
      return (
        <Typography color="text.secondary" variant="body2">
          {PASSWORD_RESET_EMAIL_SENT}
        </Typography>
      );
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LottieLoader sx={{ height: '16px', width: '16px' }} />
      </Box>
    );
  }, [clicked, emailSent, errorMessage, sendForgotPassword, text]);

  return <Box sx={sx}>{status}</Box>;
});
