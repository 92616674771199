export const RESULT_COLLECTION = 'Result' as const;

export const toResultPath = ({
  gameId,
  tournamentId,
  roundId,
  matchId,
  resultId,
}: {
  gameId: string;
  tournamentId: string;
  roundId: string;
  matchId: string;
  resultId: string;
}) => {
  return `Game/${gameId}/Tournament/${tournamentId}/Round/${roundId}/Match/${matchId}/Result/${resultId}`;
};

export const toResultCollectionPath = ({
  gameId,
  tournamentId,
  roundId,
  matchId,
}: {
  gameId: string;
  tournamentId: string;
  roundId: string;
  matchId: string;
}) => {
  return `Game/${gameId}/Tournament/${tournamentId}/Round/${roundId}/Match/${matchId}/Result`;
};

export const RESULT_PATH =
  'Game/{gameId}/Tournament/{tournamentId}/Round/{roundId}/Match/{matchId}/Result/{resultId}' as const;
export type ResultPath = typeof RESULT_PATH;
