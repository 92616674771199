export const CHANNEL_GROUP_PATH =
  'User/{userId}/ChannelGroup/{channelGroupId}' as const;
export type ChannelGroupPath = typeof CHANNEL_GROUP_PATH;

export const toChannelGroupCollectionPath = (userId: string) => {
  return `User/${userId}/ChannelGroup`;
};

export type ChannelGroupDocumentPathParams = {
  userId: string;
  channelGroupId: string;
};

export const toChannelGroupDocumentPath = ({
  userId,
  channelGroupId,
}: ChannelGroupDocumentPathParams) => {
  return `${toChannelGroupCollectionPath(userId)}/${channelGroupId}`;
};
