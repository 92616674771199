import { ReactNode, useRef, useCallback, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ChevronRightIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftRounded';
import { useTheme } from '@mui/material/styles';
import { GradientIconButton } from '../../../gradients/GradientIconButton';
import {
  GliderContainer,
  ReactGlider,
  ReactGliderProps,
} from '../../../ReactGlider';
import { memo } from '../../../../util/memo';
import { useNearEndContent } from '../../../../hooks/content/useNearEndContent';
import { CatalogWrapperProps } from '../../catalog-wrappers/CatalogWrapperProps';
import { Hit } from '../../../../../functions/src/types/Hit';
import { Identifiable } from '../../../../../functions/src/types/Identifiable';
import { InfiniteCatalog } from './InfiniteCatalog';

export const HORIZONTAL_CAROUSEL_BUFFER = 7 as const;

export type HorizontalCarouselProps<THit> = Omit<
  CatalogWrapperProps<THit>,
  'hits' | 'transformHits' | 'onNearEnd'
> &
  InfiniteCatalog &
  Omit<ReactGliderProps, 'hasArrows' | 'children'> & {
    header?: ReactNode;
  };

const HorizontalCarouselUnmemoized = <THit extends Identifiable & Hit>({
  content,
  header,
  onNearEnd,
  ...gliderProps
}: HorizontalCarouselProps<THit>) => {
  const { nearEndContent } = useNearEndContent({
    content,
    onNearEnd,
    scrollDirection: 'right',
  });

  const gliderRef = useRef<GliderContainer>(null);

  const theme = useTheme();

  const shiftLeft = useCallback(() => {
    gliderRef.current?.shiftCursor(-1);
  }, [gliderRef]);

  const shiftRight = useCallback(() => {
    gliderRef.current?.shiftCursor(1);
  }, [gliderRef]);

  const iconButtonStyle = useMemo(() => {
    return {
      backgroundColor: theme.palette.action.background,
      borderRadius: '100px',
      padding: '8px',
      width: '40px',
      height: '40px',
    } as const;
  }, [theme.palette.action.background]);

  const navigationArrows = useMemo(() => {
    return (
      content.length > 1 && (
        // eslint-disable-next-line @blumintinc/blumint/no-margin-properties
        <Box sx={{ marginLeft: 'auto' }}>
          <Stack direction="row" spacing={1}>
            <GradientIconButton
              gradientColor="primary.vertical"
              IconComponent={ChevronLeftIcon}
              sx={iconButtonStyle}
              onClick={shiftLeft}
            />
            <GradientIconButton
              gradientColor="primary.vertical"
              IconComponent={ChevronRightIcon}
              sx={iconButtonStyle}
              onClick={shiftRight}
            />
          </Stack>
        </Box>
      )
    );
  }, [content.length, iconButtonStyle, shiftLeft, shiftRight]);

  return (
    <Stack spacing={2} sx={{ height: '100%', width: '100%' }}>
      <Stack direction="row" justifyContent="space-between">
        {header}
        {navigationArrows}
      </Stack>
      <Box
        sx={{
          height: '100%',
          '& .glider-track': { height: '100%', width: '100% !important' },
          '& .glider-contain': { height: '100%' },
          '& .glider-children-container': { height: '100%' },
          '& .glider-slide.active': {
            width: '100% !important',
          },
          '& .glider': {
            overflowX: content.length > 1 ? 'auto' : 'hidden',
          },
        }}
      >
        <ReactGlider
          {...gliderProps}
          draggable={content.length > 1}
          gliderRef={gliderRef}
          hasArrows={false}
        >
          {nearEndContent}
        </ReactGlider>
      </Box>
    </Stack>
  );
};

export const HorizontalCarousel = memo(HorizontalCarouselUnmemoized);
