import { useState, useMemo, useCallback } from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutlineRounded';
import GroupAddIcon from '@mui/icons-material/GroupAddRounded';
import { memo } from '../../util/memo';
import { GradientIcon } from '../gradients/GradientIcon';
import { useAuth } from '../../contexts/AuthContext';
import { LottieLoader } from '../LottieLoader';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useSubgroupPermissions } from '../../hooks/group/useSubgroupPermissions';
import { GroupChannelCreationDialog } from '../tournaments/registration/dialogs/GroupChannelCreateDialog';
import { useDialog } from '../../hooks/useDialog';
import { useGroupRouterExternal } from '../../hooks/group/useGroupRouterExternal';

export type ChatMenuProps = Omit<MenuProps, 'open'> & {
  createChat: () => Promise<void> | void;
  onCreateSupport?: () => Promise<void> | void;
};

const ChatMenuUnmemoized = ({
  anchorEl,
  createChat,
  onCreateSupport,
  ...props
}: ChatMenuProps) => {
  const { uid } = useAuth();
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const { groupId } = useGroupRouterExternal();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const subgroupPermissions = useSubgroupPermissions(groupId);

  const canManageChannels = subgroupPermissions.includes('manageChannels');

  const createSupportChat = useCallback(async () => {
    if (!uid) {
      return;
    }
    setIsLoading(true);

    const { id } = await setPersonalChannelGroup({
      type: 'support',
      friendIds: [],
      messagesInitial: [
        {
          text: 'Welcome to BluMint Support. Drop your message below and a member of our team will get back to you shortly :)',
        },
      ],
    });
    openChannelGroup({ channelGroupId: id });
    await onCreateSupport?.();
    setIsLoading(false);
  }, [uid, setPersonalChannelGroup, openChannelGroup, onCreateSupport]);

  const { open: openChannelCreationDialog, close: closeChannelCreationDialog } =
    useDialog('groupChannelCreation');

  const createGroupChannel = useCallback(() => {
    openChannelCreationDialog({
      children: (
        <GroupChannelCreationDialog
          onCancel={closeChannelCreationDialog}
          onSubmit={closeChannelCreationDialog}
        />
      ),
    });
  }, [openChannelCreationDialog, closeChannelCreationDialog]);

  const menuItems = useMemo(() => {
    const items = [
      {
        onClick: createChat,
        label: 'Create Chat',
        Icon: AddIcon,
      },
      {
        onClick: createSupportChat,
        label: 'Create Support Chat',
        Icon: HelpOutlineIcon,
      },
    ];

    if (canManageChannels) {
      items.push({
        onClick: createGroupChannel,
        label: 'Create Group Channel',
        Icon: GroupAddIcon,
      });
    }

    return items;
  }, [createChat, createSupportChat, canManageChannels, createGroupChannel]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onCreateSupport}
      {...props}
    >
      {menuItems.map(({ onClick, label, Icon }) => {
        return (
          <MenuItem key={label} sx={{ maxWidth: '230px' }} onClick={onClick}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              spacing={2}
              width="100%"
            >
              {isLoading && label === 'Create Support Chat' ? (
                <LottieLoader sx={{ width: '20px', height: '20px' }} />
              ) : (
                <GradientIcon
                  gradientColor="primary.horizontal"
                  IconComponent={Icon}
                  sx={{
                    height: '24px',
                    width: '24px',
                  }}
                />
              )}
              <Typography
                color="text.primary"
                sx={{ pr: 3, textTransform: 'capitalize' }}
                variant="body1"
              >
                {label}
              </Typography>
            </Stack>
          </MenuItem>
        );
      })}
    </Menu>
  );
};

export const ChatMenu = memo(ChatMenuUnmemoized);
