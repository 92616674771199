import { forwardRef } from 'react';
import NotificationsIcon from '@mui/icons-material/NotificationsRounded';
import { Optional } from 'utility-types';
import { GradientIconProps, GradientIcon } from '../gradients/GradientIcon';
import { BadgeNotification, BadgeNotificationProps } from './BadgeNotification';
import { memo } from 'src/util/memo';

export type LiveNotificationIconProps = Optional<
  GradientIconProps,
  'IconComponent'
> &
  Pick<BadgeNotificationProps, 'wheres'>;

const LiveNotificationIconUnmemoized = forwardRef<
  SVGSVGElement,
  LiveNotificationIconProps
>(({ wheres, ...props }, ref) => {
  return (
    <BadgeNotification wheres={wheres}>
      <GradientIcon ref={ref} IconComponent={NotificationsIcon} {...props} />
    </BadgeNotification>
  );
});

LiveNotificationIconUnmemoized.displayName = 'LiveNotificationIconUnmemoized';

export const LiveNotificationIcon = memo(LiveNotificationIconUnmemoized);
