/* eslint-disable @blumintinc/blumint/class-methods-read-top-to-bottom */
import { TimeDifference } from './TimeDifference';

export type RelativeTimeUnit =
  (typeof RelativeTimeFormatter.UNITS_LARGE_TO_SMALL)[number];

export type RelativeTimeFormatterSettings = {
  unitsCount?: number;
  options?: Intl.RelativeTimeFormatOptions;
  unitsToConsider?: readonly RelativeTimeUnit[];
};

export class RelativeTimeFormatter {
  private static readonly JUST_NOW_THRESHOLD_SECONDS = 120;
  private static readonly JUST_NOW_TEXT = 'Just now';
  private static readonly TODAY_TEXT = 'Today';
  public static readonly UNITS_LARGE_TO_SMALL = [
    'year',
    'month',
    'week',
    'day',
    'hour',
    'minute',
    'second',
  ] as const;

  private readonly settings: RelativeTimeFormatterSettings;
  public constructor({
    unitsCount = 1,
    options,
    unitsToConsider = RelativeTimeFormatter.UNITS_LARGE_TO_SMALL,
  }: RelativeTimeFormatterSettings = {}) {
    this.settings = {
      unitsCount,
      options,
      unitsToConsider,
    };
  }

  private formatUnits(date: Date, relativeTo: Date) {
    const increments = RelativeTimeFormatter.allIncrements(date, relativeTo);
    const units = this.truncate(this.nonZeroUnits(date, relativeTo));
    return units
      .map((unit, i) => {
        return this.formatUnit(
          unit,
          increments[unit as keyof typeof increments],
          i,
          units.length,
        );
      })
      .join(' ');
  }

  private static allIncrements(date: Date, relativeTo: Date) {
    const differences = new TimeDifference(date, relativeTo);
    const coefficient = differences.date1LaterThanDate2 ? 1 : -1;
    return {
      second: (coefficient * differences.seconds) % 60,
      minute: (coefficient * differences.minutes) % 60,
      hour: (coefficient * differences.hours) % 24,
      day: (coefficient * differences.days) % 7,
      week: (coefficient * differences.weeks) % 4,
      month: coefficient * differences.months,
      year: coefficient * differences.years,
    };
  }

  private truncate(units: RelativeTimeUnit[]) {
    return units.slice(
      0,
      Math.min(this.settings.unitsCount ?? 1, units.length),
    );
  }

  private nonZeroUnits(date: Date, relativeTo: Date) {
    const increments = RelativeTimeFormatter.allIncrements(date, relativeTo);
    return RelativeTimeFormatter.UNITS_LARGE_TO_SMALL.filter((unit) => {
      return (
        increments[unit as keyof typeof increments] !== 0 &&
        (
          this.settings.unitsToConsider ??
          RelativeTimeFormatter.UNITS_LARGE_TO_SMALL
        ).includes(unit)
      );
    });
  }

  // eslint-disable-next-line max-params
  private formatUnit(
    unit: string,
    value: number,
    index: number,
    totalUnits: number,
  ) {
    if (!Number.isFinite(value)) {
      throw new RangeError(
        `Value for unit "${unit}" is not a finite number: ${value}`,
      );
    }

    const formatted = this.rtf.format(
      value,
      unit as Intl.RelativeTimeFormatUnit,
    );

    if (index === 0) {
      return formatted;
    }
    if (index < totalUnits - 1) {
      return RelativeTimeFormatter.stripPrefix(
        RelativeTimeFormatter.stripSuffix(formatted),
      );
    }
    return RelativeTimeFormatter.stripPrefix(formatted);
  }

  private get rtf(): Intl.RelativeTimeFormat {
    return new Intl.RelativeTimeFormat('en', this.settings.options);
  }

  public format(date: Date, relativeTo: Date = new Date()): string {
    const nonZeroUnits = this.nonZeroUnits(date, relativeTo);
    if (nonZeroUnits.length === 0) {
      const differenceInSeconds = Math.abs(
        (date.getTime() - relativeTo.getTime()) / 1000,
      );
      if (
        differenceInSeconds < RelativeTimeFormatter.JUST_NOW_THRESHOLD_SECONDS
      ) {
        return RelativeTimeFormatter.JUST_NOW_TEXT;
      }
      return RelativeTimeFormatter.TODAY_TEXT;
    }
    return this.formatUnits(date, relativeTo);
  }

  private static stripPrefix(formatted: string) {
    return formatted.replace('in ', '');
  }

  private static stripSuffix(formatted: string) {
    return formatted.replace(' ago', '');
  }
}
