/* eslint-disable no-restricted-syntax */
/* eslint-disable @blumintinc/blumint/enforce-boolean-naming-prefixes */
/* eslint-disable import/no-cycle */
import { useCallback } from 'react';
import { useWizardDialog } from '../wizard/useWizardDialog';
import { AuthenticationRegistrationStartDialog } from '../../components/authentication/flow-dialogs/AuthenticationRegistrationStartDialog';
import { AuthenticationEnterPhoneDialog } from '../../components/authentication/flow-dialogs/AuthenticationEnterPhoneDialog';
import { AuthenticationEnterConfirmationCodeDialog } from '../../components/authentication/flow-dialogs/AuthenticationEnterConfirmationCodeDialog';
import { AuthenticationEmailSignInDialog } from '../../components/authentication/flow-dialogs/AuthenticationEmailSignInDialog';
import { AuthenticationEmailSignUpDialog } from '../../components/authentication/flow-dialogs/AuthenticationEmailSignUpDialog';
import { AuthenticationEnterEmailDialog } from '../../components/authentication/flow-dialogs/AuthenticationEnterEmailDialog';
import { AuthenticationVerifyingEmailDialog } from '../../components/authentication/flow-dialogs/AuthenticationVerifyingEmailDialog';
import { AuthenticationFlowCompleteDialog } from '../../components/authentication/flow-dialogs/AuthenticationFlowCompleteDialog';
import { AuthenticationResetPasswordDialog } from '../../components/authentication/flow-dialogs/AuthenticationResetPasswordDialog';
import { AuthLinkMethodsDialog } from '../../components/authentication/flow-dialogs/AuthLinkMethodsDialog';
import { AuthSubmitProvider } from '../../contexts/AuthSubmitContext';
import { WizardDialogProps } from '../../components/wizard/WizardDialog';
import { useRemoveAuthQueryParams } from '../useRemoveAuthQueryParams';
import { WizardElements, WizardProps } from '../../components/wizard/Wizard';
import { EmailVerificationCompleteDialog } from '../../components/authentication/flow-dialogs/EmailVerificationCompleteDialog';
import { assertSafe } from 'functions/src/util/assertSafe';

// eslint-disable-next-line @blumintinc/blumint/no-hungarian, @blumintinc/blumint/enforce-singular-type-names
export type StoredPhoneNumberData = {
  country: string;
  countryDialCode: string;
  phoneNumber: string;
  phoneNumberUnformatted: string;
};

export type AuthFlowStore = {
  authMethod: string;
  email: string;
  password: string;
  /** We can update this later once we have the information to determine this */
  accountStatus: string;
  existingAccountHasBeenAuthenticated: boolean;
  phone: StoredPhoneNumberData;
  hasValidatedPhoneNumber: boolean;
  validatedPhoneNumber: StoredPhoneNumberData;
  agreedTermsOfUse: boolean;
  agreedPrivacyPolicy: boolean;
  agreed13OrOlder: boolean;
};

export const WELCOME_TITLE = 'WELCOME TO BLUMINT!' as const;
export const LINKED_TITLE = 'NEW SIGN IN METHOD ADDED!' as const;
export const REGISTRATION_SUCCESSFUL_MESSAGE =
  'You have successfully registered!' as const;
export const EMAIL_VERIFIED_SUCCESS = 'Email verified successfully.' as const;
export const PASSWORD_UPDATE_SUCESS = 'Password updated successfully.' as const;
export const AUTH_STORE_DEFAULT: AuthFlowStore = {
  authMethod: 'none',
  email: '',
  password: '',
  // We can update this later once we have the information to determine this
  accountStatus: 'new',
  existingAccountHasBeenAuthenticated: false,
  hasValidatedPhoneNumber: false,
  validatedPhoneNumber: {
    country: '',
    countryDialCode: '',
    phoneNumber: '',
    phoneNumberUnformatted: '',
  },
  phone: {
    country: 'us',
    countryDialCode: '1',
    phoneNumber: '',
    phoneNumberUnformatted: '',
  },
  agreedTermsOfUse: true,
  agreedPrivacyPolicy: true,
  agreed13OrOlder: true,
};

export type AuthFlowId =
  | 'Sign In Options'
  | 'Enter Phone Number'
  | 'Enter Confirmation Code'
  | 'Email Sign In'
  | 'Email Sign Up'
  | 'Enter Email'
  | 'Verifying Email'
  | 'Verifying Email Success'
  | 'Reset Password'
  | 'Reset Password Success'
  | 'Registration End'
  | 'Link More Methods'
  | 'Link Successful'
  | 'Link Email'
  | 'Re-Authenticate';

export const AUTH_IDS_TO_DIALOG = {
  'Sign In Options': <AuthenticationRegistrationStartDialog />,
  'Enter Phone Number': <AuthenticationEnterPhoneDialog />,
  'Enter Confirmation Code': <AuthenticationEnterConfirmationCodeDialog />,
  'Email Sign In': <AuthenticationEmailSignInDialog />,
  'Email Sign Up': <AuthenticationEmailSignUpDialog title="Sign Up" />,
  'Enter Email': <AuthenticationEnterEmailDialog />,
  'Verifying Email': <AuthenticationVerifyingEmailDialog />,
  'Verifying Email Success': <EmailVerificationCompleteDialog />,
  'Reset Password': <AuthenticationResetPasswordDialog />,
  'Reset Password Success': (
    <AuthenticationFlowCompleteDialog
      description={PASSWORD_UPDATE_SUCESS}
      title={WELCOME_TITLE}
    />
  ),
  'Registration End': (
    <AuthenticationFlowCompleteDialog
      description={REGISTRATION_SUCCESSFUL_MESSAGE}
      title={WELCOME_TITLE}
    />
  ),
  'Link More Methods': <AuthLinkMethodsDialog />,
  'Link Successful': (
    <AuthenticationFlowCompleteDialog
      description="You have successfully added a new sign in method to your account."
      title={LINKED_TITLE}
    />
  ),
  'Link Email': (
    <AuthenticationEmailSignUpDialog
      description="This email is new! Link now?"
      title="Link Email"
    />
  ),
} as const;

export const BASE_AUTH_DEPENDENCY_IDS: AuthFlowId[] = [
  'Enter Email',
  'Verifying Email',
  'Verifying Email Success',
  'Enter Phone Number',
  'Enter Confirmation Code',
  'Email Sign In',
];

export type OpenAuthFlowProps = Omit<
  WizardProps<AuthFlowStore, WizardElements>,
  'elements' | 'Wrapper'
> & {
  wrapperProps?: Partial<WizardDialogProps>;
  elementIds: AuthFlowId[];
};
export const useAuthFlowBase = () => {
  const { open: openWizardDialog, close, isOpen } = useWizardDialog();
  const removeAuthQueryParams = useRemoveAuthQueryParams();

  const open = useCallback(
    ({
      storeDefault,
      elementIds,
      wrapperProps,
      elementIdEntry,
      onClose,
      ...rest
    }: OpenAuthFlowProps) => {
      const elements = elementIds.reduce(
        (acc, curr) => {
          // eslint-disable-next-line security/detect-object-injection
          acc[assertSafe(curr)] = AUTH_IDS_TO_DIALOG[assertSafe(curr)];
          return acc;
        },
        // eslint-disable-next-line security/detect-object-injection
        { [elementIdEntry]: AUTH_IDS_TO_DIALOG[assertSafe(elementIdEntry)] },
      );

      openWizardDialog({
        storeDefault,
        elements,
        elementIdEntry,
        Wrapper: <AuthSubmitProvider />,
        wrapperProps: {
          shouldPreventBackdropClick: true,
          ...wrapperProps,
        },
        onClose: () => {
          removeAuthQueryParams();
          onClose?.();
        },
        ...rest,
      });
    },
    [openWizardDialog, removeAuthQueryParams],
  );

  return { open, close, isOpen } as const;
};
