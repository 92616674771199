import Typography, { TypographyProps } from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { User } from '../../../../functions/src/types/firestore/User';
import { ELLIPSIS_SX } from '../../../util/string';

export const MAX_WIDTH_USER_CARD_STATUS = '124px' as const;

export type UserCardStatusProps = Pick<User, 'status'> & TypographyProps;

const UserCardStatusUnmemoized = ({
  status,
  sx,
  maxWidth = MAX_WIDTH_USER_CARD_STATUS,
  ...statusProps
}: UserCardStatusProps) => {
  if (!status) {
    return null;
  }
  return (
    <Typography
      color="text.secondary"
      sx={{ ...ELLIPSIS_SX, maxWidth, ...sx }}
      variant="subtitle2"
      {...statusProps}
    >
      {status}
    </Typography>
  );
};

export const UserCardStatus = memo(UserCardStatusUnmemoized);
