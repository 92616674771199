import type { DocumentData } from 'firebase/firestore';
import { CentralizerContext } from './CentralizerContext';
import { PathDocumentCentralizedProvider } from './provider/PathDocumentCentralizedProvider';
import { PathStateCentralizedProvider } from './provider/PathStateCentralizedProvider';

export class CentralizerContextFactory {
  public static buildReactState<TObj extends Record<string, unknown>>() {
    const provider = new PathStateCentralizedProvider<TObj>();
    return new CentralizerContext(provider);
  }

  public static buildFirestoreDocument<TObj extends DocumentData>() {
    const provider = new PathDocumentCentralizedProvider<TObj>();
    return new CentralizerContext(provider);
  }

  public static buildFirestoreDocumentNonRealtime<TObj extends DocumentData>() {
    const provider = new PathDocumentCentralizedProvider<TObj>(
      ({ initialData }) => {
        return initialData;
      },
    );
    return new CentralizerContext(provider);
  }
}
