import { GridColDef } from '@mui/x-data-grid';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { Rank } from '../heats-leaderboards/Rank';

export const HEADER_NAME_RANK = 'RANK' as const;
export const RANK_COLUMN: GridColDef<ResultRanked> = {
  field: 'rank',
  headerName: HEADER_NAME_RANK,
  minWidth: 80,
  maxWidth: 100,
  flex: 1,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  sortable: false,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  editable: false,
  renderCell: ({ row: { rank } }) => {
    return <Rank rank={rank} />;
  },
};
