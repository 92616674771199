/* eslint-disable max-lines */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import type {
  GroupFilter,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { memo } from '../../../util/memo';
import { AvatarGroupNext } from '../../avatar/AvatarGroupNext';
import { ReplyProvider } from '../ReplyContext';
import { toKebabCase } from '../../../util/string';
import { useAuth } from '../../../contexts/AuthContext';
import { LottieLoader } from '../../LottieLoader';
import { toChannelId } from '../../../../functions/src/util/messaging/toChannelId';
import { ActiveChannelGroup } from '../../../../functions/src/util/messaging/toActiveChannelGroup';
import { isPersonalFilter } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isPersonal';
import { sortGroups } from '../../../util/messaging/sortGroups';
import { useStream } from '../../../contexts/get-stream/StreamContext';
import { ChannelListProvider } from '../../../contexts/ChannelListContext';
import { MessageInputFocusProvider } from '../../../contexts/get-stream/MessageInputFocusContext';
import { toEventUrl } from '../../../util/event/toEventUrl';
import { composeCid } from '../../../util/messaging/composeCid';
import { useDetectPresence } from '../../../hooks/presence/useDetectPresence';
import { isTournamentFilter } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isTournament';
import {
  DynamicImport,
  StreamChatReact,
  STREAM_CHAT_REACT,
} from '../../../../functions/src/types/DynamicModule';
import { useDynamic } from '../../../hooks/useDynamic';
import { withDynamicImport } from '../../../util/withDynamicImport';
import { AVATAR_SIZE_PX_RESPONSIVE_SECONDARY } from '../../avatar/AvatarNext';
import { ChannelGroupHeader } from './ChannelGroupHeader';
import { ChannelGroup } from './ChannelGroup';
import { assertSafe } from 'functions/src/util/assertSafe';

export type ChannelGroupActiveProps = {
  channelGroup: ActiveChannelGroup;
  modules: DynamicImport<[StreamChatReact]>;
};

const ChannelGroupActiveUnmemoized = ({
  channelGroup,
  modules,
}: ChannelGroupActiveProps) => {
  const theme = useTheme();
  const { userData } = useAuth();

  const {
    groupFilter,
    title,
    subtitle,
    hasMultipleChannels,
    avatarGroupTotal,
    isDm,
    isSupport,
    isTournament,
    isChannelCreated,
    permanence,
    memberIds,
    avatarEntities,
    roles,
    id,
  } = channelGroup;

  const channelGroupRef = useRef<HTMLDivElement>(null);
  useDetectPresence<HTMLDivElement>({
    elementId: id,
    target: channelGroupRef.current,
  });

  const channelGroupTitleColor = `${
    isTournament ? 'warning' : 'primary'
  }.vertical`;

  const avatarBorderColor = useMemo(() => {
    if (isTournament) {
      return theme.palette.warning.main;
    }
    if (!isSupport) {
      return;
    }
    return theme.palette.primary.main;
  }, [
    isTournament,
    isSupport,
    theme.palette.primary.main,
    theme.palette.warning.main,
  ]);

  const avatarBorder = avatarBorderColor
    ? undefined
    : `2px solid ${theme.palette.background.elevationSolid[6]} !important`;

  const profileId = useMemo(() => {
    const userId = memberIds?.filter((memberId) => {
      return memberId !== userData?.id;
    });

    if (!title || !isDm || !userId) {
      return;
    }
    return userId[0];
  }, [isDm, memberIds, title, userData?.id]);

  const tournamentId = isTournamentFilter(groupFilter)
    ? groupFilter[0].tournamentId
    : undefined;

  const headerHref = useMemo(() => {
    if (isDm) {
      return `/${profileId}`;
    }
    if (!tournamentId || !roles) {
      return;
    }

    const { eventUrlPath } = toEventUrl({
      id: tournamentId,
      gameId: toKebabCase(subtitle),
      roles,
    });

    return eventUrlPath;
  }, [isDm, tournamentId, subtitle, roles, profileId]);

  const groupFilterModified: GroupFilter<keyof GroupFilterMap> = useMemo(() => {
    if (!isPersonalFilter(groupFilter)) {
      return groupFilter;
    }

    const { type } = groupFilter[0];

    const channelId = toChannelId(groupFilter[0]);

    const cid = composeCid({ channelType: type, channelId });

    const personalFilter: GroupFilter<'Direct'> = [
      {
        cid: { $in: [cid] },
        type,
      },
    ];

    return personalFilter;
  }, [groupFilter]);

  const streamChatFrontendModule = useDynamic(
    import('../../../config/get-stream/streamChatFrontend'),
  );
  const { streamChatFrontend } = streamChatFrontendModule || {};
  const { isLoading } = useStream();
  const { Chat } = modules[assertSafe(STREAM_CHAT_REACT)];

  return (
    <ReplyProvider>
      <ChannelListProvider defaultOpen={hasMultipleChannels}>
        {!isLoading && !!streamChatFrontend?.user && (
          <Chat client={streamChatFrontend} theme="str-chat__theme-dark">
            <ChannelGroupHeader
              Figure={
                <AvatarGroupNext
                  entities={avatarEntities}
                  size={AVATAR_SIZE_PX_RESPONSIVE_SECONDARY}
                  sx={{
                    '.MuiAvatar-root': {
                      border: avatarBorder,
                      backgroundColor: theme.palette.action.disabled,
                      fontSize: '16px',
                    },
                    borderColor: avatarBorderColor,
                  }}
                  total={avatarGroupTotal}
                />
              }
              gradientColor={channelGroupTitleColor}
              href={headerHref}
              permanence={permanence}
              subtitle={subtitle}
              title={title}
              tournamentId={tournamentId}
            />
            {isChannelCreated ? (
              <MessageInputFocusProvider>
                <Box ref={channelGroupRef} height="100%" width="100%">
                  <ChannelGroup
                    groupFilter={groupFilterModified}
                    hasMultipleChannels={hasMultipleChannels}
                    sortGroups={sortGroups}
                  />
                </Box>
              </MessageInputFocusProvider>
            ) : (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{ height: '100%', width: '100%' }}
              >
                <LottieLoader sx={{ height: '40px', width: '40px' }} />
              </Stack>
            )}
          </Chat>
        )}
      </ChannelListProvider>
    </ReplyProvider>
  );
};

export const ChannelGroupActive = memo(
  withDynamicImport({
    Component: ChannelGroupActiveUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
