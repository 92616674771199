export const ANCHOR_ORIGIN_TOP_RIGHT = {
  horizontal: 'right',
  vertical: 'top',
} as const;

export const ANCHOR_ORIGIN_BOTTOM_RIGHT = {
  horizontal: 'right',
  vertical: 'bottom',
} as const;

export const ANCHOR_ORIGIN_BOTTOM_CENTER = {
  horizontal: 'center',
  vertical: 'bottom',
} as const;

export const ANCHOR_ORIGIN_BOTTOM_LEFT = {
  horizontal: 'left',
  vertical: 'bottom',
} as const;
