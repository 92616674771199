import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useRouterState } from '../routing/useRouterState';
import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';
import { useMatchDetailsWizard } from './match-details/useMatchDetailsWizard';

export const MATCH_DIALOG_KEY = 'match-session' as const;
export const SESSION_DIALOG_KEY = 'session-dialog' as const;

export const DEFAULT_MATCH_ROUTER_VALUES: ActiveMatchRouter = {
  matchId: undefined,
  sessionIndex: 0,
} as const;

export type UseMatchDetailsRouterProps = {
  matchIdRouter?: string;
  roundId?: string;
  tournament?: Tournament<Date>;
};

export type ActiveMatchRouter = {
  matchId?: string;
  sessionIndex?: number;
};

export type SetActiveMatchRouter = Dispatch<SetStateAction<ActiveMatchRouter>>;

export const useMatchDetailsRouter = ({
  matchIdRouter: match,
  tournament,
  roundId,
}: UseMatchDetailsRouterProps = {}) => {
  const { open: openMatchDetails } = useMatchDetailsWizard();

  const [matchId, setMatchId] = useRouterState({
    key: MATCH_DIALOG_KEY,
    location: 'queryParam',
    silent: true,
  });

  const [sessionIndexStr, setSessionIndexStr] = useRouterState({
    key: SESSION_DIALOG_KEY,
    location: 'queryParam',
    defaultValue: '0',
  });

  const sessionIndex = Number.parseInt(sessionIndexStr || '0');

  const currentState: ActiveMatchRouter = useMemo(() => {
    return {
      matchId,
      sessionIndex,
    };
  }, [matchId, sessionIndex]);

  const setState: SetActiveMatchRouter = useCallback(
    (newState) => {
      const nextState =
        typeof newState === 'function' ? newState(currentState) : newState;
      setMatchId(nextState.matchId);
      setSessionIndexStr(nextState.sessionIndex?.toString());
    },
    [currentState, setMatchId, setSessionIndexStr],
  );

  const close = useCallback(() => {
    setState(DEFAULT_MATCH_ROUTER_VALUES);
  }, [setState]);

  // Effect to handle match details
  useEffect(() => {
    const matchVerified = matchId === match && match && tournament && roundId;
    if (!matchVerified) {
      return;
    }
    openMatchDetails({
      onClose: close,
      matchId: match,
      tournament,
      roundId,
    });
  }, [close, match, matchId, openMatchDetails, roundId, tournament]);

  return [currentState, setState] as const;
};
