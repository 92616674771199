import diff from 'microdiff';
import { useCallback, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useRouter } from '../routing/useRouter';
import { useExtractUrl } from '../../hooks/messaging/useExtractUrl';
import { useDynamic } from '../useDynamic';
import { useTemporaryChannelGroups } from './useTemporaryChannelGroups';
import { assertSafe } from 'functions/src/util/assertSafe';

export const useTemporaryChannelGroupSyncer = () => {
  const router = useRouter();
  const { uidFull } = useAuth();
  const { extractUrlTemporaryIdentifiers } = useExtractUrl();
  const { temporaryChannelGroups, setTemporaryChannelGroups } =
    useTemporaryChannelGroups();

  const firebaseModule = useDynamic(import('firebase/firestore'));

  const updateTemporaryChannelGroup = useCallback(
    (url: string) => {
      if (!uidFull || !firebaseModule) {
        return;
      }

      const identifiers = extractUrlTemporaryIdentifiers(url);

      const differences = diff(temporaryChannelGroups || {}, identifiers);
      if (differences.length === 0) {
        return;
      }

      const { deleteField } = firebaseModule;
      const updates = { ...identifiers };
      for (const difference of differences) {
        if (difference.type === 'REMOVE') {
          updates[assertSafe(difference.path[0])] = deleteField();
        }
      }

      setTemporaryChannelGroups(updates);
    },
    [
      extractUrlTemporaryIdentifiers,
      setTemporaryChannelGroups,
      temporaryChannelGroups,
      firebaseModule,
      uidFull,
    ],
  );

  useEffect(() => {
    updateTemporaryChannelGroup(window.location.href);

    const routeChange = (url: string) => {
      updateTemporaryChannelGroup(url);
    };

    router.events.on('routeChangeComplete', routeChange);

    return () => {
      router.events.off('routeChangeComplete', routeChange);
    };
  }, [router.events, updateTemporaryChannelGroup]);
};
