import { useEffect } from 'react';

function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js', {
        scope: '/',
      })
      .then((registration) => {
        // eslint-disable-next-line no-console
        console.log(
          'Service Worker registered with scope:',
          registration.scope,
        );
      })
      .catch((err) => {
        console.error('Service Worker registration failed:', err);
      });
  }
}

export const useRegisterServiceWorker = (): void => {
  useEffect(() => {
    registerServiceWorker();
  }, []);
};
