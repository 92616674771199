/* eslint-disable @blumintinc/blumint/require-dynamic-firebase-imports */
import type { Query } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import {
  RealtimeContextFactory,
  RealtimeContextType,
} from './realtime/RealtimeContextFactory';
import { Notification } from 'functions/src/types/firestore/User/Notification';
import { toUserNotificationCollectionPath } from 'functions/src/types/firestore/User/Notification/path';

export type ArchivedNotification = Omit<Notification<Date>, 'status'> & {
  status: 'archived';
};
export type RealtimeArchivedNotificationsContextType =
  RealtimeContextType<ArchivedNotification>;

const buildArchivedQuery = (collectionRef: Query<ArchivedNotification>) => {
  return query(collectionRef, where('status', '==', 'archived'));
};

const {
  Context: RealtimeArchivedNotificationsContext,
  Provider: RealtimeArchivedNotificationsProvider,
  useRealtimeContext: useRealtimeArchivedNotifications,
} = RealtimeContextFactory.build<ArchivedNotification>({
  getPath: toUserNotificationCollectionPath,
  buildQuery: buildArchivedQuery,
  timeKey: 'createdAt',
});

export {
  RealtimeArchivedNotificationsContext,
  RealtimeArchivedNotificationsProvider,
  useRealtimeArchivedNotifications,
};
