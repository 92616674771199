import { useTheme } from '@mui/material/styles';
import { FC, useCallback, useMemo } from 'react';
import Chip from '@mui/material/Chip';
import { memo } from '../../../util/memo';
import { GradientTypography } from '../../gradients/GradientTypography';
import { useFriendRequestDialog } from '../../../hooks/friends/useFriendRequestDialog';
import { useAuth } from '../../../contexts/AuthContext';
import { User } from '../../../../functions/src/types/firestore/User';
import { useRemoveFriendDialog } from '../../../hooks/friends/useRemoveFriendDialog';
import { useCancelFriendRequestDialog } from '../../../hooks/friends/useCancelFriendRequestDialog';
import { GroupInfoBasic } from '../../../../functions/src/types/firestore/Guild';
import { useEstimateMutualFriends } from '../../../hooks/friends/useEstimateMutualFriends';
import { FriendCardLayout } from './FriendCardLayout';

export type FriendCardAddProps = GroupInfoBasic &
  Pick<User, 'friends'> & {
    friendRequests?: {
      sent: string[];
      received: string[];
    };
  };

const FriendCardAddUnmemoized: FC<FriendCardAddProps> = ({
  imgUrl,
  username,
  id,
  friends,
}) => {
  const theme = useTheme();

  const { userData } = useAuth();
  const { open: openAddFriendDialog } = useFriendRequestDialog({
    username,
    imgUrl,
    userId: id,
  });
  const { open: openRemoveFriendDialog } = useRemoveFriendDialog({
    username,
    imgUrl,
    userId: id,
  });
  const { open: openCancelFriendRequestDialog } = useCancelFriendRequestDialog({
    username,
    imgUrl,
    userId: id,
  });

  const { friends: myFriends, hidden: myHidden } = userData || {};
  const { friendRequests: myFriendRequests } = myHidden || {};

  const friendshipButtonStatus = useMemo(() => {
    if (myFriends?.includes(id)) {
      return 'REMOVE';
    }
    if (myFriendRequests?.sent?.includes(id)) {
      return 'SENT';
    }
    return 'ADD';
  }, [id, myFriends, myFriendRequests?.sent]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const FriendshipStatus = useMemo(() => {
    return (
      <GradientTypography variant="subtitle1">
        {friendshipButtonStatus}
      </GradientTypography>
    );
  }, [friendshipButtonStatus]);

  const confirmAction = useCallback(() => {
    if (friendshipButtonStatus === 'ADD') {
      openAddFriendDialog();
    } else if (friendshipButtonStatus === 'REMOVE') {
      openRemoveFriendDialog();
    } else if (friendshipButtonStatus === 'SENT') {
      openCancelFriendRequestDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    friendshipButtonStatus,
    openAddFriendDialog,
    openCancelFriendRequestDialog,
    openRemoveFriendDialog,
  ]);

  const mutualFriendsCountEstimate = useEstimateMutualFriends({
    friends,
  });

  return (
    <FriendCardLayout {...{ imgUrl, username, mutualFriendsCountEstimate, id }}>
      <Chip
        label={FriendshipStatus}
        sx={{
          backgroundColor: theme.palette.background.elevation[14],
          width: '100px',
          height: '42px',
          '.MuiChip-label': {
            p: 0,
          },
        }}
        onClick={confirmAction}
      />
    </FriendCardLayout>
  );
};

export const FriendCardAdd = memo(FriendCardAddUnmemoized);
