import {
  DynamicModule,
  StreamChatReact,
} from '../../../functions/src/types/DynamicModule';
import { useIsUserInChannel } from './useIsUserInChannel';

export type UseIsUserInMatchChatProps = {
  streamChatModule: DynamicModule[StreamChatReact];
};

export const useIsUserInMatchChat = ({
  streamChatModule,
}: UseIsUserInMatchChatProps) => {
  const { useChatContext } = streamChatModule;
  const { channel } = useChatContext();
  const { type } = channel || {};

  const isUserInChat = useIsUserInChannel({ streamChatModule });

  return isUserInChat && type === 'match';
};
