import { useMemo } from 'react';
import { useSessionState } from '../useSessionState';

export const useVoiceChannel = () => {
  const [voiceChannelRaw, setVoiceChannel] =
    useSessionState<'voiceChannel'>('voiceChannel');

  const voiceChannel = useMemo(() => {
    if (!voiceChannelRaw) {
      return;
    }

    const { current, next, roomToken } = voiceChannelRaw;
    return {
      current: typeof current === 'string' ? current : undefined,
      next: typeof next === 'string' ? next : undefined,
      roomToken: typeof roomToken === 'string' ? roomToken : undefined,
    } as const;
  }, [voiceChannelRaw]);

  return { voiceChannel, setVoiceChannel } as const;
};
