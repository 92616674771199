import Badge, { BadgeProps } from '@mui/material/Badge';
import { SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { useUserNotificationCount } from '../../../contexts/UserNotificationCountContext';

export type FriendRequestsBadgeProps = BadgeProps & {
  children: ReactNode;
  sx?: SxProps;
};

const FriendRequestsBadgeUnmemoized = ({
  children,
  sx,
  ...props
}: FriendRequestsBadgeProps) => {
  const { friendRequests: count } = useUserNotificationCount();

  return (
    <Badge badgeContent={count} sx={sx} {...props}>
      {children}
    </Badge>
  );
};

export const FriendRequestsBadge = memo(FriendRequestsBadgeUnmemoized);
