import {
  createContext,
  useState,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
// import { useLivestreamMode } from '../hooks/mux-player/useLivestreamMode';
// import { useIsViewingLivestreamEvent } from '../hooks/mux-player/useIsViewingLivestreamEvent';

export type PictureInPictureContextProps = {
  isPictureInPicture: boolean;
  enterPictureInPicture: () => void;
  exitPictureInPicture: () => void;
};

export const PictureInPictureContext = createContext<
  PictureInPictureContextProps | undefined
>(undefined);

export type PictureInPictureProviderProps = {
  children: ReactNode;
};

export const usePictureInPicture = () => {
  const context = useContext(PictureInPictureContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'usePictureInPicture must be used within a PictureInPictureProvider',
    );
  }
  return context;
};

// eslint-disable-next-line @blumintinc/blumint/enforce-verb-noun-naming
const PictureInPictureProviderUnmemoized: FC<PictureInPictureProviderProps> = ({
  children,
}) => {
  const [isPictureInPicture, setIsPictureInPicture] = useState(false);

  const enterPictureInPicture = useCallback(() => {
    setIsPictureInPicture(true);
  }, [setIsPictureInPicture]);

  // const isViewingEvent = useIsViewingLivestreamEvent();
  // const { enterMiniPlayerMode } = useLivestreamMode();

  const exitPictureInPicture = useCallback(() => {
    setIsPictureInPicture(false);
    // if (isViewingEvent) {
    //   return;
    // }
    // enterMiniPlayerMode();
  }, [setIsPictureInPicture]);

  const memoizedValue = useMemo(() => {
    return {
      isPictureInPicture,
      enterPictureInPicture,
      exitPictureInPicture,
    };
  }, [isPictureInPicture, enterPictureInPicture, exitPictureInPicture]);

  return (
    <PictureInPictureContext.Provider value={memoizedValue}>
      {children}
    </PictureInPictureContext.Provider>
  );
};

export const PictureInPictureProvider = memo(
  PictureInPictureProviderUnmemoized,
);
