import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/material/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { memo } from '../../util/memo';
import { OverlayAvatar } from './OverlayAvatar';

const DEFAULT_SX = {} as const;

export type IconOverlayAvatarProps = {
  IconComponent: OverridableComponent<SvgIconTypeMap>;
  outerSx?: SxProps;
  iconSx?: SxProps;
};

const IconOverlayAvatarUnmemoized = ({
  IconComponent,
  outerSx = DEFAULT_SX,
  iconSx = DEFAULT_SX,
}: IconOverlayAvatarProps) => {
  return (
    <OverlayAvatar sx={outerSx}>
      <Stack
        sx={{
          fontSize: { xs: '20px', md: '24px' },
        }}
      >
        <IconComponent sx={iconSx} />
      </Stack>
    </OverlayAvatar>
  );
};

export const IconOverlayAvatar = memo(IconOverlayAvatarUnmemoized);
