//import { AdapexAd } from './AdapexAd';
import { AdContainerProps, AdSize } from './AdContainer';

export type AdProps = Omit<
  AdContainerProps<AdSize>,
  'findAdSize' | 'AdInsert' | 'refreshAd' | 'doIntervalRefresh'
> & {
  sticky?: boolean;
};

// Note: In the future, this component will decide whether to use
// AdapexAd, InternalAd, PersonaAd, or some other.
//export const Ad = AdapexAd;
export const Ad = () => {
  return <></>;
};
