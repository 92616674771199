import { Timestamp } from 'firebase-admin/firestore';
import {
  ChannelGroup,
  ChannelGroupFilterType,
  GroupFilterMap,
  PlaybackType,
  GroupFilter,
  PLAYBACK_TYPES,
} from '..';

export function isPlayback<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<Capitalize<PlaybackType>, TTime> {
  const { groupFilter } = channelGroup;
  return isPlaybackFilter(groupFilter);
}

export function isPlaybackType(
  type: ChannelGroupFilterType,
): type is PlaybackType {
  return PLAYBACK_TYPES.includes(type as PlaybackType);
}

export function isPlaybackFilter(
  filter: GroupFilter,
): filter is GroupFilter<Capitalize<PlaybackType>> {
  return (
    filter.length === 1 &&
    isPlaybackType(filter[0].type) &&
    'playbackId' in filter[0]
  );
}
