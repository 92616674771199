import Chip, { ChipProps } from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { memo } from '../util/memo';
import { Link } from './Link';
import { AvatarNext } from './avatar/AvatarNext';
import { truncateIfTooLong } from 'src/util/truncate';
import { assertSafe } from 'functions/src/util/assertSafe';

export const CHIP_USER_SIZE_PX_SMALL = 15 as const;
export const CHIP_USER_SIZE_PX_MEDIUM = 24 as const;

const SIZE_TO_WIDTH: Record<NonNullable<ChipProps['size']>, number> = {
  small: CHIP_USER_SIZE_PX_SMALL,
  medium: CHIP_USER_SIZE_PX_MEDIUM,
};

export type ChipUserProps = {
  username: string;
  avatarUrl: string;
  href?: string;
} & ChipProps;

function ChipUserUnmemoized({
  username,
  avatarUrl,
  onDelete,
  sx,
  href,
  size,
  ...propsChip
}: ChipUserProps) {
  const theme = useTheme();
  const avatarSize = size
    ? SIZE_TO_WIDTH[assertSafe(size)]
    : CHIP_USER_SIZE_PX_MEDIUM;

  const chip = useMemo(() => {
    return (
      <Chip
        avatar={<AvatarNext alt={username} size={avatarSize} src={avatarUrl} />}
        color="info"
        label={truncateIfTooLong(username)}
        size={size}
        sx={{
          '.MuiChip-label': {
            color: '#fff',
          },
          '&:hover': { background: theme.palette.info.vertical },
          cursor: 'pointer',
          ...sx,
        }}
        variant="outlined"
        onDelete={onDelete}
        {...propsChip}
      />
    );
  }, [
    username,
    avatarUrl,
    avatarSize,
    onDelete,
    sx,
    size,
    theme.palette.info.vertical,
    propsChip,
  ]);

  if (!href) return chip;

  return <Link href={href}>{chip}</Link>;
}

export const ChipUser = memo(ChipUserUnmemoized);
