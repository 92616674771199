import { useMemo } from 'react';
import { useOptimisticValue } from '../../contexts/OptimisticValueContext';
import {
  toRouterOptimisticKey,
  ToRouterOptimisticKeyProps,
} from '../../util/routing/toRouterOptimisticKey';
import { useRouter } from './useRouter';

// eslint-disable-next-line @blumintinc/blumint/no-unused-props
export type UseRouterValueOptimisticProps = ToRouterOptimisticKeyProps & {
  isCatchAll?: boolean;
};

export const useRouterValueOptimistic = ({
  key,
  location,
  isCatchAll = false,
}: UseRouterValueOptimisticProps) => {
  const optimisticKey = useMemo(() => {
    return toRouterOptimisticKey({ key, location });
  }, [key, location]);

  /**
   * If value is undefined, then we know nobody else has set this key's value
   * in the OptimisticValueContext from the router. So we reference the
   * router instead.
   */
  const value = useOptimisticValue<string>(optimisticKey);

  const { getParam, getSegment } = useRouter();

  const queryValue = useMemo(() => {
    const queryValues =
      location === 'queryParam' ? getParam(key) : getSegment(key, isCatchAll);
    if (Array.isArray(queryValues)) {
      return queryValues[0];
    }
    return queryValues;
  }, [location, getParam, getSegment, key, isCatchAll]);

  return value === undefined ? queryValue : value || undefined;
};
