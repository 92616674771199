import { Identifiable } from '../../../../../../Identifiable';
import { Payout } from '../../../../../User/Payout';
import { Result } from '../Result';

export const WINS_INDEX = 0 as const;
export const LOSSES_INDEX = 1 as const;

export type SummaryMatchPropagation = {
  totalWinsNeeded: number;
};

/**
 * Represents a summary of a participant team's results in a match.
 *
 * For simplicity and direct access, the document ID for a ResultSummary
 * should be the same as the team.id field.
 *
 * @important scoresMatch[WINS_INDEX] gives the number of wins. scoresMatch[LOSSES_INDEX] gives the number of losses.
 * @important scoresMatch[LOSSES_INDEX] MUST be stored as a negative number!
 */
export type ResultSummary = Identifiable &
  Pick<Result, 'team' | 'matchLexographic' | 'scoresMatch'> & {
    payout?: Payout;
    matchPropagation: SummaryMatchPropagation;
  };
