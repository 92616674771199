import { ReactNode, useMemo } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { memo, compareDeeply } from '../../util/memo';
import { RelativeTimeFormatter } from 'functions/src/util/date/RelativeTimeFormatter';

export type ElementsDated = {
  date: Date;
  element: ReactNode;
};

export type ListChronologicalProps = {
  elementsDated: ElementsDated[];
};

function ListChronologicalUnmemoized({
  elementsDated,
}: ListChronologicalProps) {
  const relativeFormatter = useMemo(() => {
    return new RelativeTimeFormatter({
      unitsCount: 1,
      unitsToConsider: ['year', 'month', 'week', 'day'],
    });
  }, []);

  const sortedElements = useMemo(() => {
    return elementsDated.sort(({ date: dateA }, { date: dateB }) => {
      const timeA = dateA.getTime();
      const timeB = dateB.getTime();
      return timeB - timeA;
    });
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [elementsDated]);

  const buckets = useMemo(() => {
    return sortedElements.reduce<{ [key: string]: ReactNode[] }>(
      (acc, { date, element }) => {
        const bucketKey = relativeFormatter.format(date || new Date());
        if (acc[String(bucketKey)]) {
          acc[String(bucketKey)].push(element);
        } else {
          acc[String(bucketKey)] = [element];
        }
        return acc;
      },
      {},
    );
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [sortedElements, relativeFormatter]);

  return (
    <>
      {Object.entries(buckets).map(([key, elements]) => {
        return (
          <Stack key={key} mb={4} spacing={2}>
            <Typography color="text.secondary">{key}</Typography>
            {elements.map((element) => {
              return <Box key={uuidv4()}>{element}</Box>;
            })}
          </Stack>
        );
      })}
    </>
  );
}

export const ListChronological = memo(
  ListChronologicalUnmemoized,
  compareDeeply('elementsDated'),
);
