import { ScoreOption } from '../../types/firestore/Game/Tournament/Round/Match';
import {
  FORTNITE_GAME_ID,
  OverwolfGameId,
  ROCKET_LEAGUE_GAME_ID,
  VALORANT_GAME_ID,
} from '../../types/firestore/User/OverwolfGame';

export const SCORE_OPTION_DEFAULT: ScoreOption = {
  name: 'PTS',
  units: 'pts',
  orderBy: 'desc',
};

export const SCORE_OPTIONS_DEFAULT: ScoreOption[] = [SCORE_OPTION_DEFAULT];

export const SCORE_OPTION_OVERWOLF_GAMES: Record<
  OverwolfGameId,
  ScoreOption[]
> = {
  [ROCKET_LEAGUE_GAME_ID]: [SCORE_OPTION_DEFAULT],
  [VALORANT_GAME_ID]: [SCORE_OPTION_DEFAULT],
  [FORTNITE_GAME_ID]: [SCORE_OPTION_DEFAULT],
};
//TODO: @shaffy9633 update SCORE_OPTION_DEFAULT for each game to the their primary outcome statistic.
//For the remaining indexes of the scoreOptions array, we can use the different non outcome statistics.
