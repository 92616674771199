import { Fragment } from 'react';
import type { TrackReference } from '@livekit/components-react';
import { memo } from '../../util/memo';
import { withDynamicImport } from '../../util/withDynamicImport';
import {
  DynamicImport,
  LIVEKIT_COMPONENTS_REACT,
  LivekitComponentsReact,
} from '../../../functions/src/types/DynamicModule';
import { assertSafe } from 'functions/src/util/assertSafe';

export type AudioTracksProps = {
  trackRefs?: TrackReference[];
  modules: DynamicImport<[LivekitComponentsReact]>;
};

const AudioTracksUnmemoized = ({ trackRefs, modules }: AudioTracksProps) => {
  const { AudioTrack } = modules[assertSafe(LIVEKIT_COMPONENTS_REACT)];

  return (
    <Fragment>
      {trackRefs?.map((trackRef) => {
        return (
          // @ts-expect-error LiveKitRoom is a valid component despite TypeScript complaints
          <AudioTrack
            key={trackRef.participant.identity}
            muted={false}
            trackRef={trackRef}
            volume={1}
          />
        );
      })}
    </Fragment>
  );
};

export const AudioTracks = memo(
  withDynamicImport({
    Component: AudioTracksUnmemoized,
    moduleNames: [LIVEKIT_COMPONENTS_REACT],
  }),
);
