import { Stack } from '@mui/material';
import { ResultRanked } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { ResultCentralizerContext } from '../../../contexts/wysiwyg/ResultCentralizerContext';
import { RESULT_COLLECTION } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result/path';
import { SessionScoreCentralized } from './SessionScoreCentralized';
import { memo } from 'src/util/memo';

export type GameResultCellProps = {
  result: ResultRanked;
  matchDocPath: string;
  teamCount: number;
  scoreIndex: number;
};

const GameResultCellUnmemoized = ({
  result,
  matchDocPath,
  teamCount,
  scoreIndex,
}: GameResultCellProps) => {
  const { id } = result;

  const docPath = `${matchDocPath}/${RESULT_COLLECTION}/${id}`;

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <ResultCentralizerContext.Provider docPath={docPath} initialData={result}>
        <SessionScoreCentralized
          isTwoTeams={teamCount === 2}
          scoreIndex={scoreIndex}
        />
        {/* <ScreenshotUploadButton resultId={id} sessionIndex={sessionIndex} /> 
         TODO: @shaffy9633 we need to refactor the screenshot upload button to correctly use wysiwyg context*/}
      </ResultCentralizerContext.Provider>
    </Stack>
  );
};

export const GameResultCell = memo(GameResultCellUnmemoized);
