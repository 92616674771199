import { useMemo } from 'react';
import {
  ANIMATION_KEYFRAMES,
  Animation,
  DEFAULT_ANIMATION_DURATION,
  DEFAULT_ANIMATION_ITERATION,
} from '../../styles/animations';

export type AnimationStyleOptions = {
  animation?: Animation;
  duration?: number;
  iterations?: number;
};

export const useAnimationStyle = ({
  animation,
  duration,
  iterations,
}: AnimationStyleOptions = {}) => {
  return useMemo(() => {
    if (!animation) return;
    const animDuration = duration || DEFAULT_ANIMATION_DURATION;
    const animIterations = iterations || DEFAULT_ANIMATION_ITERATION;

    return {
      ...ANIMATION_KEYFRAMES[animation],
      animation: `${animation} ${animDuration} ${animIterations}`,
    };
  }, [animation, duration, iterations]);
};
