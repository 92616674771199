import { createContext, ReactNode, useContext, useMemo } from 'react';
import { memo } from '../util/memo';
import {
  Match,
  TeamAggregation,
} from '../../functions/src/types/firestore/Game/Tournament/Round/Match';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { useDocSnapshot } from '../hooks/firestore/useDocSnapshot';
import { calculateBestOf } from '../../functions/src/util/tournaments/match/calculateBestOf';
import { toMatchPath } from '../../functions/src/types/firestore/Game/Tournament/Round/Match/path';
import { TournamentContext } from './docs/TournamentContext';

export type MatchDetailsContextProps = {
  teams?: TeamAggregation;
  match?: Match;
  bestOf: number;
  teamCount?: number;
  matchDocPath: string;
  activeSessionIndex: number;
};

export const MatchDetailsContext =
  createContext<MatchDetailsContextProps | null>(null);

export const useMatchDetails = () => {
  const context = useContext(MatchDetailsContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useMatchDetails must be used within a MatchDetailsProvider',
    );
  }
  return context;
};

export type MatchDetailsProviderProps = {
  roundId: string;
  matchId: string;
  children: ReactNode;
};

export const MatchDetailsProvider = memo(
  function MatchDetailsProviderUnmemoized({
    roundId,
    matchId,
    children,
  }: MatchDetailsProviderProps) {
    const { gameId, id: tournamentId } = useContext(TournamentContext);
    const matchDocPath = toMatchPath({
      gameId,
      tournamentId,
      roundId,
      matchId,
    });

    const match = useDocSnapshot<Match>({
      docPath: matchDocPath,
    });

    const { matchEndCondition, resultsAggregation } = match || {};
    const { teams, teamCount } = resultsAggregation || {};

    const { totalWinsNeeded } = matchEndCondition || {};

    const bestOf = useMemo(() => {
      return calculateBestOf(totalWinsNeeded);
    }, [totalWinsNeeded]);

    const activeSessionIndex = useMemo(() => {
      return match?.activeSessionIndex ?? 0;
    }, [match?.activeSessionIndex]);

    const matchDetails = useMemo(() => {
      const value: MatchDetailsContextProps = {
        teams,
        match,
        bestOf,
        teamCount,
        matchDocPath,
        activeSessionIndex,
      };
      return value;
    }, [teams, match, bestOf, teamCount, matchDocPath, activeSessionIndex]);

    return (
      <MatchDetailsContext.Provider value={matchDetails}>
        {children}
      </MatchDetailsContext.Provider>
    );
  },
);
