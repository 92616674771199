import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import { ReactNode, useCallback, useMemo } from 'react';
import { DialogBodyStandard } from '../../dialog/DialogBodyStandard';
import { memo } from '../../../util/memo';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { useWizard } from 'src/components/wizard/Wizard';
import { useAuth } from 'src/contexts/AuthContext';
import { useRemoveAuthQueryParams } from 'src/hooks/useRemoveAuthQueryParams';

export type AuthenticationFlowCompleteDialogProps = {
  title: string;
  description?: string | ReactNode;
  buttonText?: string;
  next?: string;
};

const AuthenticationFlowCompleteDialogUnmemoized = ({
  title,
  description,
  buttonText,
  next,
}: AuthenticationFlowCompleteDialogProps) => {
  const { go } = useWizard();
  const removeAuthQueryParams = useRemoveAuthQueryParams();
  const { uid } = useAuth();

  const buttonMessage = useMemo(() => {
    if (uid) {
      return buttonText ?? 'Close';
    }
    return 'Sign In';
  }, [uid, buttonText]);

  const takeNextStep = useCallback(() => {
    try {
      if (!uid) {
        go('Enter Email');
        return;
      }
      go(next);
    } finally {
      removeAuthQueryParams();
    }
  }, [go, next, removeAuthQueryParams, uid]);

  return (
    <Stack width="100%">
      <DialogBodyStandard
        description={description}
        gradientColor="primary.vertical"
        title={title}
      >
        <Box textAlign={'center'}>
          <GradientIcon
            gradientColor="secondary.horizontal"
            IconComponent={CheckCircleIcon}
            sx={{ fontSize: '40px' }}
          />
        </Box>
        {/* eslint-disable-next-line @blumintinc/blumint/no-margin-properties */}
        <Stack alignSelf={'center'} maxWidth="440px" mx="auto" width="100%">
          <Button fullWidth variant="contained" onClick={takeNextStep}>
            {buttonMessage}
          </Button>
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationFlowCompleteDialog = memo(
  AuthenticationFlowCompleteDialogUnmemoized,
);
