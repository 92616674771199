import { useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';

export const useFcmTokenConfigurer = () => {
  const { uidFull } = useAuth();

  const setupTokens = useCallback(async () => {
    if (!uidFull) return;

    const { configureFcmTokens } = await import(
      '../../config/firebase-client/messaging'
    );
    configureFcmTokens();
  }, [uidFull]);

  useEffect(() => {
    setupTokens();
  }, [uidFull, setupTokens]);

  return setupTokens;
};
