import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { transformAttributesToActionButtonProps } from '../../util/notifications/transformAttributesToActionButtonProps';
import { useUpdateNotification } from '../../hooks/notification/useUpdateNotification';
import { useAuth } from '../../contexts/AuthContext';
import { useRouterState } from '../../hooks/routing/useRouterState';
import { memo } from 'src/util/memo';

export const ROUTER_KEY_NOTIFICATION = 'notif' as const;
export const ROUTER_KEY_NOTIFICATION_ACTION = 'notif-action' as const;

export type ActionButtonProps = {
  id: string;
  attributes: Record<string, string>;
};

const NotificationActionButtonGroupUnmemoized = ({
  id,
  attributes,
}: ActionButtonProps) => {
  const { uidFull } = useAuth();
  const actionButtonProps = transformAttributesToActionButtonProps(attributes);
  const [_, setNotificationId] = useRouterState({
    key: ROUTER_KEY_NOTIFICATION,
  });
  const [notificationAction, setNotificationAction] = useRouterState({
    key: ROUTER_KEY_NOTIFICATION_ACTION,
  });
  const { markAsArchived } = useUpdateNotification({
    toId: uidFull,
    id,
  });

  if (actionButtonProps.length === 0) {
    return null;
  }

  return (
    <Stack direction="row" spacing={1}>
      {actionButtonProps.map(({ label, href }) => {
        const isButtonLoading =
          !!notificationAction && notificationAction === href;
        return (
          <LoadingButton
            key={label}
            loading={isButtonLoading}
            size="small"
            sx={{
              padding: '2px 8px',
              minHeight: 0,
              minWidth: 0,
            }}
            variant="text"
            // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
            onClick={async () => {
              setNotificationId(id);
              setNotificationAction(href);
              await markAsArchived();
            }}
          >
            {label}
          </LoadingButton>
        );
      })}
    </Stack>
  );
};

export const NotificationActionButtonGroup = memo(
  NotificationActionButtonGroupUnmemoized,
);
