import { Fragment } from 'react';
import Stack from '@mui/material/Stack';
import { SxProps, useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { LivestreamTitleEditable } from './LivestreamTitleEditable';
import { LivestreamDescriptionEditable } from './LivestreamDescriptionEditable';

export type LivestreamInfoBodyProps = {
  streamerId: string;
  sx?: SxProps;
};

const LivestreamInfoBodyUnmemoized = ({
  streamerId,
  sx,
}: LivestreamInfoBodyProps) => {
  const theme = useTheme();

  const { isMiniPlayerMode } = useLivestreamMode();

  if (isMiniPlayerMode) {
    return <Fragment />;
  }

  return (
    <Stack
      sx={{
        ...theme.scrollbars.invisible,
        overflowY: 'auto',
        maxHeight: '100px',
        ...sx,
      }}
    >
      <LivestreamTitleEditable streamerId={streamerId} />
      <LivestreamDescriptionEditable streamerId={streamerId} />
    </Stack>
  );
};

export const LivestreamInfoBody = memo(LivestreamInfoBodyUnmemoized);
