import { Fragment, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDialog } from '../hooks/useDialog';
import { Link } from '../components/Link';
import { IS_ELECTRON } from '../util/platform';
import { useRouterState } from './routing/useRouterState';

export const AD_BLOCK_DIALOG_ID = 'AD_BLOCK_DIALOG' as const;
export const ROUTER_KEY_ADBLOCK = 'adblock' as const;
const INLINE_PADDING_STYLE = {
  paddingLeft: '2px',
  paddingRight: '2px',
} as const;

export const useAdBlockDialog = () => {
  const { open } = useDialog(AD_BLOCK_DIALOG_ID);

  const [_, setAdblock] = useRouterState({ key: ROUTER_KEY_ADBLOCK });

  const refresh = useCallback(() => {
    return window.location.reload();
  }, []);

  const openAdBlockDialog = useCallback(() => {
    if (IS_ELECTRON) {
      return;
    }
    setAdblock('detected');
    open({
      title: 'Ad Block Detected',
      shouldPreventBackdropClick: true,
      PaperProps: {
        maxWidth: '800px',
      },
      description: (
        <Fragment>
          <Typography>
            It looks like an ad blocker, browser extension, or privacy setting
            is preventing ads from loading on our website. To continue using our
            services, please disable these settings for our domain.
          </Typography>
          <Typography sx={{ pt: 2 }}>
            If you’ve disabled ad blockers and still encounter issues, we’re
            here to help! Reach out to us at
            <Link
              href="https://blumint.com/contact"
              style={INLINE_PADDING_STYLE}
            >
              Contact Support
            </Link>
            . Alternatively, connect with us on
            <Link
              href="https://twitter.com/blumint_"
              style={INLINE_PADDING_STYLE}
            >
              Twitter
            </Link>
            or
            <Link
              href="https://discord.gg/QfcJRBkrGX"
              style={INLINE_PADDING_STYLE}
            >
              Discord
            </Link>
            for further assistance.
          </Typography>
        </Fragment>
      ),
      children: (
        // eslint-disable-next-line @blumintinc/blumint/no-margin-properties
        <Button sx={{ mt: 2 }} variant="contained" onClick={refresh}>
          Refresh
        </Button>
      ),
    });
  }, [open, refresh, setAdblock]);

  return { openAdBlockDialog } as const;
};
