import { parseRoundNumber } from '../regex';

export type SortGroupsParams = {
  groupNameA: string;
  groupNameB: string;
};

export const sortGroups = ({ groupNameA, groupNameB }: SortGroupsParams) => {
  const isGeneralA = groupNameA === 'general';
  const isGeneralB = groupNameB === 'general';
  if (isGeneralA || isGeneralB) {
    if (isGeneralA && isGeneralB) {
      return 0;
    }
    if (isGeneralA) {
      return -1;
    }
    return 1;
  }
  const isRoundA = groupNameA.includes('Round');
  const isRoundB = groupNameB.includes('Round');

  if (isRoundA && isRoundB) {
    return parseRoundNumber(groupNameA) - parseRoundNumber(groupNameB);
  }

  if (isRoundA) return -1;
  if (isRoundB) return 1;

  return 0;
};
