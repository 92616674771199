import { useCallback, useMemo } from 'react';
import {
  DialogActionsStandard,
  DialogActionsStandardProps,
} from '../../components/dialog/DialogActionsStandard';
import { useDialog } from '../useDialog';
import type { Props as ExitChannelGroupProps } from '../../firebaseCloud/messaging/exitChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { ChannelGroupType } from '../../../functions/src/types/firestore/User/ChannelGroup';
import { usePersonalChannelGroup } from './usePersonalChannelGroup';

export const CHANNEL_GROUP_DIALOG_ID = 'CHANNEL_GROUP_DIALOG' as const;

// eslint-disable-next-line @blumintinc/blumint/no-unused-props
export type UseRemoveChannelGroupDialogProps = ExitChannelGroupProps & {
  channelGroupType: ChannelGroupType;
};

export const useRemoveChannelGroupDialog = ({
  channelGroupType,
  groupFilter,
}: UseRemoveChannelGroupDialogProps) => {
  const { open: openDialog, close } = useDialog(CHANNEL_GROUP_DIALOG_ID);
  const { remove: removeChannelGroup } = usePersonalChannelGroup();
  const { closeChannelGroup } = useActiveChannelGroup();

  const isSupport = channelGroupType === 'Support';

  const deleteChannelGroup = useCallback(async () => {
    await removeChannelGroup({ groupFilter });
    closeChannelGroup();
    close();
  }, [close, closeChannelGroup, groupFilter, removeChannelGroup]);

  const buttons: DialogActionsStandardProps['buttons'] = useMemo(() => {
    return [
      {
        children: isSupport ? 'Close' : 'Delete',
        isAsync: true,
        onClick: deleteChannelGroup,
        color: 'error',
      },
      {
        children: 'Cancel',
        color: 'primary',
        isAsync: false,
        onClick: close,
      },
    ];
  }, [isSupport, deleteChannelGroup, close]);

  const open = useCallback(() => {
    openDialog({
      title: isSupport ? 'Close Support Ticket' : 'Delete Chat',
      description: isSupport
        ? `By closing this support ticket, you will conclude the support session and this chat will no longer be accessible. Thank you for allowing us to assist you!`
        : `Warning, this action is irreversible! You will no longer have access to this chats messages. Wish to continue?`,
      shouldShowCloseIcon: true,
      children: <DialogActionsStandard buttons={buttons} />,
    });
  }, [openDialog, isSupport, buttons]);

  return { open, close } as const;
};
