import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  DataGrid,
  DataGridProps,
  GridSlotsComponentsProps,
} from '@mui/x-data-grid';
import { ReactNode, useMemo } from 'react';
import { GradientTypography } from '../gradients/GradientTypography';
import { memo } from '../../util/memo';
import { GradientBorder } from 'src/components/gradients/GradientBorder';

export const ROW_HEIGHT = 52 as const;
export const BASE_SPACING = 30 as const;
export const DECREASING_FACTOR = 0.5 as const;
export const ROW_SPACING = { top: 5, bottom: 5 } as const;
export const STANDARD_PAGE_SIZE = 10 as const;

export type LeaderboardStandardProps = {
  title?: ReactNode;
  pageSize?: number;
} & GridSlotsComponentsProps &
  Omit<DataGridProps, 'pageSize'>;

const LeaderboardStandardUnmemoized = ({
  title,
  pageSize = STANDARD_PAGE_SIZE,
  ...dataGridProps
}: LeaderboardStandardProps) => {
  const theme = useTheme();

  const titleComponent = useMemo(() => {
    if (!title) return null;
    if (typeof title === 'string') {
      return (
        <GradientTypography
          gradientColor="primary.vertical"
          sx={{ mb: -2 }}
          textTransform={'uppercase'}
          variant="h6"
        >
          {title}
        </GradientTypography>
      );
    }
    return title;
  }, [title]);

  return (
    <GradientBorder
      disableBorder
      gradientBackground={theme.palette.background.elevationSolid[7]}
      innerSx={{ p: 3 }}
      sx={{ borderRadius: '10px' }}
    >
      {titleComponent}
      <Box>
        <DataGrid
          {...dataGridProps}
          // initialState={INITIAL_STATE}
          pageSize={pageSize}
        />
      </Box>
    </GradientBorder>
  );
};

export const LeaderboardStandard = memo(LeaderboardStandardUnmemoized);
