import { useCallback, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useDynamic } from '../useDynamic';
import { toPresencePath } from '../../../functions/src/util/presence/toPresencePath';
import { PresenceOperation } from '../../../functions/src/util/presence/PresenceProcessor';
import { LiveViewer } from '../../../functions/src/types/realtimeDb/LiveViewer';

export const useTrackPresence = () => {
  const { userDataFull } = useAuth();
  const activeElementRef = useRef<string | null>(null);
  const databaseModule = useDynamic(import('firebase/database'));
  const realtimeDbModule = useDynamic(
    import('../../config/firebase-client/database'),
  );

  const trackPresence = useCallback(
    async (elementId: string, operation: PresenceOperation) => {
      if (
        !userDataFull?.id ||
        !userDataFull?.username ||
        !userDataFull?.imgUrl ||
        !databaseModule ||
        !realtimeDbModule
      ) {
        return;
      }

      const { ref, set, remove, onDisconnect } = databaseModule;
      const { database } = realtimeDbModule;

      const dbRef = ref(
        database,
        toPresencePath({
          elementId,
          choice: 'liveViewers',
          viewerId: userDataFull.id,
        }),
      );

      if (operation === 'add' && activeElementRef.current !== elementId) {
        activeElementRef.current = elementId;

        const viewer: LiveViewer = {
          id: userDataFull.id,
          username: userDataFull.username,
          imgUrl: userDataFull.imgUrl,
        };

        await set(dbRef, viewer);

        return onDisconnect(dbRef).remove();
      }

      if (operation === 'remove' && activeElementRef.current) {
        activeElementRef.current = null;

        return await remove(dbRef);
      }
    },
    [
      databaseModule,
      realtimeDbModule,
      userDataFull?.id,
      userDataFull?.imgUrl,
      userDataFull?.username,
    ],
  );

  useEffect(() => {
    if (userDataFull?.id || !activeElementRef.current) {
      return;
    }

    const elementId = activeElementRef.current;
    trackPresence(elementId, 'remove');
  }, [userDataFull?.id, trackPresence]);

  return trackPresence;
};
