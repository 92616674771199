import { toUserPath } from '../path';

export const NOTIFICATION_COLLECTION_NAME = 'Notification' as const;

export const NOTIFICATION_PATH =
  'User/{userId}/Notification/{notificationId}' as const;

export type NotificationPath = typeof NOTIFICATION_PATH;

export const toUserNotificationCollectionPath = (userId: string) => {
  return `${toUserPath(userId)}/Notification`;
};

export type ToNotificationPathParams = {
  userId: string;
  notificationId: string;
};

export const toNotificationPath = ({
  userId,
  notificationId,
}: ToNotificationPathParams) => {
  return `${toUserNotificationCollectionPath(userId)}/${notificationId}`;
};

export type ToNotificationCountCollectionPathParams = {
  collection: 'User' | 'Guild';
  receiverId: string;
};
export const toNotificationCountCollectionPath = ({
  collection,
  receiverId,
}: ToNotificationCountCollectionPathParams) => {
  return `${collection}/${receiverId}/NotificationCount`;
};
