import { FC, useMemo } from 'react';
import { InstantSearch, InstantSearchProps } from 'react-instantsearch';
import algoliasearch from 'algoliasearch';
import { prefixIndex } from '../../../functions/src/util/algolia/prefixIndex';
import { useAlgoliaClientCached } from '../../hooks/algolia/useAlgoliaClientCached';
import { memo } from '../../util/memo';

export type CachedInstantSearchProps = { caching?: boolean } & Omit<
  InstantSearchProps,
  'searchClient'
>;

const FUTURE = {
  preserveSharedStateOnUnmount: false,
} as const;

const ALGOLIA_FIRST_VISIT_KEY = 'algolia-first-visit' as const;

const CachedInstantSearchUnmemoized: FC<CachedInstantSearchProps> = ({
  indexName,
  caching = true,
  ...rest
}) => {
  const searchClientCached = useAlgoliaClientCached();
  const searchClient = useMemo(() => {
    const isFirstVisit = !localStorage.getItem(ALGOLIA_FIRST_VISIT_KEY);
    if (isFirstVisit) {
      localStorage.setItem(ALGOLIA_FIRST_VISIT_KEY, 'true');
    }

    // eslint-disable-next-line @blumintinc/blumint/extract-global-constants
    const shouldCache = false; // TODO: REVERT. This is temporary disabling of caching; caching && !isFirstVisit;

    return shouldCache
      ? searchClientCached
      : algoliasearch(
          process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
          process.env.NEXT_PUBLIC_FRONTEND_ALGOLIA_API_KEY as string,
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caching]);

  const indexPrefixed = indexName ? prefixIndex(indexName) : undefined;

  return (
    <InstantSearch
      future={FUTURE}
      indexName={indexPrefixed}
      searchClient={searchClient}
      {...rest}
    />
  );
};

export const CachedInstantSearch = memo(CachedInstantSearchUnmemoized);
