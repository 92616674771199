import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../util/memo';
import {
  hasUnreadMatchChannel,
  HasUnreadMatchChannelParams,
} from '../util/messaging/hasUnreadMatchChannel';
import { useUnreadMessageCount } from '../contexts/UnreadMessageCountContext';
import { Pulsate, PulsateProps } from './animations/Pulsate';

export type PulsateUnreadMatchProps = Pick<PulsateProps, 'style'> &
  Omit<HasUnreadMatchChannelParams, 'unreadMessageCount'> & {
    children: ReactNode;
  };

const PulsateUnreadMatchUnmemoized = ({
  children,
  style,
  ...rest
}: PulsateUnreadMatchProps) => {
  const theme = useTheme();
  const { unreadMessageCount } = useUnreadMessageCount();

  const isLatestMatchChannelUnread = hasUnreadMatchChannel({
    unreadMessageCount,
    ...rest,
  });

  return (
    <Pulsate
      color={theme.palette.error.main}
      shouldPulsate={isLatestMatchChannelUnread}
      style={style}
    >
      {children}
    </Pulsate>
  );
};

export const PulsateUnreadMatch = memo(PulsateUnreadMatchUnmemoized);
