import { ChannelGroup, GroupFilterMap, ChannelGroupPermanence } from '..';

// eslint-disable-next-line @blumintinc/blumint/no-firestore-object-arrays
export const TEMPORARY_PERMANENCE_TYPES: ChannelGroupPermanence[] = [
  'temporary',
  'pinned',
];

export function isTemporaryPermanence(permanence: ChannelGroupPermanence) {
  return TEMPORARY_PERMANENCE_TYPES.includes(permanence);
}

export function isTemporary<TTime = Date>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
) {
  return isTemporaryPermanence(channelGroup.permanence);
}
