export const TOURNAMENT_COLLECTION = 'Tournament' as const;

export const TOURNAMENT_PATH =
  `Game/{gameId}/${TOURNAMENT_COLLECTION}/{tournamentId}` as const;
export type TournamentPath = typeof TOURNAMENT_PATH;

export type ToTournamentPathProps = {
  gameId: string;
  tournamentId: string;
};
export const toTournamentPath = ({
  gameId,
  tournamentId,
}: ToTournamentPathProps) => {
  return `${TOURNAMENT_PATH.replace('{gameId}', gameId).replace(
    '{tournamentId}',
    tournamentId,
  )}` as const;
};

export const toTournamentCollectionPath = (gameId: string) => {
  return `Game/${gameId}/${TOURNAMENT_COLLECTION}` as const;
};
