import { useMemo } from 'react';
import { useRealtimeCount } from '../presence/useRealtimeCount';
import { toPresencePath } from '../../../functions/src/util/presence/toPresencePath';

export type UseLivestreamViewerCountParams = {
  playbackId: string;
};

export const useLivestreamViewerCount = ({
  playbackId,
}: UseLivestreamViewerCountParams) => {
  const liveViewers = useRealtimeCount(
    toPresencePath({
      elementId: playbackId,
      choice: 'liveViewerCount',
    }),
  );

  const totalViews = useRealtimeCount(
    toPresencePath({
      elementId: playbackId,
      choice: 'totalViews',
    }),
  );

  return useMemo(() => {
    return { liveViewers, totalViews };
  }, [liveViewers, totalViews]);
};
