import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import Stack from '@mui/system/Stack';
import { memo } from '../../../util/memo';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import { useRemoveFriendDialog } from '../../../hooks/friends/useRemoveFriendDialog';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { FriendCardLayout } from './FriendCardLayout';

export type FriendCardEditProps = FriendHit;

const FriendCardEditUnmemoized = ({ ...hit }: FriendCardEditProps) => {
  const { uid } = useAuth();
  const { sender, receiver } = hit;
  const friendToRender = sender.id === uid ? receiver : sender;
  const { username, imgUrl, id } = friendToRender;
  const { open: openRemoveFriendDialog } = useRemoveFriendDialog({
    imgUrl,
    username,
    userId: id,
  });

  return (
    <FriendCardLayout {...friendToRender}>
      <Stack direction="row">
        <GradientIconButton
          IconComponent={HighlightOffIcon}
          onClick={openRemoveFriendDialog}
        />
      </Stack>
    </FriendCardLayout>
  );
};

export const FriendCardEdit = memo(FriendCardEditUnmemoized);
