import Badge, { BadgeProps } from '@mui/material/Badge';
import { useNotificationCount } from '../../hooks/notification/useNotificationCount';
import { memo } from 'src/util/memo';
import { WhereType } from 'functions/src/util/firestore/FirestoreQuery';
// eslint-disable-next-line no-shadow
import { Notification } from 'functions/src/types/firestore/User/Notification';

export type BadgeNotificationProps = {
  wheres?: WhereType<Notification<Date>, keyof Notification<Date>>[];
} & Omit<BadgeProps, 'badgeContent'>;

function BadgeNotificationUnmemoized({
  wheres,
  ...badgeProps
}: BadgeNotificationProps) {
  const { unread } = useNotificationCount();

  return (
    <Badge
      color="error"
      invisible={unread <= 0}
      max={9}
      overlap="circular"
      {...badgeProps}
      badgeContent={unread}
    />
  );
}

export const BadgeNotification = memo(BadgeNotificationUnmemoized);
