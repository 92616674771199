/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';
import { toUserPath } from '../../../functions/src/types/firestore/User/path';
import { useAuth } from '../../contexts/AuthContext';
import {
  FCM_TOKENS_FIELD,
  NEW_TOKEN,
  TOKEN_EXPIRED,
} from '../../../functions/src/util/fcmToken/FcmTokenEvent';

export function useFcmTokenEventListener() {
  const { uidFull } = useAuth();

  useEffect(() => {
    const propogateAddedToken = async (event: Event) => {
      if (!uidFull) return;

      const { firestore } = await import(
        '../../config/firebase-client/firestore'
      );
      const { updateDoc, arrayUnion, doc } = await import('firebase/firestore');
      const customEvent = event as CustomEvent<{ fcmToken: string | null }>;
      const { fcmToken } = customEvent.detail;

      if (!fcmToken) return;

      const userDocRef = doc(firestore, toUserPath(uidFull));
      await updateDoc(userDocRef, {
        [FCM_TOKENS_FIELD]: arrayUnion(fcmToken),
      });
    };

    const propogateRemovedToken = async (event: Event) => {
      if (!uidFull) return;
      const { firestore } = await import(
        '../../config/firebase-client/firestore'
      );
      const { updateDoc, arrayRemove, doc } = await import(
        'firebase/firestore'
      );
      const customEvent = event as CustomEvent<{ fcmToken: string | null }>;
      const { fcmToken } = customEvent.detail;

      if (!fcmToken) return;

      const userDocRef = doc(firestore, toUserPath(uidFull));
      await updateDoc(userDocRef, {
        [FCM_TOKENS_FIELD]: arrayRemove(fcmToken),
      });
    };

    window.addEventListener(NEW_TOKEN, propogateAddedToken);
    window.addEventListener(TOKEN_EXPIRED, propogateRemovedToken);

    return () => {
      window.removeEventListener(NEW_TOKEN, propogateAddedToken);
      window.removeEventListener(TOKEN_EXPIRED, propogateRemovedToken);
    };
  }, [uidFull]);
}
