import { useSessionState } from '../useSessionState';

export const useTemporaryChannelGroups = () => {
  const [temporaryChannelGroups, setTemporaryChannelGroups] =
    useSessionState<'temporaryChannelGroups'>('temporaryChannelGroups');

  return {
    temporaryChannelGroups,
    setTemporaryChannelGroups,
  } as const;
};
