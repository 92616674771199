import CloseIcon from '@mui/icons-material/CloseRounded';
import { useTheme } from '@mui/material/styles';
import { Fragment } from 'react';
import { PushPinIcon } from '../../icons/PushPinIcon';
import { Pulsate } from '../../animations/Pulsate';
import { memo } from '../../../util/memo';
import {
  ChannelGroupType,
  ChannelGroupPermanence,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { PushPinCancelIcon } from '../../icons/PushPinCancelIcon';

const PULSATE_STYLE = {
  borderRadius: '50%',
} as const;

const PUSHPIN_SX = {
  width: '14px',
  height: '14px',
} as const;

export type ChannelGroupBadgeContentProps = {
  permanence: ChannelGroupPermanence;
  channelGroupType: ChannelGroupType;
};

const ChannelGroupBadgeContentUnmemoized = ({
  permanence,
  channelGroupType,
}: ChannelGroupBadgeContentProps) => {
  const theme = useTheme();

  if (permanence === 'temporary' && channelGroupType !== 'Playback') {
    return (
      <Pulsate
        shouldPulsate={permanence === 'temporary'}
        spacing={4}
        style={PULSATE_STYLE}
      >
        <PushPinIcon
          sx={{ ...PUSHPIN_SX, color: theme.palette.primary.dark }}
        />
      </Pulsate>
    );
  }

  if (permanence === 'pinned') {
    return (
      <PushPinCancelIcon
        sx={{ ...PUSHPIN_SX, color: theme.palette.primary.dark }}
      />
    );
  }

  if (channelGroupType !== 'Support') {
    return <Fragment />;
  }

  return (
    <CloseIcon
      sx={{
        width: '16px',
        height: '16px',
        color: theme.palette.primary.dark,
      }}
    />
  );
};

export const ChannelGroupBadgeContent = memo(
  ChannelGroupBadgeContentUnmemoized,
);
