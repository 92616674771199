import { useMemo } from 'react';
import { OnlineStatusEnum } from '../../../functions/src/types/realtimeDb/Status';
import { toStatusCountPath } from '../../../functions/src/types/realtimeDb/Status/path';
import { useRealtimeDbSnapshot } from '../realtimeDb/useRealtimeDbSnapshot';

export type UseStatusCountProps = {
  groupId?: string;
  status: OnlineStatusEnum;
};

export const useStatusCount = ({ groupId, status }: UseStatusCountProps) => {
  const dbPath = useMemo(() => {
    if (!groupId) return;
    return toStatusCountPath({ groupId, status });
  }, [groupId, status]);

  const statusCount = useRealtimeDbSnapshot<number>(dbPath, 0);

  return typeof statusCount === 'number' ? statusCount : 0;
};
