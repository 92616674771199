/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import {
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import type GliderBase from 'glider-js';
import { memo } from '../../util/memo';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { GliderMethods, GliderProps, MakeGliderProps } from './types';
import Glider from './Glider';

const makeGliderOptions: (
  props: MakeGliderProps & {
    nextButtonEl: HTMLElement | null;
    prevButtonEl: HTMLElement | null;
    dotsEl: HTMLElement | null;
  },
) => GliderBase.Options = ({
  arrows,
  hasArrows,
  dots,
  hasDots,
  nextButtonEl,
  prevButtonEl,
  dotsEl,
  ...restProps
}) => {
  return {
    ...restProps,
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    skipTrack: true,
    arrows:
      (hasArrows && {
        next: (arrows && arrows.next) || nextButtonEl,
        prev: (arrows && arrows.prev) || prevButtonEl,
      }) ||
      undefined,
    dots: (hasDots && dots) || dotsEl || undefined,
  };
};

const GliderComponentUnmemoized = forwardRef(function GliderComponentUnmemoized(
  props: GliderProps,
  ref: Ref<GliderMethods>,
) {
  const {
    id,
    containerElement,
    className,
    hasArrows,
    arrows,
    hasDots,
    dots,
    scrollToSlide,
    scrollToPage,
    iconLeft,
    iconRight,
    skipTrack,
    children,
    onLoad,
    onSlideVisible,
    onAnimated,
    onRemove,
    onRefresh,
    onAdd,
    onDestroy,
    onSlideHidden,
    dragVelocity,
    draggable,
    duration,
    exactWidth,
    itemWidth,
    rewind,
    scrollLock,
    scrollPropagate,
    slidesToScroll,
    slidesToShow,
  } = props;
  const autoId = useId();

  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const dotsRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const gliderRef = useRef<GliderMethods | null>(null);

  // initialize the glider
  const callbackRef = useCallback(
    (element: HTMLDivElement) => {
      elementRef.current = element;
      if (element && !gliderRef.current) {
        const glider = new Glider(
          element,
          makeGliderOptions({
            dragVelocity,
            draggable,
            duration,
            exactWidth,
            itemWidth,
            rewind,
            scrollLock,
            scrollPropagate,
            slidesToScroll,
            slidesToShow,
            arrows,
            hasArrows,
            dots,
            hasDots,
            nextButtonEl: nextButtonRef.current,
            prevButtonEl: prevButtonRef.current,
            dotsEl: dotsRef.current,
          }),
        ) as GliderMethods;

        gliderRef.current = glider;

        if (onLoad) {
          onLoad.call(
            glider,
            new CustomEvent('glider-loaded', {
              detail: { target: element },
            }),
          );
        }
        if (scrollToSlide) {
          glider.scrollItem(scrollToSlide - 1);
        } else if (scrollToPage) {
          glider.scrollItem(scrollToPage - 1, true);
        }

        // bind event listeners
        const addEventListener = (
          event: string,
          fn: ((e: CustomEvent) => void) | undefined,
        ) => {
          if (typeof fn === 'function') {
            element.addEventListener(event, ((e: CustomEvent) => {
              return fn(e);
            }) as EventListener);
          }
        };

        addEventListener('glider-slide-visible', onSlideVisible);
        addEventListener('glider-animated', onAnimated);
        addEventListener('glider-remove', onRemove);
        addEventListener('glider-refresh', onRefresh);
        addEventListener('glider-add', onAdd);
        addEventListener('glider-destroy', onDestroy);
        addEventListener('glider-slide-hidden', onSlideHidden);
      }
    },
    [
      dragVelocity,
      draggable,
      duration,
      exactWidth,
      itemWidth,
      rewind,
      scrollLock,
      scrollPropagate,
      slidesToScroll,
      slidesToShow,
      arrows,
      hasArrows,
      dots,
      hasDots,
      onLoad,
      scrollToSlide,
      scrollToPage,
      onSlideVisible,
      onAnimated,
      onRemove,
      onRefresh,
      onAdd,
      onDestroy,
      onSlideHidden,
    ],
  );

  // when the props update, sync the glider
  useEffect(() => {
    if (gliderRef.current) {
      gliderRef.current.setOption(
        makeGliderOptions({
          dragVelocity,
          draggable,
          duration,
          exactWidth,
          itemWidth,
          rewind,
          scrollLock,
          scrollPropagate,
          slidesToScroll,
          slidesToShow,
          arrows,
          hasArrows,
          dots,
          hasDots,
          nextButtonEl: nextButtonRef.current,
          prevButtonEl: prevButtonRef.current,
          dotsEl: dotsRef.current,
        }),
        true,
      );
      gliderRef.current.refresh(true);
    }
  }, [
    arrows,
    dots,
    dragVelocity,
    draggable,
    duration,
    exactWidth,
    hasArrows,
    hasDots,
    itemWidth,
    rewind,
    scrollLock,
    scrollPropagate,
    slidesToScroll,
    slidesToShow,
  ]);

  // when the event listeners change, sync the glider
  useEffect(() => {
    if (elementRef.current) {
      const addEventListener = (
        event: string,
        fn: ((e: CustomEvent) => void) | undefined,
      ) => {
        if (typeof fn === 'function') {
          elementRef.current?.addEventListener(event, ((e: CustomEvent) => {
            return fn(e);
          }) as EventListener);
        }
      };

      addEventListener('glider-slide-visible', onSlideVisible);
      addEventListener('glider-animated', onAnimated);
      addEventListener('glider-remove', onRemove);
      addEventListener('glider-refresh', onRefresh);
      addEventListener('glider-add', onAdd);
      addEventListener('glider-destroy', onDestroy);
      addEventListener('glider-slide-hidden', onSlideHidden);
    }
    return () => {
      const removeEventListener = (
        event: string,
        fn: ((e: CustomEvent) => void) | undefined,
        // eslint-disable-next-line unicorn/consistent-function-scoping
      ) => {
        if (typeof fn === 'function') {
          elementRef.current?.removeEventListener(event, ((e: CustomEvent) => {
            return fn(e);
          }) as EventListener);
        }
      };

      removeEventListener('glider-slide-visible', onSlideVisible);
      removeEventListener('glider-animated', onAnimated);
      removeEventListener('glider-remove', onRemove);
      removeEventListener('glider-refresh', onRefresh);
      removeEventListener('glider-add', onAdd);
      removeEventListener('glider-destroy', onDestroy);
      removeEventListener('glider-slide-hidden', onSlideHidden);
    };
  }, [
    onAdd,
    onAnimated,
    onDestroy,
    onRefresh,
    onRemove,
    onSlideHidden,
    onSlideVisible,
  ]);

  /* expose the glider instance to the user so they can call the methods too */
  useImperativeHandle(ref, () => {
    if (!gliderRef.current) {
      throw new HttpsError('failed-precondition', 'Glider instance not found');
    }
    return gliderRef.current;
  });

  const Element = useMemo(() => {
    return containerElement || 'div';
  }, [containerElement]);

  return (
    <Element className="glider-contain">
      {!!hasArrows && !arrows && (
        <button
          ref={prevButtonRef}
          aria-label="Previous"
          className="glider-prev"
          type="button"
        >
          {iconLeft || '«'}
        </button>
      )}

      <div ref={callbackRef} className={className} id={id || autoId}>
        {skipTrack ? children : <div>{children}</div>}
      </div>

      {!!hasDots && !dots && <div ref={dotsRef} />}

      {!!hasArrows && !arrows && (
        <button
          ref={nextButtonRef}
          aria-label="Next"
          className="glider-next"
          type="button"
        >
          {iconRight || '»'}
        </button>
      )}
    </Element>
  );
});

export const GliderComponent = memo(GliderComponentUnmemoized);
