import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionMui from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import Badge from '@mui/material/Badge';
import { useTheme, SxProps } from '@mui/material/styles';
import { ReactNode } from 'react';
import { memo } from '../../../util/memo';
import { PulsateUnreadMatchTypography } from '../../PulsateUnreadMatchTypography';

export type ChannelAccordionProps = {
  title: string;
  groupId?: string;
  unreadCount: number;
  children?: ReactNode;
  sx?: SxProps;
};

export const ChannelAccordion = memo(function ChannelAccordionUnmemoized({
  title,
  children,
  unreadCount,
  groupId,
  sx = {},
}: ChannelAccordionProps) {
  const theme = useTheme();

  return (
    <AccordionMui
      expanded
      sx={{
        '&.MuiAccordion-root': {
          m: 0,
          borderRadius: 0,
          boxShadow: 'none',
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(0deg)',
        },
        background: theme.palette.background.elevation[0],
        width: '100%',
        ...sx,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          height: '64px',
          '&.MuiAccordionSummary-root:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '&.MuiAccordionSummary-root': {
            borderBottom: `0.5px solid ${theme.palette.action.disabled}`,
            borderImage: 'none',
            boxShadow: 'none',
          },
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '64px',
          },
        }}
      >
        <PulsateUnreadMatchTypography text={title} groupId={groupId} />
        {!!unreadCount && (
          <Badge
            badgeContent={unreadCount}
            sx={{
              ml: 5,
              mt: '14px',
              '&.MuiBadge-root .MuiBadge-badge': {
                width: '24px',
                height: '18px',
                borderRadius: '10px',
              },
            }}
          />
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          p: 0,
        }}
      >
        {children}
      </AccordionDetails>
    </AccordionMui>
  );
});
