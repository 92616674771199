import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { LiveBadge } from '../LiveBadge';
import { useVideo } from '../../contexts/video/VideoContext';
import { AvatarNextOverlay, AvatarNextOverlayProps } from './AvatarNextOverlay';

export const DEFAULT_OVERLAY_SX = {
  bottom: -4,
  left: '50%',
  transform: 'translateX(-50%)',
} as const;

export type AvatarNextLiveProps = Omit<
  AvatarNextOverlayProps,
  'overlay' | 'overlaySx'
> & {
  overlaySx?: SxProps;
};

const AvatarNextLiveUnmemoized = ({
  overlaySx = DEFAULT_OVERLAY_SX,
  ...props
}: AvatarNextLiveProps) => {
  const { isLive } = useVideo();

  return (
    <AvatarNextOverlay
      {...props}
      overlay={
        isLive ? <LiveBadge sx={{ height: '18px', px: 2 }} /> : undefined
      }
      overlaySx={overlaySx}
    />
  );
};

export const AvatarNextLive = memo(AvatarNextLiveUnmemoized);
