import { useMemo } from 'react';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useDocSnapshot } from '../firestore/useDocSnapshot';
import { useAuth } from '../../contexts/AuthContext';
import { toChannelGroupPathSpecified } from '../../../functions/src/util/messaging/toChannelGroupPath';
import { useVoiceChannel } from './useVoiceChannel';

export const useVoiceChannelGroupRealtime = () => {
  const { uid } = useAuth();
  const { voiceChannel } = useVoiceChannel();

  const channelGroupId = useMemo(() => {
    if (!voiceChannel?.current) {
      return;
    }
    return voiceChannel.current.split('-')[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceChannel?.current]);

  const voiceChannelGroup = useDocSnapshot<
    ChannelGroup<keyof GroupFilterMap, Date>
  >({
    docPath:
      channelGroupId && uid
        ? toChannelGroupPathSpecified({
            userId: uid,
            documentId: channelGroupId,
          })
        : undefined,
  });

  return useMemo(() => {
    return {
      voiceChannelGroup: voiceChannel?.current ? voiceChannelGroup : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voiceChannelGroup, voiceChannel?.current]);
};
