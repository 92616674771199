import {
  Context,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import { memo } from '../util/memo';
import { Token } from '../../functions/src/types/firestore/User/Payout';
import {
  CryptoToFiatConverter,
  PriceCrypto,
} from '../../functions/src/util/currencies/CryptoToFiatConverter';
import { formatPayout as formatPayoutUtil } from '../../functions/src/util/currencies/formatPayout';
import { formatTotalPayout as formatPayoutTotalUtil } from '../../functions/src/util/currencies/formatTotalPayout';
import { formatPrizeAmount } from '../util/tournaments/formatPrizeAmount';
import {
  COUNTRY_CODE_TO_CURRENCY,
  CountryCode,
  CurrencyName,
  EUROPEAN_COUNTRIES,
  CURRENCY_TO_SYMBOL as CURRENCY_NAME_TO_SYMBOL,
} from '../../functions/src/types/country';
import { CurrencyOptions } from '../../functions/src/types/firestore/ExchangeRates/Config';
import { ExchangeRatesPrices } from '../../functions/src/types/firestore/ExchangeRates/Prices';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { PRIZES_DEFAULT } from '../../functions/src/types/firestore/Game/Tournament';

// export type OrWrapped<T extends string> = T | `W${T}`;

export type CurrencyState = {
  currencyOptions: CurrencyOptions;
  exchangeRatesReady: boolean;
  format: (
    amount: number,
    symbol: string,
    options?: Intl.NumberFormatOptions,
  ) => string;
  formatPayout: (payout?: Token[]) => {
    amountFiat: number;
    countIlliquid: number;
    countUnassured: number;
  };
  formatTotalPayout: (
    payouts: {
      tokens: Token[];
    }[],
    bracketMode?: BracketMode,
  ) => {
    amountFiat: number;
    countIlliquid: number;
    countUnassured: number;
  };
  formatFiat: (priceCrypto: PriceCrypto) => string;
  formatCrypto: (priceCrypto: PriceCrypto) => string;
  convertFiat: (priceCrypto: PriceCrypto) => number;
};

type CurrencyUpdate = {
  handleCountryChange: (countryCode: CountryCode) => void;
  exchangeRatesReady: boolean;
};

const CurrencyContext = createContext({}) as unknown as Context<CurrencyState>;
const CurrencyUpdateContext = createContext(
  {},
) as unknown as Context<CurrencyUpdate>;

export function useCurrency() {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useCurrency must be used within a CurrencyProvider',
    );
  }
  return context;
}

export function useCurrencyUpdate() {
  const context = useContext(CurrencyUpdateContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useCurrencyUpdate must be used within a CurrencyProvider',
    );
  }
  return context;
}

export type CurrencyProviderProps = {
  children: ReactNode;
  exchangeRates: ExchangeRatesPrices<Date>;
};

export const CurrencyProvider = memo(function CurrencyProviderUnmemoized({
  children,
  exchangeRates,
}: CurrencyProviderProps) {
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOptions>({
    currency: { name: 'USD', symbol: '$' },
    exchangeRates: exchangeRates
      ? { ...exchangeRates.fiat, ...exchangeRates.crypto }
      : {},
  });

  // const unsubscribePrices = useRef<Unsubscribe | null>(null);

  // useEffect(() => {
  //   const handler = async () => {
  //     const { doc, onSnapshot } = await import('firebase/firestore');
  //     const { firestore } = await import('../config/firebase-client/firestore');

  //     unsubscribePrices.current = onSnapshot(
  //       doc(
  //         firestore,
  //         EXCHANGE_RATES_PRICES_PATH,
  //       ) as DocumentReference<ExchangeRatesPrices>,
  //       (pricesDoc) => {
  //         const prices = pricesDoc.data();
  //         if (prices) {
  //           setCurrencyOptions((prev) => {
  //             return {
  //               ...prev,
  //               exchangeRates: {
  //                 ...prices.fiat,
  //                 ...prices.crypto,
  //               },
  //             };
  //           });
  //         }
  //       },
  //       (error) => {
  //         console.error(error);
  //       },
  //     );
  //   };
  //   handler();

  //   return () => {
  //     if (unsubscribePrices.current) {
  //       unsubscribePrices.current();
  //       unsubscribePrices.current = null;
  //     }
  //   };
  // }, []);
  //TODO: revisit when we need live currency exchange info
  const exchangeRatesReady = useMemo(() => {
    return Object.keys(currencyOptions.exchangeRates).length > 0;
  }, [currencyOptions.exchangeRates]);

  const handleCountryChange = useCallback(
    (countryCode: CountryCode) => {
      localStorage.setItem('selectedCountry', countryCode);
      const isEuropean = EUROPEAN_COUNTRIES.includes(countryCode);
      const currency: { name: string; symbol: string } = isEuropean
        ? COUNTRY_CODE_TO_CURRENCY['EU']
        : COUNTRY_CODE_TO_CURRENCY[`${countryCode}`] ||
          COUNTRY_CODE_TO_CURRENCY['US'];
      if (!currencyOptions.exchangeRates[currency.name]) {
        setCurrencyOptions((prev) => {
          return { ...prev, currency: COUNTRY_CODE_TO_CURRENCY['US'] };
        });
      } else {
        setCurrencyOptions((prev) => {
          return { ...prev, currency };
        });
      }
    },
    [currencyOptions.exchangeRates],
  );

  const format = useCallback(
    (
      amount: number,
      currencyName: string,
      options: Intl.NumberFormatOptions = {},
    ) => {
      const isCurrency = CURRENCY_NAME_TO_SYMBOL.has(
        currencyName as CurrencyName,
      );
      const formattedAmount = formatPrizeAmount(amount, {
        style: isCurrency ? 'currency' : undefined,
        currency: isCurrency ? currencyName : undefined,
        currencyDisplay: 'narrowSymbol',
        ...options,
      });
      return `${formattedAmount}${isCurrency ? '' : ` ${currencyName}`}`;
    },
    [],
  );

  const formatCrypto = useCallback(
    ({ wei, cryptocurrency, isWrapped: wrapped = false }: PriceCrypto) => {
      return format(
        Number(wei) / 1e18,
        `${wrapped ? 'W' : ''}${cryptocurrency}`,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: undefined,
          minimumSignificantDigits: 4,
          maximumSignificantDigits: 4,
        },
      );
    },
    [format],
  );

  const cryptoToFiatConverter = useMemo(() => {
    return new CryptoToFiatConverter(currencyOptions.exchangeRates, 'USD');
  }, [currencyOptions.exchangeRates]);

  const convertFiat = useCallback(
    ({
      wei,
      cryptocurrency,
      isWrapped = false,
      isUsdPegged = false,
    }: PriceCrypto) => {
      return cryptoToFiatConverter.convert(
        { wei, cryptocurrency, isWrapped, isUsdPegged },
        currencyOptions.currency.name,
      );
    },
    [cryptoToFiatConverter, currencyOptions.currency.name],
  );

  const formatFiat = useCallback(
    (price: PriceCrypto) => {
      const fiat = convertFiat(price);
      if (!fiat && fiat !== 0) {
        return '';
      }
      return format(fiat, currencyOptions.currency.name);
    },
    [convertFiat, format, currencyOptions.currency.name],
  );

  const formatPayout = useCallback(
    (payout?: Token[]) => {
      if (!payout) {
        return PRIZES_DEFAULT;
      }
      return formatPayoutUtil(payout, convertFiat);
    },
    [convertFiat],
  );

  const formatTotalPayout = useCallback(
    (
      payouts: {
        tokens: Token[];
      }[],
      bracketMode?: BracketMode,
    ) => {
      return formatPayoutTotalUtil(
        formatPayout,
        convertFiat,
        payouts,
        bracketMode,
      );
    },
    [convertFiat, formatPayout],
  );

  const currencyContextValue = useMemo(() => {
    return {
      currencyOptions,
      exchangeRatesReady,
      format,
      formatFiat,
      formatCrypto,
      formatPayout,
      convertFiat,
      formatTotalPayout,
    };
  }, [
    convertFiat,
    currencyOptions,
    exchangeRatesReady,
    format,
    formatCrypto,
    formatFiat,
    formatPayout,
    formatTotalPayout,
  ]);

  const currencyUpdateContextValue = useMemo(() => {
    return {
      handleCountryChange,
      exchangeRatesReady,
    };
  }, [exchangeRatesReady, handleCountryChange]);

  return (
    <CurrencyContext.Provider value={currencyContextValue}>
      <CurrencyUpdateContext.Provider value={currencyUpdateContextValue}>
        {children}
      </CurrencyUpdateContext.Provider>
    </CurrencyContext.Provider>
  );
});
