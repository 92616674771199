import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { SxProps, useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { ImageOptimized, ImageOptimizedProps } from '../image/ImageOptimized';
import {
  MASCOT_BACKGROUNDS,
  BACKGROUND_URL_PARAM,
} from '../../../functions/src/util/user/defaultAvatar';
import { memo } from '../../util/memo';

export const AVATAR_SIZE_PX_PRIMARY = 52 as const;
export const AVATAR_SIZE_PX_PRIMARY_MOBILE = 40 as const;

export const AVATAR_SIZE_PX_SECONDARY = 40 as const;
export const AVATAR_SIZE_PX_SECONDARY_MOBILE = 30 as const;

export const AVATAR_SIZE_PX_RESPONSIVE_PRIMARY = {
  xs: AVATAR_SIZE_PX_PRIMARY_MOBILE,
  md: AVATAR_SIZE_PX_PRIMARY,
} as const;
export const AVATAR_SIZE_PX_RESPONSIVE_SECONDARY = {
  xs: AVATAR_SIZE_PX_SECONDARY_MOBILE,
  md: AVATAR_SIZE_PX_SECONDARY,
} as const;

export const PLACEHOLDER_AVATAR_URL =
  '/assets/images/avatar-default.svg' as const;

const extractBackgroundIndex = (src: string) => {
  const params = new URLSearchParams(src.split('?')[1]);
  // eslint-disable-next-line @blumintinc/blumint/no-always-true-false-conditions
  return Number.parseInt(params.get(BACKGROUND_URL_PARAM) || '0');
};

export type AvatarNextProps = Omit<AvatarProps, 'sx'> &
  Pick<Omit<ImageOptimizedProps, 'width' | 'height'>, 'objectFit'> & {
    sx?: SxProps;
    size?: ImageOptimizedProps['width'];
  };

export const AvatarNext = memo(function AvatarNextUnmemoized({
  src,
  alt,
  children,
  sx = {},
  objectFit = 'cover',
  size = AVATAR_SIZE_PX_RESPONSIVE_SECONDARY,
  ...props
}: AvatarNextProps) {
  const theme = useTheme();

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const inner = useMemo(() => {
    if (children) {
      return children;
    }
    return (
      <ImageOptimized
        alt={alt}
        height={size}
        objectFit={objectFit}
        src={src && src !== 'DEFAULT_IMAGE' ? src : PLACEHOLDER_AVATAR_URL}
        width={size}
      />
    );
  }, [children, alt, objectFit, size, src]);

  const srcBackgroundIndex =
    !src || !src.includes(BACKGROUND_URL_PARAM)
      ? undefined
      : extractBackgroundIndex(src);
  const background = srcBackgroundIndex
    ? MASCOT_BACKGROUNDS[assertSafe(Number(srcBackgroundIndex))]
    : theme.palette.background.elevation[16];

  return (
    <Avatar
      {...props}
      sx={{
        background,
        border: `1.5px solid ${theme.palette.background.elevation[16]}`,
        '& img': {
          borderRadius: '50%',
        },
        height: size,
        width: size,
        ...sx,
      }}
    >
      {inner}
    </Avatar>
  );
});
