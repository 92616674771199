import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { getSessionId } from '../util/session/getSessionId';
import { useDynamic } from '../hooks/useDynamic';
import { memo } from '../util/memo';
// eslint-disable-next-line no-restricted-imports
import type { SessionManager as SessionManagerType } from '../util/session/SessionManager.dynamic';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { useAuth } from './AuthContext';

export type SessionContextProps = {
  sessionId: string | undefined;
  disconnect: () => Promise<void>;
};

const SessionContext = createContext<SessionContextProps | undefined>(
  undefined,
);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useSession must be used within SessionProvider',
    );
  }
  return context;
};

type SessionProviderProps = {
  children: ReactNode;
};

const SessionProviderUnmemoized = ({ children }: SessionProviderProps) => {
  const { user } = useAuth();
  const { uid, isAnonymous } = user || {};
  const sessionId = getSessionId();
  const managerRef = useRef<SessionManagerType | undefined>();

  const SessionManagerModule = useDynamic(
    import('../util/session/SessionManager.dynamic'),
  );

  useEffect(() => {
    if (!SessionManagerModule) {
      return;
    }

    const { SessionManager } = SessionManagerModule;

    const setupManager = async () => {
      await disconnect();

      if (uid && !isAnonymous && sessionId) {
        managerRef.current = new SessionManager({
          userId: uid,
          sessionId,
        });
        managerRef.current.connect();
      }
    };

    setupManager();

    return () => {
      if (managerRef.current) {
        // eslint-disable-next-line promise/prefer-await-to-then
        managerRef.current.disconnect().catch(console.error);
      }
    };
  }, [SessionManagerModule, uid, isAnonymous, sessionId]);

  const disconnect = async () => {
    const currentManager = managerRef.current;

    if (currentManager) {
      await currentManager.disconnect();

      if (managerRef.current === currentManager) {
        managerRef.current = undefined;
      }
    }
  };

  const value = useMemo(() => {
    return {
      sessionId,
      disconnect,
    } as const;
  }, [sessionId]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const SessionProvider = memo(SessionProviderUnmemoized);
