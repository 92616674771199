import {
  createContext,
  ReactElement,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { useRouter } from 'src/hooks/routing/useRouter';
import { PageLoadingWrapper } from 'src/components/PageLoadingWrapper';

export type PageLoadingContextProps = {
  toggleLoadingOverlay: (show: boolean) => void;
};

const DEFAULT_STATE: PageLoadingContextProps = {
  toggleLoadingOverlay: () => {
    /* */
  },
};

const PageLoadingContext =
  createContext<PageLoadingContextProps>(DEFAULT_STATE);

export function usePageLoadingContext() {
  const context = useContext(PageLoadingContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'usePageLoadingContext must be used within a PageLoadingProvider',
    );
  }
  return context;
}

export type PageLoadingProviderProps = {
  children: ReactElement;
};

export const PageLoadingProvider = memo(function PageLoadingProviderUnmemoized({
  children,
}: PageLoadingProviderProps) {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const start = (_url, { shallow }) => {
      shallow ? setIsLoading(false) : setIsLoading(true);
    };
    const complete = () => {
      return setIsLoading(false);
    };

    router.events.on('routeChangeStart', start);
    router.events.on('routeChangeComplete', complete);
    router.events.on('routeChangeError', complete);

    return () => {
      router.events.off('routeChangeStart', start);
      router.events.off('routeChangeComplete', complete);
      router.events.off('routeChangeError', complete);
    };
  });

  const toggleLoadingOverlay = useCallback((isLoadingExternal: boolean) => {
    setIsLoading(isLoadingExternal);
  }, []);

  const contextValue = useMemo(() => {
    return {
      toggleLoadingOverlay,
    } as const;
  }, [toggleLoadingOverlay]);

  return (
    <PageLoadingContext.Provider value={contextValue}>
      <PageLoadingWrapper isLoading={isLoading}>{children}</PageLoadingWrapper>
    </PageLoadingContext.Provider>
  );
});
