/* eslint-disable security/detect-unsafe-regex */
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

export function rgbToHex(r: number, g: number, b: number): string {
  const isValidRGB = (n: number) => {
    return n >= 0 && n <= 255;
  };
  if (![r, g, b].every(isValidRGB)) {
    throw new HttpsError(
      'invalid-argument',
      'RGB values must be between 0 and 255',
      { r, g, b },
    );
  }
  const toHex = (n: number) => {
    const hex = Math.round(n).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function hslToRgb(
  h: number,
  s: number,
  l: number,
): [number, number, number] {
  if (h < 0 || h >= 360)
    throw new HttpsError('invalid-argument', 'Hue must be between 0 and 360', {
      h,
    });
  if (s < 0 || s > 100)
    throw new HttpsError(
      'invalid-argument',
      'Saturation must be between 0 and 100',
      { s },
    );
  if (l < 0 || l > 100)
    throw new HttpsError(
      'invalid-argument',
      'Lightness must be between 0 and 100',
      { l },
    );

  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    [r, g, b] = [c, x, 0];
  } else if (60 <= h && h < 120) {
    [r, g, b] = [x, c, 0];
  } else if (120 <= h && h < 180) {
    [r, g, b] = [0, c, x];
  } else if (180 <= h && h < 240) {
    [r, g, b] = [0, x, c];
  } else if (240 <= h && h < 300) {
    [r, g, b] = [x, 0, c];
  } else if (300 <= h && h < 360) {
    [r, g, b] = [c, 0, x];
  }

  return [(r + m) * 255, (g + m) * 255, (b + m) * 255];
}

export function hexify(color: string): string {
  // Already hex
  if (color.startsWith('#')) {
    return color;
  }

  // RGB/RGBA
  const rgbMatch = color.match(
    /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/,
  );
  if (rgbMatch) {
    const [, r, g, b] = rgbMatch.map(Number);
    return rgbToHex(r, g, b);
  }

  // HSL/HSLA - updated regex to handle more formats
  const hslMatch = color.match(
    /hsla?\(\s*(\d+(?:\.\d+)?)\s*,\s*(\d+(?:\.\d+)?)\s*%?\s*,\s*(\d+(?:\.\d+)?)\s*%?(?:\s*,\s*[\d.]+)?\s*\)/i,
  );
  if (hslMatch) {
    const [, h, s, l] = hslMatch.map(Number);
    if (!Number.isNaN(h) && !Number.isNaN(s) && !Number.isNaN(l)) {
      const [r, g, b] = hslToRgb(h, s, l);
      return rgbToHex(r, g, b);
    }
  }

  return color;
}

export function normalizeGradient(gradientCss: string): string {
  // Convert any color format to hex in the gradient string
  const normalizedGradient = gradientCss.replace(COLOR_REGEX, (match) => {
    return hexify(match);
  });

  return normalizedGradient.replace(/[(),\s#%]/g, '');
}

export const COLOR_REGEX = /(#[0-9a-f]{6}|rgb[a]?\([^)]+\)|hsl[a]?\([^)]+\))/gi;

/**
 * Darkens a hex color by a percentage
 * @param hex - Hex color string (with or without #)
 * @param percent - Percentage to darken by (0-100)
 */
export const darkenHexColor = (hex: string, percent: number): string => {
  let color = hex.replace('#', '');
  
  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16); 
  const b = parseInt(color.slice(4, 6), 16);

  const darkenComponent = (c: number) => Math.max(0, Math.floor(c * (1 - percent / 100)));
  
  const dr = darkenComponent(r);
  const dg = darkenComponent(g);
  const db = darkenComponent(b);

  const toHex = (n: number) => n.toString(16).padStart(2, '0');
  return `#${toHex(dr)}${toHex(dg)}${toHex(db)}`;
};

/**
 * Sets the alpha/opacity of a color
 * @param hex - Hex color string (with or without #)
 * @param alpha - Alpha value between 0 and 1
 */
export const setAlpha = (hex: string, alpha: number): string => {
  let color = hex.replace('#', '');
  
  // Convert hex to RGB
  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
