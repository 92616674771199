/* eslint-disable @blumintinc/blumint/require-dynamic-firebase-imports */
import type { Query, DocumentData } from 'firebase/firestore';
import { query, where } from 'firebase/firestore';
import { RealtimeContextFactory } from './realtime/RealtimeContextFactory';
import { Notification } from 'functions/src/types/firestore/User/Notification';
import { toUserNotificationCollectionPath } from 'functions/src/types/firestore/User/Notification/path';

export type ReadNotification = Omit<Notification<Date>, 'status'> & {
  status: 'read';
};

const buildReadQuery = (
  collectionRef: Query<ReadNotification, DocumentData>,
) => {
  return query(collectionRef, where('status', '==', 'read')) as Query<
    ReadNotification,
    DocumentData
  >;
};

const {
  Context: RealtimeReadNotificationsContext,
  Provider: RealtimeReadNotificationsProvider,
  useRealtimeContext: useRealtimeReadNotifications,
} = RealtimeContextFactory.build<ReadNotification>({
  getPath: toUserNotificationCollectionPath,
  buildQuery: buildReadQuery,
  timeKey: 'createdAt',
});

export {
  RealtimeReadNotificationsContext,
  RealtimeReadNotificationsProvider,
  useRealtimeReadNotifications,
};
