import { ChannelFilters as StreamChannelFilters } from 'stream-chat';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../types/firestore/User/ChannelGroup';
import { toChannelFilters } from './channel/toChannelFilters';

export const filtersWithoutKeywords = (filters: StreamChannelFilters) => {
  const { type, members, groupNumber, ...rest } = filters;
  return rest;
};

export const toChannelData = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
) => {
  const { groupFilter, imgUrl, title: name } = channelGroup;
  const channelFilters = toChannelFilters(groupFilter);
  const filters = filtersWithoutKeywords(channelFilters);
  return {
    name,
    image: imgUrl,
    ...filters,
  };
};
