import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/CloseRounded';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import type { ThreadHeaderProps as StreamThreadHeaderProps } from 'stream-chat-react';
import { memo } from '../../util/memo';

export type ThreadHeaderProps = Pick<StreamThreadHeaderProps, 'closeThread'>;

const ThreadHeaderUnmemoized = ({ closeThread }: ThreadHeaderProps) => {
  const theme = useTheme();
  return (
    <Stack spacing={1}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          px: 4,
          py: 1,
          width: '100%',
          background: theme.palette.background.elevation[12],
        }}
      >
        <Typography variant="h6">Thread</Typography>
        <IconButton
          sx={{
            color: theme.palette.text.primary,
            svg: {
              height: '24px',
              width: '24px',
            },
          }}
          onClick={closeThread}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export const ThreadHeader = memo(ThreadHeaderUnmemoized);
