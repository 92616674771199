import { ReactNode, Fragment, useMemo } from 'react';
import { SxProps } from '@mui/material';
import stringify from 'safe-stable-stringify';
import { OverlayAvatar } from './OverlayAvatar';
import { memo } from 'src/util/memo';

const DEFAULT_CYCLE_SECONDS = 5 as const;
const DEFAULT_TRANSITION_SECONDS = 0.5 as const;

export type CyclingOverlayAvatarProps = {
  overlays: ReactNode[];
  cycleTimeSeconds?: number;
  transitionTimeSeconds?: number;
  sx?: SxProps;
};

const CyclingOverlayAvatarUnmemoized = ({
  overlays,
  cycleTimeSeconds = DEFAULT_CYCLE_SECONDS,
  transitionTimeSeconds = DEFAULT_TRANSITION_SECONDS,
  sx,
}: CyclingOverlayAvatarProps) => {
  const totalDurationSeconds = cycleTimeSeconds * overlays.length;
  const visiblePercentage = 100 / overlays.length;
  const fadeOutStartPercent = visiblePercentage * 0.8;

  const keyframes = useMemo(() => {
    return {
      [`@keyframes cyclingOverlayFade`]: {
        '0%': {
          opacity: 0,
        },
        [`${(transitionTimeSeconds / totalDurationSeconds) * 100}%`]: {
          opacity: 1,
        },
        [`${fadeOutStartPercent}%`]: {
          opacity: 1,
        },
        [`${
          fadeOutStartPercent +
          (transitionTimeSeconds / totalDurationSeconds) * 100
        }%`]: {
          opacity: 0,
        },
        '100%': {
          opacity: 0,
        },
      },
    } as const;
  }, [transitionTimeSeconds, totalDurationSeconds, fadeOutStartPercent]);

  return (
    <Fragment>
      {overlays.map((overlay, index) => {
        const delayPercentage = (index / overlays.length) * 100;
        return (
          <OverlayAvatar
            key={stringify(overlay)}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0,
              width: '100%',
              height: '100%',
              animation: ` infinite cyclingOverlayFade`,
              animationDelay: `${
                -totalDurationSeconds * (delayPercentage / 100)
              }s`,
              ...keyframes,
              ...sx,
            }}
          >
            {overlay}
          </OverlayAvatar>
        );
      })}
    </Fragment>
  );
};

export const CyclingOverlayAvatar = memo(CyclingOverlayAvatarUnmemoized);
