import { useCallback } from 'react';
import {
  Notification,
  TeamInviteNotification,
} from '../../../functions/src/types/firestore/User/Notification';
import { acceptShipRequest } from '../../util/ship/acceptShipRequest';
import { rejectShipRequest } from '../../util/ship/rejectShipRequest';
import { assertSafe } from 'functions/src/util/assertSafe';
// import { useRegistrationFunctions } from '../tournaments/useRegistrationFunctions';

export const NOTIFICATION_ACTION_MAPPINGS: Record<string, NotificationAction> =
  {
    acceptFriendRequest: async (notification) => {
      const { toId, fromId } = notification;
      await acceptShipRequest({
        userId: toId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        groupId: fromId!,
        variant: 'friendship',
      });
    },
    rejectFriendRequest: async (notification) => {
      const { toId, fromId } = notification;
      await rejectShipRequest({
        userId: toId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        groupId: fromId!,
        variant: 'friendship',
      });
    },
    acceptTeamInvite: async (notification) => {
      const { acceptInvite } = await import(
        '../../firebaseCloud/tournament/acceptInvite'
      );

      const { gameId, tournamentId, teamId } =
        notification as TeamInviteNotification;
      await acceptInvite({
        gameId,
        tournamentId,
        teamId,
      });
    },
    rejectTeamInvite: async (notification) => {
      const { rejectInvite } = await import(
        '../../firebaseCloud/tournament/rejectInvite'
      );

      const { gameId, tournamentId, teamId } =
        notification as TeamInviteNotification;
      await rejectInvite({
        gameId,
        tournamentId,
        teamId,
      });
    },
    // checkIn: async (notification) => {
    // checkIn: async (notification) => {
    //   const { toId } = notification;
    //   await checkIn(false, toId);
    //   await deleteNotification(notification.id, toId);
    // },
  } as const;

export type NotificationAction = (
  notification: Notification<Date>,
) => Promise<void>;

export const useNotificationActions = () => {
  // const { checkIn } = useRegistrationFunctions();

  const executeNotificationAction = useCallback(
    async (notification: Notification<Date>, actionParam: string) => {
      const action = NOTIFICATION_ACTION_MAPPINGS[assertSafe(actionParam)];
      if (action) {
        await action(notification);
      } else {
        throw new Error(`No action found for ${actionParam}`);
      }
    },
    [],
  );

  return { executeNotificationAction } as const;
};
