import Stack from '@mui/material/Stack';
import { useMemo, useCallback } from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAddRounded';
import AddIcon from '@mui/icons-material/AddRounded';
import SettingsIcon from '@mui/icons-material/SettingsRounded';
import PeopleIcon from '@mui/icons-material/PeopleRounded';
import { MenuProps } from '@mui/material/Menu';
import {
  ChannelManager,
  ROUTER_KEY_FRIENDS,
} from '../../messaging/ChannelManager';
import { TabPane, TabPanes } from '../../routing/TabPanes';
import { useRouterState } from '../../../hooks/routing/useRouterState';
import { memo } from '../../../util/memo';
import { useGlobalComponentsContext } from '../../../contexts/GlobalComponentsContext';
import { ChatMenu } from '../../messaging/ChatMenu';
import { useAuth } from '../../../contexts/AuthContext';
import { GradientIcon } from '../../gradients/GradientIcon';
import { ANCHOR_ORIGIN_BOTTOM_RIGHT } from '../../../util/styles/anchorOrigin';
import { TRANSFORM_ORIGIN_TOP_RIGHT } from '../../../util/styles/transformOrigin';
import { useStatusCount } from '../../../hooks/status/useStatusCount';
import { stableHash } from '../../../../functions/src/util/hash/stableHash';
import { FriendsAdd } from './FriendsAdd';
import { FriendsEdit } from './FriendsEdit';
import { FriendsView } from './FriendsView';
import { FriendRequestBadgePane } from './FriendRequestBadgePane';

export const CHAT_MENU_NAME = 'CHAT_MENU' as const;
export const FRIEND_PANE_OPTIONS = ['View', 'Add', 'Edit', 'Chat'] as const;
export type FriendPaneOption = (typeof FRIEND_PANE_OPTIONS)[number];

const SLOT_PROPS_MENU: MenuProps['slotProps'] = {
  root: {
    style: { top: '8px' },
  },
};

const FriendsPaneUnmemoized = () => {
  const { union, remove } = useGlobalComponentsContext();
  const { userData } = useAuth();

  const { friends = [] } = userData || {};

  const onlineFriendsCount = useStatusCount({
    groupId: friends.length === 0 ? undefined : stableHash(friends),
    status: 'online',
  });

  const closeChatMenu = useCallback(() => {
    remove(CHAT_MENU_NAME);
  }, [remove]);

  const tabState = useRouterState({
    key: ROUTER_KEY_FRIENDS,
    defaultValue: 'View' as FriendPaneOption,
  });

  const tabPanes: TabPane<string>[] = useMemo(() => {
    return [
      {
        value: 'View' as FriendPaneOption,
        component: (
          <FriendsView
            friendCount={friends.length}
            onlineFriendsCount={onlineFriendsCount}
          />
        ),
        customization: {
          IconComponent: <GradientIcon IconComponent={PeopleIcon} />,
        },
      },
      {
        value: 'Add' as FriendPaneOption,
        component: <FriendsAdd />,
        customization: {
          IconComponent: <GradientIcon IconComponent={PersonAddIcon} />,
        },
        Wrapper: FriendRequestBadgePane,
      },
      {
        value: 'Edit' as FriendPaneOption,
        component: <FriendsEdit onlineFriendsCount={onlineFriendsCount} />,
        customization: {
          IconComponent: <GradientIcon IconComponent={SettingsIcon} />,
        },
      },
      {
        value: 'Chat' as FriendPaneOption,
        onClick: (tabRef, go) => {
          union(
            CHAT_MENU_NAME,
            <ChatMenu
              anchorEl={tabRef.current}
              slotProps={SLOT_PROPS_MENU}
              transformOrigin={TRANSFORM_ORIGIN_TOP_RIGHT}
              onCreateSupport={closeChatMenu}
              anchorOrigin={ANCHOR_ORIGIN_BOTTOM_RIGHT}
              // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
              createChat={() => {
                go();
                closeChatMenu();
              }}
            />,
          );
        },
        component: <ChannelManager />,
        customization: {
          IconComponent: <GradientIcon IconComponent={AddIcon} />,
          label: 'Chat',
        },
      },
    ];
  }, [friends.length, onlineFriendsCount, union, closeChatMenu]);

  return (
    <Stack height="100%" pt={2} width="100%">
      <TabPanes state={tabState} tabPanes={tabPanes} variant="chip" />
    </Stack>
  );
};

export const FriendsPane = memo(FriendsPaneUnmemoized);
