/* eslint-disable @blumintinc/blumint/no-firestore-object-arrays */
/* eslint-disable @blumintinc/blumint/enforce-boolean-naming-prefixes */
/* eslint-disable max-lines */
import type { Timestamp } from '../../../../config/firebaseAdmin';
import { Subscribable } from '../../../Subscribable';
import { WithAdditionalImages } from '../../../MoreImages';
import { Identifiable } from '../../../Identifiable';
import { Resolve } from '../../../utility-types';
import { Roled } from '../../../Roled';
import { Dated } from '../../../Dated';
import { Payoutable, Token } from '../../User/Payout';
import { AllSignInMethod } from '../../User';
import { OverwolfGameId } from '../../User/OverwolfGame';
import { Round, RoundIndex, RoundSettings } from './Round';
import {
  GameEndCondition,
  MatchSettings,
  MatchSettingsElimination,
} from './Round/Match';
import type { ParticipantTeam } from './Participant';
import { GuestlistTeam } from './Guestlist';
import type { WaitlistTeam } from './Waitlist';
import { Cohort } from './Cohort';

export type TournamentDatum = {
  tournament: Tournament<Date>;
};

export const DEFAULT_SQUARE_IMG =
  '/assets/images/blumint_squareImg.png' as const;
export const DEFAULT_CALENDAR_IMG =
  '/assets/images/tournament-calendar/img-url-default.png' as const;

export type HeatEndpoint = {
  url: string;
  pollLevel: 'heat' | 'result';
  method: 'GET' | 'POST';
  postBody?: Record<string, unknown>;
};

export type QualificationCondition =
  | { type: 'proportion'; proportion: number }
  | { type: 'top'; numberOfPlaces: number };

export const SINGLE_ELIMINATION_MODE = 'single-elimination' as const;
export const DOUBLE_ELIMINATION_MODE = 'double-elimination' as const;
export const MULTI_BRACKET_MODE = 'multi-bracket' as const;
export const VERSUS_MODE = 'versus' as const;
export const LEADERBOARD_MODE = 'leaderboard' as const;
export const HEATS_MODE = 'heats' as const;
export const SWISS_MODE = 'swiss' as const;
export const CONTINUOUS_KING_MODE = 'continuous-king' as const;
export const NO_BRACKET_MODE = 'no-bracket' as const;

export const BRACKET_MODE_COHORT = [
  SINGLE_ELIMINATION_MODE,
  DOUBLE_ELIMINATION_MODE,
  MULTI_BRACKET_MODE,
] as const;

export type BracketModeCohort = (typeof BRACKET_MODE_COHORT)[number];

export const BRACKET_MODE_SINGLE_ROUND = [
  VERSUS_MODE,
  LEADERBOARD_MODE,
] as const;

export type BracketModeSingleRound = (typeof BRACKET_MODE_SINGLE_ROUND)[number];

export const BRACKET_MODE = [
  HEATS_MODE,
  SWISS_MODE,
  CONTINUOUS_KING_MODE,
  NO_BRACKET_MODE,
  ...BRACKET_MODE_SINGLE_ROUND,
  ...BRACKET_MODE_COHORT,
] as const;
/**
 * whenever we add a new bracket type on here please add it to src/util/tournaments/toFormInput.ts under the Bracket Type Field */
export type BracketMode = (typeof BRACKET_MODE)[number];

/**
 * @remarks
 * Note that for versus mode, the maxTeamsPerMatch has to be equal to the maxTeamCount of the tournament.
 */
// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type VersusSettings = Omit<MatchSettings, 'includesRedemption'>;

{
  /**
   * @remarks
   * There will be Math.ceil(participants / maxTeamsPerMatch) matches.
   * Teams will be divided up as evenly among the matches as possible.
   *
   * Set this to Number.MAX_SAFE_INTEGER to indicate that
   * there is no upper limit to the number of teams per match.
   *
   * This MUST be greater than 1.
   */
  // maxTeamsPerMatch: number;
}
// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type LeaderboardSettings<
  TCondition extends GameEndCondition = GameEndCondition,
> = MatchSettings<TCondition>;

/**
 * @remarks
 * '0' corresponds to Round 1.
 * '1' corresponds to Round 2.
 * '-1' corresponds to the final round.
 * '-2' corresponds to the penultimate round.
 * 'default' will be used if there is not a specific round key.
 * For example, if you apply:
 * {
 *   '-1': { foo: 'foo' },
 *   'default': { bar: 'bar' }
 * }
 * Then the final round will have the foo settings and all other
 * rounds will have the bar settings.
 */
export type RoundKey = 'default' | `${RoundIndex}`;

/**
 * @remarks
 * The number of rounds must be known and greater than 1.
 */
// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type HeatsSettings = {
  numberOfRounds: number;
  instantDisqualification?: boolean;
} & Record<RoundKey, MatchSettings>; //scoreToQualify: minimum score needed to qualify to next round.

/**
 * @remarks
 * Always the case that minWinners === maxWinners === 1.
 */

export const MAX_TEAMS_PER_MATCH_COHORT = 2 as const;
export type MaxTeamsPerMatchCohort = typeof MAX_TEAMS_PER_MATCH_COHORT;
// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type MultiBracketSettings = Record<RoundKey, MatchSettingsElimination>; //n level brackets: n is the number of fallback brackets, single elimination is n = 0

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type DoubleEliminationSettings = {
  [K in keyof MultiBracketSettings]: Omit<
    MultiBracketSettings[K],
    'includesRedemption' | 'maxTeamsPerMatch'
  > & {
    includesRedemption?: boolean;
    maxTeamsPerMatch: MaxTeamsPerMatchCohort;
  };
};

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type SingleEliminationSettings = {
  [K in keyof MultiBracketSettings]: Omit<
    MultiBracketSettings[K],
    'includesRedemption' | 'maxTeamsPerMatch'
  > & {
    maxTeamsPerMatch: MaxTeamsPerMatchCohort;
    includesRedemption: false;
  };
};

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type RoundRobinSettings = RoundSettings & {
  /**
   * @remarks
   * The number of times each team will play each other.
   * For example, if repetitions === 2, then each team will play each other twice.
   */
  repetitions: number;
};

/**
 * @remarks
 *
 * NOTE: Round Robin is a variation of Swiss.
 */
// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type SwissSettings = RoundSettings &
  (
    | {
        pairing: 'monrad';
        /**
         * @remarks
         * The number of rounds to be played.
         * If roundsTotal === 'minimum-conclusive', then the number of
         * rounds will be Math.ceil(Math.logX(participants)), where X is the
         * number of teams per match. This is the number of rounds
         * required to have a conclusive winner.
         */
        roundsTotal: number | 'minimum-conclusive';
      }
    | {
        pairing: 'round-robin';
        roundsTotal:
          | number
          | {
              /**
               * @remarks
               * With this setting, the total number of rounds is
               * determined by how many times each team will play each other.
               *
               * For example, if repetitions === 2, then each team will
               * play each other twice.
               */
              repetitions: number;
            };
      }
  );

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type ContinuousKingSettings = RoundSettings & {
  /**
   * @remarks
   * Firestore User ID of the user who will remain king even if they're defeated.
   */
  kingId: string;

  /**
   * @remarks
   * How registered participants will be plucked off the participant list
   * to challenge the king.
   */
  pairing: 'random' | 'random-no-repeat' | 'donation-desc' | 'manual';
};

export type PairingGeneric =
  | 'monrad'
  | 'round-robin'
  | 'winners'
  | 'losers'
  | 'random'
  | 'random-no-repeat'
  | 'donation-desc'
  | 'manual';

export type Pairing =
  | PairingGeneric
  | {
      kingId: string;
      selection: PairingGeneric;
    };

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type NoBracketSettings = Record<string, never>;

export type BracketSetting<TMode extends BracketMode, TSettings> = {
  mode: TMode;
  settings: TSettings;
};

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type BracketSettings =
  | BracketSetting<'versus', VersusSettings>
  | BracketSetting<'leaderboard', LeaderboardSettings>
  | BracketSetting<'heats', HeatsSettings>
  | BracketSetting<'single-elimination', SingleEliminationSettings>
  | BracketSetting<'double-elimination', DoubleEliminationSettings>
  | BracketSetting<'multi-bracket', MultiBracketSettings>
  | BracketSetting<'swiss', SwissSettings>
  | BracketSetting<'continuous-king', ContinuousKingSettings>
  | BracketSetting<'no-bracket', NoBracketSettings>;

export const TOURNAMENT_PHASE_REGISTRATION = 'registration' as const;
export const TOURNAMENT_PHASE_READY = 'ready' as const;
export const TOURNAMENT_PHASE_LIVE = 'live' as const;
export const TOURNAMENT_PHASE_FINISHED = 'finished' as const;
export const TOURNAMENT_PHASE_PAYOUT = 'payout' as const;
export const TOURNAMENT_PHASE_CHECK_IN = 'checkIn' as const;
export const TOURNAMENT_PHASE_UNPUBLISHED = 'unpublished' as const;
export const TOURNAMENT_PHASE_REVIEW = 'review' as const;

export const TOURNAMENT_PHASE_PAST = [
  TOURNAMENT_PHASE_FINISHED,
  TOURNAMENT_PHASE_PAYOUT,
] as const;

export const TOURNAMENT_PHASE_PRESENT = [
  TOURNAMENT_PHASE_CHECK_IN,
  TOURNAMENT_PHASE_READY,
  TOURNAMENT_PHASE_LIVE,
] as const;

export const TOURNAMENT_PHASE_PUBLISHED = [
  'registration',
  'checkIn',
  'ready',
  'live',
  'finished',
  'payout',
] as const;

export const TOURNAMENT_PHASE = [
  'unpublished',
  'review',
  ...TOURNAMENT_PHASE_PUBLISHED,
] as const;

export const TOURNAMENT_PHASE_EDITABLE = [
  'unpublished',
  'review',
  'registration',
] as const;

export const TOURNAMENT_PHASE_BEFORE_REGISTRATION = [
  'unpublished',
  'review',
] as const;

export const TOURNAMENT_PHASE_BEFORE_READY = [
  'unpublished',
  'review',
  'registration',
  'checkIn',
] as const;

export const TOURNAMENT_PHASE_AFTER_CHECK_IN = [
  'ready',
  'live',
  'finished',
  'payout',
] as const;

export type TournamentPhaseBeforeReady =
  (typeof TOURNAMENT_PHASE_BEFORE_READY)[number];

export type TournamentPhase = (typeof TOURNAMENT_PHASE)[number];

export type TournamentPhasePublished =
  (typeof TOURNAMENT_PHASE_PUBLISHED)[number];

export type TournamentPhaseEditable =
  (typeof TOURNAMENT_PHASE_EDITABLE)[number];

export type TournamentPhasePast = (typeof TOURNAMENT_PHASE_PAST)[number];

export type TournamentPhasePresent = (typeof TOURNAMENT_PHASE_PRESENT)[number];

export type TournamentPhaseBeforeRegistration =
  (typeof TOURNAMENT_PHASE_BEFORE_REGISTRATION)[number];

export type TournamentPhaseRegistration =
  (typeof TOURNAMENT_PHASE_REGISTRATION)[number];

export type TournamentPhaseAfterCheckIn =
  (typeof TOURNAMENT_PHASE_AFTER_CHECK_IN)[number];

export type TournamentDetail = {
  title: string;
  body: string;
  id?: string;
};

// eslint-disable-next-line @blumintinc/blumint/enforce-singular-type-names
export type TournamentAggregations<T = Timestamp> = {
  waitlistAggregated: WaitlistTeam<T>[];
  guestlistAggregated: GuestlistTeam<T>[];
  participantsAggregated: ParticipantTeam<T>[];
};

/**
 * Rename once TournamentAggregations is removed
 */
export type TournamentAggregationsTotal = {
  waitlistTotal: number;
  guestlistTotal: number;
  participantTotal: number;
};

export type RegistrationOptionBase = Identifiable & {
  preamble: string;
  postambleText?: string;
  postambleLink?: string;
  optional: boolean;
};

export type RegistrationOptionCustom = RegistrationOptionBase & {
  formatExample: string;
  regex: string;
  regexExplained?: string;
  name: string;
};

export type RegistrationOptionAuto = RegistrationOptionBase & {
  providers: AllSignInMethod[] | 'fetched';
};

export type RegistrationOption =
  | RegistrationOptionCustom
  | RegistrationOptionAuto;

export type RoundPreview = Pick<Round, 'name' | 'stage'>;
// eslint-disable-next-line @blumintinc/blumint/enforce-id-capitalization
export type CohortPreview = Pick<Cohort, 'id' | 'name'>;

export const EVENT_VISIBILITY = ['listed', 'unlisted'] as const;
export type EventVisibility = (typeof EVENT_VISIBILITY)[number];
/**
 * The key is the livestreamId, the value is the livestream status
 */
export type PlaybacksDownloadable = Record<string, string | 'still-live'>;

export type TournamentRole =
  | 'contributor'
  | 'moderator'
  | 'streamer'
  | 'registrant'
  | 'admin';

export type Tournament<T = Timestamp> = Resolve<
  WithAdditionalImages<
    Subscribable<
      Identifiable &
        Dated<T> &
        Roled<TournamentRole> &
        TournamentAggregations<T> &
        TournamentAggregationsTotal &
        Payoutable<{
          title: string;
          gameTitle: string;
          gameId: string;
          overwolfGameId?: OverwolfGameId;
          endDate: T;
          imgUrl?: string;
          tournamentThumbnailImgUrl?: string;
          intermissions?: Intermission<T>[];
          visibility?: EventVisibility;
          blurb: Blurb;
          sponsors?: Sponsor[];
          payouts: { tokens: Token[] }[];
          payoutGiveaway?: Token[];
          region?: string;
          maxTeamSize: number;
          maxTeamCount: number;
          minTeamSize?: number;
          phase: TournamentPhase;
          tournamentDetails: TournamentDetail[];
          checkInTime: T;
          readyTime: T;
          qualification?: QualificationCondition[];
          registrationOptions: RegistrationOption[];
          squareImgUrl?: string;
          skipCheckIn?: boolean;
          continuousRegistration?: boolean;
          prizePoolId: string | null;
          roundsAggregation: RoundsAggregation;
          cohortPreviews?: CohortPreview[];
          settings: BracketSettings;
          requirementTokens?: RequirementToken;
          preventMerging?: boolean;
          primaryPlaybackId?: string;
          livestreamId?: string;
        }>
    >,
    'profileImgUrl' | 'streamerLiveThumbnail'
  >
>;

export type RequirementToken = Record<
  string,
  { collectionName: string; templateId: string; count: number }[]
>;

export type RoundsAggregation = {
  roundIds: string[];
  roundPreviews: Record<string, RoundPreview>;
};

export const PRIZES_DEFAULT = {
  amountFiat: 0,
  countIlliquid: 0,
  countUnassured: 0,
} as const;

export type Sponsor = {
  name: string;
  imgUrl?: string;
  redirectUrl?: string;
  width?: number;
  height?: number;
};

export type PayoutFormatted = {
  amountFiat?: number;
  countIlliquid?: number;
  countUnassured?: number;
};

export type Blurb = {
  title: string;
  description: string;
  rules?: string[];
};

export type Intermission<T = Timestamp> = {
  start: T;
  end: T;
};

export type CompetitionPeriod<T = Timestamp> = Resolve<
  // eslint-disable-next-line @blumintinc/blumint/enforce-id-capitalization
  Pick<Tournament<T>, 'id' | 'date' | 'title' | 'endDate'>
>;
