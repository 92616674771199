import {
  YEAR_IN_MS,
  MONTH_IN_MS,
  WEEK_IN_MS,
  DAY_IN_MS,
  HOUR_IN_MS,
  MINUTE_IN__MS,
  SECOND_IN_MS,
} from './conversions';

export class TimeDifference {
  public readonly date1LaterThanDate2: boolean;
  public readonly datesAreSame: boolean;
  public readonly seconds: number;
  public readonly minutes: number;
  public readonly hours: number;
  public readonly days: number;
  public readonly weeks: number;
  public readonly months: number;
  public readonly years: number;

  constructor(date1: Date, date2: Date) {
    const timeUntilDate = Math.abs(Number(date1) - Number(date2));
    this.date1LaterThanDate2 = Number(date1) - Number(date2) > 0;

    let differenceRemainingMs = timeUntilDate;

    this.years = Math.floor(differenceRemainingMs / YEAR_IN_MS);
    differenceRemainingMs -= this.years * YEAR_IN_MS;

    this.months = Math.floor(differenceRemainingMs / MONTH_IN_MS);
    differenceRemainingMs -= this.months * MONTH_IN_MS;

    this.weeks = Math.floor(differenceRemainingMs / WEEK_IN_MS);
    differenceRemainingMs -= this.weeks * WEEK_IN_MS;

    this.days = Math.floor(differenceRemainingMs / DAY_IN_MS);
    differenceRemainingMs -= this.days * DAY_IN_MS;

    this.hours = Math.floor(differenceRemainingMs / HOUR_IN_MS);
    differenceRemainingMs -= this.hours * HOUR_IN_MS;

    this.minutes = Math.floor(differenceRemainingMs / MINUTE_IN__MS);
    differenceRemainingMs -= this.minutes * MINUTE_IN__MS;

    this.seconds = Math.floor(differenceRemainingMs / SECOND_IN_MS);
    differenceRemainingMs -= this.seconds * SECOND_IN_MS;

    this.datesAreSame = timeUntilDate === 0;
  }
}
