import { useCallback } from 'react';
import { useRealtimeChannelGroups } from '../../contexts/RealtimeChannelGroupContext';
import { useVoiceChannelGroupRealtime } from '../voice-chat/useVoiceChannelGroupRealtime';
import {
  ChannelGroup,
  GeneralType,
  PersonalGroupType,
} from '../../../functions/src/types/firestore/User/ChannelGroup';

export const useFindChannelGroup = () => {
  const { realtimeDocuments: realtimeChannelGroups } =
    useRealtimeChannelGroups();
  const { voiceChannelGroup } = useVoiceChannelGroupRealtime();

  const findByChannelGroupId = useCallback(
    (channelGroupId?: string) => {
      if (voiceChannelGroup?.id === channelGroupId) {
        return voiceChannelGroup;
      }

      return realtimeChannelGroups.find((channelGroup) => {
        return channelGroup.id === channelGroupId;
      });
    },
    [realtimeChannelGroups, voiceChannelGroup],
  );

  const findByTournamentId = useCallback(
    (
      tournamentIdToFind: string | undefined,
      channelGroupType: GeneralType | PersonalGroupType,
    ) => {
      return realtimeChannelGroups.find((channelGroup) => {
        const { groupFilter } =
          // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
          channelGroup as unknown as ChannelGroup<'Tournament'>;
        return groupFilter.find(({ type, tournamentId }) => {
          return (
            type === channelGroupType && tournamentId === tournamentIdToFind
          );
        });
      });
    },
    [realtimeChannelGroups],
  );

  return {
    findByChannelGroupId,
    findByTournamentId,
  } as const;
};
