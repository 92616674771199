import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import { Suspense, useState, useEffect } from 'react';
import { memo } from '../util/memo';

const Lottie = dynamic(
  () => {
    return import('lottie-light-react');
  },
  { ssr: false },
);

export type LottieLoaderProps = {
  sx?: SxProps;
};

function LottieLoaderUnmemoized({ sx }: LottieLoaderProps) {
  const [animationData, setAnimationData] = useState<unknown>();

  useEffect(() => {
    const loadAnimation = async () => {
      const { default: json } = await import(
        'public/assets/animations/loading-spinner.json'
      );
      setAnimationData(json);
    };

    loadAnimation();
  }, []);

  return (
    <Box sx={sx}>
      <Suspense>
        <Lottie animationData={animationData} loop />
      </Suspense>
    </Box>
  );
}
export const LottieLoader = memo(LottieLoaderUnmemoized);
