import { GridColDef } from '@mui/x-data-grid';
import { useContext, useMemo } from 'react';
import { ResultSummary } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary';
import { Username } from '../../components/tournaments/heats-leaderboards/Username';
import {
  toAvatar,
  toName,
} from '../../../functions/src/types/firestore/Game/Tournament/Guestlist/util';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { ResultRanked } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';

export const HEADER_NAME_TEAM = 'TEAM' as const;
export const HEADER_NAME_USERNAME = 'USERNAME' as const;

export function useTeamColumn<T extends ResultRanked | ResultSummary>() {
  const { minTeamSize, maxTeamSize } = useContext(TournamentContext);
  const isSolo = minTeamSize === 1 && maxTeamSize === 1;
  const teamColumn: GridColDef<T> = useMemo(() => {
    const value: GridColDef<T> = {
      field: 'team',
      headerName: isSolo ? HEADER_NAME_USERNAME : HEADER_NAME_TEAM,
      minWidth: 200,
      flex: 1,
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      sortable: false,
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      editable: false,
      renderCell: ({ row: { team } }) => {
        const { members, captainId } = team;
        return (
          <Username
            imgUrl={toAvatar(team)}
            members={members}
            name={toName(team)}
            userIdCaptain={captainId}
          />
        );
      },
    };
    return value;
  }, [isSolo]);

  return teamColumn;
}
