import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { OnChange } from '../EditProps';
import {
  EditableWrapperText,
  EditableWrapperTextProps,
} from './EditableWrapperText';

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export type EditableWrapperNumberProps<
  TValue extends number,
  TUrl extends string,
> = Omit<EditableWrapperTextProps<TValue, TUrl>, 'onChange'> & {
  onChange: OnChange<TValue, number> | 'disabled';
};

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
function EditableWrapperNumberUnmemoized<
  TValue extends number,
  TUrl extends string = string,
>({ edit, onChange, ...props }: EditableWrapperNumberProps<TValue, TUrl>) {
  const editProps = useMemo(() => {
    return {
      ...edit,
      type: 'number',
    };
  }, [edit]);

  const onChangeConverted = useMemo(() => {
    if (onChange === 'disabled') {
      return 'disabled';
    }

    return (value?: string, oldValue?: TValue) => {
      const parsedValue = value === undefined ? undefined : Number(value);
      return onChange(parsedValue, oldValue);
    };
  }, [onChange]);

  return (
    <EditableWrapperText
      {...props}
      edit={editProps}
      onChange={onChangeConverted}
    />
  );
}

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const EditableWrapperNumber = memo(EditableWrapperNumberUnmemoized);
