import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { memo } from '../../util/memo';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useRouterState } from '../../hooks/routing/useRouterState';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';

export const ROUTER_KEY_DOWNLOAD_URL = 'download-url' as const;
export const ROUTER_KEY_PLAYBACK_ID = 'playback-id' as const;

export type VideoContextProps = {
  downloadUrl?: string;
  isLive: boolean;
  viewingPlaybackId?: string;
  hasLivestream: boolean;
  replaceDownloadUrl: (url?: string) => void;
  replaceViewingPlaybackId: (playbackId?: string) => void;
  replaceHasLivestream: (livestreamsExist: boolean) => void;
  // playbackIds?: string[];
  // mergePlaybackIds: (playbackIds: string[]) => void;
  // clearPlaybackIds: () => void;
};

export const VideoContext = createContext<VideoContextProps | undefined>(
  undefined,
);

export type VideoProviderProps = {
  children: ReactNode;
};

export const useVideo = () => {
  const context = useContext(VideoContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useVideo must be used within a VideoProvider',
    );
  }
  return context;
};

const VideoProviderUnmemoized: FC<VideoProviderProps> = ({ children }) => {
  const [downloadUrl, setDownloadUrl] = useRouterState({
    key: ROUTER_KEY_DOWNLOAD_URL,
  });
  const [viewingPlaybackId, setViewingPlaybackId] = useRouterState({
    key: ROUTER_KEY_PLAYBACK_ID,
  });

  const replaceDownloadUrl = useCallback(
    (url?: string) => {
      setDownloadUrl(url);
    },
    [setDownloadUrl],
  );

  // const [playbackIds, setPlaybackIds] = useState<string[]>();
  // const clearPlaybackIds = useCallback(() => {
  //   setPlaybackIds(viewingPlaybackId ? [viewingPlaybackId] : undefined);
  // }, [viewingPlaybackId]);

  // const mergePlaybackIds = useCallback(
  //   (ids: string[]) => {
  //     setPlaybackIds((prev) => {
  //       const playbacksToAdd = [
  //         ...new Set([
  //           ...(viewingPlaybackId ? [viewingPlaybackId] : []),
  //           ...ids,
  //         ]),
  //       ];
  //       const changes = diff(prev || [], playbacksToAdd);
  //       if (changes.length === 0) {
  //         return prev;
  //       }
  //       return playbacksToAdd;
  //     });
  //   },
  //   [viewingPlaybackId],
  // );

  const replaceViewingPlaybackId = useCallback(
    (playbackId?: string) => {
      if (playbackId === viewingPlaybackId) {
        return;
      }
      setViewingPlaybackId(playbackId);
    },
    [setViewingPlaybackId, viewingPlaybackId],
  );

  const [hasLivestream, setHasLivestream] = useState(false);

  const replaceHasLivestream = useCallback(
    (doesLivestreamExist: boolean) => {
      setHasLivestream((prev) => {
        if (doesLivestreamExist === prev) {
          return prev;
        }
        return doesLivestreamExist;
      });
    },
    [setHasLivestream],
  );

  const { exitMode } = useLivestreamMode();

  useEffect(() => {
    if (hasLivestream) {
      return;
    }
    replaceViewingPlaybackId(undefined);
    exitMode();
  }, [hasLivestream, replaceViewingPlaybackId, exitMode]);

  const memoizedValue = useMemo(() => {
    return {
      downloadUrl,
      isLive: !downloadUrl,
      viewingPlaybackId,
      hasLivestream,
      replaceHasLivestream,
      replaceDownloadUrl,
      replaceViewingPlaybackId,
      // playbackIds,
      // mergePlaybackIds,
      // clearPlaybackIds,
    } as const;
  }, [
    downloadUrl,
    viewingPlaybackId,
    hasLivestream,
    replaceHasLivestream,
    replaceDownloadUrl,
    replaceViewingPlaybackId,
    // playbackIds,
    // mergePlaybackIds,
    // clearPlaybackIds,
  ]);

  return (
    <VideoContext.Provider value={memoizedValue}>
      {children}
    </VideoContext.Provider>
  );
};

export const VideoProvider = memo(VideoProviderUnmemoized);
