/* eslint-disable security/detect-object-injection */
/* eslint-disable react-hooks/rules-of-hooks */
import { ReactNode, useMemo, useCallback } from 'react';
import type {
  DocumentData,
  FieldPath,
  FieldValue,
  UpdateData,
} from 'firebase/firestore';
import { Memoize } from 'typescript-memoize';
import { useDocSnapshot } from '../../../hooks/firestore/useDocSnapshot';
import { useDocUpdate } from '../../../hooks/firestore/useDocUpdate';
import { usePathState } from '../../../hooks/edit/usePathState';
import { PathValue } from './CentralizedProvider';
import { PathCentralizedProvider } from './PathCentralizedProvider';
import { memo } from 'src/util/memo';

export type UpdateFieldPath<T> = {
  [K in keyof UpdateData<T>]: K extends `${infer Path}`
    ? Path
    : K extends FieldPath
    ? K
    : never;
}[keyof UpdateData<T>];

export type PathDocumentCentralizedContextType<
  TObj extends Record<string, unknown>,
> = {
  values: Partial<TObj>;
  setValue: <TPath extends UpdateFieldPath<TObj>>(
    path: TPath,
    value: UpdateData<TObj>[TPath] | FieldValue,
  ) => Promise<void>;
  updateObj: (newValues: UpdateData<TObj>) => void | Promise<void>;
};

export type PathDocumentProviderProps<TObj extends DocumentData> = {
  docPath?: string;
  children: ReactNode;
} & (
  | { initialData?: TObj; dataOverride?: never }
  | { initialData?: never; dataOverride?: TObj }
);

const DEFAULT_VALUES = {} as const;

export class PathDocumentCentralizedProvider<
  TObj extends DocumentData,
> extends PathCentralizedProvider<TObj, PathDocumentProviderProps<TObj>> {
  // eslint-disable-next-line class-methods-use-this
  @Memoize()
  public get Provider() {
    const UnmemoizedProvider = ({
      docPath,
      initialData,
      dataOverride,
      children,
    }: PathDocumentProviderProps<TObj>) => {
      const {
        values: overridenValues,
        setValue: setOverridenValue,
        update: updateOverridenValues,
      } = usePathState(dataOverride);

      const values = useDocSnapshot<TObj>({
        docPath: dataOverride ? undefined : docPath,
        initialData: (initialData || overridenValues) as TObj,
      });

      const updateDoc = useDocUpdate<TObj>(docPath);

      const setValue = useCallback(
        async <TPath extends string>(
          path: TPath,
          value: PathValue<TObj, TPath>,
        ) => {
          if (dataOverride) {
            setOverridenValue(path, value);
          }

          const { deleteField } = await import('firebase/firestore');
          await updateDoc({
            [path]: value === undefined ? deleteField() : value,
          } as UpdateData<TObj>);
        },
        [updateDoc, dataOverride, setOverridenValue],
      );

      // eslint-disable-next-line @blumintinc/blumint/no-hungarian
      const updateObj = useCallback(
        async (newValues: UpdateData<TObj>) => {
          if (dataOverride) {
            updateOverridenValues(newValues);
          }

          await updateDoc(newValues);
        },
        [updateDoc, dataOverride, updateOverridenValues],
      );

      const providerValue = useMemo(() => {
        return {
          values: values || (DEFAULT_VALUES as TObj),
          setValue,
          updateObj,
        } as const;
      }, [values, setValue, updateObj]);

      return (
        <this.context.Provider value={providerValue}>
          {children}
        </this.context.Provider>
      );
    };
    return memo(UnmemoizedProvider);
  }
}
