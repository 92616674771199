import { Fragment, useCallback } from 'react';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { Tournament } from '../../../../functions/src/types/firestore/Game/Tournament';
import { MatchDetailsProvider } from '../../../contexts/MatchDetailsContext';
import { MatchDetails } from '../../../components/tournaments/rounds/MatchDetails';
import { useDialog } from '../../useDialog';

export type OpenMatchDetailsWizardProps = {
  onClose?: () => void;
  matchId: string;
  tournament: Tournament<Date>;
  roundId: string;
};

export const MATCH_DETAILS_DIALOG_ID = 'match-details-dialog' as const;
export const MATCH_DETAILS_DIALOG_TITLE = 'Match Details' as const;

export const useMatchDetailsWizard = () => {
  const { open: openDialog } = useDialog(MATCH_DETAILS_DIALOG_ID);

  const open = useCallback(
    ({
      onClose,
      matchId,
      tournament,
      roundId,
    }: OpenMatchDetailsWizardProps) => {
      openDialog({
        onClose,
        title: MATCH_DETAILS_DIALOG_TITLE,
        description: <MatchDetails />,
        Wrapper: (
          <TournamentContext.Provider value={tournament}>
            <MatchDetailsProvider matchId={matchId} roundId={roundId}>
              <Fragment />
            </MatchDetailsProvider>
          </TournamentContext.Provider>
        ),
      });
    },
    [openDialog],
  );
  return { open, close } as const;
};
