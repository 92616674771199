import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Animation } from '../../styles/animations';
import { memo } from '../../util/memo';
import { ONE_SECOND_MILLIS } from '../../../functions/src/util/conversions';
import { useAudio } from '../../hooks/useAudio';
import { useAnimationStyle } from '../../hooks/styles/useAnimationStyle';

const DEFAULT_ANNOY_ANIMATION = 'shake' as const;

export type BaseAnnoyProps = {
  children: React.ReactNode;
  volume?: number;
  animation?: Animation;
  shakeAfter?: number;
};

// eslint-disable-next-line @blumintinc/blumint/no-unused-props
export type AnnoyWrapperProps = BaseAnnoyProps & {
  ringAlert?: { after: number; url: string };
};

export const AnnoyWrapperUnmemoized = ({
  animation = DEFAULT_ANNOY_ANIMATION,
  shakeAfter,
  ringAlert,
  volume,
  children,
}: AnnoyWrapperProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const intervalRef = useRef<number>();
  const { url: soundUrl, after: ringAfter } = ringAlert || {};
  const { playSound, pauseSound } = useAudio(soundUrl, {
    volume,
    autoDestroy: false,
  });

  useEffect(() => {
    if (!ringAfter) return;
    const timer = window.setTimeout(() => {
      playSound();
      const interval = window.setInterval(() => {
        playSound();
      }, ringAfter * ONE_SECOND_MILLIS);
      intervalRef.current = interval;
    }, ringAfter * ONE_SECOND_MILLIS);

    return () => {
      window.clearTimeout(timer);
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = undefined;
      }
      pauseSound();
    };
  }, [ringAfter, playSound, pauseSound]);

  useEffect(() => {
    if (!shakeAfter) return;
    const timer = window.setTimeout(() => {
      setIsAnimating(true);
    }, shakeAfter * ONE_SECOND_MILLIS);
    return () => {
      window.clearTimeout(timer);
    };
  }, [shakeAfter]);

  const animationStyle = useAnimationStyle({
    animation: isAnimating ? animation : undefined,
  });

  return <Box sx={animationStyle}>{children}</Box>;
};

export const AnnoyWrapper = memo(AnnoyWrapperUnmemoized);
