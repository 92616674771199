import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { memo, compareDeeply } from '../../util/memo';
import { ListChronological } from '../layout/ListChronological';
import { NotificationKind } from '../../../functions/src/types/firestore/User/NotificationSettings';
import {
  DisplayableNotificationStatus,
  useNotificationFilter,
} from '../../hooks/notification/useNotificationFilter';
import { Notification } from '../../../functions/src/types/firestore/User/Notification';
import { NotificationLayout } from './NotificationLayout';

export type ListNotificationsProps = {
  status: DisplayableNotificationStatus;
  limit?: number;
  filter?: NotificationKind;
  negativeFilter?: NotificationKind;
  initialNotifications?: Notification<Date>[];
};

const ListNotificationsUnmemoized = ({
  status,
  limit,
  filter,
  negativeFilter,
  initialNotifications,
}: ListNotificationsProps) => {
  const { filteredNotifications, containerRef } = useNotificationFilter(
    status,
    {
      // eslint-disable-next-line @blumintinc/blumint/no-compositing-layer-props
      filter,
      negativeFilter,
      limit,
      initialNotifications,
    },
  );

  const NotificationsDated = useMemo(() => {
    return filteredNotifications.map((notification) => {
      return {
        date: notification.createdAt,
        element: (
          <NotificationLayout
            key={notification.id}
            notification={notification}
          />
        ),
      };
    });
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [filteredNotifications]);

  return (
    <Stack ref={containerRef} sx={{ alignItems: 'center' }}>
      <ListChronological elementsDated={NotificationsDated} />
    </Stack>
  );
};

export const ListNotifications = memo(
  ListNotificationsUnmemoized,
  compareDeeply('filteredNotifications'),
);
