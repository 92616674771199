import { useMemo } from 'react';
import { ConverterFactory } from '../../../functions/src/util/firestore/ConverterFactory';
import { Hit } from '../../../functions/src/types/Hit';

export const useConvertedHits = (hits: Hit[]) => {
  return useMemo(() => {
    const converter = ConverterFactory.buildDateConverter<Hit>();

    return hits.map((hit) => {
      return converter.convertData(hit);
    });
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
  }, [hits]);
};
