import Chip, { ChipProps } from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { forwardRef, ForwardedRef, ReactNode, useMemo } from 'react';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { memo } from '../util/memo';
import { GradientTypography } from './gradients/GradientTypography';

export const CHIP_TAB_HEIGHT_LARGE_MOBILE = '40px' as const;
export const CHIP_TAB_HEIGHT_LARGE_DESKTOP = '44px' as const;
export const CHIP_TAB_HEIGHT_SMALL_MOBILE = '32px' as const;
export const CHIP_TAB_HEIGHT_SMALL_DESKTOP = '36px' as const;

export type ChipTabProps = Omit<ChipProps, 'variant'> & {
  isActive: boolean;
  label?: string | ReactNode;
  IconComponent?: JSX.Element;
  variant?: 'large' | 'small';
  typographySx?: SxProps<Theme>;
};

const ChipTabUnmemoized = forwardRef(
  (props: ChipTabProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      IconComponent,
      label,
      isActive,
      sx,
      variant,
      typographySx,
      ...rest
    } = props;
    const theme = useTheme();
    const isLarge = variant === 'large';

    const labelStyle: SxProps<Theme> = useMemo(() => {
      return {
        variant: isLarge ? 'subtitle1' : 'body3',
        textTransform: 'uppercase',
        fontWeight: '600',
      } as const;
    }, [isLarge]);

    // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
    const labelComponent = useMemo(() => {
      if (typeof label !== 'string') {
        return label;
      }
      if (isLarge) {
        return (
          <GradientTypography
            gradientColor="primary.vertical"
            sx={{ ...labelStyle, ...typographySx }}
            variant="subtitle1"
          >
            {label}
          </GradientTypography>
        );
      }
      return (
        <Typography
          color={'text.secondary'}
          sx={{ ...labelStyle, ...typographySx }}
          variant="body1"
        >
          {label}
        </Typography>
      );
    }, [isLarge, label, labelStyle, typographySx]);

    return (
      <Chip
        ref={ref}
        icon={IconComponent}
        label={labelComponent}
        sx={{
          minHeight: {
            xs: isLarge
              ? CHIP_TAB_HEIGHT_LARGE_MOBILE
              : CHIP_TAB_HEIGHT_SMALL_MOBILE,
            md: isLarge
              ? CHIP_TAB_HEIGHT_LARGE_DESKTOP
              : CHIP_TAB_HEIGHT_SMALL_DESKTOP,
          },
          height: '100%',
          borderRadius: '100px',
          px: 2,
          background: isActive
            ? theme.palette.background.elevation[22]
            : theme.palette.background.elevation[9],
          '&:hover': {
            backgroundColor: `${theme.palette.background.elevation[18]}`,
          },
          boxShadow:
            isActive && isLarge
              ? `0px 0px 2px 1px ${theme.palette.background.elevation[22]}`
              : 'none',
          border: `1px solid ${
            isActive && isLarge ? theme.palette.primary.dark : 'transparent'
          }`,
          'MuiButtonBase-root-MuiChip-root': {
            justifyContent: 'center',
          },
          '.MuiChip-label': {
            pl: label ? undefined : '0px',
          },
          alignItems: 'center',
          ...sx,
        }}
        {...rest}
      />
    );
  },
);

export const ChipTab = memo(ChipTabUnmemoized);
ChipTabUnmemoized.displayName = 'ChipTabUnmemoized';
