import LogoutIcon from '@mui/icons-material/LogoutRounded';
import AddLinkIcon from '@mui/icons-material/AddLinkRounded';
import PersonIcon from '@mui/icons-material/PersonRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverRounded';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTipRounded';
import { useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from '../components/Link';
import { SignInMenuItemProps } from '../components/header/SignInMenuItem';
import { CMPWindowProps } from '../types/global';
import { useLinkAdditionalAuthDialog } from './auth/useLinkAdditionalAuthDialog';
// import { useMenuItemsUnlink } from './auth/useMenuItemsUnlink';
// import { useMenuItemsWallet } from './web3/useMenuItemsWallet';
import { useDeleteAccountRouter } from './useDeleteAccountRouter';
import { useSignOut } from './auth/useSignOut';
import { useShowCMP } from './electron/useShowCMP';

export const VIEW_PROFILE_TEXT = 'View Profile' as const;
export const SIGN_IN_METHOD_TEXT = 'Sign In Method' as const;
export const SIGN_OUT_TEXT = 'Sign Out' as const;
export const DELETE_ACCOUNT_TEXT = 'Delete Account' as const;
export const PRIVACY_SETTINGS_TEXT = 'Privacy' as const;

export const useSignInItems = (onMenuClose: () => void) => {
  const { uid } = useAuth();
  const { open: openDeleteAccountDialog } = useDeleteAccountRouter();
  const { open: openLinkAdditionalAuthDialog } = useLinkAdditionalAuthDialog();
  // const { MenuItemsWallet } = useMenuItemsWallet();
  // const { MenuItemsUnlink } = useMenuItemsUnlink();
  const shouldShowPrivacySettings = useShowCMP();

  const { signOut } = useSignOut();
  const signOutClose = useCallback(async () => {
    await signOut();
    onMenuClose();
  }, [onMenuClose, signOut]);

  const openPrivacy = useCallback(
    (options?: CMPWindowProps) => {
      if (shouldShowPrivacySettings) {
        window.electron?.openPrivacySettings(options);
      }
      onMenuClose();
    },
    [onMenuClose, shouldShowPrivacySettings],
  );

  const menuItems: SignInMenuItemProps[] = useMemo(() => {
    const items: SignInMenuItemProps[] = [
      {
        label: VIEW_PROFILE_TEXT,
        onClick: onMenuClose,
        IconComponent: PersonIcon,
        // eslint-disable-next-line @blumintinc/blumint/require-usememo-object-literals
        Wrapper: <Link href={`/${uid}`} style={{ textDecoration: 'none' }} />,
      },
      {
        label: SIGN_IN_METHOD_TEXT,
        onClick: openLinkAdditionalAuthDialog,
        IconComponent: AddLinkIcon,
      },
      // ...MenuItemsWallet,
      // ...MenuItemsUnlink,
    ];

    if (shouldShowPrivacySettings) {
      items.push({
        label: PRIVACY_SETTINGS_TEXT,
        onClick: openPrivacy,
        IconComponent: PrivacyTipIcon,
      });
    }

    items.push(
      {
        label: DELETE_ACCOUNT_TEXT,
        onClick: openDeleteAccountDialog,
        IconComponent: DeleteForeverIcon,
      },
      {
        label: SIGN_OUT_TEXT,
        onClick: signOutClose,
        IconComponent: LogoutIcon,
      },
    );

    return items;
  }, [
    onMenuClose,
    uid,
    openLinkAdditionalAuthDialog,
    shouldShowPrivacySettings,
    openDeleteAccountDialog,
    signOutClose,
    openPrivacy,
  ]);

  return menuItems;
};
