import { createContext, useContext, ReactNode, useMemo } from 'react';
import { memo } from '../../util/memo';
import { useAuth } from '../../contexts/AuthContext';
import { toUserId } from '../../../functions/src/util/messaging/mapId';
import { truncateIfTooLong } from '../../util/truncate';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import {
  DynamicImport,
  StreamChatReact,
  STREAM_CHAT_REACT,
} from '../../../functions/src/types/DynamicModule';
import { withDynamicImport } from '../../util/withDynamicImport';
import { assertSafe } from 'functions/src/util/assertSafe';

export type TypingProviderProps = {
  children: ReactNode;
  modules: DynamicImport<[StreamChatReact]>;
};

export type TypingContextProps = {
  typingIndicatorText?: string;
};

export const TypingContext = createContext<TypingContextProps | null>(null);

export const useTyping = () => {
  const context = useContext(TypingContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useTyping must be used within a TypingProvider',
    );
  }
  return context;
};

const TypingProviderUnmemoized = ({
  children,
  modules,
}: TypingProviderProps) => {
  const { useTypingContext } = modules[assertSafe(STREAM_CHAT_REACT)];
  const { typing: typingState } = useTypingContext();
  const { uid } = useAuth();

  const typingUsers = useMemo(() => {
    if (!typingState) return [] as const;

    // eslint-disable-next-line no-restricted-properties
    return Object.keys(typingState)
      .filter((getStreamUserId) => {
        return uid !== toUserId(getStreamUserId);
      })
      .sort();
  }, [typingState, uid]);

  const typingIndicatorText = useMemo(() => {
    if (typingUsers.length === 1) {
      return `${truncateIfTooLong(
        `${typingState?.[assertSafe(typingUsers[0])]?.user?.name}`,
        36,
      )} is`;
    }
    if (typingUsers.length === 0) {
      return;
    }
    return `${typingUsers.length} people are`;
  }, [typingUsers, typingState]);

  const valueMemoized = useMemo(() => {
    return { typingIndicatorText } as const;
  }, [typingIndicatorText]);

  return (
    <TypingContext.Provider value={valueMemoized}>
      {children}
    </TypingContext.Provider>
  );
};

export const TypingProvider = memo(
  withDynamicImport({
    Component: TypingProviderUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
