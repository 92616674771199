import type { UserMetadata } from 'firebase/auth';
import { computeTimeBetweenDates } from '../dates/Dates';

const RESPONSE_WINDOW = 30000 as const;

export const isNewUser = (metadata: UserMetadata) => {
  const accountCreationTime = metadata.creationTime;
  const lastSignInTime = metadata.lastSignInTime;

  if (!!accountCreationTime && !!lastSignInTime) {
    const timeDifference = computeTimeBetweenDates({
      startDate: new Date(accountCreationTime),
      endDate: new Date(lastSignInTime),
    });
    const differenceInMillis =
      Date.now() - Number(new Date(accountCreationTime));

    return (
      (timeDifference.datesAreSame ||
        !timeDifference.startDateLaterThanEndDate) &&
      differenceInMillis < RESPONSE_WINDOW
    );
  }
  return true;
};
