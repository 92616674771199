import { useMemo, MutableRefObject } from 'react';
import { useRealtimeReadNotifications } from '../../contexts/RealtimeReadNotificationsContext';
import { useRealtimeUnreadNotifications } from '../../contexts/RealtimeUnreadNotificationsContext';
import { useRealtimeArchivedNotifications } from '../../contexts/RealtimeArchivedNotificationContext';
import {
  NotificationStatus,
  Notification,
} from '../../../functions/src/types/firestore/User/Notification';
import { NotificationKind } from '../../../functions/src/types/firestore/User/NotificationSettings';

export type DisplayableNotificationStatus = NotificationStatus | 'all';

export type NotificationFilterResult = {
  filteredNotifications: Array<Notification<Date>>;
  containerRef: MutableRefObject<HTMLDivElement | null> | null;
};

export const useNotificationFilter = (
  status: DisplayableNotificationStatus,
  options?: {
    filter?: NotificationKind;
    negativeFilter?: NotificationKind;
    limit?: number;
    initialNotifications?: Array<Notification<Date>>;
  },
): NotificationFilterResult => {
  const { realtimeDocuments: readNotifications, containerRef: readRef } =
    useRealtimeReadNotifications();
  const { realtimeDocuments: unreadNotifications, containerRef: unreadRef } =
    useRealtimeUnreadNotifications();
  const {
    realtimeDocuments: archivedNotifications,
    containerRef: archivedRef,
  } = useRealtimeArchivedNotifications();

  // First get the base notifications and ref based on status
  const { notifications, containerRef } = useMemo(() => {
    switch (status) {
      case 'read':
        return {
          notifications: readNotifications,
          containerRef: readRef,
        };
      case 'unread':
        return {
          notifications: unreadNotifications,
          containerRef: unreadRef,
        };
      case 'archived':
        return {
          notifications: archivedNotifications,
          containerRef: archivedRef,
        };
      case 'all':
        return {
          notifications: [...readNotifications, ...unreadNotifications],
          containerRef: unreadRef,
        };
    }
  }, [
    status,
    readNotifications,
    unreadNotifications,
    archivedNotifications,
    readRef,
    unreadRef,
    archivedRef,
  ]);

  const filteredNotifications = useMemo(() => {
    const uniqueNotifications = new Map<string, Notification<Date>>();

    if (options?.initialNotifications) {
      for (const notification of options.initialNotifications) {
        uniqueNotifications.set(notification.id, notification);
      }
    }
    for (const notification of notifications) {
      uniqueNotifications.set(notification.id, notification);
    }

    let filtered = [...uniqueNotifications.values()];

    if (options?.filter) {
      filtered = filtered.filter((notification) => {
        return notification.kind === options.filter;
      });
    }

    if (options?.negativeFilter) {
      filtered = filtered.filter((notification) => {
        return notification.kind !== options.negativeFilter;
      });
    }

    const sorted = filtered.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime();
    });

    return options?.limit ? sorted.slice(0, options.limit) : sorted;
  }, [
    notifications,
    options?.filter,
    options?.negativeFilter,
    options?.limit,
    options?.initialNotifications,
  ]);

  return {
    filteredNotifications,
    containerRef,
  };
};
