import { useCallback, useMemo } from 'react';
import { memo } from '../../util/memo';
import { AlgoliaLayout, EMPTY_FILTER } from '../algolia/AlgoliaLayout';
import { useGroupRouterExternal } from '../../hooks/group/useGroupRouterExternal';
import { Offline } from './Offline';
import { VideoPlaceholder } from './VideoPlaceholder';
import {
  LivestreamHorizontalCarousel,
  LivestreamHorizontalCarouselProps,
} from './LivestreamHorizontalCarousel';
import { fillTemplate } from 'functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from 'functions/src/util/algoliaRealtime/templates';

export const LIVESTREAM_HITS_PER_PAGE = 5 as const;

const LivestreamUnmemoized = () => {
  const { groupId } = useGroupRouterExternal();

  const filters = useMemo(() => {
    if (!groupId) {
      return EMPTY_FILTER;
    }
    return fillTemplate({
      template: REALTIME_CONFIGURATION_TEMPLATES.livestream,
      placeholderValue: groupId,
    });
  }, [groupId]);

  const configureOptions = useMemo(() => {
    return {
      filters,
      hitsPerPage: LIVESTREAM_HITS_PER_PAGE,
    } as const;
  }, [filters]);
  const noHitsPlaceholder = useMemo(() => {
    return <VideoPlaceholder placeholder={<Offline />} />;
  }, []);

  const CatalogWrapper = useCallback(
    (props: Omit<LivestreamHorizontalCarouselProps, 'nohits' | 'content'>) => {
      return (
        <LivestreamHorizontalCarousel {...props} noHits={noHitsPlaceholder} />
      );
    },
    [noHitsPlaceholder],
  );

  if (!groupId) {
    return null;
  }

  return (
    <AlgoliaLayout
      CatalogWrapper={CatalogWrapper}
      configureOptions={configureOptions}
      index="CONTENT"
    />
  );
};

export const Livestream = memo(LivestreamUnmemoized);
