import { FC } from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { normalizeTab } from '../../util/normalizeTab';
import { memo } from '../../util/memo';
import type { TabsVariantProps } from './TabsRouted';

const TabsScrollableUnmemoized: FC<TabsVariantProps> = ({
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const tabsNormalized = useDeepCompareMemo(() => {
    return tabs.map((tab) => {
      return normalizeTab(tab);
    });
  }, [tabs]);

  return (
    <Tabs
      scrollButtons={false}
      sx={sx}
      value={activeTab}
      variant="scrollable"
      onChange={routeTab}
    >
      {tabsNormalized.map(({ id, value, sx: sxTab, customization }) => {
        return (
          <Tab
            key={id}
            id={id}
            label={value}
            sx={{ ...sxTab, textTransform: 'uppercase' }}
            value={value}
            {...customization}
          />
        );
      })}
    </Tabs>
  );
};

export const TabsScrollable = memo(TabsScrollableUnmemoized);
