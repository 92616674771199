import { FC, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { LoadingIconButton } from '../buttons/LoadingIconButton';
import { memo } from '../../util/memo';

export type DeleteableOverlayProps = {
  children: ReactNode;
  onDelete: () => Promise<void>;
  sx?: SxProps;
  zIndex?: number;
};

const DeleteableOverlayUnmemoized: FC<DeleteableOverlayProps> = ({
  children,
  onDelete,
  sx,
  zIndex = 1,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        ...sx,
      }}
    >
      {children}
      <Box
        sx={{
          position: 'absolute',
          top: -8,
          right: -8,
          zIndex,
        }}
      >
        <LoadingIconButton
          color="error"
          size="small"
          sx={{
            p: '1px',
            bgcolor: 'error.main',
            '&:hover': {
              bgcolor: 'error.dark',
              '& svg': {
                color: 'error.darker',
              },
            },
            '& svg': {
              color: 'error.dark',
            },
          }}
          onClick={onDelete}
        >
          <CloseRoundedIcon fontSize="small" />
        </LoadingIconButton>
      </Box>
    </Box>
  );
};

export const DeleteableOverlay = memo(DeleteableOverlayUnmemoized);
