import { useEffect } from 'react';
import { usePictureInPicture } from '../../contexts/PictureInPictureContext';

export const usePictureInPictureListener = () => {
  const { exitPictureInPicture, isPictureInPicture } = usePictureInPicture();

  useEffect(() => {
    if (!isPictureInPicture) {
      return;
    }

    const pipElement = document.pictureInPictureElement;
    if (!pipElement) {
      return;
    }

    pipElement.addEventListener('leavepictureinpicture', exitPictureInPicture);
    return () => {
      pipElement.removeEventListener(
        'leavepictureinpicture',
        exitPictureInPicture,
      );
    };
  }, [exitPictureInPicture, isPictureInPicture]);
};
