export const TRANSFORM_ORIGIN_TOP_CENTER = {
  vertical: 'top',
  horizontal: 'center',
} as const;

export const TRANSFORM_ORIGIN_TOP_RIGHT = {
  vertical: 'top',
  horizontal: 'right',
} as const;

export const TRANSFORM_ORIGIN_TOP_LEFT = {
  vertical: 'top',
  horizontal: 'left',
} as const;

export const TRANSFORM_ORIGIN_BOTTOM_RIGHT = {
  vertical: 'bottom',
  horizontal: 'right',
} as const;
