import { Timestamp } from 'firebase-admin/firestore';
import {
  ChannelGroup,
  GroupFilterMap,
  ChannelGroupFilterType,
  PERSONAL_GROUP_CHANNEL_TYPE,
  PersonalGroupType,
} from '..';
import { isSpecificPersonal } from './isPersonal';

export function isPersonalGroup<TTime = Timestamp>(
  channelGroup: ChannelGroup<keyof GroupFilterMap, TTime>,
): channelGroup is ChannelGroup<'PersonalGroup', TTime> {
  return isSpecificPersonal(channelGroup, 'personalGroup');
}

export const isPersonalGroupType = (
  type: ChannelGroupFilterType,
): type is PersonalGroupType => {
  return PERSONAL_GROUP_CHANNEL_TYPE === type;
};
