import { useCallback } from 'react';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useVoiceChannelGroupRealtime } from '../voice-chat/useVoiceChannelGroupRealtime';
import { useFilterTemporaryChannelGroups } from './useFilterTemporaryChannelGroups';

export const useChannelGroupFilter = () => {
  const { filterTemporaryChannelGroups } = useFilterTemporaryChannelGroups();
  const { voiceChannelGroup } = useVoiceChannelGroupRealtime();

  const filterVoice = useCallback(
    (channelGroups: ChannelGroup<keyof GroupFilterMap, Date>[]) => {
      return channelGroups.filter(({ id }) => {
        return id !== voiceChannelGroup?.id;
      });
    },
    [voiceChannelGroup?.id],
  );

  const filter = useCallback(
    (documents: ChannelGroup<keyof GroupFilterMap, Date>[]) => {
      const filteredTemporary = filterTemporaryChannelGroups(documents);
      return filterVoice(filteredTemporary);
    },
    [filterTemporaryChannelGroups, filterVoice],
  );

  return filter;
};
