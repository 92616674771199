import type {
  FirestoreDataConverter,
  WithFieldValue,
} from 'firebase-admin/firestore';
import type { DocumentDataEverywhere } from '../../types/DocumentDataEverywhere';
import type { QueryDocumentSnapshotEverywhere } from '../../types/QuerySnapshotEverywhere';
import { assertSafe } from '../assertSafe';
import { isTimestamp } from './timestamp';

export function isIrreducible(data: unknown) {
  return (
    data === null ||
    data === undefined ||
    (typeof data !== 'object' && !Array.isArray(data))
  );
}

export function isReference(data: Record<string, unknown> | unknown[]) {
  return (
    typeof data === 'object' &&
    'type' in data &&
    (data.type === 'document' ||
      data.type === 'collection' ||
      ('path' in data && 'parent' in data))
  );
}

export type ConverterProps<TConvertableType, TConvertedType> = {
  shouldConvertValue: (value: unknown) => boolean;
  convertValue: (value: TConvertableType) => TConvertedType;
  shouldPreserveTimestamp?: boolean;
};

export class Converter<
  T extends DocumentDataEverywhere,
  TConvertableType,
  TConvertedType,
> implements FirestoreDataConverter<T>
{
  public constructor(
    private readonly props: ConverterProps<TConvertableType, TConvertedType>,
  ) {}

  // eslint-disable-next-line class-methods-use-this
  public toFirestore<TDocument extends WithFieldValue<T>>(model: TDocument) {
    return model;
  }

  public fromFirestore<TSnapshot extends QueryDocumentSnapshotEverywhere>(
    snapshot: TSnapshot,
  ) {
    const data = snapshot.data();
    return this.convertData(data);
  }

  public convertData(data: unknown) {
    const result: T = this.reduceData(data);
    return result;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @blumintinc/blumint/no-explicit-return-type, @blumintinc/blumint/no-type-assertion-returns
  private reduceData(data: unknown): any {
    if (this.shouldConvertValue(data)) {
      return this.convertValue(data as TConvertableType);
    }

    if (
      isIrreducible(data) ||
      isReference(data as Record<string, unknown> | unknown[])
    ) {
      return data;
    }

    if (Array.isArray(data)) {
      return data.map((valueEntity: unknown | unknown[]) => {
        return this.reduceData(valueEntity);
      });
    }

    if (this.shouldPreserveTimestamp && isTimestamp(data)) {
      return data;
    }
    const dataObjectified = data as Record<string, unknown>;
    // eslint-disable-next-line no-restricted-properties
    return Object.keys(dataObjectified).reduce((accumulator, key) => {
      const value = dataObjectified[assertSafe(key)];
      accumulator[assertSafe(key)] = this.reduceData(value);

      return accumulator;
    }, {} as Record<string, unknown>);
  }

  private get shouldConvertValue() {
    return this.props.shouldConvertValue;
  }

  private get convertValue() {
    return this.props.convertValue;
  }

  private get shouldPreserveTimestamp() {
    return this.props.shouldPreserveTimestamp ?? true;
  }
}
