import Stack from '@mui/material/Stack';
import { ReactNode, useState, useCallback } from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { memo } from '../../util/memo';
import { LottieLoader } from '../LottieLoader';

export type AttendableProps = Omit<BadgeProps, 'onClick'> & {
  children: ReactNode;
  isLoading?: boolean;
  isRemovable?: boolean;
  onClick?: () => Promise<void>;
};

const AttendableUnmemoized = (props: AttendableProps) => {
  const {
    children,
    onClick,
    badgeContent,
    color = 'primary',
    sx = {},
    ...restProps
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const performBadgeAction = useCallback(async () => {
    if (!onClick) {
      return;
    }
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  }, [onClick]);

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{ position: 'relative' }}
    >
      {children}
      {isLoading ? (
        <LottieLoader
          sx={{
            height: '17px',
            width: '17px',
            position: 'absolute',
            bottom: '2px',
            right: 0,
          }}
        />
      ) : (
        <Badge
          badgeContent={badgeContent}
          color={color}
          overlap="circular"
          sx={{
            '.MuiBadge-badge': {
              height: '20px',
              minWidth: '20px',
              width: '100%',
              right: '8px',
            },
            ...sx,
          }}
          onClick={performBadgeAction}
          {...restProps}
        />
      )}
    </Stack>
  );
};
export const Attendable = memo(AttendableUnmemoized);
