/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef } from 'react';
import { useWagmiFunc, WagmiCoreModule } from './useWagmiFunc';
import { AsyncWrapper } from '../../../functions/src/types/utility-types';
import { WatchSubscriber, useWeb3 } from '../../contexts/web3/Web3Context';
import stringify from 'safe-stable-stringify';

export type WatcherConfig<TConfig = any, TResult = any> = (
  config: TConfig,
  callback: (result: TResult) => any,
) => () => void;

export type Watcher<TResult = any> = (
  callback: (result: TResult) => any,
) => () => void;

export type WatcherKeys<T, TResult = any, TConfig = any> = {
  [K in keyof T]: T[K] extends
    | WatcherConfig<TConfig, TResult>
    | Watcher<TResult>
    ? K
    : never;
}[keyof T];

export type WagmiWatcherKey = WatcherKeys<WagmiCoreModule> & `watch${string}`;

export type ConfigOf<TWatcherName extends WagmiWatcherKey> =
  TWatcherName extends keyof WagmiCoreModule
    ? WagmiCoreModule[TWatcherName] extends WatcherConfig<infer TConfig, any>
      ? NonNullable<TConfig>
      : undefined
    : undefined;

export type ResultOf<TWatcherName extends WagmiWatcherKey> =
  TWatcherName extends keyof WagmiCoreModule
    ? WagmiCoreModule[TWatcherName] extends
        | WatcherConfig<any, infer TResult>
        | Watcher<infer TResult>
      ? TResult
      : never
    : never;

//export type TFetchName<TWatcherName extends WagmiWatcherKey> = {};

export const useWagmiWatch = <TWatcherName extends WagmiWatcherKey>(
  watcherName: TWatcherName,
  config: ConfigOf<TWatcherName>,
) => {
  const subscriberKey = useMemo(() => {
    return `${watcherName}-${stringify(config)}`;
  }, [watcherName, config]);
  const watcherAwaited = useWagmiFunc(watcherName);

  const { watchSubscribers, watchResults, setResult } = useWeb3();

  const subscriberRef = useRef<WatchSubscriber>({
    subscribersCount: 0,
  });

  useEffect(() => {
    const initSubscriber = () => {
      if (!watchSubscribers[String(subscriberKey)]) {
        watchSubscribers[String(subscriberKey)] = {
          subscribersCount: 0,
        };
      }
      return watchSubscribers[String(subscriberKey)];
    };

    subscriberRef.current = initSubscriber();

    const currentSubscriber = watchSubscribers[String(subscriberKey)];
    if (currentSubscriber !== subscriberRef.current) {
      subscriberRef.current.subscribersCount -= 1;
      currentSubscriber.subscribersCount += 1;
      subscriberRef.current = currentSubscriber;
    }
  }, [subscriberKey, watchSubscribers]);

  useEffect(() => {
    const subscriber = subscriberRef.current;
    const onResult = (result: ResultOf<TWatcherName>) => {
      setResult(subscriberKey, result);
    };

    const subscribe = () => {
      if (config !== undefined) {
        return (
          watcherAwaited as AsyncWrapper<
            WatcherConfig<ConfigOf<TWatcherName>, ResultOf<TWatcherName>>
          >
        )(config, onResult);
      }
      return (
        watcherAwaited as unknown as AsyncWrapper<
          Watcher<ResultOf<TWatcherName>>
        >
      )(onResult);
    };

    if (!subscriber.unsubscribePromise) {
      subscriber.unsubscribePromise = subscribe();
    }

    return () => {
      subscriber.subscribersCount -= 1;
      if (subscriber.subscribersCount === 0) {
        if (subscriber.unsubscribePromise) {
          subscriber.unsubscribePromise.then((unsubscribe) => {
            unsubscribe();
          });
        }
        delete watchSubscribers[String(subscriberKey)];
      }
    };
    // Using subscriberKey instead of config avoids unncessary re-rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcherAwaited, subscriberKey]);

  return useMemo(() => {
    return watchResults[String(subscriberKey)];
  }, [watchResults, subscriberKey]);
};
