import { ReactNode, useMemo, useState, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useMobile } from '../../hooks/useMobile';
import { memo } from 'src/util/memo';

export const END_ICON_WIDTH = '22px' as const;
export const END_ICON_HEIGHT = '22px' as const;

export type ShareButtonInfoProps = {
  children: ReactNode;
  tooltipTitle?: string;
};

export const ShareButtonInfo = memo(function ShareButtonInfoUnmemoized({
  children,
  tooltipTitle,
}: ShareButtonInfoProps) {
  const isMobile = useMobile();
  const [isClicked, setIsClicked] = useState(false);

  const markClicked = useCallback(() => {
    setIsClicked(true);
  }, []);

  const button = useMemo(() => {
    return (
      <Button size="large" variant="text" onClick={markClicked}>
        {children}
      </Button>
    );
  }, [children, markClicked]);

  if (isMobile || !tooltipTitle || isClicked) {
    return button;
  }

  return (
    <Tooltip arrow title={tooltipTitle}>
      <Box width="100%">{button}</Box>
    </Tooltip>
  );
});
