import { ReactElement, ReactNode, useCallback, useState } from 'react';
import { useRedirectCallback } from '../useRedirectCallback';
import {
  Wizard,
  WizardElements,
  WizardProps,
  WizardStore,
} from 'src/components/wizard/Wizard';
import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';

export const WIZARD_OPTIONS_DEFAULT = {
  shouldCloseOnRouteChange: true,
} as const;

// eslint-disable-next-line @blumintinc/blumint/enforce-props-argument-name
export type WizardOptions = {
  shouldCloseOnRouteChange: boolean;
};

export type WrapperComponentProps = {
  Wrapper?: ReactElement<{ children?: ReactNode }>;
  // We use 'open' to conform to MUI API
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  open: boolean;
};

export type CreateUseWizardProps = {
  WrapperComponent: React.ComponentType<WrapperComponentProps>;
  wrapperId: string;
  options?: WizardOptions;
};

export function createUseWizard<TWrapper extends WrapperComponentProps>({
  WrapperComponent,
  wrapperId,
  options = WIZARD_OPTIONS_DEFAULT,
}: CreateUseWizardProps) {
  return ({
    Wrapper: WrapperDefault,
  }: {
    Wrapper?: ReactElement<{ children?: ReactNode }>;
  } = {}) => {
    const { union, remove } = useGlobalComponentsContext();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const close = useCallback(() => {
      setIsOpen(false);
      remove(wrapperId);
    }, [remove]);

    useRedirectCallback(options.shouldCloseOnRouteChange ? close : undefined);

    const open = useCallback(
      <TStore extends WizardStore, TElements extends WizardElements>({
        onClose,
        Wrapper: InnerWrapper = WrapperDefault,
        wrapperProps,
        ...props
      }: Omit<WizardProps<TStore, TElements>, 'Wrapper'> &
        Partial<Pick<TWrapper, 'Wrapper'>> & {
          wrapperProps?: Partial<TWrapper>;
        }) => {
        union(
          wrapperId,
          <Wizard
            Wrapper={
              <WrapperComponent
                open
                Wrapper={InnerWrapper}
                {...(wrapperProps || {})}
              />
            }
            // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
            onClose={() => {
              onClose?.();
              close();
            }}
            {...props}
          />,
        );
        setIsOpen(true);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [union],
    );
    return {
      open,
      close,
      isOpen,
    } as const;
  };
}
