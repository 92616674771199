import { useCallback } from 'react';
import MessageIcon from '@mui/icons-material/MessageRounded';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { LoadingIconButton } from '../../buttons/LoadingIconButton';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { usePersonalChannelGroup } from '../../../hooks/messaging/usePersonalChannelGroup';
import { GradientIcon } from '../../gradients/GradientIcon';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { FriendCardLayout } from './FriendCardLayout';

export type FriendCardProps = FriendHit;

const FriendCardUnmemoized = (hit: FriendCardProps) => {
  const { uid } = useAuth();

  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();

  const { sender, receiver } = hit;
  const friendToRender = sender.id === uid ? receiver : sender;
  const { id } = friendToRender;

  const openDm = useCallback(async () => {
    const { id: channelGroupId } = await setPersonalChannelGroup({
      type: 'dm',
      friendIds: [id],
    });

    openChannelGroup({ channelGroupId });
  }, [openChannelGroup, setPersonalChannelGroup, id]);

  return (
    <FriendCardLayout {...friendToRender}>
      <LoadingIconButton onClick={openDm}>
        <GradientIcon IconComponent={MessageIcon} />
      </LoadingIconButton>
    </FriendCardLayout>
  );
};

export const FriendCard = memo(FriendCardUnmemoized);
