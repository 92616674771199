// NOTE: DO NOT IMPORT TIMESTAMP SO THAT THIS CAN BE USED ON THE FRONTEND
import { RequireAtLeastOne } from '../../types/utility-types';

export type SafeTimestamp = { toDate?: () => Date } & RequireAtLeastOne<{
  seconds: number;
  _seconds: number;
}> &
  RequireAtLeastOne<{ nanoseconds: number; _nanoseconds: number }>;

const MILLISECONDS_IN_SECOND = 1e3 as const;
const NANOSECONDS_IN_MILLISECOND = 1e6 as const;

export const isTimestamp = (value: unknown) => {
  return !!(
    value &&
    typeof value === 'object' &&
    ('toDate' in value || 'seconds' in value || '_seconds' in value)
  );
};

export const toMillis = (timestamp: SafeTimestamp) => {
  const seconds = timestamp.seconds || timestamp._seconds || 0;
  const nanoseconds = timestamp.nanoseconds || timestamp._nanoseconds || 0;
  return (
    seconds * MILLISECONDS_IN_SECOND + nanoseconds / NANOSECONDS_IN_MILLISECOND
  );
};

export const fromTimestamp = <T>(
  timestamp: SafeTimestamp,
  dateMethod: (date: Date) => T,
) => {
  return dateMethod(
    timestamp.toDate ? timestamp.toDate() : new Date(toMillis(timestamp)),
  );
};
