import { memo } from '../../util/memo';
import { LivestreamFieldEditable } from './LivestreamFieldEditable';

export type LivestreamTitleEditableProps = {
  streamerId: string;
};

const LivestreamTitleEditableUnmemoized = ({
  streamerId,
}: LivestreamTitleEditableProps) => {
  return (
    <LivestreamFieldEditable
      fieldName="title"
      streamerId={streamerId}
      sx={{
        lineHeight: '28px',
      }}
      variant="subtitle1"
    />
  );
};

export const LivestreamTitleEditable = memo(LivestreamTitleEditableUnmemoized);
