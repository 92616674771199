import { Fragment } from 'react';
import {
  DynamicImport,
  LivekitComponentsReact,
  LIVEKIT_COMPONENTS_REACT,
} from '../../../functions/src/types/DynamicModule';
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { useEstablishLivekitAudio } from '../../hooks/voice-chat/useEstablishLivekitAudio';
import { useJoinCall } from '../../hooks/voice-chat/useJoinCall';
import { useLeaveCall } from '../../hooks/voice-chat/useLeaveCall';
import { withDynamicImport } from '../../util/withDynamicImport';
import { memo } from 'src/util/memo';

export type LivekitDynamicProps = {
  modules: DynamicImport<[LivekitComponentsReact]>;
};

const LivekitDynamicUnmemoized = ({ modules }: LivekitDynamicProps) => {
  const livekitModule = modules[assertSafe(LIVEKIT_COMPONENTS_REACT)];

  useJoinCall({ livekitModule });
  useLeaveCall({ livekitModule });
  useEstablishLivekitAudio({ livekitModule });

  return <Fragment />;
};

export const LivekitDynamic = memo(
  withDynamicImport({
    Component: LivekitDynamicUnmemoized,
    moduleNames: [LIVEKIT_COMPONENTS_REACT],
  }),
);
