import { convertTime } from '../numberConversions';

type TimeUnit = {
  hours: number;
  minutes: number;
};

const deriveTimeUnit = ({ hours, minutes }: TimeUnit) => {
  if (hours > 0) return 'h';
  if (minutes > 0) return 'm';
  return 's';
};

export const formatDuration = (seconds: number, displaySeconds = true) => {
  const hours = Math.floor(
    seconds / convertTime({ from: 'SECONDS', to: 'HOURS' }),
  );
  const minutes = Math.floor(
    (seconds % convertTime({ from: 'HOURS', to: 'SECONDS' })) /
      convertTime({ from: 'MINUTES', to: 'SECONDS' }),
  );
  const remainingSeconds = seconds % 60;
  const unit = deriveTimeUnit({ hours, minutes });
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}${
    // eslint-disable-next-line no-restricted-syntax
    displaySeconds ? `:${formattedSeconds}` : ''
  } ${unit}`;
};
