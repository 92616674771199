import { useCallback, useState, useRef, useEffect } from 'react';

export type UseInfiniteScrollProps = {
  onLoadMore: () => Promise<void>;
  hasMore: boolean;
  scrollThreshold?: number;
};

export function useInfiniteScroll({
  onLoadMore,
  hasMore,
  scrollThreshold = 0.75,
}: UseInfiniteScrollProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const loadMore = useCallback(async () => {
    if (hasMore && !isLoading) {
      setIsLoading(true);
      await onLoadMore();
      setIsLoading(false);
    }
  }, [hasMore, isLoading, onLoadMore]);

  useEffect(() => {
    const scrollElements = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const scrolledPercentage = (scrollTop + clientHeight) / scrollHeight;

        if (scrolledPercentage > scrollThreshold) {
          loadMore();
        }
      }
    };

    const currentRef = containerRef.current;

    if (currentRef) {
      currentRef.addEventListener('scroll', scrollElements);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', scrollElements);
      }
    };
  }, [loadMore, scrollThreshold]);

  return containerRef;
}
