import { useMemo, useCallback } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toEventUrl } from '../../util/event/toEventUrl';
import { toKebabCase } from '../../../functions/src/util/Strings';
import { useRouterState } from '../routing/useRouterState';
import { RoleMap } from '../../../functions/src/types/Roles';
import { TournamentRole } from '../../../functions/src/types/firestore/Game/Tournament';
import { useActiveTournamentChannel } from '../messaging/useActiveTournamentChannel';
import { useFindChannelId } from '../messaging/useFindChannelId';
import { useIsViewingLivestreamEvent } from './useIsViewingLivestreamEvent';

export const useLivestreamRouting = () => {
  const { channelGroupActive, channelId: channelIdRouter } =
    useActiveChannelGroup();
  const isViewingEvent = useIsViewingLivestreamEvent();

  const eventHref = useMemo(() => {
    if (isViewingEvent || !channelGroupActive) {
      return;
    }

    const { subtitle, roles, groupFilter } = channelGroupActive;
    const generalChannelFilter = groupFilter[0];

    if (generalChannelFilter.type !== 'general' || !roles) {
      return;
    }

    const { eventUrlPath } = toEventUrl({
      id: generalChannelFilter.tournamentId,
      gameId: toKebabCase(subtitle),
      roles: roles as RoleMap<TournamentRole>,
    });

    return eventUrlPath;
  }, [channelGroupActive, isViewingEvent]);

  const [event] = useRouterState({ key: 'event' });

  const tournamentId = useMemo(() => {
    return event?.split('Tournament/')[1];
  }, [event]);

  const { openGeneralChannel } = useActiveTournamentChannel(tournamentId);
  const { findGeneralChannelId } = useFindChannelId();

  // NOTE: @builtbyjavi - this fails to open channel when Tournament segment is not present in the router
  const openLivestreamChannel = useCallback(() => {
    const generalChannelId = findGeneralChannelId(tournamentId || '');
    if (channelIdRouter === generalChannelId) {
      return;
    }

    openGeneralChannel();
  }, [channelIdRouter, findGeneralChannelId, openGeneralChannel, tournamentId]);

  return { eventHref, openLivestreamChannel };
};
