import { useCallback } from 'react';
import { useGroupRouterExternal } from '../group/useGroupRouterExternal';

export const TOURNAMENT_URL_MATCH = /Tournament(?:%2F|\/)([\dA-Za-z-]+)/;
export const PLAYBACK_URL_MATCH = /playback-id=([\dA-Za-z-]+)/;

export const useExtractUrl = () => {
  const { groupId } = useGroupRouterExternal();

  const extractTournamentId = useCallback((url: string) => {
    const tournamentIdMatch = url.match(TOURNAMENT_URL_MATCH);
    return tournamentIdMatch
      ? { tournamentId: tournamentIdMatch[1] }
      : undefined;
  }, []);

  const extractGroupId = useCallback(() => {
    return groupId ? { groupId } : undefined;
  }, [groupId]);

  const extractPlaybackId = useCallback((url: string) => {
    const playbackIdMatch = url.match(PLAYBACK_URL_MATCH);
    return playbackIdMatch ? { playbackId: playbackIdMatch[1] } : undefined;
  }, []);

  const extractUrlTemporaryIdentifiers = useCallback(
    (url: string) => {
      return {
        ...extractGroupId(),
        ...extractTournamentId(url),
        ...extractPlaybackId(url),
      };
    },
    [extractGroupId, extractPlaybackId, extractTournamentId],
  );

  return { extractUrlTemporaryIdentifiers } as const;
};
