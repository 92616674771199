/* eslint-disable @blumintinc/blumint/require-image-optimized */
/* eslint-disable jsx-a11y/alt-text */
import Image, { ImageProps } from 'next/image';
import {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  CSSProperties,
  useCallback,
} from 'react';
import { Breakpoint } from '@mui/material/styles';
import { memo } from '../../util/memo';

const OBSERVER_OPTIONS: MutationObserverInit = {
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  attributes: true,
  attributeFilter: ['class'],
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  childList: false,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  subtree: false,
};

const NEAR_GLIDER_REGEX = /(?:^|\s)(left-[1-6]|right-[1-6])(?:\s|$)/;

export type ResponsiveSize = {
  [key in Breakpoint]?: number;
};

const computeLargestSize = (sizes: ResponsiveSize) => {
  // eslint-disable-next-line no-restricted-properties
  return Math.max(...Object.values(sizes));
};

export type ImageOptimizedProps = Omit<
  ImageProps,
  'lazyBoundary' | 'lazyRoot' | 'width' | 'height'
> & {
  width?: ImageProps['width'] | ResponsiveSize;
  height?: ImageProps['height'] | ResponsiveSize;
  objectFit?: CSSProperties['objectFit'];
};

const ImageOptimizedUnmemoized: FC<ImageOptimizedProps> = ({
  src,
  loading: loadingDefault,
  width,
  height,
  style,
  objectFit = 'contain',
  priority,
  ...props
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<ImageProps['loading']>(loadingDefault);
  const [isLoaded, setIsLoaded] = useState(loading === 'eager' || priority);

  const findTargetNode = () => {
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    let parent = imageRef.current && imageRef.current.parentElement;
    while (parent) {
      const isSlide = parent.className.includes('glider-slide');
      if (isSlide) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  };

  useEffect(() => {
    if (loadingDefault === 'eager') {
      return;
    }

    const targetNode = findTargetNode();
    if (!targetNode) {
      return;
    }

    const observeMutations = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type !== 'attributes') {
          continue;
        }

        const target = mutation.target as HTMLElement;
        if (NEAR_GLIDER_REGEX.test(target.className)) {
          setLoading('eager');
          observer.disconnect();
          break;
        }
      }
    };

    const observer = new MutationObserver(observeMutations);
    observer.observe(targetNode, OBSERVER_OPTIONS);

    return () => {
      observer.disconnect();
    };
  }, [loadingDefault]);

  const finalWidth =
    typeof width === 'object' ? computeLargestSize(width) : width;
  const finalHeight =
    typeof height === 'object' ? computeLargestSize(height) : height;

  const imageStyle = useMemo(() => {
    const shouldBeVisible = priority || loading === 'eager' || isLoaded;

    const baseStyle = {
      borderRadius: style?.borderRadius || 'inherit',
      opacity: shouldBeVisible ? 1 : 0,
      transition:
        priority || loading === 'eager'
          ? undefined
          : `opacity 100ms ease-in-out`,
      ...style,
    } as const;

    return baseStyle;
  }, [style, isLoaded, priority, loading]);

  const setLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div ref={imageRef} id="inline-span" style={{ aspectRatio: 'auto' }}>
      <Image
        {...props}
        height={finalHeight}
        loading={loading}
        objectFit={objectFit}
        priority={priority}
        quality={60}
        src={src}
        style={imageStyle}
        width={finalWidth}
        onLoadingComplete={setLoaded}
      />
    </div>
  );
};

export const ImageOptimized = memo(ImageOptimizedUnmemoized);

// const firebaseEndpoint = 'https://firebasestorage.googleapis.com/';

// const imageLoader = ({ src, width, quality }: ImageProps) => {
//   let imagekitEndpoint = 'https://ik.imagekit.io/blumint';

//   src = src.slice(firebaseEndpoint.length);

//   if (src[0] === '/') src = src.slice(1);
//   const params = [`w-${width}`];
//   if (quality) {
//     params.push(`q-${quality}`);
//   }
//   const paramsString = params.join(',');
//   if (imagekitEndpoint[imagekitEndpoint.length - 1] === '/')
//     imagekitEndpoint = imagekitEndpoint.substring(
//       0,
//       imagekitEndpoint.length - 1,
//     );
//   return `${imagekitEndpoint}/tr:${paramsString}/${src}`;
// };

// export const ImageOptimized: React.FC<ImageProps> = (props) => {
//   /**
//    * if (props.src?.includes(firebaseEndpoint)) {
//     return <Image loader={imageLoader} {...props} />;
//   }
//    */
//   // eslint-disable-next-line jsx-a11y/alt-text
//   return <Image {...props} />;
// };

// export { imageLoader };
