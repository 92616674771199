import type {
  QuerySnapshot,
  DocumentData,
  QueryConstraint,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { useDeepCompareCallback } from '@blumintinc/use-deep-compare';
import { FirestoreHandler, useFirestore } from './useFirestore';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DEFAULT_INITIAL_DATA: any[] = [];

export type CollectionSnapshotProps<TData> = {
  initialData?: TData[];
  queryConstraints?: QueryConstraint[];
};

/**
 * Custom hook to retrieve a collection snapshot from Firestore
 *
 * @param collectionPath Path to the collection in Firestore
 * @param options Options for the collection query including:
 *               - initialData: Initial data loaded from Firestore SSR
 *               - queryConstraints: Firebase query constraints (orderBy, limit, startAfter, etc.)
 * @returns realtime array data of the associated collection
 */
export function useCollectionSnapshot<TData extends DocumentData>(
  collectionPath?: string,
  {
    initialData = DEFAULT_INITIAL_DATA,
    queryConstraints = DEFAULT_INITIAL_DATA,
  }: CollectionSnapshotProps<TData> = {},
) {
  const firestoreHandler = useDeepCompareCallback<FirestoreHandler<TData[]>>(
    (firestoreModule, firebaseFirestoreModule, setData) => {
      if (!collectionPath) {
        return;
      }
      const { firestore } = firestoreModule;
      const { collection, query, onSnapshot } = firebaseFirestoreModule;

      const collectionRef = collection(firestore, collectionPath);
      const collectionQuery = query(
        collectionRef.withConverter<TData>(
          ConverterFactory.buildDateConverter(),
        ),
        ...queryConstraints,
      );

      return onSnapshot(
        collectionQuery,
        (snapshot: QuerySnapshot<TData>) => {
          const docsData = snapshot.docs.map((docSnap) => {
            return docSnap.data();
          });
          setData(docsData);
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [collectionPath, queryConstraints],
  );

  const data = useFirestore<TData[]>(firestoreHandler, initialData);
  return useMemo(() => {
    return data || [];
  }, [data]);
}
