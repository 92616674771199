/* eslint-disable @blumintinc/blumint/require-dynamic-firebase-imports */
/* eslint-disable max-depth */
import { getMessaging, Messaging } from 'firebase/messaging';
import {
  initializeApp,
  getApps,
  getApp as initApp,
  FirebaseApp,
} from 'firebase/app';
import { getAnalytics, setUserProperties } from 'firebase/analytics';
import { detectPlatform } from '../../util/platform';

const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
} as const;

export const FCM_TOKEN_LOCAL_STORAGE_KEY = 'fcmToken' as const;

const isSupported = () => {
  return (
    typeof window !== 'undefined' &&
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  );
};

let initialized = false;
let messagingInstance: Messaging | null = null;

function initializeMessaging(app: FirebaseApp): Messaging | null {
  try {
    if (!isSupported()) {
      console.warn('Push notifications are not supported in this environment');
      return null;
    }
    return getMessaging(app);
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        'messaging/unsupported-browser',
      )
    ) {
      console.warn('Push notifications are not supported in this browser');
      return null;
    }
    console.error('An error occurred while initializing messaging:', error);
    return null;
  }
}

const platform = detectPlatform();

export const getApp = () => {
  const app = !getApps().length ? initializeApp(FIREBASE_CONFIG) : initApp();

  if (!initialized && typeof window !== 'undefined') {
    initialized = true;

    messagingInstance = initializeMessaging(app);
    const analytics = getAnalytics(app);

    setUserProperties(analytics, {
      platform,
    });
  }
  return app;
};

export const getMessagingInstance = () => {
  return messagingInstance;
};
