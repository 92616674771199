import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';
import { Team } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import { ChipUser } from 'src/components/ChipUser';

export type TeamDisplayProps = Pick<
  Team<Date>,
  'name' | 'avatarUrl' | 'members'
>;

const TeamDisplayUnmemoized = ({
  name,
  avatarUrl,
  members,
}: TeamDisplayProps) => {
  const theme = useTheme();

  return (
    <Stack justifyContent={'center'}>
      <Stack
        alignItems={'center'}
        direction={'row'}
        spacing={2}
        sx={{
          px: 3,
          py: 2,
          backgroundColor: theme.palette.background.elevation[17],
          borderRadius: '10px 10px 0 0',
        }}
      >
        <AvatarNext src={avatarUrl} />
        <Typography color="text.primary" variant={'subtitle1'}>
          {name}
        </Typography>
      </Stack>

      <Box
        sx={{
          borderRadius: '0px 0px 10px 10px',
        }}
      >
        <Stack alignItems={'center'} spacing={2} sx={{ p: 2 }}>
          {members.map(({ username, imgUrl, userId }) => {
            return (
              <ChipUser
                key={username}
                avatarUrl={imgUrl}
                href={`/${userId}`}
                sx={{
                  height: '35px',
                  width: '200px',
                  border: '0px',
                  px: 1,
                  justifyContent: 'flex-start',
                  '&:hover': {
                    background: theme.palette.background.elevation[24],
                  },
                  '.MuiChip-label': {
                    pl: '30px',
                    color: 'text.primary',
                  },
                }}
                username={username}
              />
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
export const TeamDisplay = memo(TeamDisplayUnmemoized);
