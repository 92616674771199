import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { memo } from '../../util/memo';
import { toGetStreamId } from '../../../functions/src/util/messaging/mapId';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { useAuth } from 'src/contexts/AuthContext';

export type StreamContextProps = {
  isLoading: boolean;
};

export type ConnectionStatus = 'Anonymous' | 'None' | 'Authenticated';

export type StreamProviderProps = {
  children?: ReactNode;
};

export const StreamContext = createContext<StreamContextProps | null>(null);

export const useStream = () => {
  const context = useContext(StreamContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useStream must be used within a StreamProvider',
    );
  }
  return context;
};

export function StreamProviderUnmemoized({ children }: StreamProviderProps) {
  const { userDataFull } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [lastConnectionStatus, setLastConnectionStatus] =
    useState<ConnectionStatus>('None');

  const chatToken = userDataFull?.hidden?.chatToken;

  const connect = useCallback(
    async (status: ConnectionStatus) => {
      if (!userDataFull?.id) {
        return;
      }
      const userIdGetStream = toGetStreamId(userDataFull.id);

      const streamChatFrontendModule = await import(
        '../../config/get-stream/streamChatFrontend'
      );
      const { streamChatFrontend } = streamChatFrontendModule;

      if (status === 'Anonymous') {
        await streamChatFrontend.setGuestUser({ id: userIdGetStream });
      } else {
        const userGetStream = {
          id: userIdGetStream,
          name: userDataFull.username,
          image: userDataFull.imgUrl,
        };
        await streamChatFrontend.connectUser(userGetStream, chatToken);
      }
      setLastConnectionStatus(status);
    },
    [chatToken, userDataFull?.id, userDataFull?.imgUrl, userDataFull?.username],
  );

  const disconnect = useCallback(async () => {
    const streamChatFrontendModule = await import(
      '../../config/get-stream/streamChatFrontend'
    );
    const { streamChatFrontend } = streamChatFrontendModule;

    await streamChatFrontend.disconnectUser();
  }, []);

  useEffect(() => {
    const connectUser = async () => {
      if (!userDataFull?.id) {
        return;
      }

      const currentConnectionStatus: ConnectionStatus =
        !chatToken || userDataFull.isAnonymous ? 'Anonymous' : 'Authenticated';

      if (currentConnectionStatus !== lastConnectionStatus) {
        if (lastConnectionStatus !== 'None') {
          await disconnect();
        }
        await connect(currentConnectionStatus);
      }
      setIsLoading(false);
    };

    connectUser();
  }, [
    chatToken,
    userDataFull?.id,
    userDataFull?.isAnonymous,
    lastConnectionStatus,
    connect,
    disconnect,
  ]);

  const valueMemoed = useMemo(() => {
    return { isLoading } as const;
  }, [isLoading]);

  return (
    <StreamContext.Provider value={valueMemoed}>
      {children}
    </StreamContext.Provider>
  );
}

export const StreamProvider = memo(StreamProviderUnmemoized);
