import { useState, useCallback, useMemo } from 'react';
import { LocationType } from '../../contexts/routing/UrlModificationsContext';
import { KebabLowerCase } from '../../../functions/src/types/utility-types';
import { useRouter } from './useRouter';
import { useRouterValueOptimistic } from './useRouterValueOptimistic';

export type RouterState<T extends string | undefined = string | undefined> = [
  T,
  ReturnType<typeof useState<string | undefined>>[1],
];
export type RouterStateGetter = [RouterState[0]] | RouterState;

export const OOB_CODE_KEY = 'oobCode' as const;
export const URL_KEY_EXCEPTIONS = [OOB_CODE_KEY] as const;

export type UrlKeyException = 'oobCode';

export type UrlKey = KebabLowerCase | UrlKeyException;

export type UseRouterStateProps = {
  key: UrlKey;
  location?: LocationType;
  defaultValue?: string;
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  silent?: boolean;
  isCatchAll?: boolean;
};

export function useRouterState<TProps extends UseRouterStateProps>({
  key,
  defaultValue,
  isCatchAll,
  location = 'queryParam',
  silent = false,
}: // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type, @blumintinc/blumint/no-type-assertion-returns
TProps): RouterState<
  TProps['defaultValue'] extends string ? string : string | undefined
> {
  const { replaceParam, replaceSegment, getParam, getSegment } = useRouter();

  const valueOptimistic = useRouterValueOptimistic({
    key,
    location,
    isCatchAll,
  });

  const setValue = useCallback(
    (
      newValue:
        | string
        | undefined
        | ((prevState: string | undefined) => string | undefined),
    ) => {
      const newValueResolved =
        typeof newValue === 'function'
          ? newValue(valueOptimistic || defaultValue)
          : newValue;

      const currentUrlValue =
        location === 'queryParam' ? getParam(key) : getSegment(key, isCatchAll);

      if (
        newValueResolved === valueOptimistic &&
        newValueResolved === currentUrlValue
      ) {
        return;
      }

      const newValueDefaulted =
        newValueResolved === defaultValue ? undefined : newValueResolved;

      const replace = location === 'queryParam' ? replaceParam : replaceSegment;
      replace({ name: key, value: newValueDefaulted, silent });
    },
    [
      valueOptimistic,
      location,
      replaceParam,
      replaceSegment,
      defaultValue,
      key,
      silent,
      getParam,
      getSegment,
      isCatchAll,
    ],
  );

  return useMemo(() => {
    const value = (valueOptimistic ||
      defaultValue) as TProps['defaultValue'] extends string
      ? string
      : string | undefined;
    return [value, setValue] as const;
  }, [valueOptimistic, setValue, defaultValue]);
}
