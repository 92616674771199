import {
  useState,
  useCallback,
  ReactNode,
  useRef,
  KeyboardEvent,
  useMemo,
} from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';
import { useGuardSignIn } from '../../hooks/useGuardSignIn';
import { memo } from '../../util/memo';

type PopoverWrapperProps = {
  PopoverChildren: ReactNode | ((onClose: () => void) => ReactNode);
  children: ReactNode;
  sx?: SxProps<Theme>;
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  'aria-label'?: string;
  guardTitle?: string;
};

const BOX_SX = { display: 'inline-flex' } as const;

export const PopoverWrapperUnmemoized = ({
  PopoverChildren,
  children,
  sx,
  anchorOrigin,
  transformOrigin,
  'aria-label': ariaLabel,
  guardTitle,
  ...popoverProps
}: PopoverWrapperProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const triggerRef = useRef<HTMLSpanElement>(null);
  const { guardSignIn } = useGuardSignIn();

  const open = Boolean(anchorEl);
  const id = open ? 'popover-wrapper' : undefined;

  const setPopoverAnchor = useCallback((event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const openPopover = useCallback(
    (event: React.MouseEvent) => {
      if (guardTitle) {
        guardSignIn(() => {
          return setPopoverAnchor(event);
        }, guardTitle)();
      } else {
        setPopoverAnchor(event);
      }
    },
    [guardTitle, guardSignIn, setPopoverAnchor],
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
    if (triggerRef.current) {
      triggerRef.current.focus();
    }
  }, []);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        setAnchorEl(e.currentTarget);
      } else if (e.key === 'Escape' && open) {
        onClose();
      }
    },
    [open, onClose],
  );

  const popoverChildren = useMemo(() => {
    return typeof PopoverChildren === 'function'
      ? PopoverChildren(onClose)
      : PopoverChildren;
  }, [PopoverChildren, onClose]);

  const slotProps = useMemo(() => {
    return {
      paper: {
        'aria-labelledby': id,
        role: 'dialog',
        tabIndex: -1,
      },
    };
  }, [id]);

  return (
    <>
      <Box
        ref={triggerRef}
        aria-controls={id}
        aria-expanded={open}
        aria-haspopup="dialog"
        aria-label={ariaLabel}
        component="span"
        role="button"
        sx={BOX_SX}
        tabIndex={0}
        onClick={openPopover}
        onKeyDown={onKeyDown}
      >
        {children}
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        id={id}
        open={open}
        sx={sx}
        transformOrigin={transformOrigin}
        onClose={onClose}
        {...popoverProps}
        aria-modal={open}
        disableEnforceFocus
        disableRestoreFocus
        role="dialog"
        slotProps={slotProps}
      >
        {popoverChildren}
      </Popover>
    </>
  );
};

export const PopoverWrapper = memo(PopoverWrapperUnmemoized);
