import { toMatchPath } from '../path';

// This file defines path types, not React components, so we disable the no-unused-props rule
/* eslint-disable @blumintinc/blumint/no-unused-props */

export const RESULT_SUMMARY_COLLECTION = 'ResultSummary' as const;

export type ResultSummaryPathProps = {
  gameId: string;
  tournamentId: string;
  roundId: string;
  matchId: string;
};

export type ResultSummaryDocPathProps = ResultSummaryPathProps & {
  teamId: string;
};

export const toResultSummaryPath = ({
  teamId,
  ...matchParams
}: ResultSummaryDocPathProps) => {
  return `${toMatchPath(matchParams)}/${RESULT_SUMMARY_COLLECTION}/${teamId}`;
};

export const toResultSummaryCollectionPath = (
  matchParams: ResultSummaryPathProps,
) => {
  return `${toMatchPath(matchParams)}/${RESULT_SUMMARY_COLLECTION}`;
};

export const RESULT_SUMMARY_PATH =
  'Game/{gameId}/Tournament/{tournamentId}/Round/{roundId}/Match/{matchId}/ResultSummary/{resultSummaryId}' as const;
export type ResultSummaryPath = typeof RESULT_SUMMARY_PATH;
