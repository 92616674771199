export const NUMERIC_NOTIMEZONE: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: undefined,
} as const;

export const NUMERIC_TIMEZONE: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
  timeZoneName: 'short',
} as const;

export const ONE_SECOND_MILLIS = 1000 as const;
