import type {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  SnapshotListenOptions,
  SnapshotMetadata,
} from 'firebase/firestore';
import diff from 'microdiff';
import { useRef } from 'react';
import { useDeepCompareCallback } from '@blumintinc/use-deep-compare';
import { FirestoreHandler, useFirestore } from './useFirestore';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';

export const USE_DOC_SNAPSHOT_OPTIONS_DEFAULT: UseDocSnapshotProps<DocumentData> =
  {
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    includeMetadataChanges: false,
  };

export type UseDocSnapshotProps<TData extends DocumentData> =
  SnapshotListenOptions & {
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    ignoreCache?: boolean;
    /**
     * Make sure onSnap is properly memoized with useCallback!
     */
    onSnap?: (snap: DocumentSnapshot<TData>) => void;
  };

export type DocSnapshotProps<TData extends DocumentData> = {
  docPath?: string;
  initialData?: TData;
  options?: UseDocSnapshotProps<TData>;
};
/**
 * Custom hook to retrieve a document snapshot from Firestore
 *
 * @param docPath Path to the document in Firestore
 * @param initialData Initial data loaded from Firestore SSR
 * @param options Additional options for the snapshot listener
 * @returns realtime data of the associated document
 * @returns all data that is returned will only be updated when there is a deep change. So the returned value need not be further memoized.
 */
export function useDocSnapshot<TData extends DocumentData>(
  settings: DocSnapshotProps<TData>,
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type, @blumintinc/blumint/no-type-assertion-returns
): TData | undefined {
  const { docPath, initialData, options } = settings;
  const previousDataRef = useRef<TData | undefined>(initialData);
  const previousMetadataRef = useRef<SnapshotMetadata | null>(null);

  const { ignoreCache = false, onSnap, ...snapshotOptions } = options || {};

  const firestoreHandler = useDeepCompareCallback<FirestoreHandler<TData>>(
    (firestoreModule, firebaseFirestoreModule, setData) => {
      if (!docPath) {
        return;
      }
      const { firestore } = firestoreModule;
      const { doc, onSnapshot } = firebaseFirestoreModule;

      const docRef = doc(firestore, docPath) as DocumentReference<TData>;

      return onSnapshot(
        docRef.withConverter<TData>(ConverterFactory.buildDateConverter()),
        snapshotOptions,
        (snap) => {
          if (ignoreCache && snap.metadata.fromCache) {
            return;
          }

          onSnap?.(snap);

          const newData = snap.data();
          const newMetadata = snap.metadata;

          setData((prevData) => {
            previousDataRef.current = newData;

            const changesData = diff({ ...prevData }, { ...newData });
            const isDataEqual = changesData.length === 0;

            if (!snapshotOptions.includeMetadataChanges) {
              return isDataEqual ? prevData : newData;
            }

            previousMetadataRef.current = newMetadata;

            const changesMetadata = diff(
              { ...previousMetadataRef.current },
              { ...newMetadata },
            );
            const isMetadataEqual = changesMetadata.length === 0;

            return isDataEqual && isMetadataEqual ? prevData : newData;
          });
        },
        (error) => {
          console.error(error);
        },
      );
    },
    [docPath, ignoreCache, snapshotOptions, onSnap],
  );

  return useFirestore(firestoreHandler, initialData);
}
