/* eslint-disable @blumintinc/blumint/no-hungarian */
import { Indexed } from '../types/Indexed';
import { isIndexed } from './isIndexed';
import { sortIndexed } from './sortIndexed';

/**
 * Converts a map to an array of its values
 * If the values extend Indexed (have an index property), the array will be sorted by index
 * @param map A record/map object
 * @returns Array of values from the map, sorted by index if applicable
 */
export function toArr<T>(map: Record<string, T>) {
  // eslint-disable-next-line no-restricted-properties
  const values = Object.values(map);

  // If the array has elements and the first element has an index property,
  // assume all elements do and sort by index
  if (values.length > 0 && isIndexed(values[0])) {
    const indexedValues = values as unknown as Indexed[];
    const sortedValues = sortIndexed(indexedValues);
    const result: T[] = sortedValues as unknown as T[];
    return result;
  }

  return values;
}
