import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { memo } from '../../util/memo';
import { GradientTypography } from '../gradients/GradientTypography';
import { AccountSettingsButton } from '../account-settings/AccountSettingsButton';
import { ListNotifications } from './ListNotifications';
import { Link } from 'src/components/Link';

export const NOTIFICATION_LIST_MAX_HEIGHT = '10vh';

export type NotificationsPreviewProps = {
  onPopoverClose?: () => void;
};

export const NotificationsPreviewUnmemoized: FC<NotificationsPreviewProps> = ({
  onPopoverClose,
}) => {
  const { uidFull } = useAuth();
  const theme = useTheme();

  return (
    <Stack
      p={4}
      pt={3}
      spacing={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: theme.palette.background.elevation['0'],
        border: theme.border,
      }}
    >
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <GradientTypography variant="h6">
          Unread Notifications
        </GradientTypography>
        <AccountSettingsButton onClick={onPopoverClose} />
      </Stack>
      <ListNotifications status="unread" />
      <Link href={`/${uidFull}/notifications`} onClick={onPopoverClose}>
        <Button sx={{ width: '100%' }} variant="contained">
          VIEW ALL
        </Button>
      </Link>
    </Stack>
  );
};

export const NotificationsPreview = memo(NotificationsPreviewUnmemoized);
