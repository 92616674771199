/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
/* eslint-disable react-hooks/rules-of-hooks */
import { ReactNode, useMemo } from 'react';
import type { UpdateData } from 'firebase/firestore';
import { Memoize } from 'typescript-memoize';
import { usePathState } from '../../../hooks/edit/usePathState';
import { PathValue } from './CentralizedProvider';
import { PathCentralizedProvider } from './PathCentralizedProvider';
import { memo } from 'src/util/memo';

export type PathStateCentralizedContextType<
  TObj extends Record<string, unknown>,
> = {
  values: TObj;
  setValue: <TPath extends string>(
    path: TPath,
    value: PathValue<TObj, TPath>,
  ) => void;
  updateObj: (newValues: UpdateData<TObj>) => void | Promise<void>;
};

export type PathStateProviderProps<TObj> = {
  initialState?: Partial<TObj>;
  children: ReactNode;
};

const DEFAULT_INITIAL_STATE = {} as const;

export class PathStateCentralizedProvider<
  TObj extends Record<string, unknown>,
> extends PathCentralizedProvider<TObj, PathStateProviderProps<TObj>> {
  // eslint-disable-next-line class-methods-use-this
  @Memoize()
  public get Provider() {
    const UnmemoizedProvider = ({
      initialState = DEFAULT_INITIAL_STATE,
      children,
    }) => {
      const { values, setValue, update } = usePathState<TObj>(
        initialState as TObj,
      );

      const providerValue = useMemo(() => {
        return {
          values,
          setValue,
          updateObj: update,
        } as const;
      }, [values, setValue, update]);

      return (
        <this.context.Provider value={providerValue}>
          {children}
        </this.context.Provider>
      );
    };
    return memo(UnmemoizedProvider);
  }
}
