export const LARGE_LEADERBOARD = {
  width: 970,
  height: 90,
} as const;
export type LargeLeaderboard = typeof LARGE_LEADERBOARD;

export const LEADERBOARD = {
  width: 728,
  height: 90,
} as const;
export type Leaderboard = typeof LEADERBOARD;

export const LARGE_RECTANGLE = {
  width: 336,
  height: 280,
} as const;
export type LargeRectangle = typeof LARGE_RECTANGLE;

export const LARGE_BANNER = {
  width: 320,
  height: 100,
} as const;
export type LargeBanner = typeof LARGE_BANNER;

export const LARGE_SHORT_BANNER = {
  width: 320,
  height: 50,
} as const;
export type LargeShortBanner = typeof LARGE_SHORT_BANNER;

export const HALF_PAGE = {
  width: 300,
  height: 600,
} as const;
export type HalfPage = typeof HALF_PAGE;

export const MEDIUM_RECTANGLE = {
  width: 300,
  height: 250,
} as const;
export type MediumRectangle = typeof MEDIUM_RECTANGLE;

export const BANNER = {
  width: 300,
  height: 100,
} as const;
export type Banner = typeof BANNER;

export const SHORT_BANNER = {
  width: 300,
  height: 50,
} as const;
export type ShortBanner = typeof SHORT_BANNER;

export const SQUARE = {
  width: 250,
  height: 250,
} as const;
export type Square = typeof SQUARE;

export const SMALL_SQUARE = {
  width: 200,
  height: 200,
} as const;
export type SmallSquare = typeof SMALL_SQUARE;

export const WIDE_SKYSCRAPER = {
  width: 160,
  height: 600,
} as const;
export type WideSkyscraper = typeof WIDE_SKYSCRAPER;

export const SKYSCRAPER = {
  width: 120,
  height: 600,
} as const;
export type Skyscraper = typeof SKYSCRAPER;

export type AdDimension =
  | LargeLeaderboard
  | Leaderboard
  | LargeRectangle
  | LargeBanner
  | LargeShortBanner
  | HalfPage
  | MediumRectangle
  | Banner
  | ShortBanner
  | Square
  | SmallSquare
  | WideSkyscraper
  | Skyscraper;

export type AdHeight = AdDimension['height'];
export type AdWidth = AdDimension['width'];

export const STACK_DISPLAY = {
  xs: 'flex',
  md: 'none',
} as const;
