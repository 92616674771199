import { useCallback } from 'react';
import { DateTimeOptionProps, formatDateTime } from 'src/util/dates/Dates';

export const useDateFormatter = (options: DateTimeOptionProps = {}) => {
  return useCallback(
    (date: Date) => {
      return formatDateTime({ date, optionsDefault: options });
    },
    [options],
  );
};
