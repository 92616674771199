import { Score } from '../../../types/firestore/Game/Tournament/Round/Match/Result';
import { Loadable } from '../../isLoading';
import { toArr } from '../../toArr';
import { assertSafe } from '../../assertSafe';
import { findTeamIndex } from './findTeamIndex';

// TODO: This function needs to be rewritten
// eslint-disable-next-line @blumintinc/blumint/prefer-settings-object, @blumintinc/blumint/no-unnecessary-verb-suffix
export const isCurrentSessionScoredBy = (
  sessionIndex: number,
  team1Scores: Record<string, Score> = {},
  team2Scores: Record<string, Score> = {},
  teamUserIds: string[][],
  uid?: Loadable<string>,
) => {
  const team1ScoresFlat = toArr(team1Scores);
  const team2ScoresFlat = toArr(team2Scores);

  const currentSession = sessionIndex + 1;
  const teamSessionScores = [team1ScoresFlat, team2ScoresFlat];
  const teamIndex = findTeamIndex(teamUserIds, uid);
  const lastIndex = (teamSessionScores[assertSafe(teamIndex)]?.length || 0) - 1;
  return lastIndex !== -1 && lastIndex + 1 === currentSession;
};
