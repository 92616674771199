import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
  persistentLocalCache,
  persistentMultipleTabManager,
  type Firestore,
} from 'firebase/firestore';
import { getApp } from './app';

const app = getApp();

// eslint-disable-next-line import/no-mutable-exports
let firestore: Firestore;
try {
  firestore = initializeFirestore(app, {
    ignoreUndefinedProperties: true,
    experimentalLongPollingOptions: {
      timeoutSeconds: 10,
    },
    localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
    }),
  });
} catch (error) {
  if (
    error instanceof Error &&
    'code' in error &&
    'message' in error &&
    typeof error.code === 'string' &&
    typeof error.message === 'string' &&
    error.code === 'failed-precondition' &&
    error.message.includes('already been called')
  ) {
    firestore = getFirestore(app);
  } else {
    throw error;
  }
}

let connectedEmulator = false;
if (!connectedEmulator && process.env.NEXT_PUBLIC_EMULATOR_MODE === 'true') {
  connectFirestoreEmulator(firestore, 'localhost', 8081);
  connectedEmulator = true;
}

export { firestore };
