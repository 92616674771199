import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { useMatchDetails } from '../../../contexts/MatchDetailsContext';
import { ResultsContextProvider } from '../../../contexts/ResultsContext';
import { RESULT_COLLECTION } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result/path';
// eslint-disable-next-line import/no-cycle
import { GameResults } from './GameResults';

export const GameTables = () => {
  const { matchDocPath, activeSessionIndex } = useMatchDetails();
  const resultCollectionPath = `${matchDocPath}/${RESULT_COLLECTION}`;

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const gameTables = useMemo(() => {
    return Array.from({ length: activeSessionIndex + 1 }, (_, index) => {
      return (
        <ResultsContextProvider
          key={`session-${index}`}
          index={index}
          path={resultCollectionPath}
        >
          <GameResults index={index} />
        </ResultsContextProvider>
      );
    });
  }, [activeSessionIndex, resultCollectionPath]);

  return <Stack spacing={2}>{gameTables}</Stack>;
};
