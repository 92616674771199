import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Logo } from '../Logo';
import { Link } from '../Link';
import { ImageOptimized } from '../image/ImageOptimized';
import { memo } from '../../util/memo';

const LINK_STYLE = {
  textDecoration: 'none',
  flexShrink: 0,
} as const;

const IMAGE_STYLE = {
  marginTop: -1,
} as const;

const LogoSloganUnmemoized = () => {
  return (
    <Link href="/" style={LINK_STYLE}>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Logo height={40} isLink={false} loading="eager" priority width={40} />
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <ImageOptimized
            alt="BluMint - Become a Champion"
            height={30}
            loading="eager"
            priority
            src="/assets/images/logo_no_slogan.png"
            style={IMAGE_STYLE}
            width={160}
          />
        </Box>
      </Stack>
    </Link>
  );
};

export const LogoSlogan = memo(LogoSloganUnmemoized);
