import { useEffect, useCallback, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { useAuth } from '../../contexts/AuthContext';
import {
  ROUTER_KEY_NOTIFICATION,
  ROUTER_KEY_NOTIFICATION_ACTION,
} from '../../components/notification/NotificationActionButtonGroup';
import { useRouterState } from '../routing/useRouterState';
import { useDocSnapshot } from '../firestore/useDocSnapshot';
import { useNotificationActions } from './useNotificationActions';
import { useNotificationDialog } from './useNotificationDialog';
import { Notification } from 'functions/src/types/firestore/User/Notification';
import { toNotificationPath } from 'functions/src/types/firestore/User/Notification/path';

export const useNotificationRouter = () => {
  const { uid } = useAuth();

  const [notificationId, setNotificationId] = useRouterState({
    key: ROUTER_KEY_NOTIFICATION,
  });
  const [notificationAction, setNotificationAction] = useRouterState({
    key: ROUTER_KEY_NOTIFICATION_ACTION,
  });

  const { executeNotificationAction } = useNotificationActions();
  const { openNotificationDialog } = useNotificationDialog();

  const cleanup = useCallback(
    (data?: Notification<Date>) => {
      if (!data) {
        setNotificationId(undefined);
        setNotificationAction(undefined);
      }
    },
    [setNotificationId, setNotificationAction],
  );

  const notification = useDocSnapshot<Notification<Date>>({
    docPath:
      notificationId && uid
        ? toNotificationPath({ userId: uid, notificationId })
        : undefined,
    options: {
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      includeMetadataChanges: false,
      onSnap: (snap) => {
        return cleanup(snap.data());
      },
    },
  });

  useEffect(() => {
    if (!uid || !notificationId || notificationAction || !notification) {
      return;
    }

    openNotificationDialog(notification);
  }, [
    uid,
    notificationId,
    notificationAction,
    notification,
    openNotificationDialog,
  ]);

  const { closeSnackbar } = useSnackbar();
  const hasExecuted = useRef(false);
  useEffect(() => {
    if (!uid || !notificationId || !notificationAction || !notification) {
      return;
    }

    const executeAction = async () => {
      try {
        hasExecuted.current = true;
        await executeNotificationAction(notification, notificationAction);
        closeSnackbar(notificationId);
      } catch (error) {
        hasExecuted.current = false;
        console.error('Failed to execute notification action:', error);
      } finally {
        cleanup();
      }
    };

    executeAction();

    return () => {
      hasExecuted.current = false;
    };
  }, [
    uid,
    notificationId,
    notificationAction,
    notification,
    setNotificationAction,
    executeNotificationAction,
    closeSnackbar,
    cleanup,
  ]);

  return { notification } as const;
};
