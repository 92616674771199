import {
  Dispatch,
  EffectCallback,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useDynamic } from '../../hooks/useDynamic';

export type RealtimeDbModule =
  typeof import('../../config/firebase-client/database');
export type FirebaseRealtimeDbModule = typeof import('firebase/database');

export type RealtimeDbHandler<TData> = (
  realtimeDbModule: RealtimeDbModule,
  firebaseRealtimeDbModule: FirebaseRealtimeDbModule,
  setData: Dispatch<SetStateAction<TData | undefined>>,
) => ReturnType<EffectCallback>;

/**
 * Base hook for RealtimeDB operations that handles dynamic imports and provides
 * a consistent pattern for all RealtimeDB hooks
 *
 * @param handler Memoized callback that handles the RealtimeDB operation
 * @param initialData Optional initial data
 * @returns Current data state
 */
export function useRealtimeDb<TData>(
  handler: RealtimeDbHandler<TData>,
  initialData?: TData,
): TData | undefined {
  const firebaseRealtimeDbModule = useDynamic(import('firebase/database'));
  const realtimeDbModule = useDynamic(
    import('../../config/firebase-client/database'),
  );

  const [data, setData] = useState<TData | undefined>(initialData);

  useEffect(() => {
    if (!realtimeDbModule || !firebaseRealtimeDbModule) {
      return;
    }
    return handler(realtimeDbModule, firebaseRealtimeDbModule, setData);
  }, [realtimeDbModule, firebaseRealtimeDbModule, handler]);

  return data;
}
