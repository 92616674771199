import { ReactNode, FC, useMemo } from 'react';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { memo } from '../../util/memo';
import { DeleteableOverlay, DeleteableOverlayProps } from './DeleteableOverlay';

const DEFAULT_SX: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 2,
};

const DEFAULT_SX_ELEMENT: SxProps = {
  display: 'inline-flex',
};

const DEFAULT_SX_ADD: SxProps = {
  display: 'inline-flex',
  cursor: 'pointer',
};

export type OnDelete = (index: number) => Promise<void> | void;
export type OnAdd = () => Promise<void> | void;

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export type ArrayOverlayProps = {
  elements: ReactNode[];
  addElement: ReactNode;
  onDelete: OnDelete | 'disabled';
  onAdd: OnAdd | 'disabled';
  sx?: SxProps;
  sxAdd?: SxProps;
  elementDeleteOverlayProps?: (
    index: number,
  ) => Omit<DeleteableOverlayProps, 'onDelete'>;
};

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
const ArrayOverlayUnmemoized: FC<ArrayOverlayProps> = ({
  elements,
  addElement,
  onDelete,
  onAdd,
  sx,
  sxAdd,
  elementDeleteOverlayProps,
}) => {
  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const renderedElements = useMemo(() => {
    return elements.map((element, index) => {
      const { sx: sxElement, ...otherProps } =
        elementDeleteOverlayProps?.(index) || {};

      if (onDelete === 'disabled') {
        return (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`element-${index}`}
            sx={{
              ...DEFAULT_SX_ELEMENT,
              ...sxElement,
            }}
          >
            {element}
          </Box>
        );
      }

      return (
        <DeleteableOverlay
          // eslint-disable-next-line react/no-array-index-key
          key={`deleteable-element-${index}`}
          // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
          onDelete={async () => {
            return await onDelete(index);
          }}
          {...otherProps}
          sx={{
            ...DEFAULT_SX_ELEMENT,
            ...sxElement,
          }}
        >
          {element}
        </DeleteableOverlay>
      );
    });
  }, [elementDeleteOverlayProps, elements, onDelete]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const renderedAddElement = useMemo(() => {
    if (onAdd === 'disabled') {
      return null;
    }
    return (
      <Box
        sx={{
          ...DEFAULT_SX_ADD,
          ...sxAdd,
        }}
        onClick={onAdd}
      >
        {addElement}
      </Box>
    );
  }, [addElement, onAdd, sxAdd]);

  return (
    <Box
      sx={{
        ...DEFAULT_SX,
        ...sx,
      }}
    >
      {renderedElements}
      {renderedAddElement}
    </Box>
  );
};

/**
 * You can use the `sx` prop to customize the layout of the array overlay. By default,
 * the elements are rendered in a row.
 *
 * @example
 * <ArrayOverlay
 *   elements={elements}
 *   sx={{ flexDirection: 'column' }}
 * />
 * This will render each element in its own row and stack them vertically.
 */
// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const ArrayOverlay = memo(ArrayOverlayUnmemoized);
