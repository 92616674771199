// eslint-disable-next-line unicorn/better-regex
export const PLACEHOLDER_REGEX = /\[PLACEHOLDER\]/g;
export const PLACEHOLDER_TEXT = '[PLACEHOLDER]';

export const REALTIME_CONFIGURATION_TEMPLATES = {
  livestream: `_tags: Livestream/END_COLLECTION AND _tags:${PLACEHOLDER_TEXT}`,
  friend:
    `_tags: Friendship/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND sender.request.status: accepted`,
  friendRequest:
    `_tags: Friendship/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND receiver.id: ${PLACEHOLDER_TEXT} AND receiver.request.status: received`,
  member:
    `_tags: Membership/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND sender.request.status: accepted`,
  memberRequest:
    `_tags: Membership/END_COLLECTION AND counterparts: ${PLACEHOLDER_TEXT}` +
    ` AND receiver.request.status: received`,
} as const;

export type RealtimeConfigurationKey =
  keyof typeof REALTIME_CONFIGURATION_TEMPLATES;
