import Stack from '@mui/material/Stack';
import { LivestreamCentralizerContext } from '../../contexts/wysiwyg/LivestreamCentralizerContext';
import { toLivestreamPath } from '../../../functions/src/types/firestore/User/Livestream/path';
import { LivestreamInfo } from './LivestreamInfo';
import { LivestreamPlayer } from './LivestreamPlayer';
import { memo } from 'src/util/memo';
import { LivestreamHit } from 'functions/src/types/firestore/User/Livestream';

export type LivestreamCardProps = LivestreamHit;

const LivestreamCardUnmemoized = (hit: LivestreamCardProps) => {
  const { id: playbackId, streamer } = hit;

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <LivestreamPlayer
        playbackId={playbackId}
        sx={{ borderRadius: '10px', overflow: 'hidden' }}
      />
      <LivestreamCentralizerContext.Provider
        dataOverride={hit}
        docPath={toLivestreamPath({ userId: streamer.id, playbackId })}
      >
        <LivestreamInfo />
      </LivestreamCentralizerContext.Provider>
    </Stack>
  );
};

export const LivestreamCard = memo(LivestreamCardUnmemoized);
