/* eslint-disable max-nested-callbacks */
import { useEffect, useCallback, useRef, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { NotificationLayout } from '../../components/notification/NotificationLayout';
import { useRealtimeUnreadNotifications } from '../../contexts/RealtimeUnreadNotificationsContext';
import type { UnreadNotification } from '../../contexts/RealtimeUnreadNotificationsContext';
import { AnnoyWrapper } from '../../components/notification/AnnoyWrapper';
import { SOUND_URLS } from '../../../functions/src/util/sound/constants';
import { ANCHOR_ORIGIN_TOP_RIGHT } from '../../util/styles/anchorOrigin';
import { useNotificationSound } from './useNotificationSound';

const ANNOY_NOTIFICATION_ANIMATION = 'shake' as const;

export const useNotificationSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const activeNotificationsRef = useRef(new Set<string>());
  const seenNotificationsRef = useRef(new Set<string>());
  const mountTime = useMemo(() => {
    return new Date();
  }, []);

  const { playIncomingNotificationSound } = useNotificationSound();

  const { realtimeDocuments: unreadNotifications } =
    useRealtimeUnreadNotifications();

  const dismissNotification = useCallback(
    (notificationId: string) => {
      closeSnackbar(notificationId);
      activeNotificationsRef.current.delete(notificationId);
    },
    [closeSnackbar],
  );

  const renderNotificationContent = useCallback(
    (notification: UnreadNotification) => {
      const { id, attributes, shakeAfter, ringAfter } = notification;
      const ringAlert = ringAfter
        ? {
            after: ringAfter,
            url: SOUND_URLS.NOTIFICATION.ANNOY,
          }
        : undefined;

      const dismiss = () => {
        // eslint-disable-next-line no-restricted-properties
        if (attributes && Object.keys(attributes).length > 0) {
          return;
        }
        dismissNotification(id);
      };

      return (
        <Box
          id={`notification-${id}`}
          sx={{ cursor: 'pointer' }}
          onClick={dismiss}
        >
          <AnnoyWrapper
            key={id}
            animation={ANNOY_NOTIFICATION_ANIMATION}
            ringAlert={ringAlert}
            shakeAfter={shakeAfter}
          >
            <NotificationLayout notification={notification} />
          </AnnoyWrapper>
        </Box>
      );
    },
    [dismissNotification],
  );

  const showNotification = useCallback(
    (notification: UnreadNotification) => {
      const { id, persistTime, createdAt } = notification;
      const dismiss = () => {
        dismissNotification(id);
      };
      if (mountTime < createdAt && !activeNotificationsRef.current.has(id)) {
        enqueueSnackbar(renderNotificationContent(notification), {
          key: id,
          anchorOrigin: ANCHOR_ORIGIN_TOP_RIGHT,
          onClose: (_event, reason) => {
            if (reason === 'timeout') {
              dismiss();
            }
          },
          autoHideDuration: persistTime * 1000,
          persist: persistTime === Infinity,
          style: {
            marginBottom: '-8px',
            marginLeft: '-8px',
            // eslint-disable-next-line @blumintinc/blumint/no-compositing-layer-props
            backgroundColor: 'transparent',
            boxShadow: 'none',
            padding: 0,
          },
        });

        activeNotificationsRef.current.add(id);
        playIncomingNotificationSound();
      }
    },
    [
      mountTime,
      enqueueSnackbar,
      dismissNotification,
      renderNotificationContent,
      playIncomingNotificationSound,
    ],
  );

  useEffect(() => {
    for (const notification of unreadNotifications) {
      const { id } = notification;
      if (!seenNotificationsRef.current.has(id)) {
        showNotification(notification);
        seenNotificationsRef.current.add(id);
      }
    }
  }, [unreadNotifications, showNotification]);

  useEffect(() => {
    const activeNotifications = activeNotificationsRef.current;
    const seenNotifications = seenNotificationsRef.current;

    return () => {
      for (const notificationId of activeNotifications) {
        closeSnackbar(notificationId);
      }
      seenNotifications.clear();
      activeNotifications.clear();
    };
  }, [closeSnackbar]);
};
