/* eslint-disable unicorn/custom-error-definition */
import {
  GRPC_ERROR_CODE_TO_HTTP_STATUS_CODE,
  HttpErrorCode,
  gRPCErrorCode,
} from './errorCodes';

export class HttpsError<TDetails = unknown> extends Error {
  public httpErrorCode: HttpErrorCode;
  // eslint-disable-next-line @blumintinc/blumint/prefer-settings-object, max-params
  constructor(
    public readonly code: gRPCErrorCode,
    public readonly message: string,
    public readonly details?: TDetails,
  ) {
    super(message);
    this.httpErrorCode =
      // We CANNOT use assertSafe as it'll cause an infinite-loop
      // eslint-disable-next-line @blumintinc/blumint/enforce-assertSafe-object-key
      GRPC_ERROR_CODE_TO_HTTP_STATUS_CODE[code] ||
      GRPC_ERROR_CODE_TO_HTTP_STATUS_CODE['unknown'];
    Object.setPrototypeOf(this, HttpsError.prototype);
  }
}
