import { Tournament } from '../../../functions/src/types/firestore/Game/Tournament';

export type ToEventUrlParams = Pick<
  Tournament<Date>,
  'id' | 'gameId' | 'roles'
>;

export const toEventUrl = ({ id, gameId, roles }: ToEventUrlParams) => {
  const { contributor } = roles;
  const contributorId = contributor[0];

  const eventDocumentPath = `Game/${gameId}/Tournament/${id}`;

  const eventPath = encodeURIComponent(eventDocumentPath);

  return {
    eventUrlPath: `${contributorId}/selected?event=${eventPath}`,
    eventDocumentPath,
  };
};
