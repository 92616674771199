import { ReactNode, useCallback, useState, ReactElement } from 'react';
import { insertInnermostChild } from '../util/insertInnermostChild';
import { useRedirectCallback } from './useRedirectCallback';
import { WizardOptions } from './wizard/createUseWizard';
import { useGlobalComponentsContext } from 'src/contexts/GlobalComponentsContext';
import {
  DialogCentered,
  DialogCenteredProps,
} from 'src/components/DialogCentered';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';

export const WIZARD_OPTIONS_DEFAULT = {
  shouldCloseOnRouteChange: true,
} as const;

export type OpenDialogProps = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  children?: ReactNode;
  Wrapper?: ReactElement<{ children?: ReactNode }>;
} & Omit<DialogCenteredProps, 'open'>;

export function useDialog(
  id: string,
  options: WizardOptions = WIZARD_OPTIONS_DEFAULT,
) {
  const { union, remove } = useGlobalComponentsContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const close = useCallback(() => {
    setIsOpen(false);
    remove(id);
  }, [id, remove]);

  useRedirectCallback(options.shouldCloseOnRouteChange ? close : undefined);

  const open = useCallback(
    ({
      title,
      description,
      children,
      onClose,
      Wrapper,
      ...props
    }: OpenDialogProps) => {
      // eslint-disable-next-line @blumintinc/blumint/extract-global-constants
      const standardBody = (
        <DialogBodyStandard description={description} title={title}>
          {children}
        </DialogBodyStandard>
      );
      const body = Wrapper
        ? insertInnermostChild(Wrapper, standardBody)
        : standardBody;

      union(
        id,
        <DialogCentered
          open
          {...props}
          // eslint-disable-next-line @blumintinc/blumint/enforce-callback-memo
          onClose={() => {
            onClose?.();
            remove(id);
          }}
        >
          {body}
        </DialogCentered>,
      );
      setIsOpen(true);
    },
    [id, remove, union],
  );
  return {
    isOpen,
    open,
    close,
  } as const;
}
