import { SxProps, Theme } from '@mui/material/styles';
import { Identifiable } from '../../functions/src/types/Identifiable';
import type {
  TabRouted,
  TabRoutedFull,
} from '../components/routing/TabsRouted';

export type NormalizedTab = Identifiable &
  Pick<
    TabRoutedFull<string>,
    'value' | 'onClick' | 'customization' | 'Wrapper'
  > & {
    sx: SxProps<Theme>;
  };

export function normalizeTab(tab: TabRouted<string>) {
  if (typeof tab === 'string') {
    const outputBasic: NormalizedTab = {
      id: `${tab}-tab`,
      value: tab,
      onClick: undefined,
      customization: {},
      sx: {},
      Wrapper: undefined,
    } as const;
    return outputBasic;
  }

  const { value, onClick, customization, Wrapper } = tab;
  const { sx = {}, onClick: _, ...restCustomization } = customization || {};
  const output: NormalizedTab = {
    id: `${value}-tab`,
    value,
    onClick,
    customization: restCustomization,
    sx,
    Wrapper,
  } as const;
  return output;
}
