import { useTheme } from '@mui/material/styles';
import { FC, ReactNode, useMemo, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { FriendCardAdd } from '../../cards/friend/FriendCardAdd';
import {
  VerticalCarousel,
  VerticalCarouselProps,
} from '../catalogs/generic/VerticalCarousel';
import { withAdInjection } from '../../../util/algolia/withAdInjections';
import { Hit, OrNode } from '../../../../functions/src/types/Hit';
import { useRenderHits } from '../../../hooks/algolia/useRenderHits';
import { SHORT_VERTICAL_AD_INJECTION_CONFIG } from '../../../util/ads/adInjectionConfigs';

export type UserHit = Hit<{
  status: string;
  username: string;
  imgUrl: string;
  id: string;
  friends: string[];
  friendRequests: {
    received: string[];
    sent: string[];
  };
  memberRequests: {
    received: string[];
    sent: string[];
  };
}>;

export type UserVerticalCarouselProps = Omit<
  VerticalCarouselProps,
  'content'
> & {
  hits: OrNode<UserHit>[];
  noHits?: ReactNode;
  ContentCard?: FC<UserHit>;
};

const UserVerticalCarouselUnmemoized = ({
  hits,
  noHits,
  ContentCard,
  ...props
}: UserVerticalCarouselProps) => {
  const theme = useTheme();
  const { uid } = useAuth();

  const renderHit = useCallback(
    (hit: UserHit) => {
      if (ContentCard) {
        return <ContentCard {...hit} />;
      }
      return <FriendCardAdd {...hit} />;
    },
    [ContentCard],
  );

  const content = useRenderHits({
    hits,
    render: renderHit,
    noResultsSearchless: noHits,
  });

  if (!uid) {
    return null;
  }

  return (
    <VerticalCarousel
      cardSpacing="8px"
      containerSx={{ background: theme.palette.background.elevation[0] }}
      content={content}
      {...props}
    />
  );
};

UserVerticalCarouselUnmemoized.displayName = 'UserVerticalCarouselUnmemoized';

export const UserVerticalCarousel = memo(UserVerticalCarouselUnmemoized);

UserVerticalCarousel.displayName = 'UserVerticalCarousel';

export const useUserVerticalCarouselAds = (containerId: string) => {
  return useMemo(() => {
    return withAdInjection({
      WrappedComponent: UserVerticalCarousel,
      id: containerId,
      ...SHORT_VERTICAL_AD_INJECTION_CONFIG,
    });
  }, [containerId]);
};
