import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useCallback } from 'react';
import { useDialog } from '../useDialog';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { useAuth } from '../../contexts/AuthContext';
import { useIndexingHits } from '../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../functions/src/util/algoliaRealtime/fillTemplate';
import { removeShip } from '../../util/ship/removeShip';
import { RemoveFriendDialogDescription } from '../../components/cards/friend/RemoveFriendDialogDescption';

export const REMOVE_FRIEND_DIALOG_ID = 'REMOVE_FRIEND_DIALOG' as const;

export type UseRemoveFriendDialogProps = {
  username: string;
  imgUrl: string;
  userId: string;
};

export const useRemoveFriendDialog = ({
  username,
  imgUrl,
  userId,
}: UseRemoveFriendDialogProps) => {
  const { uid } = useAuth();
  const { open: openDialog, close } = useDialog(REMOVE_FRIEND_DIALOG_ID);
  const { remove } = useIndexingHits();

  const removeFriend = useCallback(async () => {
    if (!uid) {
      return;
    }
    await removeShip({
      userId: uid,
      groupId: userId,
      variant: 'friendship',
    });
    // eslint-disable-next-line @blumintinc/blumint/no-hungarian
    const objectID = `Friendship/${[userId, uid].sort().join('-')}`;

    const friendTemplateFilledHash = convertToHash(
      fillTemplate({
        template: REALTIME_CONFIGURATION_TEMPLATES['friend'],
        placeholderValue: uid,
      }),
    );
    remove(friendTemplateFilledHash, objectID);
    close();
  }, [close, remove, uid, userId]);

  const open = () => {
    openDialog({
      title: 'Remove Friend',
      description: (
        <RemoveFriendDialogDescription
          imgUrl={imgUrl}
          userId={userId}
          username={username}
        />
      ),
      shouldShowCloseIcon: true,
      children: (
        <Stack alignItems="center" direction="row" spacing={2} width="100%">
          <LoadingButton
            color="error"
            fullWidth
            variant="contained"
            onClick={removeFriend}
          >
            Remove
          </LoadingButton>
          <Button color="primary" fullWidth variant="contained" onClick={close}>
            Cancel
          </Button>
        </Stack>
      ),
    });
  };
  return { open, close } as const;
};
