import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';
import { memo } from '../util/memo';
import { useCollectionSnapshot } from '../hooks/firestore/useCollectionSnapshot';
import { useDynamic } from '../hooks/useDynamic';
import { assertSafe } from '../../functions/src/util/assertSafe';
import { ResultSummary } from '../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary';
import {
  Result,
  ResultRanked,
} from '../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';

export const RESULTS_PAGE_SIZE_DEFAULT = 10 as const;
export type ResultContextProps<T extends ResultSummary | ResultRanked> = {
  results: T[];
  page: number;
  setPage: (page: number) => void;
};

export const ResultsContext = createContext<ResultContextProps<
  ResultSummary | ResultRanked
> | null>(null);

export const useResults = <T extends ResultSummary | ResultRanked>() => {
  const context = useContext(ResultsContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useResults must be used within a ResultContextProvider',
    );
  }

  return {
    ...context,
    results: context.results as T[],
  };
};

export type ResultsContextProviderProps = {
  children: ReactNode;
  path: string;
  index?: number;
};

export const ResultsContextProvider = memo(
  function ResultsContextProviderUnmemoized({
    children,
    path,
    index,
  }: ResultsContextProviderProps) {
    const [page, setPage] = useState(0);
    const [pageEndCursors, setPageEndCursors] = useState<
      Record<number, string>
    >({});

    const firestoreModule = useDynamic(
      import('../config/firebase-client/firestore'),
    );
    const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

    const queryConstraints = useMemo(() => {
      if (!firestoreModule || !firebaseFirestoreModule) {
        return;
      }
      const { orderBy, limit, startAfter, where } = firebaseFirestoreModule;

      const whereConstraints =
        index === undefined ? [] : [where('index', '==', index)];

      const baseConstraints = [
        orderBy('matchLexographic', 'asc'),
        limit(RESULTS_PAGE_SIZE_DEFAULT),
      ];

      const priorPage = page - 1;
      const priorPageCursor =
        priorPage in pageEndCursors
          ? pageEndCursors[assertSafe(priorPage)]
          : null;

      const paginationConstraints = priorPageCursor
        ? [startAfter(priorPageCursor)]
        : [];

      return [
        ...whereConstraints,
        ...baseConstraints,
        ...paginationConstraints,
      ];

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firestoreModule, firebaseFirestoreModule, page, index, pageEndCursors]);

    const resultDocs = useCollectionSnapshot<ResultSummary | Result>(path, {
      queryConstraints,
    });

    useEffect(() => {
      if (resultDocs.length > 0) {
        const lastResult = resultDocs[assertSafe(resultDocs.length - 1)];
        setPageEndCursors((prev) => {
          return { ...prev, [page]: lastResult.matchLexographic };
        });
      }
    }, [page, resultDocs]);

    const results = useMemo(() => {
      const baseRank = page * RESULTS_PAGE_SIZE_DEFAULT;
      return resultDocs.map((result, resultIndex) => {
        return { ...result, rank: baseRank + resultIndex + 1 };
      });
    }, [page, resultDocs]);

    const value = useMemo(() => {
      return {
        results,
        page,
        setPage,
      } as const;
    }, [results, page, setPage]);
    return (
      <ResultsContext.Provider value={value}>
        {children}
      </ResultsContext.Provider>
    );
  },
);
