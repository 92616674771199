import type { ChannelFilters } from 'stream-chat';
import { HttpsError } from '../errors/HttpsError';

export const GET_STREAM_ID_PREFIX = 'GET_STREAM_' as const;

export function toGetStreamId(userId: string) {
  if (!userId) {
    throw new HttpsError(
      'invalid-argument',
      'Cannot convert empty-string userId',
    );
  }
  if (!userId.startsWith(GET_STREAM_ID_PREFIX)) {
    return `${GET_STREAM_ID_PREFIX}${userId}`;
  }
  return userId;
}

export function toGetStreamIds(userIds: string[]) {
  return userIds.map((userId) => {
    return toGetStreamId(userId);
  });
}

export function toUserId(getStreamId: string) {
  if (getStreamId.startsWith(GET_STREAM_ID_PREFIX)) {
    return getStreamId.slice(GET_STREAM_ID_PREFIX.length);
  }
  return getStreamId;
}

const MEMBERS_KEY_OPTIONS = ['$eq', '$in', '$nin'] as const;
export type MembersKeyOption = (typeof MEMBERS_KEY_OPTIONS)[number];
export type ChannelFiltersMembers = {
  [K in MembersKeyOption]: string[];
};

export function hasKeys(
  members: ChannelFilters['members'],
): members is ChannelFiltersMembers {
  if (!members || typeof members !== 'object') {
    return false;
  }

  return MEMBERS_KEY_OPTIONS.some((keyOption) => {
    return keyOption in members;
  });
}

export function toGetStreamMembers(members: ChannelFilters['members']) {
  if (!hasKeys(members)) {
    return members;
  }

  return (Object.keys(members) as MembersKeyOption[]).reduce((acc, key) => {
    // eslint-disable-next-line security/detect-object-injection
    acc[key] = members[key].map((userId) => {
      return toGetStreamId(userId);
    });
    return acc;
  }, {} as ChannelFiltersMembers);
}

export function withGetStreamMembers<T extends Pick<ChannelFilters, 'members'>>(
  channelFilters: T,
) {
  const membersGetStream = toGetStreamMembers(channelFilters.members);
  const members =
    membersGetStream && Object.values(membersGetStream).length > 0
      ? { members: membersGetStream }
      : {};
  return {
    ...channelFilters,
    ...members,
  };
}
