import { OnlineStatusEnum } from '.';

export const STATUS_PARENT_PATH = '/status/{groupId}' as const;
export type StatusParentPath = typeof STATUS_PARENT_PATH;

export const toStatusParentPath = (groupId: string) => {
  return STATUS_PARENT_PATH.replace('{groupId}', groupId);
};

export const STATUS_PATH = `${STATUS_PARENT_PATH}/status` as const;
export type StatusPath = typeof STATUS_PATH;

export const toStatusPath = (groupId: string) => {
  return STATUS_PATH.replace('{groupId}', groupId);
};

export const STATUS_COUNT_PATH = `${STATUS_PARENT_PATH}/{status}` as const;
export type StatusCountPath = typeof STATUS_COUNT_PATH;

export type ToStatusCountPathProps = {
  groupId: string;
  status: OnlineStatusEnum;
};

export const toStatusCountPath = ({
  groupId,
  status,
}: ToStatusCountPathProps) => {
  return STATUS_COUNT_PATH.replace('{groupId}', groupId).replace(
    '{status}',
    status,
  );
};
