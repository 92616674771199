import type { Timestamp } from 'firebase-admin/firestore';

export const ONLINE_STATUSES = [
  'online',
  'offline',
  'away',
  'do not disturb',
] as const;

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export type OnlineStatusEnum = (typeof ONLINE_STATUSES)[number];

export type OnlineStatusCount = {
  [status in OnlineStatusEnum]: number;
};

export type OnlineStatusUser<TTime = Timestamp> = {
  status: OnlineStatusEnum;
  lastChanged: TTime;
};

export type OnlineStatus<TTime = Timestamp> = Partial<OnlineStatusCount> &
  OnlineStatusUser<TTime>;

export type OnlineStatusGroup<TTime = Timestamp> = OnlineStatusCount &
  OnlineStatusUser<TTime>;
