import type {
  DocumentData,
  PartialWithFieldValue,
  UpdateData,
  WithFieldValue,
  FirestoreError,
} from 'firebase/firestore';
import { ConverterFactory } from 'functions/src/util/firestore/ConverterFactory';
import { useCallback } from 'react';
import { useDynamic } from '../useDynamic';

/**
 * Converts a flat object with dot notation paths into a nested object
 * e.g. { 'foo.bar': 'value' } becomes { foo: { bar: 'value' } }
 */
function convertDotNotationToNested<T extends DocumentData>(
  data: UpdateData<T>,
): PartialWithFieldValue<T> {
  const result: Record<string, unknown> = {};

  Object.entries(data).forEach(([key, value]) => {
    const parts = key.split('.');
    let current = result;

    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[Number(i)];
      current[String(part)] = current[String(part)] || {};
      current = current[String(part)] as Record<string, unknown>;
    }

    current[parts[parts.length - 1]] = value;
  });

  return result as PartialWithFieldValue<T>;
}

/**
 * Custom hook to update a document in Firestore, creating it if it doesn't exist
 *
 * @param docPath Path to the document in Firestore
 * @returns function to update the document
 */
export function useDocUpdate<TData extends DocumentData>(docPath?: string) {
  const firestoreModule = useDynamic(
    import('../../config/firebase-client/firestore'),
  );
  const firebaseFirestoreModule = useDynamic(import('firebase/firestore'));

  return useCallback(
    async (data: UpdateData<TData>) => {
      if (!docPath || !firestoreModule || !firebaseFirestoreModule) {
        return;
      }

      const { firestore } = firestoreModule;
      const { doc, updateDoc, setDoc } = firebaseFirestoreModule;

      const docRef = doc(firestore, docPath).withConverter(
        ConverterFactory.buildDateConverter<TData>(),
      );

      try {
        await updateDoc(docRef, data as UpdateData<TData>);
      } catch (error) {
        const firestoreError = error as FirestoreError;
        if (firestoreError.code === 'not-found') {
          const nestedData = convertDotNotationToNested(data);
          await setDoc(docRef, nestedData as WithFieldValue<TData>);
        } else {
          throw error;
        }
      }
    },
    [docPath, firestoreModule, firebaseFirestoreModule],
  );
}
