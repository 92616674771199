import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import { useAuthSubmit } from '../../../contexts/AuthSubmitContext';
import { useSendConfirmationCode } from '../../../hooks/auth/useSendConfirmationCode';
import { DialogAuthenticationNavBar } from '../DialogAuthenticationNavBar';
import { StoredPhoneNumberData } from '../../../hooks/auth/useAuthFlowBase';
import { useKeypressListener } from '../../../hooks/useKeypressListener';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import { LoadingButton } from 'src/components/buttons/LoadingButton';
import { PhoneNumberInput } from 'src/components/authentication/PhoneNumberInput';

// eslint-disable-next-line @blumintinc/blumint/enforce-singular-type-names
export type AuthenticationEnterPhonePageUserData = {
  country: string;
  number: string;
};

export type StoredPhoneNumberDataWithValidity = StoredPhoneNumberData & {
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  validFormat: boolean;
};

const CUSTOM_INPUT_STYLE = {
  width: '100%',
} as const;

export const AuthenticationEnterPhoneDialogUnmemoized = () => {
  const theme = useTheme();
  const { hasUserTyped, errorMessage, isValidated, isLoading } =
    useAuthSubmit();
  const { sendConfirmationCode } = useSendConfirmationCode({
    // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
    firstCode: true,
  });

  const attemptSubmit = useCallback(async () => {
    await sendConfirmationCode();
  }, [sendConfirmationCode]);

  useKeypressListener(['Enter', 'NumpadEnter'], attemptSubmit);

  return (
    <Stack sx={{ width: '100%' }}>
      <DialogAuthenticationNavBar />
      <DialogBodyStandard
        description={
          <Stack alignItems="center" maxWidth="440px" pb={2} spacing={4}>
            <Typography>
              Please enter your phone number in full. Remember to key in your
              country code so that your country&apos;s flag appears. Do NOT omit
              your area code.
            </Typography>
            <Box width="100%">
              <PhoneNumberInput inputStyle={CUSTOM_INPUT_STYLE} />
            </Box>
          </Stack>
        }
        title={'Enter Phone Number'}
      >
        <Stack
          alignItems="center"
          maxWidth="440px"
          // eslint-disable-next-line @blumintinc/blumint/no-margin-properties
          mx="auto"
          spacing={6}
          width="100%"
        >
          {!!errorMessage.phoneNumber && (
            <Typography
              color={theme.palette.error.main}
              textAlign={'center'}
              variant="body2"
            >
              {errorMessage.phoneNumber}
            </Typography>
          )}
          <LoadingButton
            disabled={
              !isValidated.phoneNumber || !hasUserTyped.phoneNumber || isLoading
            }
            id="recaptcha"
            size="large"
            sx={{ width: '100%' }}
            type="submit"
            variant="contained"
            onClick={attemptSubmit}
          >
            Send Code
          </LoadingButton>
        </Stack>
      </DialogBodyStandard>
    </Stack>
  );
};

export const AuthenticationEnterPhoneDialog = memo(
  AuthenticationEnterPhoneDialogUnmemoized,
);
