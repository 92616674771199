import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { memo } from '../../util/memo';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';

export type MessageReply = {
  id: string;
  username: string;
};

type ReplyContextProps = {
  replyingTo?: MessageReply;
  setReplyingTo: Dispatch<SetStateAction<MessageReply | undefined>>;
};

export const ReplyContext = createContext<ReplyContextProps | undefined>(
  undefined,
);

export const useReply = () => {
  const context = useContext(ReplyContext);
  if (!context) {
    throw new HttpsError(
      'invalid-argument',
      'useReply must be used within a ReplyProvider',
    );
  }
  return context;
};

// eslint-disable-next-line react-memo/require-memo
const ReplyProviderUnmemoized = ({ children }) => {
  const [replyingTo, setReplyingTo] = useState<MessageReply | undefined>(
    undefined,
  );

  const valueMemoized = useMemo(() => {
    return {
      replyingTo,
      setReplyingTo,
    } as const;
  }, [replyingTo, setReplyingTo]);

  return (
    <ReplyContext.Provider value={valueMemoized}>
      {children}
    </ReplyContext.Provider>
  );
};

export const ReplyProvider = memo(ReplyProviderUnmemoized);
