import { isSupport } from '../../../types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../../types/firestore/User/ChannelGroup/util/isTournament';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../types/firestore/User/ChannelGroup';
import { isPlayback } from '../../../types/firestore/User/ChannelGroup/util/isPlayback';
import { isTemporary } from '../../../types/firestore/User/ChannelGroup/util/isTemporary';

const computeChannelGroupPriority = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
) => {
  if (isPlayback(channelGroup)) return 5;
  if (isTemporary(channelGroup)) return 4;
  if (isTournament(channelGroup)) return 3;
  if (isSupport(channelGroup)) return 2;
  return 1;
};

export const sortChannelGroups = (
  channelPreviews: ChannelGroup<keyof GroupFilterMap, Date>[],
) => {
  return channelPreviews.sort((a, b) => {
    const priorityDiff =
      computeChannelGroupPriority(b) - computeChannelGroupPriority(a);
    return priorityDiff || b.lastUpdated.getTime() - a.lastUpdated.getTime();
  });
};
