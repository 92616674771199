import { useCallback, useMemo } from 'react';
import { useRouterState } from '../routing/useRouterState';
import { useGroupRouterExternal } from '../group/useGroupRouterExternal';
import { useLivestreamRouting } from './useLivestreamRouting';

export const ROUTER_KEY_LIVESTREAM_MODE = 'livestream-mode' as const;

export type LivestreamMode =
  | 'theater'
  | 'fullscreen'
  | 'miniplayer'
  | 'preview';

export function useLivestreamMode() {
  const { groupId } = useGroupRouterExternal();
  const [mode, setMode] = useRouterState({
    key: ROUTER_KEY_LIVESTREAM_MODE,
    defaultValue: groupId ? 'theater' : 'miniplayer',
  });

  const exitMode = useCallback(() => {
    setMode(undefined);
  }, [setMode]);

  const enterTheaterMode = useCallback(() => {
    setMode('theater');
  }, [setMode]);

  const enterFullscreenMode = useCallback(() => {
    setMode('fullscreen');
  }, [setMode]);

  const { openLivestreamChannel } = useLivestreamRouting();

  const enterMiniPlayerMode = useCallback(() => {
    setMode('miniplayer');
    openLivestreamChannel();
  }, [setMode, openLivestreamChannel]);

  const enterPreviewMode = useCallback(() => {
    setMode('preview');
  }, [setMode]);

  return useMemo(() => {
    return {
      mode,
      isTheaterMode: mode !== 'miniplayer' && mode !== 'preview',
      isFullscreenMode: mode === 'fullscreen',
      isMiniPlayerMode: mode === 'miniplayer',
      isPreviewPlayerMode: mode === 'preview',
      enterTheaterMode,
      enterFullscreenMode,
      enterMiniPlayerMode,
      enterPreviewMode,
      exitMode,
    } as const;
  }, [
    mode,
    exitMode,
    enterTheaterMode,
    enterFullscreenMode,
    enterMiniPlayerMode,
    enterPreviewMode,
  ]);
}
