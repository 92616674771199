import { useCallback } from 'react';

export type UseEmailVerificationProps = {
  onVerified?: () => void;
};

export const useEmailVerification = ({
  onVerified,
}: UseEmailVerificationProps) => {
  const verifyEmail = useCallback(async () => {
    const authImport = import('../../config/firebase-client/auth');
    const { auth } = await authImport;

    const { currentUser } = auth;
    if (!currentUser) {
      return;
    }

    await currentUser.reload?.();
    const { emailVerified } = currentUser;

    if (!emailVerified) {
      return;
    }

    onVerified?.();
  }, [onVerified]);

  return { verifyEmail } as const;
};
