import { useCallback } from 'react';
import type { Optional } from 'utility-types';
import { OpenDialogSettings, useDialog } from '../useDialog';
import {
  EditLinkDialogBody,
  EditLinkDialogBodyProps,
} from '../../components/edit/link/EditLinkDialogBody';

export const EDIT_LINK_DIALOG_ID = 'edit-link-dialog' as const;

export function useEditLinkDialog() {
  const { open, close } = useDialog(EDIT_LINK_DIALOG_ID);

  const openEditDialog = useCallback(
    <TUrl extends string = string>({
      title,
      // eslint-disable-next-line no-restricted-syntax
      initialUrl = '' as TUrl,
      onSave,
      onClose,
      urlOptions,
      ...rest
    }: Optional<EditLinkDialogBodyProps<TUrl>, 'initialUrl'> &
      Omit<OpenDialogSettings, 'onClose' | 'children'>) => {
      open({
        title,
        children: (
          <EditLinkDialogBody<TUrl>
            initialUrl={initialUrl}
            urlOptions={urlOptions}
            onClose={close}
            onSave={onSave}
          />
        ),
        ...rest,
      });
    },
    [open, close],
  );

  return {
    open: openEditDialog,
  } as const;
}
