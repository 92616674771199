import { GridColDef } from '@mui/x-data-grid';
import { useMemo } from 'react';
import {
  LOSSES_INDEX,
  ResultSummary,
  WINS_INDEX,
} from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary';
import { RecordDisplay } from '../../components/tournaments/heats-leaderboards/RecordDisplay';
import { useMatchDetails } from '../../contexts/MatchDetailsContext';
import { assertSafe } from 'functions/src/util/assertSafe';

export const HEADER_NAME_RECORD = 'RECORD (W-L)' as const;

export function useRecordColumn() {
  const { match } = useMatchDetails();
  const { stage } = match || {};
  const recordColumn: GridColDef<ResultSummary> = useMemo(() => {
    const column: GridColDef<ResultSummary> = {
      field: 'wins',
      headerName: HEADER_NAME_RECORD,
      minWidth: 130,
      flex: 1,
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      sortable: false,
      // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row: { scoresMatch } }) => {
        const teamRecord = {
          wins: scoresMatch[assertSafe(WINS_INDEX)].value,
          losses: scoresMatch[assertSafe(LOSSES_INDEX)].value,
        };
        return <RecordDisplay stage={stage} teamRecord={teamRecord} />;
      },
    };
    return column;
  }, [stage]);

  return recordColumn;
}
