import { createContext, useContext, ReactNode } from 'react';
import { memo } from '../util/memo';
import {
  useLoadingWrapper,
  UseLoadingWrapperProps,
} from '../hooks/useLoadingWrapper';
import { HttpsError } from '../../functions/src/util/errors/HttpsError';

const LoadingWrapperContext = createContext<UseLoadingWrapperProps | undefined>(
  undefined,
);

export const LoadingWrapperProvider = memo(
  function LoadingWrapperProviderUnmemoized({
    children,
  }: {
    children: ReactNode;
  }) {
    const loadingWrapper = useLoadingWrapper();

    return (
      <LoadingWrapperContext.Provider value={loadingWrapper}>
        {children}
      </LoadingWrapperContext.Provider>
    );
  },
);

export function useLoadingWrapperContext(): UseLoadingWrapperProps {
  const context = useContext(LoadingWrapperContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useLoadingWrapperContext must be used within a LoadingWrapperProvider',
    );
  }
  return context;
}
