import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { SxProps } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { ContentOverlay } from '../ContentOverlay';
import { useVideo } from '../../contexts/video/VideoContext';
import { LivestreamCentralizerContext } from '../../contexts/wysiwyg/LivestreamCentralizerContext';
import { DownloadLivestreamButton } from './DownloadLivestreamButton';
import { AvatarLivestream } from './AvatarLivestream';
import { LivestreamInfoBody } from './LivestreamInfoBody';
import { LivestreamInfoHeader } from './LivestreamInfoHeader';

export type LivestreamInfoProps = {
  sx?: SxProps;
};

const DEFAULT_SX = {} as const;

const LivestreamInfoUnmemoized = ({ sx = DEFAULT_SX }: LivestreamInfoProps) => {
  const { isMiniPlayerMode } = useLivestreamMode();
  const { isLive } = useVideo();

  const { obj } = LivestreamCentralizerContext.useEntireObject();

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const livestreamInfo = useMemo(() => {
    if (!obj.streamer) {
      return null;
    }
    const { imgUrl, username, id: streamerId } = obj.streamer;
    return (
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ px: 4, pb: 2, ...sx }}
        width="100%"
      >
        <Stack alignItems="flex-start" direction="row" flexWrap="wrap" gap={4}>
          <Stack flex="0 1 auto">
            <AvatarLivestream
              imgUrl={imgUrl}
              streamerId={streamerId}
              username={username}
            />
          </Stack>
          <LivestreamInfoHeader
            streamerId={streamerId}
            sx={{ flex: '0 1 auto' }}
            username={username}
          />
          <LivestreamInfoBody
            streamerId={streamerId}
            sx={{ flex: '1 1 auto' }}
          />
        </Stack>
        <DownloadLivestreamButton />
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sx, obj.streamer?.imgUrl, obj.streamer?.username, obj.streamer?.id]);

  if (!livestreamInfo) {
    return null;
  }

  return isMiniPlayerMode ? (
    <ContentOverlay
      sx={{
        position: 'absolute',
        top: 2,
        left: 4,
        pl: isLive ? 0 : 2,
        pr: isLive ? 2 : 0,
      }}
    >
      {livestreamInfo}
    </ContentOverlay>
  ) : (
    livestreamInfo
  );
};

export const LivestreamInfo = memo(LivestreamInfoUnmemoized);
