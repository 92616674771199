import { LoadingWrapper } from '../LoadingWrapper';
import { SignUpButton } from './SignUpButton';
import { SignInDropdown } from './SignInDropdown';
import { useAuth } from 'src/contexts/AuthContext';

export function UserButton() {
  const { uid, isSubscribingUserData, isUserDeleted = false } = useAuth();

  return (
    <LoadingWrapper
      isLoading={isSubscribingUserData || isUserDeleted}
      sx={{ height: '36px', width: '36px' }}
    >
      {uid ? <SignInDropdown /> : <SignUpButton />}
    </LoadingWrapper>
  );
}
