import { ComponentType, useMemo, useState, useEffect } from 'react';
import { SxProps } from '@mui/material/styles';
import { useDynamic } from '../hooks/useDynamic';
import {
  LoadingWrapper,
  LoadingWrapperProps,
} from '../components/LoadingWrapper';
import {
  DynamicImport,
  DynamicModule,
} from '../../functions/src/types/DynamicModule';
import { memo } from '../util/memo';

export type WithDynamicImportProps<
  TModuleNameList extends (keyof DynamicModule)[],
  TProps extends {
    modules: DynamicImport<TModuleNameList>;
  },
> = Omit<LoadingWrapperProps, 'isLoading' | 'children'> & {
  Component: ComponentType<TProps>;
  moduleNames: TModuleNameList;
};

// add 100ms fade to component so it doesn't flash when loading

export function withDynamicImport<
  TModuleNameList extends (keyof DynamicModule)[],
  TProps extends {
    modules: DynamicImport<TModuleNameList>;
    sx?: SxProps;
  },
>({
  Component,
  moduleNames,
  LoadingComponent,
  ...loadingWrapperProps
}: WithDynamicImportProps<TModuleNameList, TProps>) {
  function DynamicImportsComponent(props: Omit<TProps, 'modules'>) {
    const modules = useDynamic(moduleNames);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
      if (modules) {
        const animationFrame = requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            setOpacity(1);
          });
        });

        return () => {
          cancelAnimationFrame(animationFrame);
        };
      }
    }, [modules]);

    // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
    const component = useMemo(() => {
      if (!modules) {
        return null;
      }

      const { sx } = props;
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const propsWithModules: TProps = {
        ...props,
        modules,
        sx: {
          ...sx,
          opacity,
          transition: `opacity 100ms ease-in-out`,
        },
      } as TProps;

      return <Component {...propsWithModules} />;
    }, [props, opacity, modules]);

    return LoadingComponent ? (
      <LoadingWrapper
        isLoading={!component}
        LoadingComponent={LoadingComponent}
        {...loadingWrapperProps}
      >
        {component}
      </LoadingWrapper>
    ) : (
      component
    );
  }

  return memo(DynamicImportsComponent);
}
