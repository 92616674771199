/* eslint-disable react/jsx-max-depth */
import '../util/ads/detectAdBlock';
import '../styles/global.css';
import '../util/supressConsole';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import Head from 'next/head';
import { Analytics } from '@vercel/analytics/react';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CurrencyProvider } from '../contexts/CurrencyContext';
import defaultTheme from '../styles/theme';
import createEmotionCache from '../util/createEmotionCache';
import { AuthProvider } from '../contexts/AuthContext';
// import { TwitchAuthProvider } from 'src/contexts/TwitchAuthContext';
import { MainLayout } from '../components/MainLayout';
import { Web3Provider } from '../contexts/web3/Web3Context';
import { SignatureRequestProvider } from '../contexts/web3/SignatureRequestContext';
import { ProgressionProvider } from '../contexts/ProgressionContext';
import { IndexingHitsProvider } from '../contexts/algolia/IndexingHitsContext';
import { UnreadMessageCountProvider } from '../contexts/UnreadMessageCountContext';
import { UrlModificationsProvider } from '../contexts/routing/UrlModificationsContext';
import { UserNotificationCountProvider } from '../contexts/UserNotificationCountContext';
import { ActiveChannelGroupProvider } from '../contexts/ActiveChannelGroupContext';
import { RealtimeChannelGroupsProvider } from '../contexts/RealtimeChannelGroupContext';
import { SessionStorageProvider } from '../contexts/SessionStorageContext';
import { LocalStorageProvider } from '../contexts/LocalStorage';
import { OptimisticValueProvider } from '../contexts/OptimisticValueContext';
import { AdBlockProvider } from '../contexts/AdBlockContext';
import { OnlineProvider } from '../contexts/OnlineContext';
import { GlobalIntervalProvider } from '../contexts/GlobalIntervalContext';
import { UserPresenceProvider } from '../contexts/UserPresenceContext';
//import { useRefreshAdapex } from '../hooks/ads/useRefreshAdapex';
import { RoomProvider } from '../contexts/RoomContext';
import { StreamProvider } from '../contexts/get-stream/StreamContext';
import { SessionProvider } from '../contexts/SessionContext';
import { ExchangeRatesPrices } from '../../functions/src/types/firestore/ExchangeRates/Prices';
import { ActiveTournamentProvider } from '../contexts/active-tournament/ActiveTournamentContext';
import { ActiveMatchProvider } from '../contexts/active-tournament/ActiveMatchContext';
import { RealtimeReadNotificationsProvider } from '../contexts/RealtimeReadNotificationsContext';
import { RealtimeUnreadNotificationsProvider } from '../contexts/RealtimeUnreadNotificationsContext';
import { RealtimeArchivedNotificationsProvider } from '../contexts/RealtimeArchivedNotificationContext';
import { PictureInPictureProvider } from '../contexts/PictureInPictureContext';
import { VideoProvider } from '../contexts/video/VideoContext';
import { useRegisterServiceWorker } from 'src/hooks/firebase/useRegisterServiceWorker';
import { MaintenanceMode } from 'src/components/MaintenanceMode';
import { PageLoadingProvider } from 'src/contexts/PageLoadingContext';
import { LocationProvider } from 'src/contexts/useLocation';
import { ServerTimeProvider } from 'src/contexts/useServerTime';
import { GlobalComponentsProvider } from 'src/contexts/GlobalComponentsContext';
import { snackbarClasses } from 'src/styles/snackbar';

/** Client-side cache, shared for the whole session of the user in the browser. */
const clientSideEmotionCache = createEmotionCache();

type PropsEveryPage = Record<string, unknown> & {
  exchangeRates: ExchangeRatesPrices<Date>;
};

export type BlumintAppProps = AppProps<PropsEveryPage> & {
  emotionCache?: EmotionCache;
};

export const MIN_VIEWPORT_WIDTH = 654 as const;

export default function BlumintApp(props: BlumintAppProps) {
  useRegisterServiceWorker();
  //useRefreshAdapex();

  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps: { exchangeRates, ...pageProps },
  } = props;

  return (
    <OptimisticValueProvider>
      <LocalStorageProvider>
        <UrlModificationsProvider>
          <Web3Provider>
            <LocationProvider>
              <ServerTimeProvider>
                {/* <TwitchAuthProvider> */}
                {/* eslint-disable-next-line @blumintinc/blumint/consistent-callback-naming */}
                <ThemeProvider theme={defaultTheme}>
                  <CssBaseline enableColorScheme />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MaintenanceMode>
                      <PageLoadingProvider>
                        <GlobalIntervalProvider>
                          <AuthProvider>
                            <RealtimeReadNotificationsProvider>
                              <RealtimeUnreadNotificationsProvider>
                                <RealtimeArchivedNotificationsProvider>
                                  <SessionProvider>
                                    <SnackbarProvider classes={snackbarClasses}>
                                      <UserPresenceProvider>
                                        <SessionStorageProvider>
                                          <StreamProvider>
                                            <ActiveTournamentProvider>
                                              <ActiveMatchProvider>
                                                <RealtimeChannelGroupsProvider>
                                                  <UnreadMessageCountProvider>
                                                    <ActiveChannelGroupProvider>
                                                      <VideoProvider>
                                                        <RoomProvider>
                                                          <OnlineProvider>
                                                            <AdBlockProvider>
                                                              <CurrencyProvider
                                                                exchangeRates={
                                                                  exchangeRates
                                                                }
                                                              >
                                                                <CacheProvider
                                                                  value={
                                                                    emotionCache
                                                                  }
                                                                >
                                                                  <PictureInPictureProvider>
                                                                    <Head>
                                                                      <title>
                                                                        BluMint
                                                                      </title>
                                                                      <meta
                                                                        content="ca-pub-4740369050630888"
                                                                        name="google-adsense-account"
                                                                      />
                                                                      <meta
                                                                        content="width=device-width, user-scalable=0"
                                                                        name="viewport"
                                                                      />
                                                                    </Head>
                                                                    <IndexingHitsProvider>
                                                                      <UserNotificationCountProvider>
                                                                        <GlobalComponentsProvider>
                                                                          <ProgressionProvider>
                                                                            <SignatureRequestProvider>
                                                                              <MainLayout>
                                                                                <Component
                                                                                  {...pageProps}
                                                                                />
                                                                                <Analytics />
                                                                              </MainLayout>
                                                                            </SignatureRequestProvider>
                                                                          </ProgressionProvider>
                                                                        </GlobalComponentsProvider>
                                                                      </UserNotificationCountProvider>
                                                                    </IndexingHitsProvider>
                                                                  </PictureInPictureProvider>
                                                                </CacheProvider>
                                                              </CurrencyProvider>
                                                            </AdBlockProvider>
                                                          </OnlineProvider>
                                                        </RoomProvider>
                                                      </VideoProvider>
                                                    </ActiveChannelGroupProvider>
                                                  </UnreadMessageCountProvider>
                                                </RealtimeChannelGroupsProvider>
                                              </ActiveMatchProvider>
                                            </ActiveTournamentProvider>
                                          </StreamProvider>
                                        </SessionStorageProvider>
                                      </UserPresenceProvider>
                                    </SnackbarProvider>
                                  </SessionProvider>
                                </RealtimeArchivedNotificationsProvider>
                              </RealtimeUnreadNotificationsProvider>
                            </RealtimeReadNotificationsProvider>
                          </AuthProvider>
                        </GlobalIntervalProvider>
                      </PageLoadingProvider>
                    </MaintenanceMode>
                  </LocalizationProvider>
                </ThemeProvider>
              </ServerTimeProvider>
            </LocationProvider>
          </Web3Provider>
        </UrlModificationsProvider>
      </LocalStorageProvider>
    </OptimisticValueProvider>
  );
}
