import { useMemo, Fragment } from 'react';
import { sortChannelGroups } from '../../../../functions/src/util/messaging/channel-group/sortChannelGroups';
import { memo } from '../../../util/memo';
import { useRealtimeChannelGroups } from '../../../contexts/RealtimeChannelGroupContext';
import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { isTemporary } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isTemporary';
import { ChannelGroupSidebar } from './ChannelGroupSidebar';

const ChannelGroupAvatarsUnmemoized = () => {
  const { realtimeDocuments } = useRealtimeChannelGroups();

  const channelPreviewsSorted = useMemo(() => {
    return sortChannelGroups(realtimeDocuments);
  }, [realtimeDocuments]);

  return (
    <Fragment>
      {channelPreviewsSorted.map(
        (
          channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
          index,
          array,
        ) => {
          const isLastChannelGroup = index === array.length - 1;

          const isNextChannelGroupTemporary =
            !isLastChannelGroup && isTemporary(array[index + 1]);

          const isLastTemporaryChannelGroup =
            !isLastChannelGroup &&
            !isNextChannelGroupTemporary &&
            isTemporary(channelGroup);

          return (
            <ChannelGroupSidebar
              key={channelGroup.id}
              channelGroup={channelGroup}
              isLastTemporaryChannelGroup={isLastTemporaryChannelGroup}
            />
          );
        },
      )}
    </Fragment>
  );
};

export const ChannelGroupAvatars = memo(ChannelGroupAvatarsUnmemoized);
