import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { useDisplayedRank } from '../../../hooks/useDisplayedRank';

export type RankProps = {
  rank: number;
};

export const Rank = memo(function RankUnmemoized({ rank }: RankProps) {
  const rankDisplayed = useDisplayedRank({ rank });
  return (
    <Typography
      variant={rank > 3 ? 'body2' : 'body1'}
      {...(rank <= 3 && { sx: { fontWeight: 700 } })}
    >
      {rankDisplayed}
    </Typography>
  );
});
