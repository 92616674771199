/* eslint-disable max-lines */
/* eslint-disable @blumintinc/blumint/consistent-callback-naming */
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import type { Channel } from 'stream-chat';
import { useTheme } from '@mui/material/styles';
import { useCallback, Fragment } from 'react';
import dynamic from 'next/dynamic';
import {
  GroupFilterMap,
  GroupFilter,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { memo } from '../../../util/memo';
import { DateSeparator } from '../DateSeparator';
import { useReply } from '../ReplyContext';
import { ChannelHeader } from '../channel/ChannelHeader';
import { toChannelFilters } from '../../../../functions/src/util/messaging/channel/toChannelFilters';
import { toChannelGroupId } from '../../../../functions/src/util/messaging/toChannelGroupPath';
import { ChannelFetcher } from '../../../../functions/src/util/messaging/ChannelFetcher';
import { EmojiPicker } from '../EmojiPicker';
import { Channels } from '../channel/Channels';
import { TypingProvider } from '../TypingContext';
import { ThreadHeader } from '../ThreadHeader';
import { useActiveChannel } from '../../../hooks/messaging/useActiveChannel';
import { ThreadStart } from '../message/ThreadStart';
import { useChannelList } from '../../../contexts/ChannelListContext';
import { SortChannelsParams } from '../../../util/messaging/sortChannels';
import { SortGroupsParams } from '../../../util/messaging/sortGroups';
import { isTournamentFilter } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isTournament';
import { isPublicChannel } from '../../../util/messaging/isPublicChannel';
import {
  DynamicImport,
  STREAM_CHAT_REACT,
  StreamChatReact,
} from '../../../../functions/src/types/DynamicModule';
import { withDynamicImport } from '../../../util/withDynamicImport';
import { CHANNEL_GROUP_HEADER_HEIGHT } from './ChannelGroupHeader';
import { assertSafe } from 'functions/src/util/assertSafe';
// import { Ad } from '../../ads/Ad';
// import { MESSAGING_AD } from '../../../../functions/src/util/ads/adIds';
//import { useMessageInputFocus } from '../../../contexts/get-stream/MessageInputFocusContext';
//export const MESSAGING_AD_HEIGHT = 50 as const;

const Message = dynamic(async () => {
  const mod = await import('../message/Message.dynamic');
  return mod.Message;
});

const ChannelInner = dynamic(async () => {
  const mod = await import('../channel/ChannelInner.dynamic');
  return mod.ChannelInner;
});

export const SORT_CREATED_AT_ASC = { created_at: 1 } as const;

export type ChannelGroupProps = {
  groupFilter: GroupFilter<keyof GroupFilterMap>;
  sortGroups: ({ groupNameA, groupNameB }: SortGroupsParams) => number;
  hasMultipleChannels: boolean;
  sortChannels?: ({ channelA, channelB }: SortChannelsParams) => number;
  modules: DynamicImport<[StreamChatReact]>;
};

function ChannelGroupUnmemoized({
  sortGroups,
  sortChannels,
  groupFilter,
  hasMultipleChannels,
  modules,
}: ChannelGroupProps) {
  const theme = useTheme();
  const { replyingTo } = useReply();
  //const { isFocused: isMessageInputFocused } = useMessageInputFocus();

  // TODO: properly handle the unread behavior
  const ComponentEmpty = useCallback(() => {
    return <Fragment />;
  }, []);

  const streamChatModule = modules[assertSafe(STREAM_CHAT_REACT)];

  useActiveChannel({ streamChatModule });

  const filters = toChannelFilters(groupFilter);
  const channelGroupId = toChannelGroupId(groupFilter);
  const { isChannelListVisible, hideChannelList } = useChannelList();

  const renderChannels = useCallback(
    (channels: Channel[]) => {
      const channelsValidated = channels.filter((channel) => {
        return isPublicChannel(channel.type) && isTournamentFilter(groupFilter)
          ? channel.data?.tournamentId === groupFilter[0].tournamentId
          : ChannelFetcher.containsEvery$In(filters, channel);
      });

      return (
        <Channels
          channelGroupId={channelGroupId}
          channels={channelsValidated}
          sortChannels={sortChannels}
          sortGroups={sortGroups}
          onClick={hideChannelList}
        />
      );
    },
    [
      channelGroupId,
      hideChannelList,
      sortGroups,
      sortChannels,
      groupFilter,
      filters,
    ],
  );

  const {
    Channel: StreamChannel,
    ChannelList: StreamChannelList,
    InfiniteScroll: StreamInfiniteScroll,
  } = streamChatModule;

  return (
    <Stack
      sx={{
        height: {
          xs: '100%',
          md: `calc(100% - ${CHANNEL_GROUP_HEADER_HEIGHT}px)`,
        },
      }}
    >
      {!!hasMultipleChannels && <ChannelHeader />}
      <Stack
        height="100%"
        position="relative"
        sx={{
          overflowX: 'hidden',
          ...theme.scrollbars.invisible,
        }}
        width="100%"
      >
        <Box
          height="100%"
          position="absolute"
          sx={{
            // eslint-disable-next-line @blumintinc/blumint/no-compositing-layer-props
            transform: `translateX(${isChannelListVisible ? '0' : '-100%'})`,
            transformOrigin: 'left center',
            transition: 'transform 0.3s ease-in-out',
          }}
          width="100%"
          zIndex={theme.zIndex.drawer}
        >
          <Box
            sx={{
              height: '100%',
              background: theme.palette.background.elevationSolid[0],
              overflowY: 'auto',
            }}
          >
            <StreamChannelList
              filters={filters}
              lockChannelOrder
              Paginator={StreamInfiniteScroll}
              renderChannels={renderChannels}
              setActiveChannelOnMount={!hasMultipleChannels}
              sort={SORT_CREATED_AT_ASC}
            />
          </Box>
        </Box>
        <Stack
          alignItems="center"
          justifyContent="center"
          position="relative"
          sx={{
            background: theme.palette.background.elevation['0'],
            '& .str-chat__channel': {
              width: '100%',
            },
            '& .str-chat__loading-indicator': {
              display: 'none',
            },
            height: '100%',
            // height: {
            //   xs: isMessageInputFocused
            //     ? '100%'
            //     : `calc(100% - ${AD_HEIGHT}px)`,
            //   md: `calc(100% - ${AD_HEIGHT}px)`,
            // },
          }}
          width="100%"
        >
          <StreamChannel
            DateSeparator={DateSeparator}
            EmojiPicker={EmojiPicker}
            Message={Message}
            MessageNotification={ComponentEmpty}
            ThreadHeader={ThreadHeader}
            ThreadStart={ThreadStart}
            TypingIndicator={ComponentEmpty}
            UnreadMessagesNotification={ComponentEmpty}
            UnreadMessagesSeparator={ComponentEmpty}
          >
            <TypingProvider>
              <ChannelInner />
            </TypingProvider>
          </StreamChannel>
          {!!replyingTo && (
            <Box
              sx={{
                background: theme.palette.background.elevation[0],
                zIndex: theme.zIndex.replyBackdrop,
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            />
          )}
        </Stack>
        {/* <Stack
          sx={{
            width: '100%',
            height: `${MESSAGING_AD_HEIGHT}px`,
            background: '#000e21',
            display: {
              xs: isMessageInputFocused ? 'none' : 'flex',
              md: 'flex',
            },
          }}
        >
          <Ad width="100%" height={AD_HEIGHT} id={MESSAGING_AD} />
        </Stack> */}
      </Stack>
    </Stack>
  );
}

export const ChannelGroup = memo(
  withDynamicImport({
    Component: ChannelGroupUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
