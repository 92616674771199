import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { normalizeTab } from '../../util/normalizeTab';
import { memo } from '../../util/memo';
import type { TabsVariantProps } from './TabsRouted';
import { NormalizedChipTabs } from './NormalizedChipTabs';

const TabsChipUnmemoized: FC<TabsVariantProps> = ({
  variant,
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const chipVariant = variant === 'chip-large' ? 'large' : 'small';

  const tabsNormalized = useDeepCompareMemo(() => {
    return tabs.map((tab) => {
      return normalizeTab(tab);
    });
  }, [tabs]);

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      justifyContent="center"
      rowGap={2}
      spacing={2}
      sx={sx}
      width="100%"
    >
      <NormalizedChipTabs
        activeTab={activeTab}
        routeTab={routeTab}
        tabs={tabsNormalized}
        variant={chipVariant}
      />
    </Stack>
  );
};

export const TabsChip = memo(TabsChipUnmemoized);
