import { GridColDef } from '@mui/x-data-grid';
import { useContext, useMemo } from 'react';
import { ResultRanked } from '../../../functions/src/types/firestore/Game/Tournament/Round/Match/Result';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import { useMatchDetails } from '../../contexts/MatchDetailsContext';
import { GameResultCell } from '../../components/tournaments/heats-leaderboards/GameResultCell';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import {
  SCORE_OPTION_OVERWOLF_GAMES,
  SCORE_OPTIONS_DEFAULT,
} from '../../../functions/src/util/in-game/scoreOptionLibrary';
import { assertSafe } from 'functions/src/util/assertSafe';

export const useGameResultColumn = () => {
  const { overwolfGameId } = useContext(TournamentContext);
  const { match, matchDocPath } = useMatchDetails();
  const { resultsAggregation, gameWinThreshold } = match || {};
  const { scoreOptions } = gameWinThreshold || {};
  const scoreOptionsAvailable = useMemo(() => {
    if (scoreOptions) {
      return scoreOptions;
    }
    if (overwolfGameId) {
      return SCORE_OPTION_OVERWOLF_GAMES[assertSafe(overwolfGameId)];
    }
    return SCORE_OPTIONS_DEFAULT;
  }, [overwolfGameId, scoreOptions]);
  const { teamCount = 0 } = resultsAggregation || {};
  const optionsHashed = sortedHash({ scoreOptions });
  const scoreColumns: GridColDef<ResultRanked>[] = useMemo(() => {
    return scoreOptionsAvailable.map(({ name }, index) => {
      return {
        field: name.toLowerCase(),
        headerName: name.toUpperCase(),
        minWidth: 130,
        maxWidth: 350,
        flex: 1,
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        sortable: false,
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        editable: false,
        renderCell: ({ row }) => {
          return (
            <GameResultCell
              matchDocPath={matchDocPath}
              result={row}
              scoreIndex={index}
              teamCount={teamCount}
            />
          );
        },
      } as const;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsHashed, matchDocPath, teamCount]);

  return scoreColumns;
};
