import { useCallback } from 'react';
import { LiveNotificationIcon } from '../notification/LiveNotificationIcon';
import { NotificationsPreview } from '../notification/NotificationsPreview';
import { PopoverWrapper } from '../popover/PopoverWrapper';
import { memo } from '../../util/memo';
import { ANCHOR_ORIGIN_BOTTOM_CENTER } from '../../util/styles/anchorOrigin';
import { TRANSFORM_ORIGIN_TOP_CENTER } from '../../util/styles/transformOrigin';
import { useAuth } from '../../contexts/AuthContext';

export const NOTIFICATION_SIGN_IN_GUARD_TITLE =
  'Please sign in to view notifications.' as const;
export const NOTIFICATION_ARIA_LABEL = 'Notifications' as const;

export const UserNotificationsUnmemoized = () => {
  const { uid } = useAuth();

  const NotificationsPreviewCloseDrilled = useCallback(
    (onClose: () => void) => {
      return <NotificationsPreview onPopoverClose={onClose} />;
    },
    [],
  );

  if (!uid) {
    return null;
  }

  return (
    <PopoverWrapper
      anchorOrigin={ANCHOR_ORIGIN_BOTTOM_CENTER}
      aria-label={NOTIFICATION_ARIA_LABEL}
      guardTitle={NOTIFICATION_SIGN_IN_GUARD_TITLE}
      PopoverChildren={NotificationsPreviewCloseDrilled}
      transformOrigin={TRANSFORM_ORIGIN_TOP_CENTER}
    >
      <LiveNotificationIcon
        sx={{
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      />
    </PopoverWrapper>
  );
};

export const UserNotifications = memo(UserNotificationsUnmemoized);
