/* eslint-disable @blumintinc/blumint/use-custom-router */
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useRouter as originalUseRouter } from 'next/router';
import { memo } from '../../util/memo';
import { segmentsOf } from '../../util/routing/segmentsOf';
import { toSegmentKey } from '../../util/routing/toSegmentKey';
import { useQueue } from '../../hooks/useQueue';
import { useRouterModifyOptimistic } from '../../hooks/routing/useRouterModifyOptimistic';
import { useOptimisticValueUtils } from '../OptimisticValueContext';
import { HttpsError } from '../../../functions/src/util/errors/HttpsError';
import { assertSafe } from '../../../functions/src/util/assertSafe';

export type LocationType = 'queryParam' | 'segment';

export type UrlModification = {
  location: LocationType;
  name: string;
  value?: string;
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  silent?: boolean;
};

export type UrlModificationsContextType = {
  appendModifications: (modifications: UrlModification[]) => void;
  refreshOptimisticValues: () => void;
};

const UrlModificationsContext = createContext<UrlModificationsContextType>(
  null as unknown as UrlModificationsContextType,
);

const UrlModificationsProviderUnmemoized: React.FC<{
  children: JSX.Element;
}> = ({ children }) => {
  const router = originalUseRouter();
  const {
    asPath,
    replace: replaceOriginal,
    pathname: pathnameOriginal,
  } = router;

  const [urlModifications, setUrlModifications] = useState<UrlModification[]>(
    [],
  );

  const keys = useMemo(() => {
    return segmentsOf(pathnameOriginal);
  }, [pathnameOriginal]);

  const values = useMemo(() => {
    return segmentsOf(asPath);
  }, [asPath]);

  const urlParamsOf = useCallback(() => {
    const url = new URL(window.location.href);
    return {
      url,
      params: new URLSearchParams(url.search),
    } as const;
  }, []);

  const replaceBatch = useCallback(
    async (modifications: UrlModification[]) => {
      const { params, url } = urlParamsOf();
      const allSilent = modifications.reduce(
        // eslint-disable-next-line no-shadow
        (allSilent, { location, name, value, silent = false }) => {
          if (location === 'queryParam') {
            // eslint-disable-next-line no-restricted-syntax
            if (value === undefined || value === '') {
              params.delete(name);
            } else {
              params.set(name, value);
            }
          } else if (location === 'segment') {
            const keyIndex = keys.indexOf(toSegmentKey(name, true));

            if (keyIndex !== -1 && keyIndex < keys.length) {
              // eslint-disable-next-line max-depth
              if (value === undefined) {
                values.splice(keyIndex, 2);
              } else {
                values[assertSafe(Number(keyIndex))] =
                  encodeURIComponent(value);
              }
            } else if (value !== undefined) {
              values.push(name, encodeURIComponent(value));
            }
          }
          return silent && allSilent;
        },
        true,
      );

      const paramsStringified = params.toString();
      // eslint-disable-next-line no-restricted-syntax
      const queryStringified = paramsStringified ? `?${paramsStringified}` : '';
      const newUrl = `${url.pathname}${queryStringified}`;

      if (allSilent) {
        window.history.replaceState(
          {},
          // eslint-disable-next-line no-restricted-syntax
          '',
          newUrl,
        );
        return;
      }

      await replaceOriginal(values.join('/'), newUrl, {
        // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
        shallow: true,
      });
    },
    [urlParamsOf, replaceOriginal, values, keys],
  );

  const { enqueue } = useQueue();
  useEffect(() => {
    if (urlModifications.length > 0) {
      const task = () => {
        return replaceBatch(urlModifications);
      };
      enqueue(task);
      setUrlModifications([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceBatch, urlModifications]);

  const modifyOptimistic = useRouterModifyOptimistic();
  const { clearAllOptimisticValues } = useOptimisticValueUtils();

  const appendModifications = useCallback(
    (modifications: UrlModification[]) => {
      setUrlModifications((current) => {
        return [...current, ...modifications];
      });
      modifyOptimistic(...modifications);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const refreshOptimisticValues = useCallback(() => {
    clearAllOptimisticValues();

    const { params } = urlParamsOf();
    for (const [key, value] of params.entries()) {
      modifyOptimistic({
        location: 'queryParam',
        name: key,
        value,
      });
    }

    for (let i = 0; i < keys.length; i++) {
      const key = keys[assertSafe(i)];
      const value = values[assertSafe(i)];
      const segmentKey = toSegmentKey(key, true);
      modifyOptimistic({
        location: 'segment',
        name: segmentKey,
        value,
      });
    }
  }, [clearAllOptimisticValues, keys, modifyOptimistic, urlParamsOf, values]);

  const valueMemoized = useMemo(() => {
    return {
      appendModifications,
      refreshOptimisticValues,
    } as const;
  }, [appendModifications, refreshOptimisticValues]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  return (
    <UrlModificationsContext.Provider value={valueMemoized}>
      {children}
    </UrlModificationsContext.Provider>
  );
};

export const UrlModificationsProvider = memo(
  UrlModificationsProviderUnmemoized,
);

export const useUrlModifications = () => {
  const context = useContext(UrlModificationsContext);
  if (!context) {
    throw new HttpsError(
      'failed-precondition',
      'useUrlModifications must be used within a UrlModificationsProvider',
    );
  }
  return useMemo(() => {
    return context;
  }, [context]);
};
