export const USER_COLLECTION = 'User' as const;
export const USER_PATH = 'User/{userId}' as const;

export type UserPath = typeof USER_PATH;

export const toUserPath = (userId: string) => {
  return USER_PATH.replace('{userId}', userId);
};

export const toUserCollection = () => {
  return `User/`;
};
