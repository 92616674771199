import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { Fragment } from 'react';
import { memo } from '../util/memo';
import { LoadingWrapperProps } from './LoadingWrapper';
import { LottieLoader } from './LottieLoader';

export type PageLoadingWrapperProps = LoadingWrapperProps;

export const PageLoadingWrapper = memo(function PageLoadingWrapperUnmemoized({
  isLoading,
  sx,
  children,
  ...rest
}: PageLoadingWrapperProps) {
  const theme = useTheme();
  return (
    <Fragment>
      {children}
      {!!isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: theme.palette.background.elevation[1],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...sx,
          }}
        >
          <LottieLoader sx={{ height: '40px', width: '40px' }} {...rest} />
        </Box>
      )}
    </Fragment>
  );
});
