import type { SvgIconProps } from '@mui/material/SvgIcon';
import type { FunctionComponent } from 'react';
// import XIcon from '@mui/icons-material/Twitter';
// eslint-disable-next-line @blumintinc/blumint/enforce-mui-rounded-icons
import GoogleIcon from '@mui/icons-material/Google';
// eslint-disable-next-line @blumintinc/blumint/enforce-mui-rounded-icons
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
// eslint-disable-next-line @blumintinc/blumint/enforce-mui-rounded-icons
import EmailOutlinedIcon from '@mui/icons-material/MailOutline';
// import WalletIcon from 'src/components/icons/WalletIcon';
// eslint-disable-next-line @blumintinc/blumint/enforce-mui-rounded-icons
import AppleIcon from '@mui/icons-material/Apple';
// import FacebookIcon from '@mui/icons-material/FacebookRounded';
import { EpicIcon } from '../../components/icons/EpicIcon';
import { TwitchIcon } from '../../components/icons/TwitchIcon';
// import { useAuth } from '../../contexts/AuthContext';
import { AllSignInMethod } from '../../../functions/src/types/firestore/User';
import { DiscordIcon } from 'src/components/icons/DiscordIcon';
// import { FractalIcon } from '../../components/icons/FractalIcon';

export const useAuthMethodsDisplay = () => {
  // const { uid, userData } = useAuth();
  const authOptionsDisplay: {
    [announcementType in AllSignInMethod]: {
      icon: FunctionComponent<SvgIconProps>;
      continueWithMessage?: string;
    };
  } = {
    discord: {
      icon: DiscordIcon,
    },
    // twitter: {
    //   icon: XIcon,
    // },
    google: {
      icon: GoogleIcon,
    },
    phone: {
      icon: LocalPhoneOutlinedIcon,
      continueWithMessage: 'Continue with Phone',
    },
    password: {
      icon: EmailOutlinedIcon,
      continueWithMessage: 'Continue with Email',
    },
    // wallet: {
    //   icon: WalletIcon,
    //   continueWithMessage: `Connect${
    //     !!uid && !!userData?.addresses?.length ? ' new' : ''
    //   } wallet`,
    // },
    apple: {
      icon: AppleIcon,
    },
    // facebook: {
    //   icon: FacebookIcon,
    // },
    epic: {
      icon: EpicIcon,
    },
    twitch: {
      icon: TwitchIcon,
    },
    // fractal: {
    //   icon: FractalIcon,
    // },
  };
  return authOptionsDisplay;
};
