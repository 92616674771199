import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import type { DateSeparatorProps as StreamDateSeparatorProps } from 'stream-chat-react';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import { WEEKDAY_NOZONE_DASH } from '../../util/dates/presets/dateTimes';
import { formatDateTime } from 'src/util/dates/Dates';

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
const DateSeparatorUnmemoized = (props: StreamDateSeparatorProps) => {
  const { date } = props;
  const theme = useTheme();
  const dateFormatted = formatDateTime({
    date,
    optionsDefault: WEEKDAY_NOZONE_DASH,
    dateOverrides: ['Today', 'Yesterday', 'Tomorrow'],
  });

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      <Divider
        sx={{
          flex: 1,
        }}
      />
      <Typography color={theme.palette.text.border} px={2} variant="overline">
        {dateFormatted}
      </Typography>
      <Divider
        sx={{
          flex: 1,
        }}
      />
    </Stack>
  );
};

// eslint-disable-next-line @blumintinc/blumint/no-hungarian
export const DateSeparator = memo(DateSeparatorUnmemoized);
