import GroupAddIcon from '@mui/icons-material/GroupAddRounded';
import type { Channel as StreamChannel } from 'stream-chat';
import { useMemo, useCallback, Fragment } from 'react';
import { Button } from '@mui/material';
import dynamic from 'next/dynamic';
import { useUnreadMessageCount } from '../../../contexts/UnreadMessageCountContext';
import { memo } from '../../../util/memo';
import { useDialog } from '../../../hooks/useDialog';
import { GroupChannelCreationDialog } from '../../tournaments/registration/dialogs/GroupChannelCreateDialog';
import { useSubgroupPermissions } from '../../../hooks/group/useSubgroupPermissions';
import { useGroupRouterExternal } from '../../../hooks/group/useGroupRouterExternal';
import type { ChannelGroupProps } from '../channel-group/ChannelGroup';
import { assertSafe } from '../../../../functions/src/util/assertSafe';
import { ChannelAccordion } from './ChannelAccordion';

const ChannelPreview = dynamic(async () => {
  const mod = await import('./ChannelPreview.dynamic');
  return mod.ChannelPreview;
});

export const NO_GROUPS = 'No Group' as const;
export const GENERAL = 'general' as const;

export type ChannelsProps = Pick<
  ChannelGroupProps,
  'sortGroups' | 'sortChannels'
> & {
  channels: StreamChannel[];
  channelGroupId: string;
  onClick: () => void;
};

const ChannelsUnmemoized = ({
  channels,
  onClick,
  sortGroups,
  sortChannels,
  channelGroupId,
}: ChannelsProps) => {
  const { unreadMessageCount, unionChannel } = useUnreadMessageCount();
  const { groupId: groupIdRouter } = useGroupRouterExternal();
  const subgroupPermissions = useSubgroupPermissions(groupIdRouter);

  const canManageChannels =
    groupIdRouter && subgroupPermissions.includes('manageChannels');

  const { open: openChannelCreationDialog, close: closeChannelCreationDialog } =
    useDialog('groupChannelCreation');

  const groupedChannels = useMemo(() => {
    const groups: Record<string, StreamChannel[]> = {};
    for (const channel of channels) {
      const { data } = channel;
      const groupName = data?.groupName ? String(data.groupName) : NO_GROUPS;

      if (!groups[assertSafe(groupName)]) {
        groups[assertSafe(groupName)] = [];
      }
      groups[assertSafe(groupName)].push(channel);
    }

    if (sortChannels) {
      // eslint-disable-next-line no-restricted-properties
      for (const groupName of Object.keys(groups)) {
        groups[assertSafe(groupName)].sort((channelA, channelB) => {
          return sortChannels({ channelA, channelB });
        });
      }
    }

    return groups;
  }, [channels, sortChannels]);

  const sortedGroups = useMemo(() => {
    // eslint-disable-next-line no-restricted-properties
    const groupNames = Object.keys(groupedChannels);
    groupNames.sort((groupNameA, groupNameB) => {
      return sortGroups({ groupNameA, groupNameB });
    });
    return groupNames;
  }, [groupedChannels, sortGroups]);

  const openDialog = useCallback(() => {
    return openChannelCreationDialog({
      children: (
        <GroupChannelCreationDialog
          onCancel={closeChannelCreationDialog}
          onSubmit={closeChannelCreationDialog}
        />
      ),
    });
  }, [openChannelCreationDialog, closeChannelCreationDialog]);

  // eslint-disable-next-line @blumintinc/blumint/react-usememo-should-be-component
  const channelList = useMemo(() => {
    return sortedGroups.map((groupName) => {
      const groupChannels = groupedChannels[assertSafe(groupName)];
      const groupId = `${groupName}:${channelGroupId}`;
      const unreadCount = unreadMessageCount[assertSafe(groupId)];

      const isSpecialGroup = groupName === GENERAL || groupName === NO_GROUPS;

      const channelPreviews = groupChannels.map((channel) => {
        const { cid, state } = channel;
        unionChannel(channel, channelGroupId);

        return (
          <ChannelPreview
            key={cid}
            channel={channel}
            unreadCount={state.unreadCount || 0}
            onClick={onClick}
          />
        );
      });

      if (isSpecialGroup) {
        return channelPreviews;
      }

      return (
        <ChannelAccordion
          key={groupName}
          groupId={groupId}
          title={groupName}
          // eslint-disable-next-line @blumintinc/blumint/no-always-true-false-conditions
          unreadCount={unreadCount?.total || 0}
        >
          {channelPreviews}
        </ChannelAccordion>
      );
    });
  }, [
    // eslint-disable-next-line @blumintinc/blumint/no-entire-object-hook-deps
    sortedGroups,
    groupedChannels,
    channelGroupId,
    unreadMessageCount,
    unionChannel,
    onClick,
  ]);

  return (
    <Fragment>
      {channelList}
      {!!canManageChannels && (
        <Button
          fullWidth
          startIcon={<GroupAddIcon />}
          sx={{ pt: 1 }}
          onClick={openDialog}
        >
          Create Custom Channel
        </Button>
      )}
    </Fragment>
  );
};

export const Channels = memo(ChannelsUnmemoized);
