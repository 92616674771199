import {
  ChannelGroup,
  GroupFilterMap,
  ChannelGroupUser,
} from '../../types/firestore/User/ChannelGroup';
import { isSupport } from '../../types/firestore/User/ChannelGroup/util/isSupport';
import { isTournament } from '../../types/firestore/User/ChannelGroup/util/isTournament';
import { isDm } from '../../types/firestore/User/ChannelGroup/util/isDm';
import { AvatarGroupEntity } from '../../types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { isGroup } from '../../types/firestore/User/ChannelGroup/util/isGroup';
import { isPersonalGroup } from '../../types/firestore/User/ChannelGroup/util/isPersonalGroup';

export type ActiveChannelGroup = Omit<
  ChannelGroup<keyof GroupFilterMap, Date>,
  'imgUrl'
> & {
  avatarEntities: AvatarGroupEntity[];
  isDm: boolean;
  isSupport: boolean;
  isTournament: boolean;
  membersWithoutSelf?: ChannelGroupUser[];
  avatarGroupTotal: number;
};

export const toActiveChannelGroup = (
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>,
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type, @blumintinc/blumint/no-type-assertion-returns
): ActiveChannelGroup => {
  const { imgUrl, id, ...rest } = channelGroup;
  const { mainUserId, members } = rest;

  const isDmChannel = isDm(channelGroup);
  const isSupportChannel = isSupport(channelGroup);
  const isTournamentChannel = isTournament(channelGroup);
  const isGroupChannel = isGroup(channelGroup);
  const isPersonalGroupChannel = isPersonalGroup(channelGroup);

  const endIndex = members && members.length > 3 ? 1 : 2;

  const membersWithoutSelf = (members || []).filter((member) => {
    return member.userId !== mainUserId;
  });

  const avatarEntities =
    isPersonalGroupChannel || isDmChannel
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        membersWithoutSelf!.slice(0, endIndex).map(({ image }) => {
          return { id, imgUrl: image } as const;
        })
      : [{ imgUrl, id: isGroupChannel ? id : undefined }];

  const avatarGroupTotal = isPersonalGroupChannel
    ? membersWithoutSelf?.length
    : 1;

  return {
    ...rest,
    id,
    avatarEntities,
    membersWithoutSelf,
    isDm: isDmChannel,
    isSupport: isSupportChannel,
    isTournament: isTournamentChannel,
    avatarGroupTotal,
  };
};
