import { useCallback } from 'react';
import type { UrlModification } from '../../contexts/routing/UrlModificationsContext';
import { useOptimisticValueUtils } from '../../contexts/OptimisticValueContext';
import { toRouterOptimisticKey } from '../../util/routing/toRouterOptimisticKey';

export const useRouterModifyOptimistic = () => {
  const { updateOptimisticValue } = useOptimisticValueUtils();

  return useCallback(
    (
      ...modifications: Pick<UrlModification, 'name' | 'location' | 'value'>[]
    ) => {
      for (const { name, location, value } of modifications) {
        updateOptimisticValue(
          toRouterOptimisticKey({ key: name, location }),
          value || null,
        );
      }
    },
    [updateOptimisticValue],
  );
};
