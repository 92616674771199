import Stack from '@mui/material/Stack';
import { useState, useMemo, useCallback } from 'react';
import { isOptionallyPrefixedUrl } from '../../../../functions/src/util/isOptionallyPrefixedUrl';
import {
  ActionButtonProps,
  DialogActionsStandard,
} from '../../dialog/DialogActionsStandard';
import { prefixUrl } from '../../../../functions/src/util/prefixUrl';
import { TextFieldEdit } from '../edit-component/TextFieldEdit';
import { memo } from 'src/util/memo';

export const VALIDATING_PLACEHOLDER = 'Validating...' as const;

/**
 * @param url The URL to validate
 * @returns true if the URL is valid, or an error message if it is not
 */
export type ValidateUrl = (url: string) => Promise<true | string>;

export const INVALID_URL_ERROR = 'Please enter a valid URL.' as const;
export const EMPTY_URL_ERROR = 'Please enter a URL.' as const;

export const isValid: ValidateUrl = () => {
  return Promise.resolve(true);
};

export type EditLinkDialogBodyProps<TUrl extends string = string> = {
  initialUrl?: TUrl;
  onSave: (url: TUrl) => Promise<void> | void;
  onClose?: () => void;
  /**
   * If a ValidateUrl function, any URL that passes validation will be accepted.
   * If an array, only the URLs in the array will be accepted.
   * Defaults to accepting any valid URL.
   */
  urlOptions?: ValidateUrl | TUrl[];
};

function EditLinkDialogBodyUnmemoized<TUrl extends string = string>({
  initialUrl,
  onSave,
  onClose,
  urlOptions = isValid,
}: EditLinkDialogBodyProps<TUrl>) {
  const [url, setUrl] = useState(initialUrl);
  const [hasError, setHasError] = useState(false);

  const validateUrl = useCallback(
    async (unvalidatedUrl?: string) => {
      // eslint-disable-next-line no-restricted-syntax
      if (unvalidatedUrl === '' || unvalidatedUrl === undefined) {
        return EMPTY_URL_ERROR;
      }
      if (!isOptionallyPrefixedUrl(unvalidatedUrl)) {
        return INVALID_URL_ERROR;
      }

      // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
      return await (urlOptions as ValidateUrl)(unvalidatedUrl);
    },
    [urlOptions],
  );

  const options = useMemo(() => {
    if (Array.isArray(urlOptions)) {
      return urlOptions;
    }
    return validateUrl;
  }, [urlOptions, validateUrl]);

  const urlValidated = useMemo(() => {
    if (!url) {
      return url;
    }
    // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
    return prefixUrl(url) as TUrl;
  }, [url]);

  const updateUrl = useCallback((newValue?: string) => {
    // eslint-disable-next-line @blumintinc/blumint/no-type-assertion-returns
    return Promise.resolve(setUrl(newValue as TUrl));
  }, []);

  const updateHasError = useCallback((error: string | false) => {
    setHasError(!!error);
  }, []);

  const actionButtons = useMemo(() => {
    const props: ActionButtonProps[] = [
      {
        children: 'Cancel',
        onClick: onClose,
        isAsync: false,
        color: 'primary',
      },
      {
        children: 'Save',
        onClick: async () => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          await onSave(urlValidated!);
          onClose?.();
        },
        disabled: hasError,
        isAsync: true,
        color: 'primary',
      },
    ];
    return props;
  }, [onClose, urlValidated, onSave, hasError]);

  return (
    <Stack spacing={2} sx={{ pt: 2 }}>
      <TextFieldEdit<TUrl>
        options={options}
        type="url"
        value={initialUrl}
        onChange={updateUrl}
        onError={updateHasError}
      />
      <DialogActionsStandard buttons={actionButtons} />
    </Stack>
  );
}

export const EditLinkDialogBody = memo(
  EditLinkDialogBodyUnmemoized,
) as typeof EditLinkDialogBodyUnmemoized;
