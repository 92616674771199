import { useEffect, useCallback } from 'react';
import { ChannelFetcher } from '../../../functions/src/util/messaging/ChannelFetcher';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useChannelList } from '../../contexts/ChannelListContext';
import {
  StreamChatReact,
  DynamicModule,
} from '../../../functions/src/types/DynamicModule';
import { useDynamic } from '../useDynamic';

export type UseActiveChannelProps = {
  streamChatModule: DynamicModule[StreamChatReact];
};

export const useActiveChannel = ({
  streamChatModule,
}: UseActiveChannelProps) => {
  const { useChatContext } = streamChatModule;
  const { setActiveChannel } = useChatContext();

  const { showChannelList, hideChannelList } = useChannelList();
  const { channelGroupActive, channelId } = useActiveChannelGroup();

  const streamChatFrontendModule = useDynamic(
    import('../../config/get-stream/streamChatFrontend'),
  );
  const { streamChatFrontend } = streamChatFrontendModule || {};

  const fetchAndSetActiveChannel = useCallback(async () => {
    if (!streamChatFrontend?.user) {
      return;
    }

    if (!channelId) {
      setActiveChannel(undefined);
      return showChannelList();
    }

    const channelFetcher = new ChannelFetcher(streamChatFrontend);
    const channelNew = await channelFetcher.fetch(channelId);

    if (!channelNew) {
      setActiveChannel(undefined);
      return showChannelList();
    }

    setActiveChannel(channelNew);
    hideChannelList();
  }, [
    channelId,
    streamChatFrontend,
    hideChannelList,
    setActiveChannel,
    showChannelList,
  ]);

  useEffect(() => {
    const setActiveChannelGuarded = async () => {
      if (!channelGroupActive?.hasMultipleChannels) {
        return;
      }

      await fetchAndSetActiveChannel();
    };

    setActiveChannelGuarded();
  }, [fetchAndSetActiveChannel, channelGroupActive?.hasMultipleChannels]);
};
