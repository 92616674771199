import Stack from '@mui/material/Stack';
import { useMemo, Fragment } from 'react';
import { memo } from '../../../util/memo';
import { useChannelList } from '../../../contexts/ChannelListContext';
import { GradientTypography } from '../../gradients/GradientTypography';
import { ELLIPSIS_SX } from '../../../util/string';
import { SCORING_BUTTON_MAX_WIDTH } from '../active-match/ActiveMatchButton';
import {
  SIDEBAR_WIDTH_MOBILE,
  SOCIAL_DRAWER_WIDTH_INTERNAL,
} from '../../SideDrawerToggle';
import { withDynamicImport } from '../../../util/withDynamicImport';
import {
  STREAM_CHAT_REACT,
  DynamicImport,
  StreamChatReact,
} from '../../../../functions/src/types/DynamicModule';
import { assertSafe } from '../../../../functions/src/util/assertSafe';

export const CHANNEL_HEADER_HEIGHT = 48 as const;
export const NAVIGATION_ARROW_WIDTH = 64 as const;

export type ChannelHeaderTitleProps = {
  modules: DynamicImport<[StreamChatReact]>;
};

const ChannelHeaderTitleUnmemoized = ({ modules }: ChannelHeaderTitleProps) => {
  const { useChatContext } = modules[assertSafe(STREAM_CHAT_REACT)];

  const { channel } = useChatContext();
  const { isChannelListVisible } = useChannelList();

  const title = useMemo(() => {
    const channelName = channel?.data?.name;
    if (isChannelListVisible) {
      return 'SELECT CHAT';
    }
    if (!channelName) {
      return;
    }
    return `${channelName} CHAT`;
  }, [channel?.data?.name, isChannelListVisible]);

  return (
    <Fragment>
      {!!title && (
        <Stack alignItems="center" direction="row" spacing={1}>
          <GradientTypography
            gradientColor="primary.vertical"
            sx={{
              lineHeight: `${CHANNEL_HEADER_HEIGHT - 14}px`,
              maxWidth: {
                xs: `calc(100vw - ${
                  SIDEBAR_WIDTH_MOBILE +
                  NAVIGATION_ARROW_WIDTH +
                  SCORING_BUTTON_MAX_WIDTH
                }px)`,
                md: `${
                  SOCIAL_DRAWER_WIDTH_INTERNAL -
                  NAVIGATION_ARROW_WIDTH -
                  SCORING_BUTTON_MAX_WIDTH
                }px`,
              },
              ...ELLIPSIS_SX,
            }}
            textTransform="uppercase"
            variant="subtitle1"
          >
            {title}
          </GradientTypography>
        </Stack>
      )}
    </Fragment>
  );
};

export const ChannelHeaderTitle = memo(
  withDynamicImport({
    Component: ChannelHeaderTitleUnmemoized,
    moduleNames: [STREAM_CHAT_REACT],
  }),
);
