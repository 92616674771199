import { FC } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import Box from '@mui/material/Box';
import { useDeepCompareMemo } from '@blumintinc/use-deep-compare';
import { normalizeTab } from '../../util/normalizeTab';
import { memo } from '../../util/memo';
import type { TabsVariantProps } from './TabsRouted';

export const BUTTON_MAX_WIDTH = 304 as const;

const TabsButtonGroupUnmemoized: FC<TabsVariantProps> = ({
  tabs,
  activeTab,
  routeTab,
  sx,
}) => {
  const tabsNormalized = useDeepCompareMemo(() => {
    return tabs.map((tab) => {
      return normalizeTab(tab);
    });
  }, [tabs]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <ToggleButtonGroup
        exclusive
        size="small"
        sx={{
          width: '100%',
          maxWidth: `calc(${tabsNormalized.length} * ${BUTTON_MAX_WIDTH}px)`,
        }}
        value={activeTab}
        onChange={routeTab}
      >
        {tabsNormalized.map(({ id, value, sx: sxTab, customization }) => {
          return (
            <ToggleButton
              key={value}
              id={id}
              sx={{
                ...sxTab,
                textTransform: 'uppercase',
                width: '100%',
                maxWidth: BUTTON_MAX_WIDTH,
              }}
              value={value}
              {...customization}
            >
              {customization?.label || value}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};
export const TabsButtonGroup = memo(TabsButtonGroupUnmemoized);
