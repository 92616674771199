import { GroupFilter } from '../../types/firestore/User/ChannelGroup';
import { sortedHash } from '../hash/sortedHash';
import { isPlaybackFilter } from '../../types/firestore/User/ChannelGroup/util/isPlayback';

export type ToChannelGroupPathSpecifiedParams = {
  userId: string;
  documentId: string;
};

export const toChannelGroupPath = (
  userId: string,
  groupFilter: GroupFilter,
) => {
  if (isPlaybackFilter(groupFilter)) {
    return toChannelGroupPathSpecified({
      userId,
      documentId: groupFilter[0].playbackId,
    });
  }

  const documentId = toChannelGroupId(groupFilter);
  return `User/${userId}/ChannelGroup/${documentId}`;
};

export const toChannelGroupPathSpecified = ({
  userId,
  documentId,
}: ToChannelGroupPathSpecifiedParams) => {
  return `User/${userId}/ChannelGroup/${documentId}`;
};

export const toChannelGroupId = (groupFilter: GroupFilter) => {
  return sortedHash(groupFilter[0]);
};
