/* eslint-disable @typescript-eslint/no-explicit-any */
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import {
  ChangeEvent,
  MouseEvent,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { memo } from '../../util/memo';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';

export type InputPropsAsync = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange'
> & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export type ButtonPropsAsync = Omit<ButtonProps, 'onClick'> & {
  // eslint-disable-next-line @blumintinc/blumint/no-unused-props
  onClick?: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => Promise<any>;
};

export type LoadingButtonProps = ButtonPropsAsync & {
  inputProps?: InputPropsAsync;
  isLoading?: boolean;
  loadingTitle?: string;
};

const LoadingButtonUnmemoized = ({
  isLoading: isLoadingExternal,
  inputProps,
  loadingTitle,
  ...props
}: LoadingButtonProps) => {
  const { onClick, color, children, sx: sxExternal } = props;

  const [isLoadingHandler, asyncHandler] = useLoadingHandler<any>(
    inputProps?.onChange || onClick,
  );
  const isLoading = isLoadingExternal || isLoadingHandler;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [buttonWidth, setButtonWidth] = useState(0);

  useLayoutEffect(() => {
    if (!buttonRef.current) {
      return;
    }

    const { width } = buttonRef.current.getBoundingClientRect();
    setButtonWidth(width);
  }, []);

  const propsLoading: ButtonProps = {
    ...props,
    endIcon: undefined,
    startIcon: undefined,
    children: undefined,
  };
  const propsConditional = isLoading ? propsLoading : props;
  const propsWithComponent: ButtonProps = {
    ...propsConditional,
    component: inputProps ? 'label' : undefined,
  };

  return (
    <Button
      {...propsWithComponent}
      ref={buttonRef}
      sx={{
        ...sxExternal,
        minWidth: `${buttonWidth}px`,
      }}
      onClick={inputProps ? undefined : asyncHandler}
    >
      {isLoading ? (
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          justifyContent="center"
          width="100%"
        >
          {loadingTitle}
          <CircularProgress color={color} size="1.5rem" />
        </Stack>
      ) : (
        <span style={{ lineHeight: 1.5 }}>{children}</span>
      )}
      {!!inputProps && <input {...inputProps} />}
    </Button>
  );
};
export const LoadingButton = memo(LoadingButtonUnmemoized);
