import { FC, useCallback, ReactNode } from 'react';
import { useDeepCompareEffect } from '@blumintinc/use-deep-compare';
import { memo } from '../../util/memo';
import {
  HorizontalCarousel,
  HorizontalCarouselProps,
} from '../algolia/catalogs/generic/HorizontalCarousel';
import { useRenderHits } from '../../hooks/algolia/useRenderHits';
import { useLivestreamMode } from '../../hooks/mux-player/useLivestreamMode';
import { useVideo } from '../../contexts/video/VideoContext';
import { assertSafe } from '../../../functions/src/util/assertSafe';
import { LivestreamCard } from './LivestreamCard';
import { LivestreamHit } from 'functions/src/types/firestore/User/Livestream';

export type LivestreamHorizontalCarouselProps = Omit<
  HorizontalCarouselProps<LivestreamHit>,
  'content'
> & {
  hits: LivestreamHit[];
  noHits?: ReactNode;
  RenderLivestreamHit?: FC<LivestreamHit>;
};

const LivestreamHorizontalCarouselUnmemoized: FC<
  LivestreamHorizontalCarouselProps
> = ({
  hits,
  noHits,
  RenderLivestreamHit = LivestreamCard,
  isLoading,
  ...props
}) => {
  const { replaceHasLivestream, replaceViewingPlaybackId } = useVideo();

  useDeepCompareEffect(() => {
    if (isLoading) {
      return;
    }
    replaceHasLivestream(hits.length > 0);
  }, [hits, isLoading, replaceHasLivestream]);

  const renderHit = useCallback(
    (hit: LivestreamHit) => {
      return <RenderLivestreamHit {...hit} />;
    },
    [RenderLivestreamHit],
  );

  const content = useRenderHits({
    hits,
    render: renderHit,
    noResultsSearchless: noHits,
  });

  const setPlaybackIdRouter = useCallback(
    (cursor: number) => {
      if (hits[assertSafe(cursor)]) {
        replaceViewingPlaybackId(hits[assertSafe(cursor)].id);
      }
    },
    [hits, replaceViewingPlaybackId],
  );

  const { isPreviewPlayerMode } = useLivestreamMode();
  if (hits.length === 0 && !isPreviewPlayerMode) {
    return null;
  }

  return (
    <HorizontalCarousel
      {...props}
      content={content}
      exactWidth={false}
      isLoading={isLoading}
      slidesToShow={1}
      onCursorChange={setPlaybackIdRouter}
    />
  );
};

export const LivestreamHorizontalCarousel = memo(
  LivestreamHorizontalCarouselUnmemoized,
);
