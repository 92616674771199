import Badge, { BadgeProps } from '@mui/material/Badge';
import type { Required } from 'utility-types';
import { memo } from '../../util/memo';
import { useStatusColor } from '../../hooks/status/useStatusColor';
import { ANCHOR_ORIGIN_BOTTOM_RIGHT } from '../../util/styles/anchorOrigin';

export type StatusBadgeProps = Required<BadgeProps, 'color'>;

export const StatusBadge = memo(function StatusBadgeUnmemoized({
  color: colorOrStatus,
  sx,
  ...badgeProps
}: StatusBadgeProps) {
  const color = useStatusColor(colorOrStatus);

  return (
    <Badge
      anchorOrigin={ANCHOR_ORIGIN_BOTTOM_RIGHT}
      overlap="circular"
      variant="dot"
      {...badgeProps}
      color={color}
      sx={{
        ...sx,
      }}
    />
  );
});
