import { Stack } from '@mui/material';
import {
  GradientTypography,
  GradientTypographyProps,
} from '../../gradients/GradientTypography';
import { ELLIPSIS_SX } from '../../../util/string';
import { memo } from '../../../util/memo';
import { User } from '../../../../functions/src/types/firestore/User';
import { UserCardStatus, UserCardStatusProps } from './UserCardStatus';

export const MAX_WIDTH_USERNAME = '248px' as const;

export type UsernameStatusProps = Pick<User, 'status' | 'username'> &
  GradientTypographyProps & {
    statusProps?: Omit<UserCardStatusProps, 'status'>;
  };

const UsernameStatusUnmemoized = ({
  username,
  status,
  sx,
  maxWidth = MAX_WIDTH_USERNAME,
  statusProps,
  ...usernameProps
}: UsernameStatusProps) => {
  return (
    <Stack spacing={1}>
      <GradientTypography
        sx={{ ...ELLIPSIS_SX, maxWidth, ...sx }}
        variant="subtitle1"
        {...usernameProps}
      >
        {username}
      </GradientTypography>
      <UserCardStatus status={status} {...statusProps} />
    </Stack>
  );
};

export const UsernameStatus = memo(UsernameStatusUnmemoized);
