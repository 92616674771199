import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';
import { useIndexingHits } from '../../../contexts/algolia/IndexingHitsContext';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
import { convertToHash } from '../../../../functions/src/util/convertToHash';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { FriendHit } from '../../../../functions/src/types/firestore/Friendship';
import { ResponseButtons } from '../../ResponseButtons';
import { acceptShipRequest } from '../../../util/ship/acceptShipRequest';
import { rejectShipRequest } from '../../../util/ship/rejectShipRequest';
import { FriendCardLayout } from './FriendCardLayout';

export type FriendRequestCardProps = FriendHit;

const FriendCardRequestUnmemoized = (hit: FriendRequestCardProps) => {
  const { uid } = useAuth();
  const { remove, add } = useIndexingHits();
  const { objectID, sender, receiver, mutualFriendsCountEstimate = 0 } = hit;
  const friendToRender = sender.id === uid ? receiver : sender;
  const { id } = friendToRender;

  const acceptRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    await acceptShipRequest({
      userId: uid,
      groupId: id,
      variant: 'friendship',
    });
    const friendLayout = convertToHash(
      fillTemplate({
        template: REALTIME_CONFIGURATION_TEMPLATES['friend'],
        placeholderValue: uid,
      }),
    );
    const friendRequestLayout = convertToHash(
      fillTemplate({
        template: REALTIME_CONFIGURATION_TEMPLATES['friendRequest'],
        placeholderValue: uid,
      }),
    );
    remove(friendRequestLayout, objectID);
    add(friendLayout, hit);
  }, [hit, objectID, uid, id, add, remove]);

  // eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
  const denyRequest = useCallback(async () => {
    if (!uid) {
      return;
    }
    await rejectShipRequest({
      userId: uid,
      groupId: id,
      variant: 'friendship',
    });
    const friendRequestLayout = convertToHash(
      fillTemplate({
        template: REALTIME_CONFIGURATION_TEMPLATES['friendRequest'],
        placeholderValue: uid,
      }),
    );
    remove(friendRequestLayout, objectID);
  }, [objectID, uid, id, remove]);

  return (
    <FriendCardLayout
      {...friendToRender}
      mutualFriendsCountEstimate={mutualFriendsCountEstimate}
    >
      <ResponseButtons onAccept={acceptRequest} onReject={denyRequest} />
    </FriendCardLayout>
  );
};

export const FriendCardRequest = memo(FriendCardRequestUnmemoized);
