import type {
  Channel,
  ChannelFilters,
  ChannelSort,
  StreamChat,
} from 'stream-chat';
import { GroupFilter } from '../../types/firestore/User/ChannelGroup';
import { toChannelFilters } from './channel/toChannelFilters';
import { withGetStreamMembers } from './mapId';

export const QUERY_LIMIT = 30 as const;

export class ChannelFetcher {
  // private assertChannelId(
  //   channel: Channel,
  // ): asserts channel is Channel & { id: string } {
  //   if (channel.id === undefined) {
  //     throw new HttpsError(
  //       'invalid-argument',
  //       'Channel has no id',
  //       JSON.stringify(this.channel),
  //     );
  //   }
  // }

  public constructor(private readonly client: StreamChat) {}

  public static containsEvery$In(
    filters: ChannelFilters,
    { state }: Pick<Channel, 'state'>,
  ) {
    const { members } = state;
    const memberIds = Object.keys(members);
    if (memberIds.length === 0) {
      return true;
    }

    const inIds = ChannelFetcher.inIds(filters);
    if (inIds.length === 0) {
      return true;
    }

    return inIds.every((inId) => {
      return inId && memberIds.includes(inId);
    });
  }

  private static inIds({
    $or,
    members,
  }: Pick<ChannelFilters, '$or' | 'members'>) {
    if ($or?.length) {
      return $or
        ?.map(({ members: partMembers }) => {
          if (!partMembers || !('$in' in partMembers)) {
            return [];
          }
          return partMembers.$in || [];
        })
        .flat();
    }
    if (members && '$in' in members) {
      return members.$in || [];
    }
    return [];
  }

  public async fetch(channelId: string) {
    const channels = await this.query({
      cid: channelId,
    });
    return channels[0];
  }

  protected async query(filterConditions: ChannelFilters) {
    const filterConditionsConverted = withGetStreamMembers(filterConditions);
    return await this.queryAll(filterConditionsConverted);
  }

  private async queryAll(filters: ChannelFilters) {
    const queryChannelsRecursive = async (
      offset: number,
      channelsAll: Channel[] = [],
    ) => {
      const channels = await this.client.queryChannels(
        filters,
        ChannelFetcher.sort(),
        { ...ChannelFetcher.options(), offset },
      );

      const channelsCurrent = [...channelsAll, ...channels];

      if (channels.length < QUERY_LIMIT) {
        return channelsCurrent;
      }

      return queryChannelsRecursive(offset + channels.length, channelsCurrent);
    };

    return await queryChannelsRecursive(0);
  }

  // Explicit return type is required due to recurison
  // eslint-disable-next-line @blumintinc/blumint/no-explicit-return-type
  private static sort(): ChannelSort {
    return { created_at: 1 };
  }

  // eslint-disable-next-line @blumintinc/blumint/enforce-verb-noun-naming
  private static options() {
    return {
      limit: QUERY_LIMIT,
    };
  }

  public async fetchGroup(groupFilter: GroupFilter) {
    return await this.fetchMatching(toChannelFilters(groupFilter));
  }

  public async fetchMatching(filterConditions: ChannelFilters) {
    return await this.query(filterConditions);
  }
}
