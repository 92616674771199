import { memo } from '../../../util/memo';
import { Link } from '../../Link';
import { MenuItemTruncated } from '../../menu/MenuItemTruncated';

export type LinkMenuItemProps = {
  url: string;
};

function LinkMenuItemUnmemoized({ url }: LinkMenuItemProps) {
  return (
    <MenuItemTruncated
      key="url-preview"
      component={Link}
      href={url}
      primary={url}
      sx={{
        textTransform: 'none',
        color: 'primary.light',
        '.MuiTypography-root': {
          fontStyle: 'italic',
        },
      }}
      target="_blank"
    />
  );
}

export const LinkMenuItem = memo(LinkMenuItemUnmemoized);
