import { Optional } from 'utility-types';
import { UnreadMessageCountStructure } from '../../contexts/UnreadMessageCountContext';
import { RequireOnlyOne } from '../../../functions/src/types/utility-types';
import {
  hasUnreadChannel,
  isSomeChannelUnseen,
  isSomeTournamentChannelUnseen,
  isChannelUnseen,
  isMatchChannelUnseen,
  isPresentMatchChannel,
} from './findChannelUnseen';
import { assertSafe } from 'functions/src/util/assertSafe';

export type HasUnreadMatchChannelProps = Optional<
  RequireOnlyOne<{
    groupId: string;
    tournamentId: string;
    channelId: string;
    matchId: string;
  }>
> & {
  unreadMessageCount: UnreadMessageCountStructure;
};

// eslint-disable-next-line @blumintinc/blumint/enforce-positive-naming
export const hasUnreadMatchChannel = (props: HasUnreadMatchChannelProps) => {
  const { tournamentId, groupId, channelId, matchId, unreadMessageCount } =
    props;

  const idGiven = !!tournamentId || !!matchId || !!channelId || !!groupId;

  // eslint-disable-next-line no-restricted-properties
  if (Object.keys(props).length > 1 && !idGiven) {
    return false;
  }

  const matchGroups = Object.fromEntries(
    // eslint-disable-next-line no-restricted-properties
    Object.entries(unreadMessageCount).filter(([_, group]) => {
      // eslint-disable-next-line no-restricted-properties
      return Object.values(group.channels).some((channel) => {
        return isPresentMatchChannel(channel);
      });
    }),
  );

  if (groupId) {
    const matchGroup = unreadMessageCount[assertSafe(groupId)];
    return (
      matchGroup &&
      // eslint-disable-next-line no-restricted-properties
      Object.values(matchGroup.channels).some((channel) => {
        return hasUnreadChannel(channel);
      })
    );
  }

  // eslint-disable-next-line no-restricted-properties
  return Object.values(matchGroups).some((group) => {
    return (
      (!idGiven && isSomeChannelUnseen(group)) ||
      (!!channelId && isChannelUnseen(group, channelId)) ||
      (!!tournamentId && isSomeTournamentChannelUnseen(group, tournamentId)) ||
      (!!matchId && isMatchChannelUnseen(group, matchId))
    );
  });
};
