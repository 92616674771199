import { Indexed } from '../types/Indexed';

/**
 * Type guard to check if an object implements the Indexed interface
 */
export function isIndexed(obj: unknown): obj is Indexed {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'index' in obj &&
    typeof obj.index === 'number'
  );
}
