import { GridColDef } from '@mui/x-data-grid';
import { PayoutSummary } from '../PayoutSummary';
import { ResultSummary } from '../../../../functions/src/types/firestore/Game/Tournament/Round/Match/ResultSummary';

export const HEADER_NAME_PRIZES = 'PRIZES' as const;

export const PAYOUT_COLUMN: GridColDef<ResultSummary> = {
  field: 'payout',
  headerName: HEADER_NAME_PRIZES,
  minWidth: 130,
  flex: 1,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  sortable: false,
  // eslint-disable-next-line @blumintinc/blumint/enforce-boolean-naming-prefixes
  editable: false,
  headerAlign: 'center',
  align: 'center',
  renderCell: ({ row: { payout } }) => {
    return <PayoutSummary payout={payout} />;
  },
};
