import Box from '@mui/material/Box';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ReactNode, useMemo } from 'react';
import { memo } from '../../util/memo';

export type ComingSoonTooltipProps = Omit<
  TooltipProps,
  'title' | 'children'
> & {
  title?: string;
  children: ReactNode;
};

const ComingSoonTooltipUnmemoized = ({
  title,
  children,
  sx,
  ...props
}: ComingSoonTooltipProps) => {
  const titleDisplay = useMemo(() => {
    // eslint-disable-next-line no-restricted-syntax
    return `${title ? `${title} ` : ''}coming soon!`;
  }, [title]);

  return (
    <Tooltip
      arrow
      sx={{ width: '100%', ...sx }}
      title={titleDisplay}
      {...props}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export const ComingSoonTooltip = memo(ComingSoonTooltipUnmemoized);
