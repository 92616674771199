import { Team } from '../../../types/firestore/Game/Tournament/Team';

export type IsMatchReadyProps<TTime> = {
  team1?: Team<TTime>;
  team2?: Team<TTime>;
};

// eslint-disable-next-line @blumintinc/blumint/no-unnecessary-verb-suffix
export const isMatchReady = <TTime>({
  team1,
  team2,
}: IsMatchReadyProps<TTime>) => {
  return !!team1 && !!team2;
};
